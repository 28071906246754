import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IAccommodationFormData, IAccommodationLineData} from "../../../../model/UAECFEvent.model";
import {ActivatedRoute, Router} from "@angular/router";
import {HostUAECFEventService} from "../../../../services/host-uaecf-event.service";
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.css']
})
export class AccommodationComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  accommodationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  roles = ['staff', 'rider'];
  genders = ['male', 'female'];
  private uaeEventId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: HostUAECFEventService
  ) {
    this.accommodationFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.accommodationForm.controls;
  }

// Getter method to access formcontrols
  get categoryId() {
    return this.accommodationForm.get('category');
  }

  get stamp() {
    return this.accommodationForm.get('stamp') as FormGroup;

  }

  get logo() {
    return this.accommodationForm.get('logo') as FormGroup;

  }

// Getter method to access formcontrols
  get raceTypeId() {
    return this.accommodationForm.get('raceType');
  }


  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.uaeEventId = +data.get('id');
    });
  }

  accommodationFormInitialization() {
    this.accommodationForm = this.fb.group({
      nfName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      teamName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      website: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      place: [''],
      lines: this.fb.array([]),
      stamp: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      logo: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      date: ['', [Validators.required]]
    });
    this.lines = this.accommodationForm.get('lines') as FormArray;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.accommodationForm.invalid) {
      return;
    }

    const formData = {
      event_name: this.uaeEventId,
      nf_name: this.accommodationForm.get('nfName').value,
      address: this.accommodationForm.get('address').value,
      team_name: this.accommodationForm.get('teamName').value,
      telephone: this.accommodationForm.get('phone').value,
      website: this.accommodationForm.get('website').value,
      email: this.accommodationForm.get('email').value,
      place: this.accommodationForm.get('place').value,
      accommodation_line_id: this.lines.controls.map(item => {
        return {
          role: item.get('role').value,
          gender: item.get('gender').value,
          name: item.get('name').value,
          check_in: item.get('checkIn').value,
          check_out: item.get('checkOut').value,
          single: item.get('single').value,
          twin: item.get('twin').value,
          passport_no: item.get('passportNo').value
        } as IAccommodationLineData;
      }),
      date: this.accommodationForm.get('date').value,
      signature: this.stamp.get('fileSource').value.toString(),
      logo: this.logo.get('fileSource').value.toString(),
    } as IAccommodationFormData;
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    //console.log('entry by name data are', this.accommodationForm.value);

    // last step
     this.api.addAccommodation(formData);
  }

  onReset(): void {
    this.submitted = false;
    this.accommodationForm.reset();
  }

  addLine(): void {
    // this.lines = this.entryByNameForm.get('lines') as FormArray;
    this.lines.push(this.createLine());
  }

  removeLine(i: number) {
    this.lines.removeAt(i);
  }

  createLine(): FormGroup {
    return this.fb.group({
      role: [''],
      gender: [''],
      name: [''],
      checkIn: [''],
      checkOut: [''],
      single: [''],
      twin: [''],
      passportNo: [''],
    });
  }

  // Choose category using select dropdown
  changeCategory(e) {
    this.categoryId.setValue(e.target.value, {
      onlySelf: true
    });
  }

  // Choose category using select dropdown
  changeRaceType(e) {
    this.raceTypeId.setValue(e.target.value, {
      onlySelf: true
    });
  }

  onFileChange(event, item: string) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      //console.log('file extensions', fileExtension);
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.imageSrc = reader.result as string;
        if (item == 'stamp') {
          this.stamp.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        if (item == 'logo') {
          this.logo.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
      };
    }
  }
}

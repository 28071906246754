<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "RiderTransfer.Header" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "CommonText.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "RiderTransfer.Header" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <p>{{ isSubmitSuccess ? "Success" : "" }}</p>
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="transferRiderRegistrationForm"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="form-group"
        *ngIf="clubTeamService.clubsData$ | async as clubs"
      >
        <label class="form-label"> Old Club </label>
        <select
          class="form-select"
          (change)="getClubDetails()"
          formControlName="oldClub"
        >
          <option value="" disabled>Choose Club</option>
          <option *ngFor="let club of clubs" [ngValue]="club.id">
            {{ club.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <mat-form-field appearance="standard">
          <mat-label>Date </mat-label>
          <input
            matInput
            [matDatepicker]="date"
            [formControl]="date"
            formControlName="date"
          />
          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-group">
        <label class="form-label">Federation ID </label>
        <select
          *ngIf="true"
          class="form-select"
          (change)="ChangeRiderLicense($event, i)"
          formControlName="federationId"
        >
          <option value="" disabled>Choose Club</option>
          <option
            *ngFor="let license of clubRidersLicenses"
            [ngValue]="license.id"
          >
            {{ license.federation_id }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label class="form-label">
          {{ "RiderTransfer.Header" | translate }}
        </label>
        <select
          *ngIf="true"
          class="form-select"
          (change)="ChangeRiderName($event, i)"
          formControlName="fullName"
        >
          <option value="" disabled>Choose Club</option>
          <option
            *ngFor="let name of clubRidersNames"
            [ngValue]="name.id ? name.id : ''"
          >
            {{ name.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <mat-form-field appearance="standard">
          <mat-label>Date Of Birth</mat-label>
          <input
            matInput
            [matDatepicker]="dateOfBirthPicker"
            formControlName="dateOfBirth"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfBirthPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthPicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div
        class="form-group"
        *ngIf="api.clubTeamCategories$ | async as clubTeamCategories"
      >
        <label class="form-label">Category</label>
        <select class="form-select" formControlName="category">
          <option value="" disabled>Choose your Category</option>
          <option
            *ngFor="let category of clubTeamCategories"
            [ngValue]="category.id"
          >
            {{ category.code }}
          </option>
        </select>
      </div>

      <div
        class="form-group"
        *ngIf="clubTeamService.clubsData$ | async as clubs"
      >
        <!--        <div class="row"> {{clubs | json}}</div>-->
        <label class="form-label">
          {{ "RiderTransfer.NewClub" | translate }}
        </label>
        <select class="form-select" formControlName="newClub">
          <option value="" disabled>
            {{ "RiderTransfer.ChooseClub" | translate }}
          </option>
          <option *ngFor="let club of clubs" [ngValue]="club.id">
            {{ club.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label class="form-label"
          >{{ "RiderTransfer.ValidUntil" | translate }}
        </label>

        <mat-form-field appearance="standard">
          <input
            matInput
            [matDatepicker]="validUntil"
            [formControl]="validUntil"
            formControlName="validUntil"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="validUntil"
          ></mat-datepicker-toggle>
          <mat-datepicker #validUntil></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-group">
        <div class="form-group">
          <label class="form-label">{{
            "RiderTransfer.EmirateIDNo" | translate
          }}</label>
          <input
            type="text"
            formControlName="emiratesId"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.emiratesId.errors }"
          />
          <div
            *ngIf="submitted && f.emiratesId.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.emiratesId.errors.required">
              {{ "RiderTransfer.EmiratesIDNoRequired" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label"
          >{{ "RiderTransfer.EmiratesExpiryDate" | translate }}
        </label>

        <mat-form-field appearance="standard">
          <input
            matInput
            [matDatepicker]="expiryDate"
            [formControl]="date"
            formControlName="expiryDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="expiryDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #expiryDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-group" formGroupName="NOC">
        <label class="form-label">{{
          "RiderTransfer.NOCLetter" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event)"
        />
      </div>

      <div class="form-group">
        <label class="form-label"
          >{{ "RiderTransfer.TeamAdmin" | translate }}
        </label>
        <input
          type="text"
          formControlName="admin"
          [value]="adminTeamName"
          readonly
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.admin.errors }"
        />
      </div>
      <div class="form-group">
        <p>
          <strong
            >{{ "RiderTransfer.AdminPhone" | translate }} :
            {{ adminTeamPhone }}</strong
          >
        </p>
        <p>
          <strong
            >{{ "RiderTransfer.AdminAddress" | translate }} :
            {{ adminTeamAddress }}</strong
          >
        </p>
      </div>

      <div class="form-group registration-form__submit-wrap">
        <button type="submit" class="button button_theme button_md">
          {{ "RiderTransfer.Submit" | translate }}
        </button>
      </div>
    </form>
  </section>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footerregistration',
  templateUrl: './footerregistration.component.html',
  styleUrls: ['./footerregistration.component.css']
})
export class FooterregistrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Lightbox} from "ng-gallery/lightbox";
import {Gallery, GalleryItem, ImageSize, ThumbnailsPosition} from "ng-gallery";
import {PhotosService} from "../../services/photos.service";

@Component({
  selector: 'app-stream-handling',
  templateUrl: './stream-handling.component.html',
  styleUrls: ['./stream-handling.component.css']
})
export class StreamHandlingComponent implements OnInit {
// package information used are : https://github.com/MurhafSousli/ngx-gallery/wiki/Gallery-API
  // sample app : https://stackblitz.com/edit/ngx-gallery-imageviewer?file=src%2Fapp%2Fhome%2Fhome.component.html
  data = [
    {
      srcUrl: "http://139.59.2.150/web/content/ir.attachment/790/datas/image.jpg",
      previewUrl: "http://139.59.2.150/web/content/ir.attachment/790/datas/image.jpg"
    },
    {
      srcUrl: "http://139.59.2.150/web/content/ir.attachment/794/datas/image.jpg",
      previewUrl: "http://139.59.2.150/web/content/ir.attachment/794/datas/image.jpg"
    },
    {
      srcUrl: "http://139.59.2.150/web/content/ir.attachment/793/datas/image.jpg",
      previewUrl: "http://139.59.2.150/web/content/ir.attachment/793/datas/image.jpg"
    },
    // {
    //   srcUrl: "https://preview.ibb.co/kZGsLm/img8.jpg",
    //   previewUrl: "https://preview.ibb.co/kZGsLm/img8.jpg"
    // }
  ];
  items: GalleryItem[];
  @ViewChild("itemTemplate", {static: true}) itemTemplate: TemplateRef<any>;
  imageData = this.data;
  eventId: number;
  private selectedYear: number;

  constructor(public gallery: Gallery, public lightbox: Lightbox, private api: PhotosService) {
  }


  ngOnInit(): void {
    // this.configuringStreamData();
    this.api.eventId$.subscribe(eventId => {
      this.eventId = eventId;
      this.configuringStreamData();
    });
    this.api.selectedYear$.subscribe(year => {
      //console.log('year is ', year);
      this.selectedYear = year;
      this.configuringStreamData();
    });
    // forkJoin(this.api.eventId$, this.api.selectedYear$).subscribe(values => {
    //   //console.log('values are ', values);
    // });
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //
  //   console.warn('change set are ', changes);
  //   this.eventId = +changes["eventId"].currentValue;
  //   this.configuringStreamData();
  // }

  configuringStreamData() {
    // todo:// enable it when deploying the back-end
    this.api.racePhotosData$.subscribe(data => {
      const intermediateData = data.photo.filter(m => m.event[0].id == this.eventId);
      intermediateData.forEach(item => {
        data.event.forEach(eventItem => {
          if (item.event[0].id == eventItem.id && eventItem.year == this.selectedYear) {
            return item;
          }
        });
      });
      //console.log('photos data are ', intermediateData);
      this.items = intermediateData.map(item => {
        return {
          type: "imageViewer",
          data: {
            src: item.img_url,
            thumb: item.img_url
          }
        };

      });

      /** Lightbox Example */

        // Get a lightbox gallery ref
      const lightboxRef = this.gallery.ref("lightbox");

      // Add custom gallery config to the lightbox (optional)
      lightboxRef.setConfig({
        imageSize: ImageSize.Cover,
        thumbPosition: ThumbnailsPosition.Top,
        itemTemplate: this.itemTemplate,
        gestures: false
      });

      // Load items into the lightbox gallery ref
      lightboxRef.load(this.items);
    });
    /** Basic Gallery Example */

    // Creat gallery items
    // this.items = this.imageData.map(item => {
    //   return {
    //     type: "imageViewer",
    //     data: {
    //       src: item.srcUrl,
    //       thumb: item.previewUrl
    //     }
    //   };
    // });
    //
    // /** Lightbox Example */
    //
    //   // Get a lightbox gallery ref
    // const lightboxRef = this.gallery.ref("lightbox");
    //
    // // Add custom gallery config to the lightbox (optional)
    // lightboxRef.setConfig({
    //   imageSize: ImageSize.Cover,
    //   thumbPosition: ThumbnailsPosition.Top,
    //   itemTemplate: this.itemTemplate,
    //   gestures: false
    // });
    //
    // // Load items into the lightbox gallery ref
    // lightboxRef.load(this.items);
  }

}

import { EventDetailsModel } from './../model/eventDetails.model';
import { User } from './../auth/UserModel';
import { AuthService } from './../auth/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventsService } from "../services/events.service";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eventdetails',
  templateUrl: './eventdetails.component.html',
  styleUrls: ['./eventdetails.component.css']
})
export class EventdetailsComponent implements OnInit, OnDestroy {
  routeDataSubscription: Subscription;
  showMoreInfo = false;
  environment = environment;
  user: User;
  eventDetailsData: EventDetailsModel;
  selectedCategory = 0;
  selectedStartingListCategory = 0;
  constructor(public api: EventsService,
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    this.user = await this.auth.currentUserValue;
    // console.log("this.user",this.user);

    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      const eventId = +data.get('id');

      this.api.loadEventDetailsData(eventId).subscribe(data => {
        this.api.eventDetails$.subscribe(data=>{
          this.eventDetailsData = data;
          // console.log("eventDetailsData",this.eventDetailsData);

        })

        // for (const result of this.eventDetailsData.results[0].lines) {
        //   console.log({result});
        // }

      });
    });

  }

  showMoreInfoState() {
    this.showMoreInfo = !this.showMoreInfo;
    // console.log(this.showMoreInfo);

  }

  ngOnDestroy(): void {
    this.routeDataSubscription.unsubscribe();
  }


}

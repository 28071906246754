import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {IPartnerModel} from "../model/about.model";

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  private partnersData = new BehaviorSubject<IPartnerModel[]>([]);

  constructor(private http: HttpClient) {
  }

  get partnersData$(): Observable<IPartnerModel[]> {
    return this.partnersData.asObservable();
  }

  loadPartnersData() {
    this.http.get(`${environment.API_URL}/partners`)
      .subscribe((response: any) => {
        const data = response.data as IPartnerModel[];
        //console.log("partners", data);

        this.partnersData.next(data);
      });
  }
}

import { environments, IEnvironment } from './environments';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// todo : angular deploy
// todo:// deploy :::  ng build --aot --prod --base-href=/ --output-hashing none

// fixme :  ng v12 deploy  : ng build --configuration production --optimization true --aot true --build-optimizer true

export const environment: IEnvironment = {
  name: environments.dev,
  version: '1.0.0',
  API_URL: 'https://web.uaecf.ae'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

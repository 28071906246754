import { ActivatedRoute } from '@angular/router';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  signInForm: FormGroup;
  submitted: boolean;
  returnUrl: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    if (this.authService.currentUserValue) {
      // window.location.reload();
    }
  }

  ngOnInit(): void {
    this.signInFormInitialization();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f(): { [key: string]: AbstractControl } {
    return this.signInForm.controls;
  }
  signInFormInitialization() {
    this.signInForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  formValidState(): boolean {
    return this.signInForm.invalid;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.signInForm.invalid) {
      return;
    }

    const formData = {
      username: this.signInForm.get('username').value,
      password: this.signInForm.get('password').value,
    };
    // console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    // console.log('entry by name data are', this.signInForm.value);

    // last step
    this.authService.login(formData.username, formData.password);
  }

  onReset(): void {
    this.submitted = false;
    this.signInForm.reset();
  }

}

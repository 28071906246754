import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-paysuccess',
  templateUrl: './paysuccess.component.html',
  styleUrls: ['./paysuccess.component.css']
})
export class PaysuccessComponent implements OnInit {

  constructor(public payment: PaymentService) {
    payment.checkTransaction().then((data) => {
      data.subscribe((res) => {
console.log({res});

      });

    });
   }

  ngOnInit(): void {
  }

}

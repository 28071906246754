import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {RankingsService} from "../../services/rankings.service";
import {HostUAECFEventService} from "../../services/host-uaecf-event.service";
import {IClubTeamCategory} from "../../model/UAECFEvent.model";
import {IRankingModel} from "../../model/rankings.model";

@Component({
  selector: 'app-race-type-rankings',
  templateUrl: './race-type-rankings.component.html',
  styleUrls: ['./race-type-rankings.component.css']
})
export class RaceTypeRankingsComponent implements OnInit, OnDestroy {
  public selectedCategory: number;
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  public selectedYear = new Date().getFullYear();
  public categories: IClubTeamCategory[] = [];
  public raceTypeId: number;
  public rankingData: IRankingModel;
  private routeDataSubscription: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public api: RankingsService,
              public categoryService: HostUAECFEventService
  ) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.raceTypeId = +data.get('id');
      this.loadingInitializationData();
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription.unsubscribe();
  }

  NavigateToRankingDetails(rankingType: number) {
    this.router.navigate(['/rankings-details'], {
      queryParams: {raceType: this.raceTypeId, year: this.selectedYear, category: this.selectedCategory, ranking: rankingType},
      queryParamsHandling: 'merge'
    });
  }

  UpdateData() {
    this.api.loadRankingForRaceTypeAndSpecifiedCategory(this.raceTypeId, this.selectedCategory, this.selectedYear);
    this.api.rankingsData$.subscribe(data => {
      if (data.individule_ranking.length > 0) {
        data.individule_ranking.forEach(item => {
          if (item.individule[0].year == this.selectedYear) {
            //console.log('here you are not');
            this.rankingData = data;
            return;
          } else {
            this.rankingData = {
              team_ranking: [],
              individule_ranking: [],
              general_ranking: []
            };
            //console.log('missed', this.selectedYear);
          }
        });
      }
    });
  }

  private loadingInitializationData() {
    this.categoryService.loadClubTeamCategory();
    this.categoryService.clubTeamCategories$.subscribe(data => {
      this.categories = data;
      if (data.length > 0) {
        this.selectedCategory = data[0].id;
        this.api.loadRankingForRaceTypeAndSpecifiedCategory(this.raceTypeId, data[0].id, this.selectedYear);
        this.api.rankingsData$.subscribe(response => {
          if (response.individule_ranking.length > 0) {
            response.individule_ranking.forEach(item => {
              if (item.individule[0].year == this.selectedYear) {
                this.rankingData = response;
                return;
              }
            });
          }
        });
      }
    });
  }
}

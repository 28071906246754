import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StreamHandlingComponent } from './stream-handling/stream-handling.component';
import { GalleryModule } from "ng-gallery";
import { LightboxModule } from "ng-gallery/lightbox";
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { NgImageSliderModule } from "ng-image-slider";

@NgModule({
  declarations: [
    StreamHandlingComponent,
  ],
  exports: [StreamHandlingComponent],
  imports: [
    CommonModule,
    GalleryModule,
    LightboxModule,
    ImageViewerModule
  ]
})
export class SharedModule {
}

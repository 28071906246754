import { ClubDetails } from './../../model/nationalTeam/club.detail.model';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {TeamsClubsService} from "../../services/teams-clubs.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import { IEventModel } from 'src/app/model/event.model';

@Component({
  selector: 'app-clubs-details',
  templateUrl: './clubs-details.component.html'
})
export class ClubsDetailsComponent implements OnInit, OnDestroy {
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  selectedYear = new Date().getFullYear();
  routeDataSubscription: Subscription;
  environment = environment;
  private id: number;
  clubDetailsData: ClubDetails;

  constructor(public api: TeamsClubsService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.id = +data.get('id');
      this.api.loadSpecificClub(this.id);
      this.api.clubDetailsData$.subscribe((data) => {
         this.clubDetailsData = data;

    //console.log("club deta", this.clubDetailsData);

      });
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription.unsubscribe();
  }

  loadEventDetails(id: number) {
    this.router.navigate([`/event/${id}`]);
  }

  LoadClubAdminDetails(id: number) {
    this.router.navigate([`/officials/club/${id}`]);
  }
  updatedNewsAppearance() {
    this.clubDetailsData.events.forEach(item => {
      // item.isHidden = item.year == this.selectedYear;
      return item;
    });

  }

  selectedYearChanged() {
    this.updatedNewsAppearance();
  }
}

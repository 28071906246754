import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {IRankingModel} from "../model/rankings.model";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RankingsService {

  private rankingsData = new BehaviorSubject<IRankingModel>({
    general_ranking: [],
    individule_ranking: [],
    team_ranking: []
  });

  constructor(private http: HttpClient) {
  }

  get rankingsData$(): Observable<IRankingModel> {
    return this.rankingsData.asObservable();
  }

  loadRankingForRaceTypeAndSpecifiedCategory(raceType: number, category: number, year: number) {

    this.http.get(`${environment.API_URL}/ranking/${year}/${raceType}/${category}`).subscribe(response => {

      const data = response as IRankingModel;
      //console.log({data});

      this.rankingsData.next(data);
    });
  }
}

<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "RegistrationComponent.EventRegistration" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "CommonText.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "RegistrationComponent.EventRegistration" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="requestToJoinEventForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-group">
        <label class="form-label">{{ "Administrative.FederationID" | translate }}</label>
        <div *ngIf="clubRidersLicenses">
          <mat-form-field
            style="width: -webkit-fill-available"
            appearance="standard"
          >
            <mat-select
              formControlName="federationId"
              (selectionChange)="ChangeRiderLicense($event, i)"
            >
              <ng-container *ngFor="let license of clubRidersLicenses">
                <mat-option *ngIf="license" [value]="license">{{
                  license
                }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          *ngIf="submitted && f.federationId.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.federationId.errors.required">
            {{ "Administrative.FederationIDRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "Administrative.FullName" | translate }}</label>
        <input
          type="text"
          formControlName="fullName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }"
        />
        <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
          <div *ngIf="f.fullName.errors.required">
            {{ "Administrative.FullNameRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Administrative.DOB" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="dateOfBirthPicker"
            formControlName="dateOfBirth"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfBirthPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthPicker></mat-datepicker>
        </mat-form-field>

        <div *ngIf="submitted && f.dateOfBirth.errors" class="invalid-feedback">
          <div *ngIf="f.dateOfBirth.errors.required">
            {{ "Administrative.DOBRequired" | translate }}
          </div>
        </div>
      </div>

      <div
        class="form-group"
        *ngIf="api.nationalities$ | async as nationalities"
      >
        <label class="form-label">{{ "Administrative.Nationality" | translate }}</label>
        <select class="form-select" formControlName="nationality">
          <option value="" disabled>
            {{ "Administrative.ChooseNationality" | translate }}
          </option>
          <option
            *ngFor="let nationality of nationalities"
            [ngValue]="nationality.id"
          >
            {{ nationality.name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.nationality.errors" class="invalid-feedback">
          <div *ngIf="f.nationality.errors.required">
            {{ "Administrative.NationalityRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "Administrative.Gender" | translate }} </label>

        <select class="form-select" formControlNae="gender">
          <option value="" disabled>
            {{ "Administrative.ChooseGender" | translate }}
          </option>
          <option *ngFor="let gender of genders" [ngValue]="gender">
            {{ "Gender." + gender | translate }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
          <div *ngIf="f.gender.errors.required">
            {{ "Administrative.GenderRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "Administrative.Phone" | translate }}</label>
        <input
          type="text"
          formControlName="phone"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
        />
        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
          <div *ngIf="f.phone.errors.required">
            {{ "Administrative.PhoneRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "Administrative.HomeAddress" | translate }}</label>
        <input
          type="text"
          formControlName="address"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
        />
        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
          <div *ngIf="f.address.errors.required">
            {{ "Administrative.HomeAddressRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "Administrative.UCIID" | translate }} </label>
        <input
          type="text"
          formControlName="uci"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.uci.errors }"
        />
        <div *ngIf="submitted && f.uci.errors" class="invalid-feedback">
          <div *ngIf="f.uci.errors.required">
            {{ "Administrative.UCIIDRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="registration-form__terms">
        <h5>{{ "Administrative.Notes" | translate }}:</h5>
        <div class="registration-form__terms-inner">
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.PaymentConfirmation'
                | translate
            "
          ></p>
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.RefundAndCancellationPolicy'
                | translate
            "
          ></p>
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.ServicePricing'
                | translate
            "
          >
            :<strong
              [innerHTML]="
                price + ' Footer.ActivityAndCalender.Complaints.Terms.Currency'
                  | translate
              "
            ></strong>
          </p>
        </div>
      </div>
      <mat-checkbox
        unchecked="agreedTerms"
        (click)="changeAgreedTerm()"
        class="form-group registration-form__agreement"
      >
      {{ "Administrative.IHaveReadAll" | translate }}
      <a (click)="openDialog(1)"
        >{{ "Administrative.TermsLable" | translate }} </a
      >,<a (click)="openDialog(2)">
        {{ "Administrative.PolicyLable" | translate }}</a
      >
      {{ "Administrative.AgreeLable" | translate }}
      </mat-checkbox>

      <div class="form-group registration-form__submit-wrap">
        <button
          type="submit"
          class="button button_theme button_md"
          [disabled]="!agreedTerms"
        >
          {{ "Administrative.SubmitButtonLable" | translate }}
        </button>
      </div>
    </form>
    <app-event-club-register
      *ngIf="registerType == 2"
    ></app-event-club-register>
    <app-event-club-register
      *ngIf="registerType == 3"
    ></app-event-club-register>
  </section>
</div>

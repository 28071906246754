<div class="main">
  <header class="header">
    <div class="header__top">
      <div class="container header__top-inner">
        <a routerLink="/" title="Home" class="">
          <img
            src="../assets/images/logo.jpg"
            alt="uaecf logo"
            class="header__logo"
          />
        </a>
        <nav class="header__top-right">
          <div class="header__top-contact-block">
            <a
              class="header__top-contact-block-link"
              routerLink="/footer/contactUs"
              title="Contact Us"
            >
              <img
                class="header__top-contact-block-icons"
                src="../assets/images/support.svg"
              />
              <span class="header__top-contact-block-text">{{
                "App.ContactUs" | translate
              }}</span>
            </a>
          </div>
          <div class="social-media-links social-media-links_header visible-mdl">
            <a
              class="social-media-links_header__a"
              target="_blank"
              href="https://www.facebook.com/mediacyclinguae"
            >
              <img
                class="social-media-links_header__icon"
                title="facebook"
                src="../assets/images/facebook.svg"
              />
            </a>
            <a
              class="social-media-links_header__a"
              target="_blank"
              href="https://www.instagram.com/uaecycf/"
            >
              <img
                class="social-media-links_header__icon"
                title="instagram"
                src="../assets/images/instagram.svg"
              />
            </a>
            <a
              class="social-media-links_header__a"
              target="_blank"
              href="https://www.twitter.com/mediacyclinguae"
            >
              <img
                class="social-media-links_header__icon"
                title="twitter"
                src="../assets/images/twitter.svg"
              />
            </a>
            <a
              class="social-media-links_header__a"
              target="_blank"
              href="https://www.youtube.com/channel/UC6otd4Ycloq-xUtANdZ7Heg/featured"
            >
              <img
                class="social-media-links_header__icon"
                title="youtube"
                src="../assets/images/youtube.svg"
              />
            </a>
          </div>

          <nav class="navbar header__language-select">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                  >{{ "App.Language" | translate }}</a
                >
                <ul class="dropdown-menu">
                  <li>
                    <a
                      (click)="changeLang('en-us')"
                      class="dropdown-item"
                      [class.active-lang]="language == 'en-us'"
                    >
                      <img src="../assets/images/uk-flag.svg" />
                      {{ "App.EN" | translate }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      (click)="changeLang('ar_001')"
                      [class.active-lang]="language == 'ar_001'"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                    >
                      <img src="../assets/images/uae-flag.svg" />
                      {{ "App.AR" | translate }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <a
            href="https://d11.ae/"
            target="_blank"
            class="official-partner__link"
          >
            <img
              src="../assets/logoD11.png"
              alt="D11 Technology"
              class="official-partner__image official-partner__image_header"
            />
          </a>
        </nav>
      </div>
    </div>
    <div class="header__bottom">
      <div class="container header__bottom-inner">
        <div class="header__bottom-menu hidden-lg">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon">
              <img src="../assets/images/menu.svg" />
            </span>
            Menu
          </button>
        </div>
        <div class="social-media-links social-media-links_header hidden-mdl">
          <a
            class="social-media-links_header__a"
            target="_blank"
            href="https://www.facebook.com/mediacyclinguae"
          >
            <img
              class="social-media-links_header__icon"
              title="facebook"
              src="../assets/images/facebook.svg"
            />
          </a>
          <a
            class="social-media-links_header__a"
            target="_blank"
            href="https://www.instagram.com/uaecycf/"
          >
            <img
              class="social-media-links_header__icon"
              title="instagram"
              src="../assets/images/instagram.svg"
            />
          </a>
          <a
            class="social-media-links_header__a"
            target="_blank"
            href="https://www.twitter.com/mediacyclinguae"
          >
            <img
              class="social-media-links_header__icon"
              title="twitter"
              src="../assets/images/twitter.svg"
            />
          </a>
          <a
            class="social-media-links_header__a"
            target="_blank"
            href="https://www.youtube.com/channel/UC6otd4Ycloq-xUtANdZ7Heg/featured"
          >
            <img
              class="social-media-links_header__icon"
              title="youtube"
              src="../assets/images/youtube.svg"
            />
          </a>
        </div>
        <nav
          class="navbar navbar-expand-lg horizontal-nav"
          id="navbarSupportedContent"
        >
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav navbar-nav_bottom">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                  >{{ "App.Home" | translate }}</a
                >
                <ul class="dropdown-menu">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/about"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.AboutUAECF" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/partners"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.OurPartners" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/board"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.ManagementBoard" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/constitution"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.Constitution" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/halloffame"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.HallOfFame" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/organizations"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.InternationalOrganizations" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/hostevent"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.HostUAECFEvent" | translate }}</a
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item" (mouseenter)="toggleMenu('calender')">
                <a
                  class="nav-link"
                  (click)="closeDrawer()"
                  routerLink="/calendar"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                >
                  {{ "App.Calendar" | translate }}
                </a>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                  >{{ "App.NationalTeams" | translate }}</a
                >
                <ul
                  class="dropdown-menu"
                  *ngIf="
                    nationalTeamService.nationTeamsData$
                      | async as nationalTeamData
                  "
                >
                  <li *ngFor="let nationalTeam of nationalTeamData">
                    <a
                      class="dropdown-item"
                      routerLink="/national-teams/{{ nationalTeam.id }}"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ nationalTeam.team_name }}</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                  >{{ "EventComponent.Categories" | translate }}</a
                >
                <ul class="dropdown-menu">
                  <li>
                    <a
                      routerLink="race-type/1"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.Road" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="race-type/2"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.Track" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="race-type/3"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.MTB" | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="race-type/4"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.Tour" | translate }}</a
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item" (mouseenter)="toggleMenu('clubTeams')">
                <a
                  class="nav-link"
                  routerLink="/clubs-teams"
                  data-sub-menu=""
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                >
                  {{ "App.ClubsTeams" | translate }}
                </a>
              </li>
              <li class="nav-item dropdown" *ngIf="user">
                <a
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                  >{{ "App.Registration" | translate }}</a
                >
                <ul class="dropdown-menu">
                  <li *ngIf="user && user.role == 'free_rider'">
                    <a
                      routerLink="/registration/registerRider"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.RidersRegistration" | translate }}</a
                    >
                  </li>
                  <li *ngIf="user && user.role != 'free_rider'">
                    <a
                      routerLink="{{ getNewRiderLink() }}"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                    >
                      {{ "App.NewRidersRegistration" | translate }}</a
                    >
                  </li>
                  <li *ngIf="user">
                    <a
                      routerLink="/registration/registerEvent"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.EventRegistrationRequest" | translate }}</a
                    >
                  </li>
                  <li *ngIf="user && user.role == 'club'">
                    <a
                      routerLink="registration/club-rider-registration"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.ClubRidersRegistration" | translate }}</a
                    >
                  </li>
                  <li *ngIf="user && user.role == 'team'">
                    <a
                      routerLink="registration/team-rider-registration"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.TeamRidersRegistration" | translate }}</a
                    >
                  </li>
                  <li *ngIf="user && user.role == 'club'">
                    <a
                      routerLink="ridertransfer"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.RiderTransfer" | translate }}</a
                    >
                  </li>
                  <li *ngIf="user && user.role != 'free_rider'">
                    <a
                      routerLink="registration/registerAdmin"
                      class="dropdown-item"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      >{{ "App.AdminRegister" | translate }}</a
                    >
                  </li>
                </ul>
              </li>
              <li *ngIf="user" class="nav-item nav-item_signin">
                <span class="nav__username">{{ user.name }}</span>
                <button
                  type="submit"
                  class="button button_danger"
                  (click)="auth.logout()"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                >
                  {{ "App.Logout" | translate }}
                  <i class="fa fa-sign-out"></i>
                </button>
              </li>
              <li *ngIf="!user" class="nav-item nav-item_signin">
                <a
                  class="button button_theme"
                  routerLink="/signin"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  >{{ "App.SignIn" | translate }}</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>

  <div id="mainContent">
    <router-outlet></router-outlet>
    <footer>
      <section class="official-partner">
        <div class="container">
          <div class="section-title-block section-title-block_center">
            <img
              class="section-title-block__image"
              src="../../assets/images/logo.svg"
            />
            <h2 class="section-title-block__title">
              {{ "App.InfoCom" | translate }}
            </h2>
          </div>
          <div class="official-partner__list">
            <a
              href="https://d11.ae/"
              target="_blank"
              class="official-partner__link"
            >
              <img
                class="sourceLogo"
                src="./assets/logoD11.png"
                alt="D11 Technology"
                class="official-partner__image"
              />
            </a>
            <!--<a
              href="http://www.zaabeel.com/"
              target="_blank"
              class="official-partner__link"
            >
              <img
                class="sourceLogo"
                src="./../assets/partner13.png"
                class="official-partner__image"
                alt="Alé"
              />
            </a>-->
          </div>
        </div>
      </section>
      <ul class="container container_section footer-links-block">
        <li class="footer-links-group">
          <h6 class="footer-links-title">
            {{ "App.CoursesAndSeminars" | translate }}
          </h6>
          <ul class="footer-links-list">
            <li class="footer-links-list__li">
              <a
                routerLink="/footer/calender"
                class="footer-links-list__link"
                >{{ "App.Calendar" | translate }}</a
              >
            </li>
          </ul>
        </li>
        <li class="footer-links-group">
          <h6 class="footer-links-title">
            {{ "App.Committeess" | translate }}
          </h6>
          <ul class="footer-links-list">
            <li class="footer-links-list__li">
              <a
                routerLink="/committees/{{ 'national-teams-committee' }}"
                class="footer-links-list__link"
                >{{ "App.NationalTeam" | translate }}</a
              >
            </li>
            <li class="footer-links-list__li">
              <a
                routerLink="/committees/referees-committee"
                class="footer-links-list__link"
                >{{ "App.CommissariesTeam" | translate }}</a
              >
            </li>
            <li class="footer-links-list__li">
              <a
                routerLink="/committees/amateur-teams-committee"
                class="footer-links-list__link"
                >{{ "App.AmateurRiderTeam" | translate }}</a
              >
            </li>
            <li class="footer-links-list__li">
              <a
                routerLink="/committees/marketing-committee"
                class="footer-links-list__link"
                >{{ "App.Marketing" | translate }}
              </a>
            </li>
            <li class="footer-links-list__li">
              <a routerLink="/committees/women" class="footer-links-list__link"
                >{{ "App.Women" | translate }}
              </a>
            </li>
            <li class="footer-links-list__li">
              <a
                routerLink="/committees/technical-committee"
                class="footer-links-list__link"
                >{{ "App.Technical" | translate }}</a
              >
            </li>
            <li class="footer-links-list__li">
              <a
                routerLink="/committees/medical-committee"
                class="footer-links-list__link"
                >{{ "App.MadiaCommittee" | translate }}</a
              >
            </li>
            <li class="footer-links-list__li">
              <a
                routerLink="/committees/strategy-planning-committee"
                class="footer-links-list__link"
                >{{ "App.StrategyAndPlanning" | translate }}</a
              >
            </li>
          </ul>
        </li>
        <li class="footer-links-group">
          <h6 class="footer-links-title">
            {{ "App.RegulationLaws" | translate }}
          </h6>
          <ul class="footer-links-list">
            <li class="footer-links-list__li">
              <button
                type="button"
                (click)="OpenTechnicalRegulation()"
                class="footer-links-list__link"
              >
                {{ "App.TechnicalRegulation" | translate }}
              </button>
            </li>
            <li class="footer-links-list__li">
              <button
                type="button"
                (click)="OpenPrimarilylaws()"
                class="footer-links-list__link"
              >
                {{ "App.PrimaryLaw" | translate }}
              </button>
            </li>
            <li class="footer-links-list__li">
              <button
                type="button"
                (click)="OpenRacingLicense()"
                class="footer-links-list__link"
              >
                {{ "App.RacingLicenseRegulation" | translate }}
              </button>
            </li>
          </ul>
        </li>
        <li class="footer-links-group">
          <ul>
            <li class="footer-links-list__li">
              <a
                routerLink="/footer/contactUs"
                class="footer-links-list__link"
                >{{ "App.ContactUs" | translate }}</a
              >
            </li>
            <li class="footer-links-list__li">
              <a routerLink="/about" class="footer-links-list__link">{{
                "App.AboutUs" | translate
              }}</a>
            </li>
            <li class="footer-links-list__li" *ngIf="user">
              <a
                routerLink="/footer/training"
                class="footer-links-list__link"
                >{{ "App.TrainingPermission" | translate }}</a
              >
            </li>
            <li class="footer-links-list__li" *ngIf="user">
              <a
                routerLink="footer/complaint"
                class="footer-links-list__link"
                >{{ "App.Complaints" | translate }}</a
              >
            </li>
          </ul>
        </li>

        <li class="footer-links-group">
          <h6 class="footer-links-title">
            {{ "App.FollowUs" | translate }}
          </h6>
          <div class="social-media-links">
            <a
              class="social-media-links__a"
              target="_blank"
              href="https://www.facebook.com/mediacyclinguae"
            >
              <img
                class="social-media-links__icon"
                title="facebook"
                src="../assets/images/facebook.svg"
              />
            </a>
            <a
              class="social-media-links__a"
              target="_blank"
              href="https://www.instagram.com/uaecycf/"
            >
              <img
                class="social-media-links__icon"
                title="instagram"
                src="../assets/images/instagram.svg"
              />
            </a>
            <a
              class="social-media-links__a"
              target="_blank"
              href="https://www.twitter.com/mediacyclinguae"
            >
              <img
                class="social-media-links__icon"
                title="twitter"
                src="../assets/images/twitter.svg"
              />
            </a>
            <a
              class="social-media-links__a"
              target="_blank"
              href="https://www.youtube.com/channel/UC6otd4Ycloq-xUtANdZ7Heg/featured"
            >
              <img
                class="social-media-links__icon"
                title="youtube"
                src="../assets/images/youtube.svg"
              />
            </a>
          </div>
        </li>
        <li class="footer-links-group">
          <h6 class="footer-links-title">
            {{ "Payment.Title" | translate }}
          </h6>
          <div class="footer__payments">
            <img
              class="sourceLogo"
              src="./../assets/mastercard.svg"
              alt="mastercard"
            />
            <img class="sourceLogo" src="./../assets/visa.svg" alt="visa" />
            <img
              class="sourceLogo"
              src="./../assets/images/unionpay.svg"
              alt="unionpay"
            />
          </div>
        </li>
      </ul>
      <section class="footer-bottom">
        <div class="container footer-bottom__inner">
          <p class="footer-bottom__text">{{ "App.Footer1" | translate }}</p>
          <p class="footer-bottom__text">{{ "App.Footer2" | translate }}</p>
        </div>
      </section>
    </footer>
    <div id="overlay"></div>

    <div id="modal" class="modal fade" role="dialog"></div>
    <div id="loader"></div>
  </div>
</div>

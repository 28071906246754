import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ThemePalette } from "@angular/material/core";
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from "moment";
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IStringifiesIdLookup } from "../../model/registration.model";
import { IRaceClassificationType } from "../../model/UAECFEvent.model";
import { HostUAECFEventService } from "../../services/host-uaecf-event.service";
import { RegistrationsService } from "../../services/registrations.service";

@Component({
  selector: 'app-event-registration',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsRegisterComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  eventRegistrationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  raceClassification: IRaceClassificationType[] = [];
  result = [];

  @ViewChild('imageFileInput') imageFileInput: ElementRef;
  @ViewChild('organizerFileInput') organizerFileInput: ElementRef;
  @ViewChild('tradeLicenseFileInput') tradeLicenseFileInput: ElementRef;
  @ViewChild('routePlanFileInput') routePlanFileInput: ElementRef;

  imageSrc: string;
  attachmentSrc: string;

  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  genders = ['male', 'female'];
  riderStatuses: IStringifiesIdLookup[] = [
    {
      id: 'citizen_rider',
      name: 'Local Emirati'
    },
    {
      id: 'children_citizen',
      name: 'Children citizen'
    },
    {
      id: 'gcc',
      name: 'GCC'
    },
    {
      id: 'born_uae',
      name: 'Born UAE'
    },
    {
      id: 'resident_rider',
      name: 'Resident rider'
    },
    {
      id: 'expat',
      name: 'Expat'
    },
    {
      id: 'amateur',
      name: 'Amateur'
    },

  ];
  private uaeEventId: number;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public api: HostUAECFEventService,
    private registrationApi: RegistrationsService
  ) {
    this.entryByNameFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.eventRegistrationForm.controls;
  }

  // get fa(): { [key: string]: AbstractControl } {
  //   return this.entryByNameForm['lines'].controls;
  // }

  // Getter method to access formcontrols
  get categoryId() {
    return this.eventRegistrationForm.get('freeCategory');
  }

  get routePlan() {
    return this.eventRegistrationForm.get('routePlan') as FormGroup;

  }

  get organizer() {
    return this.eventRegistrationForm.get('organizer') as FormGroup;

  }

  get tradeLicense() {
    return this.eventRegistrationForm.get('tradeLicense') as FormGroup;

  }

  get image() {
    return this.eventRegistrationForm.get('image') as FormGroup;
  }

  // Getter method to access formcontrols
  get raceTypeId() {
    return this.eventRegistrationForm.get('raceType');
  }

  ngOnInit(): void {
    this.loadingInitializationData();
  }

  entryByNameFormInitialization() {
    this.eventRegistrationForm = this.fb.group({
      eventName: ['', [Validators.required]],
      raceDate: ['', [Validators.required]],
      requiredDate: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      companyPhone: ['', [Validators.required]],
      companyAddress: ['', [Validators.required]],
      companyEmail: ['', [Validators.required]],
      companyWebsite: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      position: ['', [Validators.required]],
      nationality: ['', [Validators.required]],

      managerPhone: ['', [Validators.required]],
      managerEmail: ['', [Validators.required]],

      startingPoint: ['', [Validators.required]],
      finishingPoint: ['', [Validators.required]],
      totalDistance: ['', [Validators.required]],
      location: ['', [Validators.required]],
      category: ['', [Validators.required]],
      participantsFees: ['', [Validators.required]],
      NoOfParticipants: ['', [Validators.required]],
      startingTime: ['', [Validators.required]],
      finishingTime: ['', [Validators.required]],
      getPolicePermission: [''],
      getSportCouncilPermission: [''],
      getTourismPermission: [''],
      ambulanceProvided: [''],
      eventInsurance: [''],
      raceType: [''],
      registrationOpen: ['', [Validators.required]],
      registrationClose: ['', [Validators.required]],
      freeCategory: ['', [Validators.required]],
      introduction: ['', [Validators.required]],
      image: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      tradeLicense: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),

      routePlan: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      organizer: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      })
    });
  }

  getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).filter((item: string) => item !== "image").forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          this.result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    console.log('errors : ', this.result);
    // return result;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.result.length > 0) {
      this.getFormValidationErrors(this.eventRegistrationForm);
      console.log("invalid")
      return;
    }

    const formTextData = {
      event_name: this.eventRegistrationForm.get('eventName').value.toString(),
      race_date: this.formatDate(this.eventRegistrationForm.get('raceDate').value.toString()),
      required_date: this.formatDate(this.eventRegistrationForm.get('requiredDate').value.toString()),
      company_name: this.eventRegistrationForm.get('companyName').value.toString(),
      comp_tel_no: this.eventRegistrationForm.get('companyPhone').value.toString(),
      comp_address: this.eventRegistrationForm.get('companyAddress').value.toString(),
      comp_email: this.eventRegistrationForm.get('companyEmail').value.toString(),
      company_website: this.eventRegistrationForm.get('companyWebsite').value.toString(),
      full_name: this.eventRegistrationForm.get('fullName').value.toString(),
      position: this.eventRegistrationForm.get('position').value.toString(),
      nationality: this.eventRegistrationForm.get('nationality').value.toString(),
      tel_manager: this.eventRegistrationForm.get('managerPhone').value.toString(),
      email_manager: this.eventRegistrationForm.get('managerEmail').value.toString(),
      starting_point: this.eventRegistrationForm.get('startingPoint').value.toString(),
      finishing_point: this.eventRegistrationForm.get('finishingPoint').value.toString(),
      total_distance: this.eventRegistrationForm.get('totalDistance').value.toString(),
      location: this.eventRegistrationForm.get('location').value.toString(),
      categories: this.eventRegistrationForm.get('category').value.toString(),
      participants_fees: this.eventRegistrationForm.get('participantsFees').value,
      num_participants: this.eventRegistrationForm.get('NoOfParticipants').value,
      starting_time: this.formatTime(this.eventRegistrationForm.get('startingTime').value.toString()),
      finishing_time: this.formatTime(this.eventRegistrationForm.get('finishingTime').value.toString()),
      got_police_permission: this.eventRegistrationForm.get('getPolicePermission').value.toString(),
      gov_sport_council_permission: this.eventRegistrationForm.get('getSportCouncilPermission').value.toString(),
      got_tourism_Authority: this.eventRegistrationForm.get('getTourismPermission').value.toString(),
      ambulance_provided: this.eventRegistrationForm.get('ambulanceProvided').value.toString(),
      event_insurance: this.eventRegistrationForm.get('eventInsurance').value.toString(),
      race_type: this.eventRegistrationForm.get('raceType').value.toString(),
      race_distance: this.eventRegistrationForm.get('raceDistance').value.toString(),
      registration_open: this.formatDate(this.eventRegistrationForm.get('registrationOpen').value.toString()),
      registration_close: this.formatDate(this.eventRegistrationForm.get('registrationClose').value.toString()),
      introduction: this.eventRegistrationForm.get('introduction').value,
      organizers: '',
    } as any;

    // const formFileData = {
    //   image: this.image.get('fileSource').value ? this.formatFile(this.image.get('fileSource').value.toString()) : "",
    //   trade_licence_copy: this.tradeLicense.get('fileSource').value ? this.formatFile(this.tradeLicense.get('fileSource').value.toString()) : "",
    //   route_plan: this.routePlan.get('fileSource').value ? this.formatFile(this.routePlan.get('fileSource').value.toString()) : "",
    //   orgnizer_id: this.organizer.get('fileSource').value ? this.formatFile(this.organizer.get('fileSource').value.toString()) : "",
    // }

    if (this.image.get('fileSource').value) {
      var eventImage = {image: this.formatFile(this.image.get('fileSource').value.toString())}
    }

    if (this.tradeLicense.get('fileSource').value) {
      var tradeLicenseImage = {trade_licence_copy: this.formatFile(this.tradeLicense.get('fileSource').value.toString())}
    }

    if (this.routePlan.get('fileSource').value) {
      var routePlanImage = {route_plan: this.formatFile(this.routePlan.get('fileSource').value.toString())}
    }

    if (this.tradeLicense.get('fileSource').value) {
      var organizerId = {orgnizer_id: this.formatFile(this.organizer.get('fileSource').value.toString())}
    }

    const formData = {
      ...formTextData,
      ...eventImage,
      ...tradeLicenseImage,
      ...organizerId
    };

    // last step
    this.registrationApi.registerEvent(formData, this.onReset);
    this.onReset();

  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }
  formatTime(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  onReset(): void {
    this.submitted = false;
    this.eventRegistrationForm.reset();
    this.eventRegistrationForm.markAsPristine();
    this.imageSrc = "";
    this.imageFileInput.nativeElement.value = "";
    this.organizerFileInput.nativeElement.value = "";
    this.tradeLicenseFileInput.nativeElement.value = "";
    this.routePlanFileInput.nativeElement.value = "";
  }

  // Choose category using select dropdown
  changeCategory(e) {
    this.categoryId.setValue(e.target.value, {
      onlySelf: true
    });
  }

  // Choose category using select dropdown
  changeRaceType(e) {
  }


  onFileChange(event, fileType: string) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        switch (fileType) {

          case 'image': {
            this.imageSrc = reader.result as string;
            this.image.patchValue({
              file: "loaded",
              fileSource: reader.result,
              extension: fileExtension
            });
          }
            break;

          case 'tradeLicense': {
            this.imageSrc = reader.result as string;
            this.tradeLicense.patchValue({
              file: "loaded",
              fileSource: reader.result,
              extension: fileExtension
            });
          }
            break;
          case 'routePlan': {
            this.imageSrc = reader.result as string;
            this.routePlan.patchValue({
              file: "loaded",
              fileSource: reader.result,
              extension: fileExtension
            });
          }
            break;
          case 'organizer': {
            this.imageSrc = reader.result as string;
            this.organizer.patchValue({
              file: "loaded",
              fileSource: reader.result,
              extension: fileExtension
            });
          }
            break;
        }
      };

    }
  }

  formatFile(file: string): string {
    const formattedFile = file.split('base64,');
    return formattedFile[1];
  }
  private loadingInitializationData() {
    this.api.loadClubTeamCategory();
    this.api.loadNationalities();
    this.api.loadRaceType();
  }

}

import { IVideoEventModel, IVideoDataModel, IVideoEvents } from './../../model/photo.model';
import { Component, ElementRef, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PhotosService } from 'src/app/services/photos.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  allVideos: IVideoEvents[];
  raceVideoData: Subscription;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public api: PhotosService
  ) {
  }
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay = false;
  routeDataSubscription: Subscription;
  selectedRaceId: number;
  eventId: number;
  selectedRaceName = 'All';
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  races = [
    // { id: 0, name: "All" },
    { id: 1, name: "Road" },
    { id: 2, name: "Track" },
    { id: 3, name: "MTB" },
    { id: 4, name: "Tour" }
  ];
  selectedYear = new Date().getFullYear();
  videos: IVideoEvents[] = [];
  selectedVideoUrl: SafeResourceUrl;
  ngOnInit() {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      //console.log('routing data are ', data);
      this.selectedRaceId = +data.get('id');
      // this.selectedYear = Number(data.get('date').toString().substring(0, 4));
      //console.log("selected year", this.selectedYear);

      if (this.selectedRaceId) {

        switch (this.selectedRaceId) {
          case 1:
            //console.log('road');
            this.selectedRaceName = 'Road';
            break;
          case 2:
            //console.log('track');
            this.selectedRaceName = 'Track';
            break;
          case 3:
            this.selectedRaceName = 'MTB';
            break;
          case 4:
            this.selectedRaceName = 'Tour';
            break;
          default:
            this.selectedRaceName = 'ALL';
        }
      }
      this.updateEventsVideosViewData();

    });

  }

  photoURL(url) {
    return this.getEmbedVideoUrl(url);
  }
  videoChange(event) {
    this.selectedVideoUrl = this.videos[event].video_url;
  }
  getThumbnailPhotoFrom(youtubeUrl: string) {
    return 'https://i.ytimg.com/vi_webp/' + youtubeUrl.split("v=")[1] + '/hqdefault.webp';
  }
  getEmbedVideoUrl(youtubeUrl: string) {
    return 'https://www.youtube.com/embed/' + youtubeUrl.split("v=")[1] + '?autoplay=1';
  }
  updateEventsVideosViewData() {
    if (this.selectedRaceId) {
      this.api.loadRaceVideoData(this.selectedRaceId);
    } else {
      this.api.loadRaceVideoData();
    }
    this.raceVideoData = this.api.raceVideosData$.subscribe(async data => {

      this.allVideos = await data.videos.reduce((prev: any, curr: any) => {
        if (curr.video_url) {
          const tempVideo: IVideoEvents = {
            id: 0,
            video_url: '',
            category_id: 0,
            event_id: 0,
            category_name: '',
            event_name: ''
          };
          tempVideo.id = curr.id;
          tempVideo.category_id = curr.category[0].category[0];
          tempVideo.category_name = curr.category[0].category[1];
          tempVideo.event_id = curr.event[0].event[0];
          tempVideo.event_name = curr.event[0].event[1];
          tempVideo.year = '2023';
          tempVideo.image = this.getThumbnailPhotoFrom(curr.video_url);
          tempVideo.thumbImage = this.getThumbnailPhotoFrom(curr.video_url);
          tempVideo.video_url = this.getEmbedVideoUrl(curr.video_url);
          prev.push(tempVideo);
        }
        return prev;
      }, []);

      //console.log("this.vodeo", this.allVideos);
      if (Array.isArray(this.allVideos) && this.allVideos.length > 0) {
        this.filterVideos();
      }
    });
  }

  ngOnDestroy(): void {
    this.raceVideoData.unsubscribe();
  }

  filterVideos() {
    const videos = [];
    this.allVideos.forEach(item => {
      // //console.log(item.year,this.selectedYear,this.selectedRaceId,item.category_id, item);
      if (item.year == this.selectedYear.toString() && this.selectedRaceId == item.category_id && item.category_id) {
        videos.push(item);
      }
      if (this.selectedRaceId == 0) {
        videos.push(item);
      }
    });
    if (Array.isArray(this.allVideos) && this.allVideos.length > 0) {
      this.selectedVideoUrl = videos[0].video_url;
      this.videos = videos;
    }
  }

  ChangeEvent(id: number) {
    this.eventId = id;
    //console.log(this.videos.length);
    this.filterVideos();
    this.videos = this.videos.filter(item => item.event_id == id);
    //console.log(id, this.videos.length);
    this.videos ? this.selectedVideoUrl = this.videos[0].video_url : "";
  }
}
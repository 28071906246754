import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from '../../../../environments/environment';
import {IRegisterActivity} from "../../../model/footer.models";
import {FooterService} from "../../../services/footer.service";

@Component({
  selector: 'app-activity-registration',
  templateUrl: './activity-registration.component.html',
  styleUrls: ['./activity-registration.component.css']
})
export class ActivityRegistrationComponent implements OnInit {
  environment = environment;
  activityRegistrationForm: FormGroup;
  submitted: boolean;

  genders = ['male', 'female'];

  private evenId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: FooterService
  ) {
    this.activityRegistrationFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.activityRegistrationForm.controls;
  }

  ngOnInit(): void {
  }

  activityRegistrationFormInitialization() {
    this.activityRegistrationForm = this.fb.group({
      name: ['', [Validators.required]],
      emiratesId: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
  }

  getFormValidationErrors(form: FormGroup) {

    const result = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    // console.log('result is : ', result);
    // return result;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.activityRegistrationForm.invalid) {
      this.getFormValidationErrors(this.activityRegistrationForm);
      return;
    }

    const formData = {
      name: this.activityRegistrationForm.get('name').value,
      phone: this.activityRegistrationForm.get('phone').value,
      emirates_id: this.activityRegistrationForm.get('emiratesId').value,
      date_birth: this.activityRegistrationForm.get('dateOfBirth').value,
      email: this.activityRegistrationForm.get('email').value,
    } as IRegisterActivity;
    // console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    // console.log('entry by name data are', this.activityRegistrationForm.value);

    // last step
    // this.api.registerActivity(formData);
  }

  onReset(): void {
    this.submitted = false;
    this.activityRegistrationForm.reset();
  }


}

<div *ngIf="(api.teamDetailsData$ | async) as teamDetailsData">
    <section class="clubs-teams-section" *ngFor="let team of teamDetailsData.team">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="club-title">
                        <div class="club-text">
                            <div class="row">
                                <div class="col-md-3 col-12 ml-auto">
                                    <div class="details-club-icon">
                                        <img [src]="environment.API_URL + team.img_url" style="border-radius: 50%" class="img-fluid" alt="">
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 mr-auto" style=" font-weight: 400; font-size: 1.7rem">
                                    <h3 class="mb-5"> {{team.name}}</h3>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">
                                                    <p> {{'TeamClubDetails.Phone' | translate}}</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> :</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> {{team.phone}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-center">
                                                    <p> {{'TeamClubDetails.Fax' | translate}}</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> :</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> {{team.fax}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-center">
                                                    <p> {{'TeamClubDetails.Email' | translate}}</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> :</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> {{team.email}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-center">
                                                    <p> {{'TeamClubDetails.Address' | translate}}</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> :</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> {{team.address}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-center">
                                                    <p> {{'TeamClubDetails.Website' | translate}}</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> :</p>
                                                </td>
                                                <td class="text-center">
                                                    <p> {{team.website}}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="container">
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="{{'TeamClubDetails.Riders' | translate}}">
                <div class="container card-details-container">
                    <div class="row">
                        <div class="col-md-3 col-12" *ngFor="let rider of teamDetailsData.riders">
                            <div class="team-div">
                                <div class="team-details">
                                    <div class="team-icon">
                                        <a routerLink="/national-teams/riders/{{rider.id}}" class="link contentImage" style="width: 90%; height: 90%">
                                            <img [src]="environment.API_URL + rider.img_url" style="width: 90%; height: 90%" class="img-fluid" alt="{{rider.full_name}}">
                                        </a>

                                    </div>
                                    <h5>{{rider.full_name}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'TeamClubDetails.Events' | translate}}">

                <div class="ms-5 me-5 mt-5">
                    <table class="table table-hover " style="table-layout: auto">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <select class="form-select" [(ngModel)]="selectedYear" (change)="selectedYearChanged()">
                  <option *ngFor="let year of years">{{year}}</option>
                </select>
                                </th>
                                <th scope="col">{{'TeamClubDetails.Logo' | translate}}</th>
                                <th scope="col">{{'TeamClubDetails.RaceType' | translate}}</th>
                                <th scope="col">{{'TeamClubDetails.Date' | translate}}</th>
                                <th scope="col">{{'TeamClubDetails.EventName' | translate}}</th>
                                <!-- <th scope="col">Distance</th>
              <th scope="col">Organizers</th> -->
                                <th scope="col">{{'TeamClubDetails.State' | translate}}</th>
                                <th scope="col" width="15%">{{'TeamClubDetails.Categories' | translate}}</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let event of teamDetailsData.events" (click)="loadEventDetails(event.id)">
                                <ng-container *ngIf="event.date.substring(0,4) == this.selectedYear">

                                    <td></td>
                                    <td>
                                        <img class="avatar avatar-64 bg-light rounded-circle text-white p-2" [src]="environment.API_URL+event.img_url">
                                    </td>
                                    <td>{{event.race_type[0].race_type[1] |titlecase}}</td>
                                    <td>{{event.date}}</td>
                                    <td>{{event.event |titlecase}}</td>
                                    <!-- <td>{{event.race_distance}}</td>
              <td>{{event.organisers}}</td> -->
                                    <td>{{event.state |titlecase}}</td>

                                    <td>
                                        <mat-chip-list #chipList>
                                            <span class="category" *ngFor="let category of event.categories">{{category.code}}</span>
                                        </mat-chip-list>
                                    </td>
                                    <!-- <td style="width: auto !important;">

                <mat-chip-list #chipList class="mat-chip-list-stacked">
                  <mat-chip  style="text-align: justify" *ngFor="let category of event.categories">
                    {{category.code}}
                  </mat-chip>
                </mat-chip-list>
              </td> -->
                                </ng-container>

                            </tr>


                        </tbody>
                    </table>

                </div>

            </mat-tab>
            <mat-tab label="Team Admins">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">{{'TeamClubDetails.Name' | translate}}</th>
                            <th scope="col" class="text-center">{{'TeamClubDetails.SpecificationType' | translate}}</th>
                            <th scope="col" class="text-center">{{'TeamClubDetails.WorkPhone' | translate}}</th>
                            <th scope="col" class="text-center">{{'TeamClubDetails.Email' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let superVisor of teamDetailsData['team supervisors']">
                            <td class="text-center">{{superVisor.name}}</td>
                            <td class="text-center">{{superVisor.specification}}</td>
                            <td class="text-center">{{superVisor.work_phone}}</td>
                            <td class="text-center">{{superVisor.email}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>
            <mat-tab label="Standing ranking">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">{{'TeamClubDetails.Category' | translate}}</th>
                            <th scope="col" class="text-center">{{'TeamClubDetails.TotalPoints' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ranking of teamDetailsData.standing_ranking">
                            <td class="text-center">{{ranking.category[0].code}}</td>
                            <td class="text-center">{{ranking.total_points}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

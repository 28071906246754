<mat-toolbar>
    <span>{{"RegistrationComponentComponent.RiderRegistration"}}</span>
</mat-toolbar>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <mat-card class="my-card">
        <mat-card-content>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.UCI' | translate}}</mat-label>
                <input matInput placeholder="UCI" formControlName="uci_id" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.FirstName' | translate}}</mat-label>
                <input matInput placeholder="First Name" formControlName="first_name" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.LastName' | translate}}</mat-label>
                <input matInput placeholder="Last Name" formControlName="last_name" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.Phone' | translate}}</mat-label>
                <input matInput placeholder="Phone" formControlName="phone" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.Email' | translate}}</mat-label>
                <input matInput placeholder="Email" formControlName="rider_email" />
            </mat-form-field>
            <mat-form-field class="full-width mb15">
                <mat-label>{{'RegistrationComponentComponent.DateOfBirth' | translate}}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="date_birth" placeholder="Choose a date" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-radio-group class="full-width" formControlName="gender">
                <mat-radio-button class="example-margin" value="male">{{'RegistrationComponentComponent.Male' | translate}}</mat-radio-button>
                <mat-radio-button class="example-margin" value="female">{{'RegistrationComponentComponent.Female' | translate}}</mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="full-width mt15">
                <mat-label>{{'RegistrationComponentComponent.PlaceOfBirth' | translate}}</mat-label>
                <input matInput placeholder="Place Of Birth" formControlName="place_birth" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.HomeAddress' | translate}}</mat-label>
                <input matInput placeholder="Home Address" formControlName="home_address" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.WorkAddress' | translate}}</mat-label>
                <input matInput placeholder="Work Address" formControlName="work_address" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.RiderType' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedValue" formControlName="register_type">
                    <mat-option *ngFor="let type of types" [value]="type.value">
                        {{ type.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.EmiratesID' | translate}}</mat-label>
                <input matInput placeholder="Emirates ID" formControlName="emirates_id" required />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'RegistrationComponentComponent.EmiratesIDExpiry' | translate}}</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="emirates_id_expiry" placeholder="Choose a date" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button type="submit" color="primary">{{'RegistrationComponentComponent.Register' | translate}}</button>
        </mat-card-actions>
    </mat-card>
</form>

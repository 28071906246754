<div class="container pt-5 pb-5">
  <form class="row g-3 activity-registration" [formGroup]="activityRegistrationForm" (ngSubmit)="onSubmit()">
    <h2 class="h1 text-danger fw-bold">{{ "Footer.ActivityAndCalender.ActivityRegistration.Header" | translate}}</h2>
    <div class="row mt-4 mb-4">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{'Footer.ActivityAndCalender.ActivityRegistration.FullNameLable' | translate}}</label>
          <input type="text" formControlName="name" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div
              *ngIf="f.name.errors.required">{{'Footer.ActivityAndCalender.ActivityRegistration.FullNameLable.Errors.FullNameRequired' | translate}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row  mt-4 mb-4">

      <div class="col-md-4">
        <div class="form-group">
          <label>{{'Footer.ActivityAndCalender.ActivityRegistration.EmiratesIdLable' | translate}}</label>
          <input type="text" formControlName="emiratesId" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.emiratesId.errors }"/>
          <div *ngIf="submitted && f.emiratesId.errors" class="invalid-feedback">
            <div
              *ngIf="f.emiratesId.errors.required">{{'Footer.ActivityAndCalender.ActivityRegistration.Errors.EmiratesIdRequired' | translate}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row  mt-4 mb-4">
      <div class="col-md-4">
        <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label>{{'Footer.ActivityAndCalender.ActivityRegistration.DateOfBirthLable' | translate}}</mat-label>
            <input matInput [matDatepicker]="activityDateOfBirthPicker" formControlName="dateOfBirth">
            <mat-datepicker-toggle matSuffix [for]="activityDateOfBirthPicker"></mat-datepicker-toggle>
            <mat-datepicker #activityDateOfBirthPicker></mat-datepicker>
          </mat-form-field>

          <div *ngIf="submitted && f.dateOfBirth.errors" class="invalid-feedback">
            <div
              *ngIf="f.dateOfBirth.errors.required">{{'Footer.ActivityAndCalender.ActivityRegistration.Erros.DateOfBirthRequired' | translate}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-4">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{'Footer.ActivityAndCalender.ActivityRegistration.PhoneLable' | translate}}</label>
          <input type="text" formControlName="phone" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"/>
          <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
            <div
              *ngIf="f.phone.errors.required">{{'Footer.ActivityAndCalender.ActivityRegistration.Errors.PhoneRequired' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{'Footer.ActivityAndCalender.ActivityRegistration.EmailLable' | translate}}</label>
          <input type="text" formControlName="email" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div
              *ngIf="f.email.errors.required">{{'Footer.ActivityAndCalender.ActivityRegistration.Erros.EmailRequired' | translate}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button type="submit"
              class="btn btn-lg smbt-btn">{{'Footer.ActivityAndCalender.ActivityRegistration.SubmitButtonLable' | translate}}</button>
    </div>
  </form>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PhotosService } from "../../services/photos.service";
import { IPhotoEventModel } from "../../model/photo.model";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit, OnDestroy {
  routeDataSubscription: Subscription;
  raceId: number;
  eventId: number;
  raceName = 'All';
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  selectedYear = new Date().getFullYear();
  // data = year event - eventType
  photosEvents: IPhotoEventModel[] = [];
  racePhotosImages: any = [];
  private racePhotoData: Subscription;
  firstCheck = false;
  isPhotoAvailable: boolean;
  env = environment;
  photoClicked: boolean;
  hightlightStatus: Array<boolean> = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    public api: PhotosService
  ) {
  }

  ngOnInit() {
    this.updateEventsPhotosViewData();
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      //console.log('routing data are ', data);
      this.raceId = +data.get('id');
      if (data.get('date')) {
        this.selectedYear = Number(data.get('date').toString().substring(0, 4));
      } else {
        this.selectedYear = 2023;
      }
      //console.log("selected year", this.selectedYear);

      if (this.raceId) {
        switch (this.raceId) {
          case 1:
            //console.log('road');
            this.raceName = 'Road';
            break;
          case 2:
            //console.log('track');
            this.raceName = 'Track';
            break;
          case 3:
            this.raceName = 'MTB';
            break;
          case 4:
            this.raceName = 'Tour';
            break;
          default:
            this.raceName = 'ALL';
        }
      }
      this.updateEventsPhotosViewData();

    });
  }

  updateEventsPhotosViewData() {
    if (this.raceId) {
      this.api.loadRaceImageData(this.raceId);
    } else {
      this.api.loadRaceImageData();
    }
    this.racePhotoData = this.api.racePhotosData$.subscribe(data => {
      this.photosEvents = data.event.filter((m) => m.year == this.selectedYear);
      this.racePhotosImages = data.photo.filter((m:any) => m.year == this.selectedYear);
      this.raceId= this.raceId;
      //console.log("test from init", this.photosEvents);
      if (this.photosEvents.length > 0) {
        this.firstCheck = true;
        this.ChangeEvent(this.photosEvents[0].event[0].id);
        this.isPhotoAvailable = true;
      } else {
        this.isPhotoAvailable = false;
      }
    });
  }

  ngOnDestroy(): void {
    // //console.log('unsubscribing');
    // this.api.updateEventIdLocally(0);
    // this.api.racePhotosData.unsubscribe();
    // this.routeDataSubscription.unsubscribe();
  }

  selectedYearChanged() {
    // this.api.resetPhotosData();
    this.api.updateYearLocally(this.selectedYear);
    this.updateEventsPhotosViewData();
  }

  ChangeEvent(id: number) {
    this.eventId = id;
    this.api.updateEventIdLocally(this.eventId);
    // this.updateEventsPhotosViewData();
  }

  showImage(photo) {
    //console.log("photos clicked", photo.img_url)
    this.photoClicked= true;
    return photo.img_url
  }
}

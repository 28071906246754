import { AuthService } from './../../auth/auth.service';
import { Nationality } from './../../model/rankings.model';
import { INationality } from './../../model/UAECFEvent.model';
import { TermsPolicyComponent } from '../../components/terms-policy/terms-policy.component';
import { EntryLine, EventClubRegistration, IEventCLubRiderRegistration, IFullName, ILicenseNo } from './../../model/registration.model';
import { EventsService } from './../../services/events.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClubAdmin, FullName } from 'src/app/model/nationalTeam/club.detail.model';
import { ReregistrationID, ICLubRiderRegistration } from 'src/app/model/registration.model';
import { HostUAECFEventService } from 'src/app/services/host-uaecf-event.service';
import { RegistrationsService } from 'src/app/services/registrations.service';
import { TeamsClubsService } from 'src/app/services/teams-clubs.service';
import { environment } from 'src/environments/environment';
import { ClubTeamRiderRegistrationComponent } from '../club-team-rider-registration/club-team-rider-registration.component';
import { LicenseNo } from 'src/app/model/eventDetails.model';
import * as localforage from 'localforage';

@Component({
  selector: 'app-event-club-register',
  templateUrl: './event-club-register.component.html',
  styleUrls: ['./event-club-register.component.css']
})
export class EventClubRegisterComponent implements OnInit {

  routeDataSubscription: Subscription;
  environment = environment;
  eventClubRiderRegistrationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  clubRidersLicenses: ILicenseNo[] = [];
  clubRidersNames: IFullName[] = [];
  teamRidersLicenses: ILicenseNo[] = [];
  nationality: Nationality;
  isClubRegistration = false;
  isTeamRegistration = false;
  private uaeEventId: number;
  public clubName: string;
  teamName: string;
  registerType: number;
  eventDate;
  eventId;
  agreedTerms = false;
  price: number;
  event_fess: number;
  teamCLubValue: string;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public api: HostUAECFEventService,
    public eventService: EventsService,
    public clubTeamService: TeamsClubsService,
    public auth: AuthService,
    private registrationService: RegistrationsService,
    public dialog: MatDialog
  ) {
    this.RiderClubRegistrationFormInitilization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.eventClubRiderRegistrationForm.controls;
  }

  ngOnInit(): void {

    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.eventId = +data.get('id');
      this.eventDate = data.get('date');
      this.price = Number(+data.get('price'));
      this.registerType = +data.get('registerType');
      this.teamCLubValue = String(+data.get('ctId'));
      //console.log("this.eventDate", this.eventDate, data);

      if (this.registerType === 2) {
        this.isClubRegistration = true;
        this.getClubDetails();
         //this.teamCLubValue = this.auth.currentUser.clubId;
      }
      if (this.registerType === 3) {
        this.isTeamRegistration = true;
        this.getTeamDetails();
       //  this.teamCLubValue = this.auth.currentUser.teamId;

      }
      this.loadingInitializationData();
      this.eventService.loadEventDetailsData(this.eventId);

    });


  }

  RiderClubRegistrationFormInitilization() {
    this.eventClubRiderRegistrationForm = this.fb.group({
      club: [Number(this.teamCLubValue)],
      team: [Number(this.teamCLubValue)],
      date: [''],
      lines: this.fb.array([])
    });
    this.lines = this.eventClubRiderRegistrationForm.get('lines') as FormArray;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.eventClubRiderRegistrationForm.invalid) {
      return;
    }

    const formData = {
      // tslint:disable-next-line: max-line-length
      club_name: this.isClubRegistration ? this.eventClubRiderRegistrationForm.get('club').value : false,
      team_name: this.isClubRegistration ? false : this.eventClubRiderRegistrationForm.get('team').value,
      event_name: this.eventId,
      date: this.eventDate.toString(),
      entry_line: this.lines.controls.map(item => {
        return {
          category: item.get('category').value.toString(),
          license_no: item.get('federation_id').value.toString(),
          full_name: item.get('fullName').value.toString(),
          uci_id: item.get('UCI').value,
          nationality: item.get('nationality').value.toString(),
          bib_no: item.get('bib_no').value.toString()
        } as EntryLine;
      })
    } as IEventCLubRiderRegistration;
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    //console.log('submit success entry by name data are', this.eventClubRiderRegistrationForm.value);

    // last step
    this.registrationService.registerBulkRidersForEvent(formData).subscribe(async (data) => {
      //console.log({ data });
        if (data.result.success && this.isTeamRegistration && this.price > 0) {
          const reference = data.result.payment_resopnse.order.ref;
          const paymentUrl = data.result.payment_resopnse.order.url;

          await localforage.setItem('reference', reference, function (err) {
            // if err is non-null, we got an error
            // localforage.getItem('key', function (err, value) {
            //   // if err is non-null, we got an error. otherwise, value is the value
            // });
          });
           window.location.href = paymentUrl;
        } else if (data.result.success ) {
          //console.log("ssuccess toaster");
          window.location.href = "/";
        }

    });
  }


  onReset(): void {
    this.submitted = false;
    this.eventClubRiderRegistrationForm.reset();
  }

  addLine(): void {
    // this.lines = this.entryByNameForm.get('lines') as FormArray;
    this.lines.push(this.createLine());
  }

  removeLine(i: number) {
    this.lines.removeAt(i);
  }

  createLine(): FormGroup {
    return this.fb.group({
      federation_id: [''],
      fullName: [''],
      UCI: [''],
      bib_no: [''],
      nationality: [''],
      category: [''],
    });
  }

  calculatePrice() {
    let emptyLines = 0;
    for (const line of this.lines.controls) {
      if (line.get('federation_id').value.length <= 0) {
        emptyLines++;
      }
    }
     return (this.lines.length - emptyLines) * this.price;
  }
  getClubDetails() {
    // this.onReset();
    const club = this.eventClubRiderRegistrationForm.get('club').value;
    this.eventClubRiderRegistrationForm.get('club').setValue(Number(this.teamCLubValue));
    this.clubTeamService.loadSpecificClub(Number(this.teamCLubValue));
    this.clubTeamService.clubDetailsData$.subscribe(data => {
      //console.log("ddddddddddddaraaaaaaaa",data);

      if (data.club.length > 0) {
        this.clubName = data.club[0].name;

      }
    });
    this.clubTeamService.loadRidersForSpecificClub(Number(this.teamCLubValue));
    this.clubTeamService.clubRiders$.subscribe(data => {
      this.clubRidersLicenses = data.map(item => item.license_no[0]);
      this.clubRidersNames = data.map(item => item.full_name[0]);
      //console.log("from club", this.teamRidersLicenses, this.clubRidersNames);
      //console.log("from club data", this.clubName,this.teamCLubValue);

    });
  }

  getTeamDetails() {
    //  this.onReset();
    const team = this.eventClubRiderRegistrationForm.get('team').value;
    this.eventClubRiderRegistrationForm.get('team').setValue(Number(this.teamCLubValue));
     this.clubTeamService.loadSpecificTeam(Number(this.teamCLubValue));
    this.clubTeamService.teamDetailsData$.subscribe(data => {
      if (data.team.length > 0) {
        //console.log("team daaaaaaaaaaaaaaataaAAAA",data.team[0])

        this.teamName = data.team[0].name;
    }});
    this.clubTeamService.loadRidersForSpecificTeam(Number(this.teamCLubValue));
    this.clubTeamService.teamRiders$.subscribe(data => {
      this.clubRidersLicenses = data.map(item => item.license_no[0]);
      this.clubRidersNames = data.map(item => item.full_name[0]);
      // //console.log("from team", this.clubRidersLicenses);

    });
  }

  ChangeRiderLicense(e, i: number) {
    const riderLicenseNumber = this.lines.controls[i].get('federation_id').value;
    let ridersData;
    if (this.isClubRegistration) {
      ridersData = this.clubTeamService.clubRiders$;
    }
    if (this.isTeamRegistration) {
      ridersData = this.clubTeamService.teamRiders$;
    }
    ridersData.subscribe(data => {
      //console.log({ riderLicenseNumber }, data);

      const rider = data.find(item => item.license_no[0].id == riderLicenseNumber);
      if (rider) {
        this.lines.controls[i].patchValue({
          fullName: rider.full_name[0].id,
          UCI: rider.uci_id,
          bib_no: rider.bib_no,
          nationality: rider.nationality[0].nationality[0],
          category: this.isClubRegistration ? rider.club_category[0].club_category[0] : rider.team_category[0].team_category[0],
          // healthFitness: null
        });
      }
    });
    //console.log('entry by ChangeRiderLicense are', this.eventClubRiderRegistrationForm.value);

  }


  ChangeRiderName(e, i: number) {
    const riderLicenseName = this.lines.controls[i].get('fullName').value;
    let ridersData;
    if (this.isClubRegistration) {
      ridersData = this.clubTeamService.clubRiders$;
    }
    if (this.isTeamRegistration) {
      ridersData = this.clubTeamService.teamRiders$;
    }
    ridersData.subscribe(data => {


      const rider = data.find(item => item.full_name[0].id == riderLicenseName);
      //console.log({ riderLicenseName }, rider.nationality[0].nationality);

      if (rider) {
        this.lines.controls[i].patchValue({
          federation_id: rider.license_no[0].id,
          fullName: rider.full_name[0].id,
          UCI: rider.uci_id,
          bib_no: rider.bib_no,
          nationality: rider.nationality[0].nationality[0],
          category: this.isClubRegistration ? rider.club_category[0].club_category[0] : rider.team_category[0].team_category[0]
          // healthFitness: null
        });
      }
    });
    //console.log('entry by riderLicenseName are', this.eventClubRiderRegistrationForm.value);

  }

  private async loadingInitializationData() {
    await this.clubTeamService.loadClubs();
    await this.api.loadClubTeamCategory();
   await this.api.loadNationalities();
    this.isClubRegistration ? this.getClubDetails() : this.getTeamDetails();

  }

  changeAgreedTerm() {
    this.agreedTerms = !this.agreedTerms;
    //console.log(this.agreedTerms);

  }
  openDialog(type) {
    //console.log('fromdialog');
    const dialogRef = this.dialog.open(TermsPolicyComponent, {
      width: '750px',
      height: '550px',
      data: { type: type }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);

    });
  }
}

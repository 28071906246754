<div class="container mt-5 mb-5" *ngIf="(api.nationTeamsData$ | async) as nationalTeamDetails">
  <div class="row row-cols-1 row-cols-md-2 g-4 justify-content-center">
    <div class="col-3 " *ngFor="let nationalTeam of nationalTeamDetails">
      <div class="card" style="border: none">
        <div class="national_team_img_container">

          <a routerLink="/national-teams/{{ nationalTeam.id}}" class="link contentImage">
            <!--<img class="card-img-top " [src]="environment.API_URL+nationalTeam.img_url" alt="{{nationalTeam.team_name}}">-->
            <img class="card-img-top " src="/assets/sample.jpg" alt="{{nationalTeam.team_name}}">
          </a>
        </div>
        <div class="card-body text_align_center">
          <h2 class="card-title" style="font-size: 1.7rem;">{{nationalTeam.team_name}}</h2>
          <p class="card-text teamDescription">{{nationalTeam.category[0].category[1]}}</p>
          <p class="card-text"><small class="text-muted">{{nationalTeam.description}}</small></p>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {BackendApiService} from './../../backend-api.service';
import {Router} from '@angular/router';
import {environment} from './../../../environments/environment';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';


@Component({
  selector: 'app-hall-of-fame',
  templateUrl: './hall-of-fame.component.html',
  styleUrls: ['./hall-of-fame.component.css']
})
export class HallOfFameComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  List: any;

  environment = environment;

  constructor(private _snackBar: MatSnackBar, private router: Router, private backendService: BackendApiService) {
  }

  ngOnInit() {

    // Get Hall Of Fame
    this.backendService.halloffame().subscribe(
      (response: HttpResponse<any>) => {
        this.List = response['data'];
        //console.log('hall of fame are ' , this.List);
      },
      (error) => {
        this._snackBar.open('Fecth  Hall Of Fame API', 'Error', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );


  }

}

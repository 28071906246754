<div class="card-list_ranking" *ngIf="pastResults && pastResults.length > 0">
  <a
    [href]="env.API_URL + result.result_pdf"
    target="_blank"
    *ngFor="let result of pastResults"
    class="card card-ranking"
  >
    <div class="card-ranking__body">
      <p>
        {{"Result.Year" | translate}}
        <br />
        <b>{{ result.year }}</b>
      </p>
      <p>
        {{"Result.Event" | translate}}
        <br />
        <b>{{ result.event }}</b>
      </p>
    </div>
    <div class="card-ranking__footer card-ranking__footer_left">
      <img src="../../../assets/images/surface1.svg" />
      {{"Result.DownloadPDF" | translate}}
    </div>
  </a>
</div>

<div class="widthConteneur marginAuto clearfix">
    <div class="contentBreadcrumb clearfix">
        <ul class="breadcrumb">
            <li><a href="#" class="link">{{'UAECF.UAECF.Home' | translate}}</a></li>
            <li><span class="link">{{'App.UAECF' | translate}}</span></li>
        </ul>
    </div>

    <div class="clearfix">
        <ul class="menuBlock fcListElement colonne3" style="position: relative; height: 1030px">
            <li class="block" style="position: absolute; top: 0px; left: 0px">
                <a routerLink="/about" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'App.AboutUAECF' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 0px; left: 415px">
                <a routerLink="/federations" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'UAECF.UAECF.NF' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 0px; left: 830px">
                <a routerLink="/partners" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'App.OurPartners' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 265px; left: 0px">
                <a routerLink="/board" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'App.ManagementBoard' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 265px; left: 415px">
                <a routerLink="/commissions" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'UAECF.UAECF.Commissions' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 265px; left: 830px">
                <a routerLink="/constitution" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'App.Constitution' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 530px; left: 0px">
                <a routerLink="/uci" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'UAECF.UAECF.UCI' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 530px; left: 415px">
                <a routerLink="/halloffame" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'App.HallOfFame' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 530px; left: 830px">
                <a routerLink="/organizations" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'App.InternationalOrganizations' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 795px; left: 0px">
                <a routerLink="/hostevent" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'App.HostUAECFEvent' | translate}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>

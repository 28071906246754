import {Component, OnInit} from '@angular/core';
import { environment } from '../../../../environments/environment';

import {HostUAECFEventService} from "../../../services/host-uaecf-event.service";

@Component({
  selector: 'app-host-uaecf-event',
  templateUrl: './host-uaecf-event.component.html',
  styleUrls: ['./host-uaecf-event.component.css']
})
export class HostUaecfEventComponent implements OnInit {
  environment = environment;

  constructor(public api: HostUAECFEventService) {
  }

  ngOnInit() {
    this.api.loadUAECF_Events();
  }

}

import { IStringifiesIdLookup } from './../model/registration.model';
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {INationalTeam} from "../model/nationalTeam/nationalTeam.model";
import {BehaviorSubject, Observable} from "rxjs";
import {INationalTeamDetails} from "../model/nationalTeam/nationalTeam.Detail.model";
import {IRiderDetails} from "../model/nationalTeam/rider.model";

@Injectable({
  providedIn: 'root'
})
export class NationalTeamService {

  riderStatuses: IStringifiesIdLookup [] = [
    {
      id: 'citizen_rider',
      name: 'Local Emirati'
    },
    {
      id: 'children_citizens',
      name: 'Children of citizens'
    },
    {
      id: 'gcc',
      name: 'GCC'
    },
    {
      id: 'born_uae',
      name: 'Born in UAE'
    },
    {
      id: 'resident_rider',
      name: 'Resident Rider'
    },
    {
      id: 'uae_passport_holder',
      name: 'UAE Passport Holders'
    },
    {
      id: 'amateur',
      name: 'foreign resident'
    },

  ];
  Functions: IStringifiesIdLookup [] = [
    {
      id: 't_manager',
      name: 'Team Manager'
    },
    {
      id: 'team_admin',
      name: 'Team Admin'
    },
    {
      id: 't_supervisor',
      name: 'Team Supervisor'
    },
    {
      id: 'administrative_assistant',
      name: 'Administrative Assistant'
    },
    {
      id: 'coach',
      name: 'Coach'
    },
    {
      id: 'coach_assistant',
      name: 'Coach Assistant'
    },
    {
      id: 'doctor',
      name: 'Doctor'
    },
    {
      id: 'masseur',
      name: 'Masseur'
    },
    {
      id: 'mechanic',
      name: 'Mechanic'
    },
    {
      id: 'technical_manager',
      name: 'Technical Manager'
    },

  ];

  nationalTeamsData = new BehaviorSubject<INationalTeam[]>([]);
  nationalTeamDetail = new BehaviorSubject<INationalTeamDetails>({
    team: [],
    achivements: [],
    officials: [],
    riders: [],
    technicals: []
  });

  riderDetails = new BehaviorSubject<IRiderDetails>({} as IRiderDetails);

  constructor(private http: HttpClient) {
  }

  get nationTeamsData$(): Observable<INationalTeam[]> {
    return this.nationalTeamsData.asObservable();
  }

  get riderDetails$(): Observable<IRiderDetails> {
    return this.riderDetails.asObservable();
  }


  get nationalTeamDetails$(): Observable<INationalTeamDetails> {
    return this.nationalTeamDetail.asObservable();
  }

  loadNationalTeam() {
    this.http.get(`${environment.API_URL}/national-team`)
      .subscribe(((response: any) => {
        const dataModel = response.data as INationalTeam[];
        this.nationalTeamsData.next(dataModel);
      }));
  }

  LoadSpecificNationalTeamInformation(id: number) {
    this.http.get<INationalTeamDetails>(`${environment.API_URL}/profile-national/${id}`)
      .subscribe(((response) => {
        console.warn('nation team details are  ', response);
        this.nationalTeamDetail.next(response);
      }));
  }

  loadRiderProfile(id: number) {
    this.http.get(`${environment.API_URL}/profile_rider/${id}`)
      .subscribe(((response: any) => {
        console.warn('rider profile is  ', response);
        const result = response.data.map(item => {
          const team = item.team[0];
          const club = item.club[0];
          const nationality = item.nationality[0];
          const teamCategory = item.team_category[0].team_category[1];
          const clubCategory = item.club_category[0].club_category[1];

          const finalResult = item;
          finalResult.team = team;
          finalResult.club = club;
          finalResult.nationality = nationality;
          finalResult.team_category = teamCategory;
          finalResult.club_category = clubCategory;
          if (teamCategory) {
            finalResult.category = teamCategory;
          } else {
            finalResult.category = clubCategory;
          }
          console.log('categoty are ');
          return finalResult;
        });
        this.riderDetails.next(result[0] as IRiderDetails);
      }));
  }
   loadNewRiderProfile(id: number): Observable<any>{
     return this.http.get(`${environment.API_URL}/profile_rider_nonapproved/${id}`);

  }
  loadTeamAdminProfile(id: number) {
    return this.http.get(`${environment.API_URL}/profile_team_admin/${id}`);
  }

  loadClubAdminProfile(id: number): Observable<any> {
    return this.http.get(`${environment.API_URL}/profile_club_admin/${id}`);
  }
  getRiderStatus(key): string {
    // console.log({key}, this.riderStatuses.find(riderStatus => riderStatus.id === key).name);
    return this.riderStatuses.find(riderStatus => riderStatus.id === key).id;
  }
  getFunction(key): string {
    // console.log({key}, this.Functions.find(func => func.id === key).name);

    return this.Functions.find(func => func.id === key).id;
  }
}

<div class="card" style="width: 18rem;">

  <div class="row">
    <div class="offset-md-1 col-md-6">

      <h3 style="border-bottom: 3px red">References </h3>
      <br>
      <p>some data</p>
    </div>
    <div class="col-md-4">
      <img src="..." class="card-img-top" alt="...">
    </div>
  </div>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">An item</li>
    <li class="list-group-item">A second item</li>
    <li class="list-group-item">A third item</li>
  </ul>
  <div class="card-body">
    <a href="#" class="card-link">Card link</a>
    <a href="#" class="card-link">Another link</a>
  </div>
</div>

<div
  class="container container__page"
  *ngIf="aboutService.partnersData$ | async as partners"
>
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "UAECF.OurPartners.Partners" | translate }}
    </h1>
    <ul class="breadcrumb">
        <li><a routerLink="/" class="link">Home >&nbsp;</a></li>
        <li>{{'UAECF.OurPartners.Partners' | translate }}</li>
    </ul>
  </div>
  <div class="page-body">
    <ul class="icon-card-list">
      <li *ngFor="let partner of partners"  class="icon-card-list__li">
        <a
          [href]="partner.website"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            [src]="env.API_URL + partner.logo_url"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <!-- <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/1.png"
            alt="Bin Shafar Holding"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/2.png"
            alt="Al Sayyah Group"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/3.png"
            alt="D11 Technology"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/4.png"
            alt="Genetic Group"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/5.png"
            alt="Al Shafar Investment"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/6.png"
            alt="Cycle Culture"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/7.png"
            alt="Al Shafar Jumeirah Cycling Team"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/8.png"
            alt="Masafi"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/9.png"
            alt="Natural Way"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="#"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/sponsors/10.png"
            alt="Zabeel Ladies Club"
            class="icon-card-list__icon"
          />
        </a>
      </li> -->
    </ul>
  </div>
</div>

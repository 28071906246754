<ul class="category-list">
  <div>
    <ng-container *ngFor="let eventData of eventsData?.slice().reverse()">
      <li class="category-list__li" *ngIf="eventData.race_type[0].race_type[1] == 'Road'">
        <a
          class="category-list__a"
          routerLink="/race-type/1"
        >
          <div class="category-list__info-block">
            <h3 class="h5 category-list__title">
              {{ "App.Road" | translate }}
            </h3>
            <p class="category-list__text">
              {{ eventData.event | titlecase }}
            </p>
            <p class="category-list__date grey-text">
              {{ eventData.date | date: "mediumDate" }}
            </p>
          </div>
          <img
            class="category-list__image"
            [src]="env.API_URL + eventData.img_url"
          />
        </a>
      </li>
    </ng-container>
  </div>
  <div>
    <ng-container *ngFor="let eventData of eventsData?.slice().reverse()">
      <li class="category-list__li" *ngIf="eventData.race_type[0].race_type[1] == 'Track'">
        <a
          class="category-list__a"
          routerLink="/race-type/2"
        >
          <div class="category-list__info-block">
            <h3 class="h5 category-list__title">
              {{ "App.Track" | translate }}
            </h3>
            <p class="category-list__text">
              {{ eventData.event | titlecase }}
            </p>
            <p class="category-list__date grey-text">
              {{ eventData.date | date: "mediumDate" }}
            </p>
          </div>
          <img
            class="category-list__image"
            [src]="env.API_URL + eventData.img_url"
          />
        </a>
      </li>
    </ng-container>
  </div>
  <div>
    <ng-container *ngFor="let eventData of eventsData?.slice().reverse()">
      <li class="category-list__li" *ngIf="eventData.race_type[0].race_type[1] == 'MTB'">
        <a
          class="category-list__a"
          routerLink="/race-type/3"
        >
          <div class="category-list__info-block">
            <h3 class="h5 category-list__title">{{ "App.MTB" | translate }}</h3>
            <p class="category-list__text">
              {{ eventData.event | titlecase }}
            </p>
            <p class="category-list__date grey-text">
              {{ eventData.date | date: "mediumDate" }}
            </p>
          </div>
          <img
            class="category-list__image"
            [src]="env.API_URL + eventData.img_url"
          />
        </a>
      </li>
    </ng-container>
  </div>
  <div>
    <ng-container *ngFor="let eventData of eventsData?.slice().reverse()">
      <li class="category-list__li" *ngIf="eventData.race_type[0].race_type[1] == 'Tour'">
        <a
          class="category-list__a"
          routerLink="/race-type/4" 
        >
          <div class="category-list__info-block">
            <h3 class="h5 category-list__title">
              {{ "App.Tour" | translate }}
            </h3>
            <p class="category-list__text">
              {{ eventData.event | titlecase }}
            </p>
            <p class="category-list__date grey-text">
              {{ eventData.date | date: "mediumDate" }}
            </p>
          </div>
          <img
            class="category-list__image"
            [src]="env.API_URL + eventData.img_url"
          />
        </a>
      </li>
    </ng-container>
  </div>
</ul>

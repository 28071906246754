import { environment } from './../../../environments/environment';
import { IGeneralNewsModel } from './../../model/home.model';
import { HomeService } from './../../services/home.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-morenews',
  templateUrl: './morenews.component.html',
  styleUrls: ['./morenews.component.css']
})
export class MorenewsComponent implements OnInit {
  generalNews: IGeneralNewsModel[];

  constructor(public api: HomeService) { }

  loadHomePageData() {
    this.api.loadHomePageData();
    this.api.homePageData$.subscribe((data) => {
      this.generalNews = data.general_news;
      this.generalNews.map(item => { item.img_url = environment.API_URL + item.img_url })
    })
  }

  ngOnInit() {
    this.loadHomePageData();

  }

}

<div class="container container__page">
  <img class="logo-outer" src="../../../assets/images/logo_ver2.svg" />

  <section class="login-block">
    <div class="login-block__header">
      <h1 class="h3">{{ "Auth.LoginTitle" | translate }}</h1>
    </div>
    <form class="form" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input
          autocomplete="on"
          class="un form-control"
          type="email"
          align="center"
          formControlName="username"
          placeholder="Username"
          [ngClass]="
            submitted && f.username.errors
              ? 'is-invalid form-control'
              : ' form-control'
          "
        />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">
            {{ "Auth.Login.UserName.Errors.UserNameRequired" | translate }}
          </div>
          <div *ngIf="f.username.errors.email">
            {{ "Auth.Login.UserName.Errors.UserNameInvalid" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <input
          autocomplete="on"
          class="pass"
          type="password"
          formControlName="password"
          align="center"
          placeholder="Password"
          [ngClass]="
            submitted && f.password.errors
              ? 'is-invalid form-control'
              : ' form-control'
          "
        />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">
            {{ "Auth.Login.Password.Errors.PasswordRequired" | translate }}
          </div>
        </div>
      </div>
      <a
        class="button button_theme button_md"
        align="center"
        (click)="onSubmit()"
        disabled="formValidState()"
        >{{ "Auth.Login.SignInButton.SignInButtonLable" | translate }}</a
      >
      <div class="button-wrap">
        <a class="button button_md" href="https://web.uaecf.ae/web/signup">{{
          "Auth.Login.SignUpButton.SignUpButtonLable" | translate
        }}</a>
        <a href="https://web.uaecf.ae/web/reset_password">{{
          "Auth.Login.ForgetPasswordButton.ForgetPasswordButtonLable"
            | translate
        }}</a>
      </div>
    </form>
  </section>
</div>

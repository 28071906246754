import { AuthService } from './../../auth/auth.service';
import { NationalTeamService } from './../../services/national-team.service';
import { IFullName, ITransferRiderModel } from './../../model/registration.model';
import { TeamsClubsService } from 'src/app/services/teams-clubs.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { IStringifiesIdLookup, IRiderRegistrationModel, ILicenseNo } from 'src/app/model/registration.model';
import { IRaceClassificationType } from 'src/app/model/UAECFEvent.model';
import { HostUAECFEventService } from 'src/app/services/host-uaecf-event.service';
import { RegistrationsService } from 'src/app/services/registrations.service';
import { environment } from 'src/environments/environment';
import { ClubTeamRiderRegistrationComponent } from '../club-team-rider-registration/club-team-rider-registration.component';
import { FullName } from 'src/app/model/nationalTeam/club.detail.model';

@Component({
  selector: 'app-rider-transfer',
  templateUrl: './rider-transfer.component.html',
  styleUrls: ['./rider-transfer.component.css']
})
export class RiderTransferComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  transferRiderRegistrationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  raceClassification: IRaceClassificationType[] = [];
  date = new FormControl(new Date());
  isSubmitSuccess: boolean;

  imageSrc: string;
  attachmentSrc: string;

  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  genders = ['male', 'female'];
  riderStatuses: IStringifiesIdLookup[] = [
    {
      id: 'citizen_rider',
      name: 'Local Emirati'
    },
    {
      id: 'children_citizen',
      name: 'Children citizen'
    },
    {
      id: 'gcc',
      name: 'GCC'
    },
    {
      id: 'born_uae',
      name: 'Born UAE'
    },
    {
      id: 'resident_rider',
      name: 'Resident rider'
    },
    {
      id: 'expat',
      name: 'Expat'
    },
    {
      id: 'amateur',
      name: 'Amateur'
    },

  ];
  public clubOrTeam: string;
  private uaeEventId: number;
  clubName: string;
  clubRidersLicenses: ILicenseNo[];
  clubRidersNames: IFullName[];
  adminTeamName: any;
  adminTeamId: any;
  adminTeamPhone: any;
  adminTeamAddress: any;
  uci_id: any;
  nationality: any;
  formFile: File;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public api: HostUAECFEventService,
    public auth: AuthService,
    public clubTeamService: TeamsClubsService,
    public nationalTeamService: NationalTeamService,
    private registrationApi: RegistrationsService
  ) {
    this.entryByNameFormInitialization();
    this.isSubmitSuccess = false;
  }


  get f(): { [key: string]: AbstractControl } {
    return this.transferRiderRegistrationForm.controls;
  }

  // get fa(): { [key: string]: AbstractControl } {
  //   return this.entryByNameForm['lines'].controls;
  // }

  // Getter method to access formcontrols
  get categoryId() {
    return this.transferRiderRegistrationForm.get('category');
  }

  formattedToday(): string {
    const nowDate = new Date();
    const date = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate();
    //console.log({ date });

    return date.toString();
  }
  get attachment() {
    return this.transferRiderRegistrationForm.get('attachment') as FormGroup;

  }

  get NOC() {
    return this.transferRiderRegistrationForm.get('NOC') as FormGroup;
  }


  ngOnInit(): void {
    this.loadingInitializationData();
  }

  entryByNameFormInitialization() {
    this.transferRiderRegistrationForm = this.fb.group({
      date: [this.formattedToday(), [Validators.required]],
      oldClub: [this.auth.currentUserValue.clubId, [Validators.required]],
      federationId: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      category: ['', [Validators.required]],
      newClub: ['', [Validators.required]],
      validUntil: ['', [Validators.required]],
      emiratesId: ['', [Validators.required]],
      expiryDate: ['', [Validators.required]],
      admin: ['', [Validators.required]],
      NOC: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      })
    });
  }

  getFormValidationErrors(form: FormGroup) {

    const result = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    //console.log('result is : ', result);
    // return result;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.transferRiderRegistrationForm.invalid) {
      this.getFormValidationErrors(this.transferRiderRegistrationForm);
      // validation message need to be shown.
      return;
    }
    //console.log('transferRiderRegistrationForm', this.transferRiderRegistrationForm.value);



    const formData = {
      federation_id: this.transferRiderRegistrationForm.get('federationId').value.toString(),
      full_name: this.transferRiderRegistrationForm.get('fullName').value.toString(),
      uci_id: this.uci_id,
      date_birth: this.transferRiderRegistrationForm.get('dateOfBirth').value,
      category: this.transferRiderRegistrationForm.get('category').value.toString(),
      old_club_name: this.transferRiderRegistrationForm.get('oldClub').value.toString(),
      new_club_name: this.transferRiderRegistrationForm.get('newClub').value.toString(),
      nationality: this.nationality.toString(),
      emirates_id: this.transferRiderRegistrationForm.get('emiratesId').value,
      emirates_id_expiry: this.transferRiderRegistrationForm.get('expiryDate').value,
      valid_until: this.transferRiderRegistrationForm.get('validUntil').value,
      team_admin: this.adminTeamId.toString(),
      team_address: this.adminTeamAddress,
      team_admin_number: this.adminTeamPhone,
      NOC: this.formFile,
    } as ITransferRiderModel;

    this.registrationApi.transferRider(formData).subscribe((reponse) => {
      //console.log('--submit response', reponse);
      this.isSubmitSuccess = true;
      this.onReset()
    });
    // //console.log('api submitted Status:', response);
  }

  onReset(): void {
    this.submitted = false;
    this.transferRiderRegistrationForm.reset();
  }

  // Choose category using select dropdown
  changeCategory(e) {
    // this.categoryId.setValue(e.target.value, {
    //   onlySelf: true
    // });
  }

  onFileChange(event) {
    const reader = new FileReader();
    const file = event.target.files[0];
    // let formData = new FormData();
    // this.formFile.append("NOC", file);

    //console.log(event);
    const files: FileList = event.target.files;
    this.formFile = files[0];
    // if (event.target.files && event.target.files.length) {
    //   const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
    //   //console.log('file extensions', fileExtension);
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     // this.imageSrc = reader.result as string;
    //     this.attachment.patchValue({
    //       fileSource: reader.result,
    //       extension: fileExtension
    //     });

    //   };

    // }
  }
  getClubDetails() {
    // this.onReset();
    const club = this.transferRiderRegistrationForm.get('oldClub').value;
    this.clubTeamService.loadSpecificClub(club);
    this.clubTeamService.clubDetailsData$.subscribe(data => {
      if (data.club.length > 0) {
        this.clubName = data.club[0].name;
      }
    });
    this.nationalTeamService.loadClubAdminProfile(club).subscribe((admin) => {
      //console.log('loadClubAdminProfile', admin);
      if (admin?.data && admin.data.length > 0) {
        console
        this.transferRiderRegistrationForm.patchValue({
          admin: admin.data[0].full_name
        });
        this.adminTeamId = admin.data[0].id;
        this.adminTeamAddress = admin.data[0].address;
        this.adminTeamPhone = admin.data[0].phone;
        this.adminTeamName = admin.data[0].full_name;
      } else {
        // handle case when data is empty
        this.adminTeamName=''
      }
    });
    this.clubTeamService.loadRidersForSpecificClub(club);
    this.clubTeamService.clubRiders$.subscribe(data => {
      //console.log('clubRiders:', data);
      if (Array.isArray(data) && data.length > 0) {
        this.clubRidersLicenses = data.map(item => item.license_no[0]);
        this.clubRidersNames = data.reduce((prev: any, curr: any) => { 
          if (curr.full_name[0]) 
            prev.push(curr.full_name[0]); 
          return prev; 
        }, []);
      }
      //console.log("from club", this.clubRidersNames);
      //console.log("from club data", data);

    });
  }
  ChangeRiderLicense(e, i: number) {
    const riderLicenseNumber = this.transferRiderRegistrationForm.get('federationId').value;
    let ridersData;
    ridersData = this.clubTeamService.clubRiders$;


    ridersData.subscribe(data => {
      //console.log({ riderLicenseNumber }, data);

      const rider = data.find(item => item.license_no[0].id == riderLicenseNumber);
      if (rider) {
        this.uci_id = rider.uci_id;
        this.nationality = rider.nationality[0].nationality[0];
        this.transferRiderRegistrationForm.patchValue({
          fullName: rider.full_name[0].id,
          UCI: rider.uci_id,
          bib_no: rider.bib_no,
          dateOfBirth: rider.date_birth,
          nationality: rider.nationality[0].nationality[0],
          validUntil: rider.vaild_until,
          emiratesId: rider.emirates_id,
          expiryDate: rider.emirates_id_expiry,
          category: rider.club_category[0].club_category[0],
          // healthFitness: null
        });
      }
    });
    //console.log('entry by ChangeRiderLicense are', this.transferRiderRegistrationForm.value);

  }


  ChangeRiderName(e, i: number) {
    const riderLicenseName = this.transferRiderRegistrationForm.get('fullName').value;
    let ridersData;
    ridersData = this.clubTeamService.clubRiders$;


    ridersData.subscribe(data => {


      const rider = data.find(item => item.full_name[0].id == riderLicenseName);
      //console.log({ riderLicenseName }, rider.nationality[0].nationality);

      if (rider) {
        this.uci_id = rider.uci_id;
        this.nationality = rider.nationality[0].nationality[0];
        this.transferRiderRegistrationForm.patchValue({
          federationId: rider.license_no[0].id,
          fullName: rider.full_name[0].id,
          dateOfBirth: rider.date_birth,
          bib_no: rider.bib_no,
          nationality: rider.nationality[0].nationality[0],
          validUntil: rider.vaild_until,
          emiratesId: rider.emirates_id,
          expiryDate: rider.emirates_id_expiry,
          category: rider.club_category[0].club_category[0]
          // healthFitness: null
        });
      }
    });
    //console.log('entry by riderLicenseName are', this.transferRiderRegistrationForm.value);

  }
  private loadingInitializationData() {
    this.api.loadClubTeamCategory();
    this.api.loadNationalities();
    this.clubTeamService.loadClubs();
    this.getClubDetails();
  }

}

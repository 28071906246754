import { Observable } from "rxjs";
import { INewsModel } from "./model/news.model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "./../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class BackendApiService {

  constructor(private http: HttpClient) {}
  environment = environment;

  // API URLS
  cycleListUrl = environment.API_URL + "/boarders";
  registerURL = environment.API_URL + "/submitted/request";
  registerEventURL = environment.API_URL + "/registration/event";
  registerAdminTeamURL = environment.API_URL + "/administrative/team";
  registerAdminClubURL = environment.API_URL + "/administrative/club";
  nationalityURL = environment.API_URL + "/nationality";
  teamsURL = environment.API_URL + "/uaecf-teams";
  clubsURL = environment.API_URL + "/uaecf-clubs";
  homeNewsURL = environment.API_URL + "/home";
  calendarURL = environment.API_URL + "/calendar";
  halloffameURL = environment.API_URL + "/hall-of-fame";
  constituationURL = environment.API_URL + "/constitution";

  regulationURL = environment.API_URL + "/technical-regulatoin";
  primaryLawURL = environment.API_URL + "/primary-law";
  racingLicenseURL = environment.API_URL + "/racing-license";
  loadEventDetailsData(eventId: any) {
    throw new Error('Method not implemented.');
  }

  // Uses http.get() to load data from a single API endpoint
  getList() {
    return this.http.get(this.cycleListUrl);
  }

  // Nationality List
  getNationality() {
    return this.http.get(this.nationalityURL);
  }

  // Teams List
  getTeams() {
    return this.http.get(this.teamsURL);
  }

  // Clubs List
  getClubs() {
    return this.http.get(this.clubsURL);
  }

  // Register Rider
  register(params) {
    return this.http.post(this.registerURL + "?" + params, params);
  }

  // Register Event
  registerEvent(params) {
    return this.http.post(this.registerEventURL + "?" + params, params);
  }

  // Register Team Administrative
  registerAdminTeam(params) {
    return this.http.post(this.registerAdminTeamURL + "?" + params, params);
  }

  // Register Club Administrative
  registerAdminClub(params) {
    return this.http.post(this.registerAdminClubURL + "?" + params, params);
  }

  // Home News
  homeNews() {
    return this.http.get(this.homeNewsURL);
  }

  // Calendar
  calendar() {
    return this.http.get(this.calendarURL);
  }

  // Hall Of Fame
  halloffame() {
    return this.http.get(this.halloffameURL);
  }

  // Constituation
  constituation() {
    return this.http.get(this.constituationURL);
  }

  // Regulations
  regulations() {
    return this.http.get(this.regulationURL);
  }

  primaryLaw() {
    return this.http.get(this.primaryLawURL);
  }

  racingLicense() {
    return this.http.get(this.racingLicenseURL);
  }
getCommittee(url): Observable<any[]> {
  return this.http.get<any[]>(this.environment.API_URL + '/' + url);

}

  newsDetails(id: number): Observable<{
    news: INewsModel[];
    other: INewsModel[];
  }> {
    // environment.API_URL
    return this.http.get<{
      news: INewsModel[];
      other: INewsModel[];
    }>(`${environment.API_URL}/news_page/${id}`);
  }
}

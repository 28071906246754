export interface IEnvironment {
  name: string;
  version: string;
  API_URL: string;
}

export const environments = {
  dev: 'dev',
  testing: 'testing',
  production: 'production'
};

import { Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {HttpEvent, HttpRequest, HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AlertService {


 constructor(private toaster: ToastrService) {
  //this.toastr = this.injector.get<ToastrService>(ToastrService);

 }
  showSuccess(msg) {
    this.toaster.success(msg);

  }

  showError(message) {
    this.toaster.error(message);

  }
  showWarning(message) {
    this.toaster.warning(message);

  }




  showToaster(event: HttpResponse<any>, req: HttpRequest<any>) {

     //console.log('post',{event});
     if (event.body.result){
     if (event.body.result.success && !event.body.result.payment_resopnse){
       this.showSuccess(event.body.result.message);
       // window.location.reload();

     }

     }else {
       if (event.body.error)
       this.showError(event.body.error.data.arguments[0])
     }



  }
}

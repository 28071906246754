import { AuthService } from 'src/app/auth/auth.service';
import { TermsPolicyComponent } from './../../components/terms-policy/terms-policy.component';
import { NationalTeamService } from './../../services/national-team.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HostUAECFEventService } from "../../services/host-uaecf-event.service";
import { environment } from '../../../environments/environment';
import { TeamsClubsService } from "../../services/teams-clubs.service";
import { ClubAdmin } from "../../model/nationalTeam/club.detail.model";
import { MatDialog } from "@angular/material/dialog";
import { ClubTeamRiderRegistrationComponent } from "../club-team-rider-registration/club-team-rider-registration.component";
import { ICLubRiderRegistration, IFullName, ILicenseNo, ReregistrationID } from "../../model/registration.model";
import { RegistrationsService } from "../../services/registrations.service";
import * as localforage from 'localforage';
import * as Constants from '../../shared/AppConstant.constant';
import { isNull } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-club-rider-registration',
  templateUrl: './club-rider-registration.component.html',
  styleUrls: ['./club-rider-registration.component.css']
})
export class ClubRiderRegistrationComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  clubRiderRegistrationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  clubRidersLicenses: ILicenseNo[] = [];
  clubRidersNames: IFullName[] = [];
  doctors: ClubAdmin[] = [];
  private uaeEventId: number;
  private clubName: string;
  price = Constants.RiderRegisterPrice;
  agreedTerms: boolean;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public api: HostUAECFEventService,
    public clubTeamService: TeamsClubsService,
    private national: NationalTeamService,
    private registrationService: RegistrationsService,
    private auth: AuthService,
    public dialog: MatDialog
  ) {
    this.RiderClubRegistrationFormInitilization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.clubRiderRegistrationForm.controls;
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(async data => {
      this.uaeEventId = +data.get('id');
      this.loadingInitializationData();
    });
  }

  RiderClubRegistrationFormInitilization() {
    this.clubRiderRegistrationForm = this.fb.group({
      club: [this.auth.currentUserValue.clubId, [Validators.required]],
      date: ['', [Validators.required]],
      lines: this.fb.array([]),
      secretary: [''],
      doctorName: [''],
    });
    this.lines = this.clubRiderRegistrationForm.get('lines') as FormArray;
    this.getClubDetails();
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.clubRiderRegistrationForm.invalid) {
      return;
    }

    const formData = {
      club_name: this.clubRiderRegistrationForm.get('club').value.toString(),
      secretary_general: this.clubRiderRegistrationForm.get('secretary').value.toString(),
      doctor_name: this.clubRiderRegistrationForm.get('doctorName').value.toString(),
      reregistration_ids: this.lines.controls.map(item => {
        return {
          category: item.get('category').value.toString(),
          license_no: item.get('federation_id').value.toString(),
          full_name: item.get('fullName').value.toString(),
          new_old: item.get('isOld').value.toString().toLowerCase(),
          date_birth: item.get('dateOfBirth').value.toString(),
          health_fitness: item.get('healthFitness').value.toString(),
          nationality: item.get('nationality').value.toString()
        } as ReregistrationID;
      })
    } as ICLubRiderRegistration;
    this.clubRiderRegistrationForm.reset();
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    // //console.log('entry by name data are', this.clubRiderRegistrationForm.value);

    // last step
    this.registrationService.registerBulkRidersForClub(formData).subscribe(async (data) => {
      //console.log({ data });
      if (data.result.success && this.price > 0) {
        const reference = data.result.payment_resopnse.order.ref;
        const paymentUrl = data.result.payment_resopnse.order.url;

        await localforage.setItem('reference', reference, function (err) {
          // if err is non-null, we got an error
          // localforage.getItem('key', function (err, value) {
          //   // if err is non-null, we got an error. otherwise, value is the value
          // });
        });
        window.location.href = paymentUrl;
      } else if (data.result.success) {
        //console.log("ssuccess toaster");
        window.location.href = "/";
      }


    });;
  }

  onReset(): void {
    this.submitted = false;
    this.clubRiderRegistrationForm.reset();
  }

  addLine(): void {
    // this.lines = this.entryByNameForm.get('lines') as FormArray;
    this.lines.push(this.createLine());
  }

  removeLine(i: number) {
    this.lines.removeAt(i);
  }

  createLine(): FormGroup {
    return this.fb.group({
      federation_id: [''],
      fullName: [''],
      dateOfBirth: [''],
      isOld: [''],
      nationality: [''],
      category: [''],
      healthFitness: [''],
    });
  }


  async getClubDetails() {


    const club = this.clubRiderRegistrationForm.get('club').value;
    this.national.loadClubAdminProfile(club).subscribe((clubadmin) => {
      // debugger
      //console.log('--sandeeep',{clubadmin});
    });
    await this.clubTeamService.loadSpecificClub(club);
    this.clubTeamService.clubDetailsData$.subscribe(data => {
      if (data.club.length > 0) {
        this.clubName = data.club[0].name;
        this.doctors = data.club_admin;
        // //console.log("from data CLUB", data);

      }
    });
    this.clubTeamService.loadRidersForSpecificClub(club);
    this.clubTeamService.clubRiders$.subscribe(data => {
      // //console.log("from club data", data);
      this.clubRidersLicenses = data.map(item => item.license_no[0]);
      // Dumpiest solution need refactor in phase 2 :)
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        try {
          this.clubRidersNames.push(element.full_name[0]);

        } catch (error) {

        }

      }
      // //console.log("from club ARRAYS", this.clubRidersNames, this.clubRidersNames);

    });

  }

  ChangeRiderLicense(e, i: number) {
    const riderLicenseNumber = this.lines.controls[i].get('federation_id').value;
    this.clubTeamService.clubRiders$.subscribe(data => {

      const rider = data.find(item => item.license_no[0].id == riderLicenseNumber);
      //console.log({ riderLicenseNumber }, rider, 'from licence');

      if (rider) {
        this.lines.controls[i].patchValue({
          fullName: rider.full_name[0].id,
          dateOfBirth: rider.date_birth,
          isOld: 'Old',
          nationality: rider.nationality[0].nationality[0],
          category: rider.club_category[0].club_category[0],
          // healthFitness: null
        });
      }
    });
  }

  ChangeRiderName(e, i: number) {
    const riderLicenseName = this.lines.controls[i].get('fullName').value;
    this.clubTeamService.clubRiders$.subscribe(data => {


      const rider = data.find(item => item.full_name[0].id == riderLicenseName);
      //console.log({ riderLicenseName }, rider, 'from names');

      if (rider) {
        this.lines.controls[i].patchValue({
          federation_id: rider.license_no[0].id,
          fullName: rider.full_name[0].id,
          dateOfBirth: rider.date_birth,
          isOld: 'Old',
          nationality: rider.nationality[0].nationality[0],
          category: rider.club_category[0].club_category[0],
          // healthFitness: null
        });
      }
    });
    //console.log('entry by riderLicenseName are', this.clubRiderRegistrationForm.value);

  }

  OpenRiderRegistrationForm() {
    const dialogRef = this.dialog.open(ClubTeamRiderRegistrationComponent, {
      width: '1200px',
      height: '900px',
      data: {
        id: this.clubRiderRegistrationForm.get('club').value,
        clubOrTeam: 'club',
        name: this.clubName
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(async result => {
      //console.log('dialog result is ', result);
      await this.national.loadNewRiderProfile(result).subscribe(rider => {
        //console.log({ rider }, this.lines.length);
        if (rider.data) {
          this.clubRidersLicenses.push({ id: rider.data[0].id, federation_id: rider.data[0].license_no });
          this.clubRidersNames.push(rider.data[0].full_name[0]);
          this.lines.controls[this.lines.length - 1].patchValue({
            federation_id: rider.data[0].id,
            fullName: rider.data[0].full_name[0].id,
            dateOfBirth: rider.data[0].date_birth,
            isOld: 'New',
            nationality: rider.data[0].nationality[0].nationality[0],
            category: rider.data[0].club_category[0].club_category[0],
            healthFitness: 'null'
          });
          ``;
        }
      });
      this.getClubDetails();
    });

  }
  calculatePrice() {
    let emptyLines = 0;
    for (const line of this.lines.controls) {
      if (line.get('federation_id').value != null) {
        if (line.get('federation_id').value.length <= 0) {
          emptyLines++;
        }
      }
    }
    return (this.lines.length - emptyLines) * this.price;
  }
  changeAgreedTerm() {
    this.agreedTerms = !this.agreedTerms;
    //console.log(this.agreedTerms);

  }

  openDialog(type) {
    //console.log('fromdialog');
    const dialogRef = this.dialog.open(TermsPolicyComponent, {
      width: '750px',
      height: '550px',
      data: { type: type }
    });

    dialogRef.afterClosed().subscribe(async result => {
      //console.log('The dialog was closed', result);
    });
  }

  private loadingInitializationData() {
    this.clubTeamService.loadClubs();
    this.api.loadClubTeamCategory();
    this.api.loadNationalities();
  }
}

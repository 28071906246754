<div class="container pt-5 pb-5">
    <form class="row g-3" [formGroup]="accommodationForm" (ngSubmit)="onSubmit()">
      <h2 class="h1 text-danger fw-bold">{{ "UAECF.Accommodation.Header" | translate}}</h2>
        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.NFName' | translate}}</label>
                    <input type="text" formControlName="nfName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nfName.errors }" />
                    <div *ngIf="submitted && f.nfName.errors" class="invalid-feedback">
                        <div *ngIf="f.nfName.errors.required">{{'UAECF.Accommodation.NFNameRequired' | translate}}</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mt-4 mb-4">
            <table class="table .table-bordered .table-sm  table-hover">
                <thead>
                    <tr>
                        <th scope="col">{{'UAECF.Accommodation.Role' | translate}}</th>
                        <th scope="col">{{'UAECF.Accommodation.Gender' | translate}}</th>
                        <th scope="col">{{'UAECF.Accommodation.Name' | translate}}</th>
                        <th scope="col">{{'UAECF.Accommodation.CheckIn' | translate}}</th>
                        <th scope="col">{{'UAECF.Accommodation.CheckOut' | translate}}</th>
                        <th scope="col">{{'UAECF.Accommodation.Single' | translate}}</th>
                        <th scope="col">{{'UAECF.Accommodation.Twin' | translate}}</th>
                        <th scope="col">{{'UAECF.Accommodation.PassportNo' | translate}}</th>
                        <th scope="col">*</th>
                    </tr>
                </thead>
                <tbody formArrayName="lines">

                    <tr *ngFor="let line of lines.controls; let i=index;let l=last" [formGroupName]="i">
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>{{'UAECF.Accommodation.ChooseRole' | translate}}</mat-label>
                                <mat-select formControlName="role">
                                    <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.Accommodation.ChooseGender' | translate}}</mat-label>
                                <mat-select formControlName="role">
                                    <mat-option *ngFor="let gender of genders" [value]="gender">{{'Gender.'+gender|translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.Accommodation.Name' | translate}}</mat-label>
                                <input matInput formControlName="name">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>{{'UAECF.Accommodation.ChooseCheckIn' | translate}}</mat-label>
                                <input matInput [matDatepicker]="CheckInPicker" formControlName="checkIn">
                                <mat-datepicker-toggle matSuffix [for]="CheckInPicker"></mat-datepicker-toggle>
                                <mat-datepicker #CheckInPicker></mat-datepicker>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.Accommodation.ChooseCheckOut' | translate}}</mat-label>
                                <input matInput [matDatepicker]="CheckOutPicker" formControlName="checkOut">
                                <mat-datepicker-toggle matSuffix [for]="CheckOutPicker"></mat-datepicker-toggle>
                                <mat-datepicker #CheckOutPicker></mat-datepicker>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-checkbox formControlName="single"></mat-checkbox>
                        </td>
                        <td>
                            <mat-checkbox formControlName="twin"></mat-checkbox>
                        </td>

                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>{{'UAECF.Accommodation.PassportNo' | translate}}</mat-label>
                                <input matInput formControlName="passportNo">
                            </mat-form-field>
                        </td>
                        <td>
                            <button mat-button (click)="removeLine(i)">{{'UAECF.Accommodation.RemoveLine' | translate}}
            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button mat-button (click)="addLine()">{{'UAECF.Accommodation.AddLine' | translate}}</button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div class="row mt-4 mb-4">

            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'UAECF.Accommodation.ChooseDate' | translate}}</mat-label>
                        <input matInput [matDatepicker]="date" formControlName="date">
                        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                    </mat-form-field>

                    <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                        <div *ngIf="f.date.errors.required">{{'UAECF.Accommodation.DateRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.Place' | translate}}</label>
                    <input type="text" formControlName="place" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.place.errors }" />
                    <div *ngIf="submitted && f.place.errors" class="invalid-feedback">
                        <div *ngIf="f.place.errors.required">{{'UAECF.Accommodation.PlaceRequired' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">

            <div class="col-6">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.TeamName' | translate}}</label>
                    <input type="text" formControlName="teamName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.teamName.errors }" />
                    <div *ngIf="submitted && f.teamName.errors" class="invalid-feedback">
                        <div *ngIf="f.teamName.errors.required">{{'UAECF.Accommodation.TeamNameRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6" formGroupName="stamp">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.Signature' | translate}}</label>
                    <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event,'stamp')" />
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-md-6" formGroupName="logo">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.Logo' | translate}}</label>
                    <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event , 'logo')" />
                </div>
            </div>
        </div>

        <h1>{{'UAECF.Accommodation.OrganizerInformation' | translate}}</h1>

        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.Address' | translate}}</label>
                    <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                        <div *ngIf="f.address.errors.required">{{'UAECF.Accommodation.AddressRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.Website' | translate}}</label>
                    <input type="text" formControlName="website" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.website.errors }" />
                    <div *ngIf="submitted && f.website.errors" class="invalid-feedback">
                        <div *ngIf="f.website.errors.required">{{'UAECF.Accommodation.WebsiteRequired' | translate}}</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row ">
            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.Phone' | translate}}</label>
                    <input type="tel" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">{{'UAECF.Accommodation.PhoneRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.Accommodation.Email' | translate}}</label>
                    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">{{'UAECF.Accommodation.EmailRequired' | translate}}</div>
                        <div *ngIf="f.email.errors.email">{{'UAECF.Accommodation.EmailInvalid' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-lg host-btn">{{'UAECF.Accommodation.Submit' | translate}}</button>
        </div>
    </form>
</div>

import { AlertService } from './../services/alert.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import { SpinnerOverlayService } from './spinner-overlay.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private readonly spinnerOverlayService: SpinnerOverlayService, private alertservice: AlertService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const toaster = this.injector.get<AlertService>(AlertService);
    const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
    return next.handle(req).pipe(
      finalize(() =>
    {

    spinnerSubscription.unsubscribe();
    }
    ),map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response and headers you want
          this.alertservice.showToaster(event,req);
        }
        return event;
      })
    );
  }
}

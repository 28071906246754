import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-policy',
  templateUrl: './terms-policy.component.html',
  styleUrls: ['./terms-policy.component.css']
})
export class TermsPolicyComponent implements OnInit {
  type: any;

  constructor(public dialogRef: MatDialogRef<TermsPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // console.log({data});
      this.type = data.type;

     }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close({
    });
  }
}

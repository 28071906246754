import {Component, OnInit} from '@angular/core';
import {IClubAdmin} from "../../../model/official.model";
import {Subscription} from "rxjs";
import {NationalTeamService} from "../../../services/national-team.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-club-official-details',
  templateUrl: './club-official-details.component.html',
  styleUrls: ['./club-official-details.component.css']
})
export class ClubOfficialDetailsComponent implements OnInit {

  environment = environment;
  public clubAdmin: IClubAdmin = {} as IClubAdmin;
  private routeDataSubscription: Subscription;
  private clubId: number;

  constructor(public nationalTeamService: NationalTeamService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.clubId = +data.get('id');
      this.loadingInitializationData();
      //console.log('this.clubId', this.clubId);
    });
  }

  private loadingInitializationData() {
    this.nationalTeamService.loadClubAdminProfile(this.clubId).subscribe((response: any) => {
      // debugger
      //console.log({response});

      const result = response.data[0];
      this.clubAdmin = {
        ...result,
        nationality: result.nationality[0].nationality[1],
        club_name: result.club_name[0].club_name[1]
      };
    });
  }
}


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BackendApiService } from './../backend-api.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

interface Type {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-registration-component',
  templateUrl: './registration-component.component.html',
  styleUrls: ['./registration-component.component.css']
})
export class RegistrationComponentComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  registerForm: FormGroup;
  submitted = false;
  selectedValue: string;

  types: Type[] = [
    { value: 'citizen_rider', viewValue: 'Local Emirati' },
    { value: 'children_citizen', viewValue: 'Children Citizen' },
    { value: 'gcc', viewValue: 'GCC' },
    { value: 'born_uae', viewValue: 'Born UAE' },
    { value: 'resident_rider', viewValue: 'Resident Rider' },
    { value: 'expat', viewValue: 'Expat' },
    { value: 'amateur', viewValue: 'Amateur' }
  ];

  constructor(private formBuilder: FormBuilder, private backendService: BackendApiService, private datePipe: DatePipe, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      uci_id: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: ['', Validators.required],
      rider_email: ['', Validators.required],
      date_birth: [''],
      gender: [''],
      place_birth: [''],
      home_address: [''],
      work_address: [''],
      register_type: [''],
      emirates_id: ['', Validators.required],
      emirates_id_expiry: [''],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    // var date = new Date();
    this.registerForm.value.date_birth = this.datePipe.transform(this.registerForm.value.date_birth, "yyyy-MM-dd");

    this.registerForm.value.emirates_id_expiry = this.datePipe.transform(this.registerForm.value.emirates_id_expiry, "yyyy-MM-dd");

    //console.log('Formated Date : ' + this.registerForm.value.date_birth);
    //console.log('Formated emirates_id_expiry : ' + this.registerForm.value.emirates_id_expiry);

    let urlParameters = Object.entries(this.registerForm.value).map(e => e.join('=')).join('&').trim();

    let params = JSON.stringify(urlParameters).replace('"', "").replace('"', "");

    this.backendService.register(params).subscribe(
      (response: HttpResponse<any>) => {
        //console.log(response);
        // this.registerForm.reset();
        this._snackBar.open('Registration Success', 'Success', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        setTimeout(() => {
          this.router.navigate(['/home'])
        }, 500);
      },
      (error) => {
        this._snackBar.open('Registration Failed', 'Failed', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );


    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));


  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

}

<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "Footer.TrainingPermission.Header" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "Footer.TrainingPermission.Header" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="trainingPermissionRequestForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form registration-form rider-registration-form form__sub-group" *ngIf="selectedSubblicant != 'group_riders'">
        <div class="form-group" *ngIf="selectedSubblicant == 'company'">
          <label class="form-label">{{
            "Footer.TrainingPermission.CompanyName" | translate
          }}</label>
          <input type="text" formControlName="companyName" class="form-control" />
        </div>
        <mat-form-field
          appearance="standard"
          *ngIf="selectedSubblicant == 'company'"
        >
          <mat-label
            >'{{
              "Footer.TrainingPermission.RequestedDate" | translate
            }}</mat-label
          >
          <input
            matInput
            [matDatepicker]="DateOfEntryPicker"
            formControlName="requestedDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="DateOfEntryPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #DateOfEntryPicker></mat-datepicker>
        </mat-form-field>
        <div class="form-group" *ngIf="selectedSubblicant == 'club'">
          <label class="form-label">{{
            "Footer.TrainingPermission.ClubName" | translate
          }}</label>
          <input type="text" formControlName="clubName" class="form-control" />
        </div>
        <div class="form-group" *ngIf="selectedSubblicant == 'team'">
          <label class="form-label">{{
            "Footer.TrainingPermission.TeamName" | translate
          }}</label>
          <input type="text" formControlName="teamName" class="form-control" />
        </div>

        <div class="form-group">
          <label class="form-label">{{
            "Footer.TrainingPermission.Address" | translate
          }}</label>
          <input
            type="text"
            formControlName="address"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
          />
          <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
            <div *ngIf="f.address.errors.required">
              {{ "Footer.TrainingPermission.AddressRequired" | translate }}
            </div>
          </div>
        </div>

        <mat-form-field appearance="standard">
          <mat-label>{{
            "Footer.TrainingPermission.ChooseSubblicant" | translate
          }}</mat-label>
          <mat-select
            formControlName="sublicant"
            (selectionChange)="getSelectedSubblicant($event)"
          >
            <mat-option
              *ngFor="let sublicant of sublicants"
              [value]="sublicant.key"
              >{{ sublicant.value }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <div class="form-group">
          <label class="form-label">{{
            "Footer.TrainingPermission.Phone" | translate
          }}</label>
          <input
            type="tel"
            formControlName="phone"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
          />
          <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
            <div *ngIf="f.phone.errors.required">
              {{ "Footer.TrainingPermission.PhoneRequired" | translate }}
            </div>
          </div>
        </div>
      </div>

      <h2 class="h4 form__sub-group-title">
        {{ "Footer.TrainingPermission.ContactPerson" | translate }}
      </h2>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.FirstName" | translate
        }}</label>
        <input
          type="text"
          formControlName="firstName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
        />
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
          <div *ngIf="f.firstName.errors.required">
            {{ "Footer.TrainingPermission.FirstNameRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.LastName" | translate
        }}</label>
        <input
          type="text"
          formControlName="lastName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
        />
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
          <div *ngIf="f.lastName.errors.required">
            {{ "Footer.TrainingPermission.LastNameRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.Phone" | translate
        }}</label>
        <input
          type="tel"
          formControlName="phoneInCharge"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.phoneInCharge.errors }"
        />
        <div
          *ngIf="submitted && f.phoneInCharge.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.phoneInCharge.errors.required">
            {{ "Footer.TrainingPermission.PhoneRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.Address" | translate
        }}</label>
        <input
          type="text"
          formControlName="addressInCharge"
          class="form-control"
          [ngClass]="{
            'is-invalid': submitted && f.addressInCharge.errors
          }"
        />
        <div
          *ngIf="submitted && f.addressInCharge.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.addressInCharge.errors.required">
            {{ "Footer.TrainingPermission.AddressRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.Email" | translate
        }}</label>
        <input
          type="text"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">
            {{ "Footer.TrainingPermission.EmailRequired" | translate }}
          </div>
          <div *ngIf="f.email.errors.email">
            {{ "Footer.TrainingPermission.EmailInvalid" | translate }}
          </div>
        </div>
      </div>
      <div *ngIf="api.nationalities$ | async as nationalities">
        <mat-form-field appearance="standard">
          <mat-label>{{
            "Footer.TrainingPermission.ChooseNationality" | translate
          }}</mat-label>
          <mat-select formControlName="nationality">
            <mat-option
              *ngFor="let nationality of nationalities"
              [value]="nationality.id"
              >{{ nationality.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group" formGroupName="groupContactPersonId">
        <label class="form-label">{{
          "Footer.TrainingPermission.GroupContactPersonId" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'groupId')"
        />
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.NumberOfParticipants" | translate
        }}</label>
        <input
          type="number"
          formControlName="NumberOfParticipants"
          class="form-control"
          [ngClass]="{
            'is-invalid': submitted && f.NumberOfParticipants.errors
          }"
        />
        <div
          *ngIf="submitted && f.NumberOfParticipants.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.NumberOfParticipants.errors.required">
            {{
              "Footer.TrainingPermission.NumberOfParticipantsRequired"
                | translate
            }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.StartingPoint" | translate
        }}</label>
        <input
          type="text"
          formControlName="startingPoint"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.startingPoint.errors }"
        />
        <div
          *ngIf="submitted && f.startingPoint.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.startingPoint.errors.required">
            {{ "Footer.TrainingPermission.StartingPointRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.FinishingPoint" | translate
        }}</label>
        <input
          type="text"
          formControlName="finishingPoint"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.finishingPoint.errors }"
        />
        <div
          *ngIf="submitted && f.finishingPoint.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.finishingPoint.errors.required">
            {{ "Footer.TrainingPermission.FinishingPointRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.TotalDistance" | translate
        }}</label>
        <input
          type="number"
          formControlName="totalDistance"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.totalDistance.errors }"
        />
        <div
          *ngIf="submitted && f.totalDistance.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.totalDistance.errors.required">
            {{ "Footer.TrainingPermission.TotalDistanceRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="standard">
          <mat-label>{{
            "Footer.TrainingPermission.StartingTime" | translate
          }}</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="startingTimePicker"
            formControlName="startingTime"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startingTimePicker"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #startingTimePicker
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [color]="color"
            [enableMeridian]="enableMeridian"
          >
          </ngx-mat-datetime-picker>
        </mat-form-field>

        <div
          *ngIf="submitted && f.startingTime.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.startingTime.errors.required">
            {{ "Footer.TrainingPermission.StartingTimeRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="standard">
          <mat-label>{{
            "Footer.TrainingPermission.FinishingTime" | translate
          }}</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="finishingTimePicker"
            formControlName="finishingTime"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="finishingTimePicker"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #finishingTimePicker
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [color]="color"
            [enableMeridian]="enableMeridian"
          >
          </ngx-mat-datetime-picker>
        </mat-form-field>

        <div
          *ngIf="submitted && f.finishingTime.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.finishingTime.errors.required">
            {{ "Footer.TrainingPermission.FinishingTimeRequired" | translate }}
          </div>
        </div>
      </div>

      <mat-form-field appearance="standard">
        <mat-label>{{
          "Footer.TrainingPermission.FrequencyTraining" | translate
        }}</mat-label>
        <mat-select formControlName="frequency">
          <mat-option
            *ngFor="let frequency of frequncies"
            [value]="frequency.key"
            >{{ frequency.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.CategoryForm" | translate
        }}</label>
        <input
          type="text"
          formControlName="categoryFrom"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.categoryFrom.errors }"
        />
        <div
          *ngIf="submitted && f.categoryFrom.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.categoryFrom.errors.required">
            {{ "Footer.TrainingPermission.CategoryFromRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">
          {{ "Footer.TrainingPermission.TO" | translate }}</label
        >
        <input
          type="text"
          formControlName="categoryTo"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.categoryTo.errors }"
        />
        <div *ngIf="submitted && f.categoryTo.errors" class="invalid-feedback">
          <div *ngIf="f.categoryTo.errors.required">
            {{ "Footer.TrainingPermission.CategoryToRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.BikesBrake" | translate
        }}</label>
        <mat-radio-group formControlName="brakes" class="example-radio-group">
          <mat-radio-button class="example-radio-button" value="yes">{{
            "Footer.TrainingPermission.Yes" | translate
          }}</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="no">{{
            "Footer.TrainingPermission.No" | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.ReflectiveJerseys" | translate
        }}</label>
        <mat-radio-group
          formControlName="reflectiveJerseys"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="yes">{{
            "Footer.TrainingPermission.Yes" | translate
          }}</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="no">{{
            "Footer.TrainingPermission.No" | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.SpareTyre" | translate
        }}</label>
        <mat-radio-group formControlName="spare" class="example-radio-group">
          <mat-radio-button class="example-radio-button" value="yes">{{
            "Footer.TrainingPermission.Yes" | translate
          }}</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="no">{{
            "Footer.TrainingPermission.No" | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.ReflectiveBikes" | translate
        }}</label>
        <mat-radio-group
          formControlName="reflectionsBike"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="yes">{{
            "Footer.TrainingPermission.Yes" | translate
          }}</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="no">{{
            "Footer.TrainingPermission.No" | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.SafetyVehicles" | translate
        }}</label>
        <mat-radio-group
          formControlName="safetyVehiclesLighting"
          class="example-radio-group"
        >
          <mat-radio-button class="example-radio-button" value="yes">{{
            "Footer.TrainingPermission.Yes" | translate
          }}</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="no">{{
            "Footer.TrainingPermission.No" | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.TrainingPermission.Helmets" | translate
        }}</label>
        <mat-radio-group formControlName="helmets" class="example-radio-group">
          <mat-radio-button class="example-radio-button" value="yes">{{
            "Footer.TrainingPermission.Yes" | translate
          }}</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="no">{{
            "Footer.TrainingPermission.No" | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <h2 class="h4 form__sub-group-title">
        {{ "Footer.TrainingPermission.Attachments" | translate }}
      </h2>

      <div class="form-group" formGroupName="noc">
        <label class="form-label">{{
          "Footer.TrainingPermission.NOCDocument" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'noc')"
        />
      </div>
      <div class="form-group" formGroupName="license">
        <label class="form-label">{{
          "Footer.TrainingPermission.CarLicense" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'license')"
        />
      </div>

      <div class="form-group" formGroupName="safetyLicense">
        <label class="form-label">{{
          "Footer.TrainingPermission.SafetyVehiclesFrontBack" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'safety')"
        />
      </div>

      <div class="form-group" formGroupName="driverLicense">
        <label class="form-label"
          >{{ "Footer.TrainingPermission.DriverLicense" | translate }}
        </label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'driverLicense')"
        />
      </div>

      <div class="form-group" formGroupName="insurance">
        <label class="form-label"
          >{{ "Footer.TrainingPermission.GroupInsurance" | translate }}
        </label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'insurance')"
        />
      </div>

      <table class="table table_default registration-form__table">
        <thead>
          <tr>
            <th scope="col">
              {{ "Footer.TrainingPermission.FirstName" | translate }}
            </th>
            <th scope="col">
              {{ "Footer.TrainingPermission.LastName" | translate }}
            </th>
            <th scope="col">
              {{ "Footer.TrainingPermission.EmiratesId" | translate }}
            </th>
            <th scope="col">
              {{ "Footer.TrainingPermission.Nationality" | translate }}
            </th>
            <th scope="col">
              {{ "Footer.TrainingPermission.Phone" | translate }}
            </th>
            <th scope="col">
              {{ "Footer.TrainingPermission.Email" | translate }}
            </th>
            <th scope="col">*</th>
          </tr>
        </thead>
        <tbody formArrayName="training_information_ids">
          <tr
            *ngFor="let line of training_information_ids.controls; let i = index; let l = last"
            [formGroupName]="i"
          >
            <td>
              <input
                type="text"
                formControlName="firstName"
                class="form-control"
              />
            </td>
            <td>
              <input
                type="text"
                formControlName="lastName"
                class="form-control"
              />
            </td>
            <td>
              <input
                type="text"
                formControlName="emiratedId"
                class="form-control"
              />
            </td>

            <td>
              <div *ngIf="api.nationalities$ | async as nationalities">
                <mat-form-field appearance="standard">
                  <mat-select formControlName="nationality">
                    <mat-option
                      *ngFor="let nationality of nationalities"
                      [value]="nationality.id"
                      >{{ nationality.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </td>

            <td>
              <input type="text" formControlName="phone" class="form-control" />
            </td>

            <td>
              <input type="text" formControlName="email" class="form-control" />
            </td>

            <td>
              <button mat-button (click)="removeLine(i)">
                {{ "Footer.TrainingPermission.RemoveLine" | translate }}
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button mat-button (click)="addLine($event)">
                {{ "Footer.TrainingPermission.AddLine" | translate }}
              </button>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <div class="form-group registration-form__term">
        <h5 class="form-label">
          {{ "Footer.TrainingPermission.Note" | translate }}
        </h5>
        <div class="registration-form__terms-inner">
          {{ "Footer.TrainingPermission.NoteText" | translate }}
        </div>
      </div>

      <div class="form-group registration-form__term">
        <h5 class="form-label">
          {{ "Footer.TrainingPermission.Agreement" | translate }}
        </h5>
        <div class="registration-form__terms-inner">
          {{ "Footer.TrainingPermission.AgreementText" | translate }}
        </div>
      </div>
      <mat-checkbox
        unchecked="agreedTerms"
        (click)="changeAgreedTerm()"
        class="form-group registration-form__agreement"
        formControlName="accept"
      >
        {{ "Footer.TrainingPermission.Accept" | translate }}
      </mat-checkbox>
      <div *ngIf="submitted && f.accept.errors" class="invalid-feedback">
        <div *ngIf="f.accept.errors.required">
          {{ "Footer.TrainingPermission.AcceptRequired" | translate }}
        </div>
      </div>
      <div class="form-group registration-form__submit-wrap">
        <button 
          type="submit" 
          class="button button_theme button_md"
          [disabled]="!agreedTerms"
        >
          {{ "Footer.TrainingPermission.Submit" | translate }}
        </button>
      </div>
    </form>
  </section>
</div>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RequestlangInterceptor implements HttpInterceptor {
   localeLang =  localStorage.getItem('language')|| 'ar_001';
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const httpsReq = request.clone({
      headers: request.headers.set('Accept-Language', this.localeLang ).set('lang', this.localeLang),
    });



    //console.log(this.localeLang);
    // console.log({httpsReq});

    return next.handle(httpsReq);
  }
}

<mat-toolbar>
    <span>{{'List.List' | translate}}</span>
</mat-toolbar>

<div class="p-2 h-100 p1rem page-wrapper">
    <div class="p-2 pl-3 pr-3 p2rem pb0">
        <div class="row">
            <div class="col-12">
                <div layout="row" layout-margin layout-wrap layout-align="center center" class="parent">
                    <mat-card class="child" *ngFor="let list of cyclist_list">
                        <mat-card-header>
                            <div mat-card-avatar class="example-header-image"></div>
                            <mat-card-title>{{ list.name }}</mat-card-title>
                            <!-- <mat-card-subtitle>{{ list.description }}</mat-card-subtitle> -->
                        </mat-card-header>
                        <img mat-card-image src="{{ list.img_url }}" alt="Photo of {{ list.name }}" />
                        <mat-card-content>
                            <!-- <p>The Shiba Inu is new cyclist.</p> -->
                        </mat-card-content>
                        <mat-card-actions>
                            <button mat-button>{{'List.LIKE' | translate}}</button>
                            <button mat-button>{{'List.SHARE' | translate}}</button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>

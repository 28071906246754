import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendApiService } from 'src/app/backend-api.service';

@Component({
  selector: 'app-committees',
  templateUrl: './committees.component.html',
  styleUrls: ['./committees.component.css']
})
export class CommitteesComponent implements OnInit {
  routeDataSubscription: any;
  committees: any;
  url: string;

  constructor(public api: BackendApiService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.url = data.get('url').toString();

      this.api.getCommittee(this.url).subscribe((data) => {
        // console.log(data);
          this.committees = data;
          // console.log(this.committees, typeof(this.committees));

      });
    });
  }
  getCommitteeName(): string {
    return this.url.replace("-", " ").toLocaleUpperCase();
  }
}

<div class="pt-5 pb-5">
    <form class="g-3" [formGroup]="visaApplicationForm" (ngSubmit)="onSubmit()">
      <h2 class="h1 text-danger fw-bold">{{ "UAECF.VisaApplication.Header" | translate}}</h2>

        <div class="container">
            <div class="row mt-4 mb-4">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{'UAECF.VisaApplication.NameOfFederation' | translate}}</label>
                        <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">{{'UAECF.VisaApplication.NameOfFederationRequired' | translate}}</div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row mt-4 mb-4">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{'UAECF.VisaApplication.ContactPersonName' | translate}}</label>
                        <input type="text" formControlName="contactPersonName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contactPersonName.errors }" />
                        <div *ngIf="submitted && f.contactPersonName.errors" class="invalid-feedback">
                            <div *ngIf="f.contactPersonName.errors.required">{{'UAECF.VisaApplication.ContactPersonNameRequired' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4 mb-4">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'UAECF.VisaApplication.WorkPhone' | translate}}</label>
                        <input type="text" formControlName="workPhone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.workPhone.errors }" />
                        <div *ngIf="submitted && f.workPhone.errors" class="invalid-feedback">
                            <div *ngIf="f.workPhone.errors.required">{{'UAECF.VisaApplication.WorkPhoneRequired' | translate}}</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'UAECF.VisaApplication.MobilePhone' | translate}}</label>
                        <input type="text" formControlName="mobilePhone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobilePhone.errors }" />
                        <div *ngIf="submitted && f.mobilePhone.errors" class="invalid-feedback">
                            <div *ngIf="f.mobilePhone.errors.required">{{'UAECF.VisaApplication.MobilePhoneRequired' | translate}}</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'UAECF.VisaApplication.Email' | translate}}</label>
                        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">{{'UAECF.VisaApplication.EmailRequired' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 mb-4 pr-4 pl-4 align-content-center" style="margin-left: 30px; margin-right: 30px">
            <table class="table .table-bordered .table-sm  table-hover">
                <thead>
                    <tr>
                        <th scope="col">{{'UAECF.VisaApplication.Name' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.FamilyName' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.Sex' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.DateOfBirth' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.Nationality' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.PassportNo' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.Position' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.VisaApplyingCountry' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.DateOfEntry' | translate}}</th>
                        <th scope="col">{{'UAECF.VisaApplication.DateOfDeparture' | translate}}</th>
                        <th scope="col">*</th>
                    </tr>
                </thead>
                <tbody formArrayName="lines">

                    <tr *ngFor="let line of lines.controls; let i=index;let l=last" [formGroupName]="i">
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.VisaApplication.Name' | translate}}</mat-label>
                                <input matInput formControlName="name">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.VisaApplication.FamilyName' | translate}}</mat-label>
                                <input matInput formControlName="familyName">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.VisaApplication.ChooseGender' | translate}}</mat-label>
                                <mat-select formControlName="sex">
                                    <mat-option *ngFor="let gender of genders" [value]="gender">{{'Gender.'+gender|translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </td>

                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.VisaApplication.DateOfBirth' | translate}}</mat-label>
                                <input matInput [matDatepicker]="dateOfBirthPicker" formControlName="dateOfBirth">
                                <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
                                <mat-datepicker #dateOfBirthPicker></mat-datepicker>
                            </mat-form-field>
                        </td>

                        <td>
                            <div *ngIf="(api.nationalities$ | async) as nationalities">
                                <mat-form-field appearance="standard">
                                    <mat-label>{{'UAECF.VisaApplication.ChooseNationality' | translate}}</mat-label>
                                    <mat-select formControlName="nationality">
                                        <mat-option *ngFor="let nationality of nationalities" [value]="nationality.id">{{nationality.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.VisaApplication.PassportNo' | translate}}</mat-label>
                                <input matInput formControlName="passportNo">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.VisaApplication.Position' | translate}}</mat-label>
                                <input matInput formControlName="position">
                            </mat-form-field>
                        </td>
                        <td>
                            <div *ngIf="(api.nationalities$ | async) as nationalities">
                                <mat-form-field appearance="standard">
                                    <mat-label>{{'UAECF.VisaApplication.ChooseNationality' | translate}}</mat-label>
                                    <mat-select formControlName="visaApplyingCountry">
                                        <mat-option *ngFor="let nationality of nationalities" [value]="nationality.id">{{nationality.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.VisaApplication.DateOfEntry' | translate}}</mat-label>
                                <input matInput [matDatepicker]="DateOfEntryPicker" formControlName="dateOfEntry">
                                <mat-datepicker-toggle matSuffix [for]="DateOfEntryPicker"></mat-datepicker-toggle>
                                <mat-datepicker #DateOfEntryPicker></mat-datepicker>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-label>{{'UAECF.VisaApplication.DateOfDeparture' | translate}}</mat-label>
                                <input matInput [matDatepicker]="DateOfDeparturePicker" formControlName="dateOfDeparture">
                                <mat-datepicker-toggle matSuffix [for]="DateOfDeparturePicker"></mat-datepicker-toggle>
                                <mat-datepicker #DateOfDeparturePicker></mat-datepicker>
                            </mat-form-field>
                        </td>
                        <td>
                            <button mat-button (click)="removeLine(i)">{{'UAECF.VisaApplication.RemoveLine' | translate}}
            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button mat-button (click)="addLine()">{{'UAECF.VisaApplication.AddLine' | translate}}</button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-lg host-btn">{{'UAECF.VisaApplication.Submit' | translate}}</button>
        </div>
    </form>
</div>

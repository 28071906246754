<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "RegistrationComponent.AdminRegister" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "RegistrationComponent.AdminRegister" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="adminRegistrationForm"
      (ngSubmit)="onSubmit()"
    >
      <ng-container *ngIf="isClub">
        <div
          class="form-group hidden"
          *ngIf="clubTeamService.clubsData$ | async as clubs"
        >
          <label class="form-label">
            {{ "Registration.ClubName" | translate }}</label
          >
          <select class="form-select" formControlName="club">
            <option value="" disabled>
              {{ "Registration.ChooseClub" | translate }}
            </option>
            <option *ngFor="let club of clubs" [ngValue]="club.id">
              {{ club.name }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="!isClub">
        <div
          class="form-group hidden"
          *ngIf="clubTeamService.teamsData$ | async as teams"
        >
          <!--        <div class="row"> {{teams | json}}</div>-->
          <label class="form-label">
            {{ "Registration.TeamName" | translate }}</label
          >
          <select class="form-select" formControlName="team">
            <option value="" disabled>
              {{ "Registration.ChooseTeam" | translate }}
            </option>
            <option *ngFor="let team of teams" [ngValue]="team.id">
              {{ team.name }}
            </option>
          </select>
        </div>
      </ng-container>
      <div class="form-group"    >
        <div formGroupName="image">
          <label class="form-label">{{
            "Registration.UserImage" | translate
          }}</label>
          <input
            type="file"
            class="form-control"
            formControlName="file"
            (change)="onFileChange($event, 'image')"
          />
        </div>

        <img
          [src]="imageSrc"
          *ngIf="imageSrc"
          style="height: 150px; width: 150px; object-fit: contain;"
        />
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Registration.FirstName" | translate
        }}</label>
        <input
          type="text"
          formControlName="firstName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
        />
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
          <div *ngIf="f.firstName.errors.required">
            {{ "Registration.FirstNameRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Registration.LastName" | translate
        }}</label>
        <input
          type="text"
          formControlName="lastName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
        />
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
          <div *ngIf="f.lastName.errors.required">
            {{ "Registration.LastNameRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label"
          >{{ "Registration.UCIID" | translate }}
        </label>
        <input
          type="text"
          formControlName="uciId"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.uciId.errors }"
        />
        <div *ngIf="submitted && f.uciId.errors" class="invalid-feedback">
          <div *ngIf="f.uciId.errors.required">
            {{ "Registration.UCIIDRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label"
          >{{ "Registration.Functions" | translate }}
        </label>
        <select class="form-select" formControlName="function">
          <option value="" disabled>
            {{ "Registration.ChooseFunction" | translate }}
          </option>
          <option *ngFor="let func of Functions" [ngValue]="func.id">
            {{ "Functions." + func.id | translate }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.function.errors" class="invalid-feedback">
          <div *ngIf="f.function.errors.required">
            {{ "Registration.FunctionRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Registration.DOB" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="dateOfBirthPicker"
            formControlName="dateOfBirth"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfBirthPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthPicker></mat-datepicker>
        </mat-form-field>

        <div *ngIf="submitted && f.dateOfBirth.errors" class="invalid-feedback">
          <div *ngIf="f.dateOfBirth.errors.required">
            {{ "Registration.DOBRequired" }}
          </div>
        </div>
      </div>

      <div
        class="form-group"
        *ngIf="api.nationalities$ | async as nationalities"
      >
        <label class="form-label">{{
          "Registration.Nationality" | translate
        }}</label>
        <select class="form-select" formControlName="nationality">
          <option value="" disabled>
            {{ "Registration.ChooseNationality" | translate }}
          </option>
          <option
            *ngFor="let nationality of nationalities"
            [ngValue]="nationality.id"
          >
            {{ nationality.name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.nationality.errors" class="invalid-feedback">
          <div *ngIf="f.nationality.errors.required">
            {{ "Registration.NationalityRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Registration.PlaceOfBirth" | translate
        }}</label>
        <input
          type="text"
          formControlName="birthPlace"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.birthPlace.errors }"
        />
        <div *ngIf="submitted && f.birthPlace.errors" class="invalid-feedback">
          <div *ngIf="f.birthPlace.errors.required">
            {{ "Registration.PlaceOfBirthRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label"
          >{{ "Registration.Gender" | translate }}
        </label>

        <select class="form-select" formControlName="gender">
          <option value="" disabled>
            {{ "Registration.ChooseGender" | translate }}
          </option>
          <option *ngFor="let gender of genders" [ngValue]="gender">
            {{ "Gender." + gender | translate }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
          <div *ngIf="f.gender.errors.required">
            {{ "Registration.GenderRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Registration.Address" | translate
        }}</label>
        <input
          type="text"
          formControlName="address"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
        />
        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
          <div *ngIf="f.address.errors.required">
            {{ "Registration.AddressRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "Registration.Phone" | translate }}</label>
        <input
          type="text"
          formControlName="phone"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
        />
        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
          <div *ngIf="f.phone.errors.required">
            {{ "Registration.PhoneRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Registration.AdminEmail" | translate
        }}</label>
        <input
          type="text"
          formControlName="adminEmail"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.adminEmail.errors }"
        />
        <div *ngIf="submitted && f.adminEmail.errors" class="invalid-feedback">
          <div *ngIf="f.adminEmail.errors.required">
            {{ "Registration.AdminEmailRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "Registration.EmiratesID" | translate
        }}</label>
        <input
          type="text"
          formControlName="emiratesId"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.emiratesId.errors }"
        />
        <div *ngIf="submitted && f.emiratesId.errors" class="invalid-feedback">
          <div *ngIf="f.emiratesId.errors.required">
            {{ "Registration.EmiratesIDRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{
            "Registration.EmiratesIdExpiryDate" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="emiratesIdExpiryPicker"
            formControlName="emiratesIdExpiry"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="emiratesIdExpiryPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #emiratesIdExpiryPicker></mat-datepicker>
        </mat-form-field>
        <div
          *ngIf="submitted && f.emiratesIdExpiry.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.emiratesIdExpiry.errors.required">
            {{ "Registration.EmiratesIdExpiryDateRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group" formGroupName="identity">
        <label class="form-label">{{
          "Registration.IDBothSides" | translate
        }}</label>
        <input
          type="file"
          multiple
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'identity')"
        />
      </div>

      <div class="form-group" formGroupName="qualifications">
        <label class="form-label">{{
          "Registration.Qualifications" | translate
        }}</label>
        <input
          type="file"
          multiple
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'qualifications')"
        />
      </div>

      <div class="registration-form__terms">
        <h5>{{ "Registration.Notes" | translate }}:</h5>
        <div class="registration-form__terms-inner">
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.PaymentConfirmation'
                | translate
            "
          ></p>
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.RefundAndCancellationPolicy'
                | translate
            "
          ></p>
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.ServicePricing'
                | translate
            "
          >
            :<strong
              [innerHTML]="
                calculatePrice() +
                  ' Footer.ActivityAndCalender.Complaints.Terms.Currency'
                  | translate
              "
            ></strong>
          </p>
        </div>
      </div>

      <mat-checkbox
        unchecked="agreedTerms"
        class="form-group registration-form__agreement"
        (click)="changeAgreedTerm()"
      >
        {{ "Registration.IHaveReadAll" | translate }}
        <a (click)="openDialog(1)"
          >{{ "Registration.TermsLable" | translate }} </a
        >,<a (click)="openDialog(2)">
          {{ "Registration.PolicyLable" | translate }}</a
        >
        {{ "Registration.AgreeLable" | translate }}
      </mat-checkbox>
      <div class="form-group registration-form__submit-wrap">
        <button
          type="submit"
          class="button button_theme button_md"
          [disabled]="!agreedTerms"
        >
          {{ "Registration.SubmitButtonLable" | translate }}
        </button>
      </div>
    </form>
  </section>
</div>

<div *ngIf="clubDetailsData" id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "CommonText.Clubs" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>
        <a routerLink="/clubs-teams" class="link"
          >{{ "UAECF.ClubsAndTeams.ClubsAndTeams" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "CommonText.Clubs" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <section class="club-info" *ngFor="let club of clubDetailsData.club">
      <div class="club-info__photo-block">
        <img
          [src]="environment.API_URL + club.img_url"
          class="club-info__image"
          alt=""
        />
        <h1 class="h5 club-info__title">{{ club.name }}</h1>
      </div>
      <div class="club-info__table-block">
        <div class="club-info__table-block-inner">
          <h5 class="club-into__table-title">
            {{ "CommonText.Details" | translate }}
          </h5>
          <table class="table table-varient1">
            <tbody>
              <tr *ngIf="club.phone">
                <th>
                  <span class="table__th-inner">
                    <img src="../../../assets/images/telephone.svg" />
                    {{ "ClubDetails.Phone" | translate }}
                  </span>
                </th>
                <td>
                  {{ club.phone }}
                </td>
              </tr>
              <tr *ngIf="club.fax">
                <th>
                  <span class="table__th-inner">
                    <img src="../../../assets/images/fax.svg" />
                    {{ "ClubDetails.Fax" | translate }}
                  </span>
                </th>
                <td>
                  {{ club.fax }}
                </td>
              </tr>
              <tr *ngIf="club.email">
                <th>
                  <span class="table__th-inner">
                    <img src="../../../assets/images/mail.svg" />
                    {{ "ClubDetails.Email" | translate }}
                  </span>
                </th>
                <td>
                  {{ club.email }}
                </td>
              </tr>
              <tr *ngIf="club.address">
                <th>
                  <span class="table__th-inner">
                    <img src="../../../assets/images/location.svg" />
                    {{ "ClubDetails.Address" | translate }}
                  </span>
                </th>
                <td>
                  {{ club.address }}
                </td>
              </tr>
              <tr *ngIf="club.website">
                <th>
                  <span class="table__th-inner">
                    <img src="../../../assets/images/internet.svg" />
                    {{ "ClubDetails.Website" | translate }}
                  </span>
                </th>
                <td>
                  {{ club.website }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section>
      <mat-tab-group class="mat-tab-secondary" mat-align-tabs="center">
        <mat-tab label="{{ 'ClubDetails.Riders' | translate }}">
          <div class="icon-card-list profile-card-minimal__list">
            <a
              class="profile-card-minimal"
              routerLink="/national-teams/riders/{{ rider.id }}"
              *ngFor="let rider of clubDetailsData.riders"
            >
              <img
                [src]="environment.API_URL + rider.img_url"
                class="profile-card-minimal__image"
                alt="{{ rider.full_name }}"
              />
              <h4 class="h6 profile-card-minimal__title">{{ rider.full_name }}</h4>
            </a>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'ClubDetails.Events' | translate }}">
          
          <ul class="card-list">
            <li 
              class="card"
              *ngFor="let event of clubDetailsData.events"
            >
              <a 
                (click)="loadEventDetails(event.id)"
              >
                <div class="card__image-wrap" data-src-href="#">
                  <img
                    class="card__image"
                    [src]="environment.API_URL + event.img_url"
                  />
                </div>
                <div class="card__body">
                  <div class="card__body-tag-wrap">
                    <span class="card__date">{{ event.date  | date: 'mediumDate' }}</span>
                    <span class="category category_sm category_secondary">{{ event.state | titlecase }}</span>
                  </div>
                  <span class="category">{{ event.race_type[0].race_type[1] | titlecase }}</span>
                  <h4 class="h6 card__title">
                    {{ event.event | titlecase }}
                  </h4>
                  <div *ngIf="event.categories">
                    <h6>Categories: </h6>
                    <ul 
                      class="card__description"
                    >
                    <li *ngFor="let category of event.categories">
                      {{ category.code }}
                    </li>
                  </ul>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </mat-tab>
        <mat-tab label="{{ 'ClubDetails.ClubAdmins' | translate }}">
          <table class="table table_default table-hover table-striped">
            <thead>
              <tr>
                <th scope="col">{{ "ClubDetails.Name" | translate }}</th>
                <th scope="col">
                  {{ "ClubDetails.SpecificationType" | translate }}
                </th>
                <th scope="col">{{ "ClubDetails.WorkPhone" | translate }}</th>
                <th scope="col">{{ "ClubDetails.Email" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container 
                *ngFor="let superVisor of clubDetailsData['club supervisors']"
              >
              <tr *ngIf="superVisor.name">
                <td>{{ superVisor.name }}</td>
                <td>{{ superVisor.specification }}</td>
                <td>{{ superVisor.work_phone }}</td>
                <td>{{ superVisor.email }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </mat-tab>
        <mat-tab label="{{ 'ClubDetails.Ranking' | translate }}">
          <table class="table table_default table-hover table-striped">
            <thead>
              <tr>
                <th scope="col">{{ "ClubDetails.Category" | translate }}</th>
                <th scope="col">{{ "ClubDetails.TotalPoints" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ranking of clubDetailsData.standing_ranking">
                <td>{{ ranking.category[0].code }}</td>
                <td>{{ ranking.total_points }}</td>
              </tr>
            </tbody>
          </table>
        </mat-tab>
      </mat-tab-group>
    </section>
  </section>
</div>

<div></div>

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {IBoardManagement} from "../model/boardManagement.model";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ManagementBaordService {


  firstLevelBoardManagementData = new BehaviorSubject<IBoardManagement[]>([]);
  secondBoardManagementData = new BehaviorSubject<IBoardManagement[]>([]);
  thirdBoardManagementData = new BehaviorSubject<IBoardManagement[]>([]);

  constructor(private http: HttpClient) {
  }

  get firstLevelBoardManagementData$(): Observable<IBoardManagement[]> {
    return this.firstLevelBoardManagementData.asObservable();
  }

  get secondBoardManagementData$(): Observable<IBoardManagement[]> {
    return this.secondBoardManagementData.asObservable();
  }

  get thirdBoardManagementData$(): Observable<IBoardManagement[]> {
    return this.thirdBoardManagementData.asObservable();
  }

  loadManagementBoardData() {
    this.http.get(`${environment.API_URL}/boarders`)
      .subscribe(((response: any) => {
        const dataModel = response.data as IBoardManagement[];
        const firstLevel: IBoardManagement[] = [];
        const secondLevel: IBoardManagement[] = [];
        const thirdLevel: IBoardManagement[] = [];
        dataModel.forEach(item => {
          if (item.level === '1') {
            firstLevel.push(item);
          }
          if (item.level === '2') {
            secondLevel.push(item);
          }
          if (item.level === '3') {
            thirdLevel.push(item);
          }
        });
        //console.log('boarders are ', dataModel);
        this.firstLevelBoardManagementData.next(firstLevel);
        this.secondBoardManagementData.next(secondLevel);
        this.thirdBoardManagementData.next(thirdLevel);
      }));
  }
}

<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{
        "Footer.ActivityAndCalender.CorusesSemenarsRegistration.Header"
          | translate
      }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>
        {{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.Header"
            | translate
        }}
      </li>
    </ul>
  </div>
  <section class="page-body" *ngIf="seminars && seminars.length > 0">
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="coursesSeminarsFormGroup"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-group">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.EnglishNameLable"
            | translate
            | titlecase
        }}</label>
        <input
          type="text"
          formControlName="englishName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.englishName.errors }"
        />
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.ArabicNameLable"
            | translate
            | titlecase
        }}</label>
        <input
          type="text"
          formControlName="arabicName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.arabicName.errors }"
        />
        <div *ngIf="submitted && f.arabicName.errors" class="invalid-feedback">
          <div *ngIf="f.arabicName.errors.required">
            {{
              "Footer.ActivityAndCalender.CorusesSemenarsRegistration.ArabicNameRequired"
                | translate
                | titlecase
            }}
          </div>
        </div>
      </div>

      <mat-form-field appearance="standard">
        <mat-label>{{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.DateLable"
            | translate
            | titlecase
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="DateFromPicker"
          formControlName="dateOfBirth"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="DateFromPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #DateFromPicker></mat-datepicker>
      </mat-form-field>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.fullName"
            | translate
            | titlecase
        }}</label>
        <input
          type="text"
          formControlName="fullName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }"
        />
        <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
          <div *ngIf="f.fullName.errors.required">
            {{
              "Footer.ActivityAndCalender.CorusesSemenarsRegistration.fullNameRequired"
                | translate
                | titlecase
            }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.emiratesId"
            | translate
            | titlecase
        }}</label>
        <input
          type="text"
          formControlName="emiratesId"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.emiratesId.errors }"
        />
        <div *ngIf="submitted && f.emiratesId.errors" class="invalid-feedback">
          <div *ngIf="f.emiratesId.errors.required">
            {{
              "Footer.ActivityAndCalender.CorusesSemenarsRegistration.emiratesIdRequired"
                | translate
                | titlecase
            }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.mobile"
            | translate
            | titlecase
        }}</label>
        <input
          type="number"
          formControlName="mobile"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
        />
        <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
          <div *ngIf="f.mobile.errors.required">
            {{
              "Footer.ActivityAndCalender.CorusesSemenarsRegistration.mobileRequired"
                | translate
                | titlecase
            }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.phone"
            | translate
            | titlecase
        }}</label>
        <input
          type="number"
          formControlName="phone"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
        />
        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
          <div *ngIf="f.phone.errors.required">
            {{
              "Footer.ActivityAndCalender.CorusesSemenarsRegistration.phoneRequired"
                | translate
                | titlecase
            }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.CorusesSemenarsRegistration.email"
            | translate
            | titlecase
        }}</label>
        <input
          type="email"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">
            {{
              "Footer.ActivityAndCalender.CorusesSemenarsRegistration.emailRequired"
                | translate
                | titlecase
            }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <div>
          <label class="form-label">{{
            "Footer.ActivityAndCalender.CorusesSemenarsRegistration.qualifications"
              | translate
          }}</label>
          <input
            type="file"
            class="form-control"
            formControlName="qualifications"
            (change)="onFileChange($event, 'qualifications')"
          />
        </div>
        <div
          *ngIf="submitted && f.qualifications.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.qualifications.errors.required">
            {{
              "Footer.ActivityAndCalender.CorusesSemenarsRegistration.qualificationsRequired"
                | translate
                | titlecase
            }}
          </div>
        </div>
      </div>

      <div class="form-group registration-form__submit-wrap">
        <button type="submit" class="button button_theme button_md">
          {{
            "Footer.ActivityAndCalender.CorusesSemenarsRegistration.SubmitButtonLable"
              | translate
              | titlecase
          }}
        </button>
      </div>
    </form>
  </section>
</div>

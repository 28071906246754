import { Observable } from 'rxjs';
import { IEventCLubRiderRegistration, ITransferRiderModel, IAdminRegistrationModel } from './../model/registration.model';
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";

import {
  ICLubRiderRegistration,
  IEventJoinRequest,
  IEventRegistrationModel,
  IRiderRegistrationModel,
  ITeamRiderRegistration
} from "../model/registration.model";
import { HttpClient } from "@angular/common/http";
import { OdooRpcServiceProvider } from "./odoo-rpc.service";

@Injectable({
  providedIn: 'root'
})
export class RegistrationsService {

  constructor(private http: HttpClient,
    private rpcService: OdooRpcServiceProvider) {
    this.rpcService.init({
      odoo_server: "https://web.uaecf.ae",
      // http_auth: "username:password" // optional
    });
  }

  registerBulkRidersForClub(data: ICLubRiderRegistration):Observable<any> {
    const requestModel = {
      params: {
        ...data
      }
    };
    console.log('&&&& re-register riders ICLubRiderRegistration are :  &&&& ', requestModel);

    return this.http.post(`${environment.API_URL}/re-register/club-riders`, requestModel);
  }

  registerBulkRidersForEvent(data: IEventCLubRiderRegistration): Observable<any> {
    console.log('entry_list request are :  &&&& ', data);

    const requestModel = {
      params: {
        ...data
      }
    };
    return this.http.post(`${environment.API_URL}/entry_list`, requestModel);
  }

  registerBulkRidersForTeam(data: ITeamRiderRegistration):Observable<any> {
    const requestModel = {
      params: {
        ...data
      }
    };
   return this.http.post(`${environment.API_URL}/re-register/team-riders`, requestModel);
  }

  registerRider(data: IRiderRegistrationModel,
  ): Observable<any> {

    const requestModel = {
      params: {
        ...data
      }
    };
    console.log('data IRiderRegistrationModel from request', requestModel);

    // of(this.rpcService.sendRequest(`/submitted/request`, requestModel))
    //   .subscribe((response => {
    //     console.log('&&&& response are &&&& ', response);
    //   }));

    // // bug in the API
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Accept': '*/*'
    //   })
    // };
    // this.http.post(`${environment.API_URL}/submitted/request`, requestModel);

    return this.http.post(`${environment.API_URL}/submitted/request`, requestModel);
  }


  registerAdmin(data: IAdminRegistrationModel,
  ): Observable<any> {

    const requestModel = {
      params: {
        ...data
      }
    };
    console.log('data IAdminRegistrationModel from request', requestModel,typeof(requestModel.params.qualifications_ids)) ;

    // of(this.rpcService.sendRequest(`/administrative`, requestModel))
    //   .subscribe((response => {
    //     console.log('&&&& response are &&&& ', response);
    //   }));

    // // bug in the API
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Accept': '*/*'
    //   })
    // };
    // this.http.post(`${environment.API_URL}/administrative`, requestModel);

    return this.http.post(`${environment.API_URL}/administrative`, requestModel);
  }

  transferRider(data: ITransferRiderModel,
  ) {

    const requestModel = {
      params: {
        ...data
      }
    };
    console.log('data ITransferRiderModel from request', requestModel);

    // of(this.rpcService.sendRequest(`/submitted/request`, requestModel))
    //   .subscribe((response => {
    //     console.log('&&&& response are &&&& ', response);
    //   }));

    // // bug in the API
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Accept': '*/*'
    //   })
    // };
    return this.http.post(`${environment.API_URL}/rider-transfer`, requestModel)
    // return this.http.post(`${environment.API_URL}/submitted/request`, requestModel);
  }

  registerEvent(data: IEventRegistrationModel, callback) {
    // bug in the API
    const requestModel = {
      params: {
        ...data
      }
    };
    this.http.post(`${environment.API_URL}/event/register/request`, requestModel).subscribe(response => {
      console.log('response are ', response);
      if(callback)
        callback();
    }, error => console.log("Error: ", error));
  }

  eventJoinRequest(data: IEventJoinRequest): Observable<any> {
    // bug in the API


    const requestModel = {
      params: {
        ...data
      }
    };
    console.log("eventJoinRequest data", data);

    return this.http.post(`${environment.API_URL}/registration-event`, requestModel);
  }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IEventJoinRequest } from "../../model/registration.model";
import { HostUAECFEventService } from "../../services/host-uaecf-event.service";
import { RegistrationsService } from "../../services/registrations.service";
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TermsPolicyComponent } from 'src/app/components/terms-policy/terms-policy.component';
import * as localforage from 'localforage';


@Component({
  selector: 'app-administrative',
  templateUrl: './administrative.component.html',
  styleUrls: ['./administrative.component.css']
})
export class AdministrativeComponent implements OnInit {
  environment = environment;
  requestToJoinEventForm: FormGroup;
  submitted: boolean;

  genders = ['male', 'female'];

  registerType: number;
  routeDataSubscription: any;
  eventId: number;
  eventDate: string;
  price: number;
  agreedTerms: boolean;
  clubRidersLicenses: string[];
  riderId: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public api: HostUAECFEventService,
    public dialog: MatDialog,
    private registrationApi: RegistrationsService
  ) {
    this.entryByNameFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.requestToJoinEventForm.controls;
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.eventId = +data.get('id');
      this.eventDate = data.get('date').toString();
      this.price = Number(+data.get('price'));

      this.loadingInitializationData();
    });

  }

  entryByNameFormInitialization() {
    this.requestToJoinEventForm = this.fb.group({
      federationId: ['', ],
      fullName: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
      uci: ['', [Validators.required]],
    });
  }

  getFormValidationErrors(form: FormGroup) {

    const result = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    //console.log('result is : ', result);
    // return result;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.requestToJoinEventForm.invalid) {
      this.getFormValidationErrors(this.requestToJoinEventForm);
      return;
    }


    const formData = {
      register_rider_id: this.eventId,
      // event_name: this.eventId,
      // event_date: this.eventDate.toString(),
      federation_id: this.riderId ,
      full_name: this.requestToJoinEventForm.get('fullName').value.toString(),
      nationality: this.requestToJoinEventForm.get('nationality').value.toString(),
      phone: this.requestToJoinEventForm.get('phone').value.toString(),
      gender: this.requestToJoinEventForm.get('gender').value.toString(),
      date_birth: this.formatDate(this.requestToJoinEventForm.get('dateOfBirth').value),
      age: this.getAge(this.requestToJoinEventForm.get('dateOfBirth').value.toString()),
      home_address: this.requestToJoinEventForm.get('address').value.toString(),
      uci_id: this.requestToJoinEventForm.get('uci').value.toString(),

    } as IEventJoinRequest;
    // last step
    this.registrationApi.eventJoinRequest(formData).subscribe(async data => {
      //console.log("freec rider register res", data);
      //console.log({ data });
      if (data.result.success && this.price > 0) {
        const reference = data.result.payment_resopnse.order.ref;
        const paymentUrl = data.result.payment_resopnse.order.url;

        await localforage.setItem('reference', reference, function (err) {
          // if err is non-null, we got an error
          // localforage.getItem('key', function (err, value) {
          //   // if err is non-null, we got an error. otherwise, value is the value
          // });
        });
        window.location.href = paymentUrl;
      } else if (data.result.success) {
        //console.log("ssuccess toaster");
        window.location.href = "/";
      }

    });
  }
  formatDate(date): string {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
}
  getAge(dateString: any): string {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }

  onReset(): void {
    this.submitted = false;
    this.requestToJoinEventForm.reset();
  }


  private loadingInitializationData() {

    this.route.paramMap.subscribe(data => {
      this.registerType = +data.get('registerType');
      //console.log(this.registerType);

    });
    this.api.loadClubTeamCategory();
    this.api.loadNationalities();
    this.api.loadRiders();
    this.api.riders$.subscribe((data) => {
      //console.log({ data });
      this.clubRidersLicenses = data.map(rider => rider.federation_id);

    });
  }
  ChangeRiderLicense(e, i: number) {
    const riderLicenseNumber = this.requestToJoinEventForm.get('federationId').value;
    let ridersData;

    ridersData = this.api.ridersDetails$;


    ridersData.subscribe(data => {

      const rider = data.find(item => item.federation_id == riderLicenseNumber);
      //console.log({ riderLicenseNumber }, rider.id, rider);

      if (rider) {
        this.riderId = rider.id;
        this.requestToJoinEventForm.patchValue({
          fullName: rider.full_name,
          uci: rider.uci_id,
          dateOfBirth: rider.date_birth,
          nationality: rider.nationality[0].nationality[0],
          gender: rider.gender,
          phone: rider.phone,
          address: rider.home_address,
          // healthFitness: null
        });
      }
    });
    //console.log('entry by ChangeRiderLicense are', this.requestToJoinEventForm.value);
  }
  changeAgreedTerm() {
    this.agreedTerms = !this.agreedTerms;
    //console.log(this.agreedTerms);

  }
  openDialog(type) {
    //console.log('fromdialog');
    const dialogRef = this.dialog.open(TermsPolicyComponent, {
      width: '750px',
      height: '550px',
      data: { type: type }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);

    });
  }
}

import { TermsPolicyComponent } from 'src/app/components/terms-policy/terms-policy.component';
import { MatDialog } from '@angular/material/dialog';
import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FooterService} from "../../../services/footer.service";
import {IComplaintModel} from "../../../model/footer.models";
import {environment} from '../../../../environments/environment';
import {HostUAECFEventService} from "../../../services/host-uaecf-event.service";
import {EventsService} from "../../../services/events.service";
import {IEventModel} from "../../../model/event.model";
import * as localforage from 'localforage';
import * as Constants from '../../../shared/AppConstant.constant';
@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.css']
})
export class ComplaintsComponent implements OnInit {
  environment = environment;
  complaintForm: FormGroup;
  submitted: boolean;

  complaintTypes = ['race', 'general'];
  complaint: string;
  eventsData: IEventModel[] = [];
  private evenId: number;
  agreedTerms: boolean;
  price = Constants.CompliantPrice;
  attchmentSrc: string;
  tempName: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: FooterService,
              public uaecfServices: HostUAECFEventService,
              public dialog: MatDialog,
              public eventService: EventsService
  ) {
    this.addComplaintFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.complaintForm.controls;
  }

  ngOnInit(): void {
    this.loadingInitializationData();
  }

  addComplaintFormInitialization() {
    this.complaintForm = this.fb.group({
      complaint: ['', [Validators.required]],
      event: [''],
      raceType: [''],
      rider: ['', [Validators.required]],
      complaintType: ['', [Validators.required]],
      attchment: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
    });
  }

  getFormValidationErrors(form: FormGroup) {

    const result = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    // console.log('result is : ', result);
    // return result;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.complaintForm.invalid) {
      this.getFormValidationErrors(this.complaintForm);
      return;
    }
    this.uaecfServices.riders$.subscribe(async data => {
      const  id = this.complaintForm.get('rider').value;
      this.tempName =  await data.find(item => item.id == id).full_name;
      // console.log(data.find(item => item.id == id).full_name, this.tempName);
      const formData = {
        complaint: this.complaintForm.get('complaint').value.toString(),
        event: this.complaintForm.get('raceType').value.toString(),
        name: this.tempName,
        rider: this.complaintForm.get('rider').value.toString(),
        complain_type: this.complaintForm.get('complaintType').value.toString(),
        attchment: this.getFormField('attchment').get('fileSource').value.toString(),


      } as IComplaintModel;
      // console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
      // console.log('entry by name data are', formData);

      // last step
      this.api.addComplaint(formData).subscribe(async (data) => {
        // console.log({ data });
        if (data.result.success &&  this.price > 0) {
          const reference = data.result.payment_resopnse.order.ref;
          const paymentUrl = data.result.payment_resopnse.order.url;

          await localforage.setItem('reference', reference, function (err) {
            // if err is non-null, we got an error
            // localforage.getItem('key', function (err, value) {
            //   // if err is non-null, we got an error. otherwise, value is the value
            // });
          });
           window.location.href = paymentUrl;
        } else if (data.result.success ) {
          // console.log("ssuccess toaster");
          window.location.href = "/";
        }

      });

    });
    //  console.log(this.tempName);


  }
  getName(id: any): string {
     this.tempName;
    this.uaecfServices.riders$.subscribe(async data => {

      this.tempName =  await data.find(item => item.id == id).full_name;
      // console.log(data.find(item => item.id == id).full_name, this.tempName);

    });
    return this.tempName;
  }

  onReset(): void {
    this.submitted = false;
    this.complaintForm.reset();
  }

  changeComplaintType() {
    this.complaint = this.complaintForm.get('complaintType').value;
    if (this.complaint !== 'general') {
      this.complaintForm.patchValue({
        fees: '',
        event: ''
      });
    }

  }

  private loadingInitializationData() {

    this.uaecfServices.loadRaceType();
    this.uaecfServices.loadRiders();
    this.eventService.loadEventData().subscribe((response: any) => {
      this.eventsData = response.data as IEventModel[];
      // console.log(response);

    });
  }
  changeAgreedTerm() {
    this.agreedTerms = !this.agreedTerms;
    // console.log(this.agreedTerms);

  }
  calculatePrice() {
    return this.price;
  }

  openDialog(type) {
    // console.log('fromdialog');
    const dialogRef = this.dialog.open(TermsPolicyComponent, {
      width: '750px',
      height: '550px',
      data: { type: type }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);

    });
  }
  getFormField(name) {
    return this.complaintForm.get(name) as FormGroup;

  }
  onFileChange(event, fileType: string) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      // console.log('file extensions', reader);
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {

        if (fileType == 'attchment') {
          this.attchmentSrc = reader.result as string;
          this.getFormField('attchment').patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
      };
    }
  }
}

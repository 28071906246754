<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "RegistrationComponent.EventRegistration" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "CommonText.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "RegistrationComponent.EventRegistration" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="eventRegistrationForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-group">
        <div>
          <label class="form-label">{{ "Event.EventImage" | translate }}</label>
          <input
            type="file"
            class="form-control"
            (change)="onFileChange($event, 'image')"
            #imageFileInput
          />
        </div>
        <img
          [src]="imageSrc"
          *ngIf="imageSrc"
          style="height: 150px; width: 150px; object-fit: contain"
        />
      </div>
      <div class="form-group">
        <label class="form-label">{{ "Event.EventName" | translate }}</label>
        <input
          type="text"
          formControlName="eventName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.eventName.errors }"
        />
        <div *ngIf="submitted && f.eventName.errors" class="invalid-feedback">
          <div *ngIf="f.eventName.errors.required">
            {{ "Event.EventNameRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Event.ChooseRaceDate" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="raceDatePicker"
            formControlName="raceDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="raceDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #raceDatePicker></mat-datepicker>
        </mat-form-field>
        <div *ngIf="submitted && f.raceDate.errors" class="invalid-feedback">
          <div *ngIf="f.raceDate.errors.required">
            {{ "Event.RaceDateRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group visible-md"></div>
      <div class="form-group">
        <label class="form-label">{{ "Event.CompanyName" | translate }}</label>
        <input
          type="text"
          formControlName="companyName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }"
        />
        <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
          <div *ngIf="f.companyName.errors.required">
            {{ "Event.CompanyNameRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">
          {{ "Event.CompanyPhoneNumber" | translate }}
        </label>
        <input
          type="text"
          formControlName="companyPhone"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.companyPhone.errors }"
        />
        <div
          *ngIf="submitted && f.companyPhone.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.companyPhone.errors.required">
            {{ "Event.CompanyPhoneNumberRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "Event.CompanyAddress" | translate
        }}</label>
        <input
          type="text"
          formControlName="companyAddress"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.companyAddress.errors }"
        />
        <div
          *ngIf="submitted && f.companyAddress.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.companyAddress.errors.required">
            {{ "Event.CompanyAddressRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">
          {{ "Event.CompanyEmail" | translate }}
        </label>
        <input
          type="text"
          formControlName="companyEmail"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.companyEmail.errors }"
        />
        <div
          *ngIf="submitted && f.companyEmail.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.companyEmail.errors.required">
            {{ "Event.CompanyEmailRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">
          {{ "EventDetails.CompanyWebsiteLable" | translate }}
        </label>
        <input
          type="text"
          formControlName="companyWebsite"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.companyWebsite.errors }"
        />
        <div
          *ngIf="submitted && f.companyWebsite.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.companyWebsite.errors.required">
            {{ "Event.companyWebsiteRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form registration-form form__sub-group">
        <h5 class="form__sub-group-title">
          {{ "Event.EventManagerInformation" | translate }}
        </h5>

        <div class="form-group">
          <label class="form-label">{{ "Event.FullName" | translate }}</label>
          <input
            type="text"
            formControlName="fullName"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }"
          />
          <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
            <div *ngIf="f.fullName.errors.required">
              {{ "Event.FullNameRequired" | translate }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label"> {{ "Event.Position" | translate }}</label>
          <input
            type="text"
            formControlName="position"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.position.errors }"
          />
          <div *ngIf="submitted && f.position.errors" class="invalid-feedback">
            <div *ngIf="f.position.errors.required">
              {{ "Event.PositionRequired" | translate }}
            </div>
          </div>
        </div>
        <div
          class="form-group"
          *ngIf="api.nationalities$ | async as nationalities"
        >
          <label class="form-label">{{
            "Event.Nationality" | translate
          }}</label>
          <select class="form-select" formControlName="nationality">
            <option value="" disabled>
              {{ "Event.ChooseNationality" | translate }}
            </option>
            <option
              *ngFor="let nationality of nationalities"
              [ngValue]="nationality.id"
            >
              {{ nationality.name }}
            </option>
          </select>
          <!-- error block -->
          <div
            *ngIf="submitted && f.nationality.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.nationality.errors.required">
              {{ "Event.NationalityRequired" | translate }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label"> {{ "Event.PhoneNo" | translate }}</label>
          <input
            type="text"
            formControlName="managerPhone"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.managerPhone.errors }"
          />
          <div
            *ngIf="submitted && f.managerPhone.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.managerPhone.errors.required">
              {{ "Event.ManagerPhoneRequired" | translate }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label"> {{ "Event.Email" | translate }}</label>
          <input
            type="text"
            formControlName="managerEmail"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.managerEmail.errors }"
          />
          <div
            *ngIf="submitted && f.managerEmail.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.managerEmail.errors.required">
              {{ "Event.ManagerEmailRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Event.StartingTime" | translate }}</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="startingTimePicker"
            placeholder="{{ 'Event.StartingTime' | translate }}"
            formControlName="startingTime"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startingTimePicker"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #startingTimePicker
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [color]="color"
            [enableMeridian]="enableMeridian"
          >
          </ngx-mat-datetime-picker>
        </mat-form-field>

        <div
          *ngIf="submitted && f.startingTime.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.startingTime.errors.required">
            Starting Time is Required
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Event.FinishingTime" | translate }}</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="finishingTimePicker"
            formControlName="finishingTime"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="finishingTimePicker"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #finishingTimePicker
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [color]="color"
            [enableMeridian]="enableMeridian"
          >
          </ngx-mat-datetime-picker>
        </mat-form-field>

        <div
          *ngIf="submitted && f.finishingTime.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.finishingTime.errors.required">
            {{ "Event.FinishingTimeRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Event.RequiredDate" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="requiredDatePicker"
            formControlName="requiredDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="requiredDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #requiredDatePicker></mat-datepicker>
        </mat-form-field>

        <div
          *ngIf="submitted && f.requiredDate.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.requiredDate.errors.required">
            {{ "Event.RequiredDateRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="api.raceTypes$ | async as raceTypes">
        <select
          class="form-select"
          (change)="changeRaceType($event)"
          formControlName="raceType"
        >
          <option value="" disabled>
            {{ "Event.ChooseRaceType" | translate }}
          </option>
          <option *ngFor="let raceType of raceTypes" [ngValue]="raceType.id">
            {{ raceType.name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.raceType.errors" class="invalid-feedback">
          <div *ngIf="f.raceType.errors.required">
            {{ "Event.ChooseRaceTypeRequired" | translate }}
          </div>
        </div>
      </div>

      <div
        class="form-group"
        *ngIf="api.clubTeamCategories$ | async as clubTeamCategories"
      >
        <label class="form-label">{{ "Event.Category" | translate }}</label>
        <select
          class="form-select"
          (change)="changeCategory($event)"
          formControlName="category"
        >
          <option value="" disabled>
            {{ "Event.ChooseCategory" | translate }}
          </option>
          <option
            *ngFor="let category of clubTeamCategories"
            [ngValue]="category.id"
          >
            {{ category.code }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
          <div *ngIf="f.category.errors.required">
            {{ "Event.CategoryRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group">
          <label class="form-label">
            {{ "Event.StartingPoint" | translate }}</label
          >
          <input
            type="text"
            formControlName="startingPoint"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.startingPoint.errors }"
          />
          <div
            *ngIf="submitted && f.startingPoint.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.startingPoint.errors.required">
              {{ "Event.StartingPointRequired" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">
          {{ "Event.FinishingPoint" | translate }}</label
        >
        <input
          type="text"
          formControlName="finishingPoint"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.finishingPoint.errors }"
        />
        <div
          *ngIf="submitted && f.finishingPoint.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.finishingPoint.errors.required">
            {{ "Event.FinishingPointRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">
          {{ "Event.TotalDistance" | translate }}</label
        >
        <input
          type="number"
          formControlName="totalDistance"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.totalDistance.errors }"
        />
        <div
          *ngIf="submitted && f.totalDistance.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.totalDistance.errors.required">
            {{ "Event.TotalDistanceRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">
          {{ "Event.ParticipantFees" | translate }}
        </label>
        <input
          type="number"
          formControlName="participantsFees"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.finishingPoint.errors }"
        />
        <div
          *ngIf="submitted && f.participantsFees.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.participantsFees.errors.required">
            {{ "Event.ParticipantFeesRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">
          {{ "Event.NumberOfParticipants" | translate }}</label
        >
        <input
          type="number"
          formControlName="NoOfParticipants"
          class="form-control"
          [ngClass]="{
            'is-invalid': submitted && f.NoOfParticipants.errors
          }"
        />
        <div
          *ngIf="submitted && f.NoOfParticipants.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.NoOfParticipants.errors.required">
            {{ "Event.NumberOfParticipantsRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label"> {{ "Event.Location" | translate }}</label>
        <input
          type="text"
          formControlName="location"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.location.errors }"
        />
        <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
          <div *ngIf="f.location.errors.required">
            {{ "Event.LocationRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Event.RegistrationOpenOn" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="registrationOpenPicker"
            formControlName="registrationOpen"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="registrationOpenPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #registrationOpenPicker></mat-datepicker>
        </mat-form-field>

        <div
          *ngIf="submitted && f.registrationOpen.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.registrationOpen.errors.required">
            {{ "Event.RegistrationOpenDateRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Event.RegistrationCloseOn" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="registrationClosePicker"
            formControlName="registrationClose"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="registrationClosePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #registrationClosePicker></mat-datepicker>
        </mat-form-field>
        <div
          *ngIf="submitted && f.registrationClose.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.registrationClose.errors.required">
            {{ "Event.RegistrationCloseDateRequired" | translate }}
          </div>
        </div>
      </div>

      <div
        class="form-group"
        *ngIf="api.nationalities$ | async as nationalities"
      >
        <label class="form-label">{{ "Event.Nationality" | translate }}</label>
        <select class="form-select" formControlName="nationality">
          <option value="" disabled>
            {{ "Event.ChooseNationality" | translate }}
          </option>
          <option
            *ngFor="let nationality of nationalities"
            [ngValue]="nationality.id"
          >
            {{ nationality.name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.nationality.errors" class="invalid-feedback">
          <div *ngIf="f.nationality.errors.required">
            {{ "Event.NationalityRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label id="sport-council-permission-label">{{
          "Event.GetSportCouncilPermission" | translate
        }}</label>
        <mat-radio-group
          aria-labelledby="sport-council-permission-label"
          class="example-radio-group"
          formControlName="getSportCouncilPermission"
        >
          <mat-radio-button value="yes"
            >{{ "Event.Yes" | translate }}
          </mat-radio-button>
          <mat-radio-button value="no"
            >{{ "Event.No" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label id="police-permission-label">{{
          "Event.GetPolicePermission" | translate
        }}</label>
        <mat-radio-group
          aria-labelledby="police-permission-label"
          class="example-radio-group"
          formControlName="getPolicePermission"
        >
          <mat-radio-button value="yes"
            >{{ "Event.Yes" | translate }}
          </mat-radio-button>
          <mat-radio-button value="no"
            >{{ "Event.No" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-group">
        <label id="sport-tourism-permission-label">{{
          "Event.GetTourismCouncilPermission" | translate
        }}</label>
        <mat-radio-group
          aria-labelledby="sport-tourism-permission-label"
          class="example-radio-group"
          formControlName="getTourismPermission"
        >
          <mat-radio-button value="yes"
            >{{ "Event.Yes" | translate }}
          </mat-radio-button>
          <mat-radio-button value="no"
            >{{ "Event.No" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label id="ambulance-provided-label"
          >{{ "Event.AmbulanceProvided" | translate }}
        </label>
        <mat-radio-group
          aria-labelledby="ambulance-provided-label"
          class="example-radio-group"
          formControlName="ambulanceProvided"
        >
          <mat-radio-button value="yes"
            >{{ "Event.Yes" | translate }}
          </mat-radio-button>
          <mat-radio-button value="no"
            >{{ "Event.No" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label id="event-insurance-label">{{
          "Event.EventInsurance" | translate
        }}</label>
        <mat-radio-group
          aria-labelledby="event-insurance-label"
          class="example-radio-group"
          formControlName="eventInsurance"
        >
          <mat-radio-button value="yes"
            >{{ "Event.Yes" | translate }}
          </mat-radio-button>
          <mat-radio-button value="no"
            >{{ "Event.No" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-group visible-md"></div>
      <div class="form-group" formGroupName="organizer">
        <div class="form-group">
          <label class="form-label">{{
            "Event.OrganizerEmiratesID" | translate
          }}</label>
          <input
            type="file"
            class="form-control"
            (change)="onFileChange($event, 'organizer')"
            #organizerFileInput
          />
        </div>
      </div>

      <div class="form-group" formGroupName="tradeLicense">
        <div class="form-group">
          <label class="form-label">{{
            "Event.TradeLicense" | translate
          }}</label>
          <input
            type="file"
            class="form-control"
            (change)="onFileChange($event, 'tradeLicense')"
            #tradeLicenseFileInput
          />
        </div>
      </div>
      <div class="form-group" formGroupName="routePlan">
        <div class="form-group">
          <label class="form-label">{{ "Event.RoutePlan" | translate }}</label>
          <input
            type="file"
            class="form-control"
            (change)="onFileChange($event, 'routePlan')"
            #routePlanFileInput
          />
        </div>
      </div>
      <div class="form-group registration-form__text-area">
        <label class="form-label">{{ "Event.Introduction" | translate }}</label>
        <textarea
          class="form-control"
          rows="6"
          formControlName="introduction"
          [ngClass]="{ 'is-invalid': submitted && f.introduction.errors }"
        ></textarea>
        <div
          *ngIf="submitted && f.introduction.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.introduction.errors.required">
            {{ "Event.IntroductionRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group registration-form__submit-wrap">
        <button type="submit" class="button button_theme button_md">
          {{ "Event.Submit" | translate }}
        </button>
      </div>
    </form>
  </section>
</div>

import { RequestlangInterceptor } from './internationalization/requestlang.interceptor';
import { AuthService } from './auth/auth.service';
import { NgImageSliderModule } from 'ng-image-slider';
import { MorenewsComponent } from './components/morenews/morenews.component';
import {NewsServices} from "./services/news-service";
import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {MyMaterialModule} from "./material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {CommonModule, DatePipe} from "@angular/common";
import {NgxPaginationModule} from 'ngx-pagination';
// import { FlexLayoutModule } from '@angular/flex-layout';
import {AppComponent} from "./app.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RegistrationComponentComponent} from "./registration-component/registration-component.component";
import {ListComponentComponent} from "./list-component/list-component.component";
import {UaecfComponent} from "./uaecf/uaecf.component";
import {AboutComponent} from "./uaecf/about/about.component";
import {FederationsComponent} from "./uaecf/federations/federations.component";
import {PartnersComponent} from "./uaecf/partners/partners.component";
import {ManagementBoardComponent} from "./uaecf/management-board/management-board.component";
import {CommisionsComponent} from "./uaecf/commisions/commisions.component";
import {ConstitutionComponent} from "./uaecf/constitution/constitution.component";
import {UciComponent} from "./uaecf/uci/uci.component";
import {HallOfFameComponent} from "./uaecf/hall-of-fame/hall-of-fame.component";
import {InternationOrganizationComponent} from "./uaecf/internation-organization/internation-organization.component";
import {HostUaecfEventComponent} from "./uaecf/hostUAECF_Event/host-uaecf-event/host-uaecf-event.component";
import {CalendarComponent} from "./calendar/calendar.component";
import {RoadComponent} from "./road/road.component";
import {NewsComponent} from "./road/news/news.component";
import {EventsComponent} from "./road/events/events.component";
import {PhotosComponent} from "./road/photos/photos.component";
import {VideosComponent} from "./road/videos/videos.component";
import {ResultComponent} from "./road/result/result.component";
import {MtbComponent} from "./mtb/mtb.component";
import {TourComponent} from "./tour/tour.component";
import {TrackComponent} from "./track/track.component";
import {RegistrationComponent} from "./registration/registration.component";
import {RidersComponent} from "./registration/riders/riders.component";
import {EventsRegisterComponent} from "./registration/events/events.component";
import {AdministrativeComponent} from "./registration/administrative/administrative.component";
import {HomeComponent} from "./home/home.component";
// import {SigninComponent} from "./components/signin/signin.component";
// import {RegisterComponent} from "./components/register/register.component";
import {NewsdetailsComponent} from "./newsdetails/newsdetails.component";
import {EventdetailsComponent} from "./eventdetails/eventdetails.component";
import {PhotosdetailsComponent} from "./photosdetails/photosdetails.component";
import {VideodetailsComponent} from "./videodetails/videodetails.component";
import {FootercalendarComponent} from "./footer/footercalendar/footercalendar.component";
import {FooterregistrationComponent} from "./footer/footerregistration/footerregistration.component";
import {GeneralcomplaintsComponent} from "./footer/generalcomplaints/generalcomplaints.component";
import {RacecomplaintsComponent} from "./footer/racecomplaints/racecomplaints.component";
import {CommitiesrefernceComponent} from "./footer/commitiesrefernce/commitiesrefernce.component";
import {CommitieswomenComponent} from "./footer/commitieswomen/commitieswomen.component";
import {CommitiesteamsComponent} from "./footer/commitiesteams/commitiesteams.component";
import {CommitiestechnicalsComponent} from "./footer/commitiestechnicals/commitiestechnicals.component";
import {CommitiesmarketingComponent} from "./footer/commitiesmarketing/commitiesmarketing.component";
import {TrainingPermissionComponent} from "./footer/training-permission/training-permission.component";
import {ContactusComponent} from "./footer/contactus/contactus.component";
import {HomeService} from "./services/home.service";
import {AppRoutingModule} from "./app-routing/app-routing.module";
import {SharedModule} from "./shared/shared.module";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {PhotosService} from "./services/photos.service";
import {EventsService} from "./services/events.service";
import {NationalTeamComponent} from './uaecf/nationalTeam/national-team/national-team.component';
import {NationalTeamService} from "./services/national-team.service";
import {NationalTeamDetailsComponent} from './uaecf/nationalTeam/national-team-details/national-team-details.component';
import {TeamsClubsComponent} from './teamsClubs/teams-clubs/teams-clubs.component';
import {HostUAECFEventDetailsComponent} from './uaecf/hostUAECF_Event/host-uaecfevent-details/host-uaecfevent-details.component';
import {HostUAECFEventService} from "./services/host-uaecf-event.service";
import {TeamsClubsDetailsComponent} from './teamsClubs/teams-clubs-details/teams-clubs-details.component';
import {ClubsDetailsComponent} from './teamsClubs/clubs-details/clubs-details.component';
import {EntryByNameComponent} from './uaecf/hostUAECF_Event/forms/entry-by-name/entry-by-name.component';
import {DelegationComponent} from './uaecf/hostUAECF_Event/forms/delegation/delegation.component';
import {AccommodationComponent} from './uaecf/hostUAECF_Event/forms/accommodation/accommodation.component';
import {VisaApplicationComponent} from './uaecf/hostUAECF_Event/forms/visa-application/visa-application.component';
import {CalenderComponent} from './footer/ActivityAndCalender/calender/calender.component';
import {ActivityRegistrationComponent} from './footer/ActivityAndCalender/activity-registration/activity-registration.component';
import {ComplaintsComponent} from './footer/ActivityAndCalender/complaints/complaints.component';
import {RaceTypeRankingsComponent} from './ranking/race-type-rankings/race-type-rankings.component';
import {RaceTypeRankingsDetailsComponent} from './ranking/race-type-rankings-details/race-type-rankings-details.component';
import {ClubRiderRegistrationComponent} from './registration/club-rider-registration/club-rider-registration.component';
import {ClubTeamRiderRegistrationComponent} from './registration/club-team-rider-registration/club-team-rider-registration.component';
import {TeamRiderRegistrationComponent} from './registration/team-rider-registration/team-rider-registration.component';
import {CorusesSemenarsRegistrationComponent} from './footer/ActivityAndCalender/coruses-semenars-registration/coruses-semenars-registration.component';
import {OdooRpcServiceProvider} from "./services/odoo-rpc.service";
import {RiderDetailsComponent} from './uaecf/nationalTeam/rider-details/rider-details.component';
import {TeamOfficialDetailsComponent} from './uaecf/nationalTeam/team-official-details/team-official-details.component';
import {ClubOfficialDetailsComponent} from './uaecf/nationalTeam/club-official-details/club-official-details.component';
import {FlightScheduleComponent} from "./uaecf/hostUAECF_Event/forms/flight-schedule/flight-schedule.component";
import { EventClubRegisterComponent } from './registration/event-club-register/event-club-register.component';
import { TermsPolicyComponent } from "./components/terms-policy/terms-policy.component";
import { PaysuccessComponent } from "./payment/paysuccess/paysuccess.component";
import { SpinnerInterceptor } from './loader/SpinnerInterceptor';
import { AdminRegisterComponent } from './registration/admin-register/admin-register.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PayfailedComponent } from './payment/payfailed/payfailed.component';
import { PaycanceledComponent } from './payment/paycanceled/paycanceled.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommitteesComponent } from './components/committees/committees.component';
import { SpinnerOverlayComponent } from './loader/spinner-overlay/spinner-overlay.component';
import { RiderTransferComponent } from './registration/rider-transfer/rider-transfer.component';
import { LoginComponent } from './auth/login/login.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { InternationalizationModule } from './internationalization/internationalization.module';
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {AuthInterceptor} from "./auth/auth.interceptor";
import { ToastrModule } from 'ngx-toastr';
import { ActivityComponent } from './registration/activity/activity.component';
import {CookieService} from "ngx-cookie-service";
import { CatListComponent } from './cat-list/cat-list.component';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery'


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponentComponent,
    ListComponentComponent,
    UaecfComponent,
    AboutComponent,
    FederationsComponent,
    PartnersComponent,
    ManagementBoardComponent,
    CommisionsComponent,
    ConstitutionComponent,
    UciComponent,
    HallOfFameComponent,
    InternationOrganizationComponent,
    HostUaecfEventComponent,
    CalendarComponent,
    RoadComponent,
    NewsComponent,
    EventsComponent,
    PhotosComponent,
    VideosComponent,
    ResultComponent,
    MtbComponent,
    TourComponent,
    TrackComponent,
    RegistrationComponent,
    RidersComponent,
    AdministrativeComponent,
    EventsRegisterComponent,
    HomeComponent,
    // SigninComponent,
    // RegisterComponent,
    NewsdetailsComponent,
    EventdetailsComponent,
    PhotosdetailsComponent,
    VideodetailsComponent,
    FootercalendarComponent,
    FooterregistrationComponent,
    GeneralcomplaintsComponent,
    RacecomplaintsComponent,
    CommitiesrefernceComponent,
    CommitieswomenComponent,
    CommitiesteamsComponent,
    CommitiestechnicalsComponent,
    CommitiesmarketingComponent,
    TrainingPermissionComponent,
    ContactusComponent,
    NationalTeamComponent,
    NationalTeamDetailsComponent,
    TeamsClubsComponent,
    HostUAECFEventDetailsComponent,
    TeamsClubsDetailsComponent,
    ClubsDetailsComponent,
    EntryByNameComponent,
    DelegationComponent,
    AccommodationComponent,
    VisaApplicationComponent,
    CalenderComponent,
    ActivityRegistrationComponent,
    ComplaintsComponent,
    RaceTypeRankingsComponent,
    RaceTypeRankingsDetailsComponent,
    ClubRiderRegistrationComponent,
    ClubTeamRiderRegistrationComponent,
    TeamRiderRegistrationComponent,
    CorusesSemenarsRegistrationComponent,
    RiderDetailsComponent,
    TeamOfficialDetailsComponent,
    ClubOfficialDetailsComponent,
    FlightScheduleComponent,
    EventClubRegisterComponent,
    TermsPolicyComponent,
    PaysuccessComponent,
    PaycanceledComponent,
    PayfailedComponent,
    SpinnerOverlayComponent,
    RiderTransferComponent,
    CommitteesComponent,
    AdminRegisterComponent,
    MorenewsComponent,
    SafeHtmlPipe,
    LoginComponent,
    ActivityComponent,
    CatListComponent

  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    OverlayModule,
    BrowserAnimationsModule,
    // FlexLayoutModule,
    MyMaterialModule,
    AppRoutingModule,
    CommonModule ,
    NgImageSliderModule,
    SharedModule,
    PhotoGalleryModule.forRoot({
      defaultOptions: {
        arrowEl: true,
        indexIndicatorSep: '-'
      }
    }),
    ToastrModule.forRoot({
      timeOut: 4000,
      preventDuplicates: true,
      positionClass: 'toast-top-full-width',
      easing:'ease-in',
      progressBar:true
    }),
    InternationalizationModule.forRoot({ locale_id: 'en-us' }), // initiating with default language: en-US
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
SweetAlert2Module.forRoot(),

  ],
  providers: [
    CookieService,
    DatePipe,
    NewsServices,
    PhotosService,
    HomeService,
    EventsService,
    AuthService,
    NationalTeamService,
    HostUAECFEventService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestlangInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    OdooRpcServiceProvider],
  bootstrap: [AppComponent],
  exports: [MatButtonModule, MatToolbarModule]
})
export class AppModule {
}

import { LocalizationService } from './internationalization/localization.service';
import { User } from './auth/UserModel';
import { AuthService } from './auth/auth.service';
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { BackendApiService } from "./backend-api.service";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import * as localforage from 'localforage';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from "@angular/material/snack-bar";
import { NationalTeamService } from "./services/national-team.service";
import { FooterService } from "./services/footer.service";
import { environment } from '../environments/environment';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "ui-angular-material";
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  config = new MatSnackBarConfig();

  currentYear: number = new Date().getFullYear();
  regulations: any;
  primaryLaw: any;
  racingLicense: any;
  user: User;
  language: string;
  dir: string;
  shownMenuId: string;

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private backendService: BackendApiService,
    public nationalTeamService: NationalTeamService,
    private footerService: FooterService,
    private localizationService: LocalizationService,
    public auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
  ) {
    this.renderer2.listen('window', 'click', (e) => {
      // to  check click outside event the nav and submenu

      if (e.target.tagName.toLowerCase() != 'ul' && e.target.tagName.toLowerCase() != 'li') {

        this.shownMenuId = null;
      } else {

      }
    });
    // this.auth.loadCurrentUser();

    // decide what to do when this event is triggered.
    router.events.subscribe(async val => {
      //  console.log("routed", router.url,this.auth.currentUser);
      if (router.url == '/signin') {
        if (JSON.parse(localStorage.getItem('currentUser'))) {
          //  await this.auth.loadCurrentUser();

          this.router.navigate(['/']);
        }
      }
      // console.log( val instanceof NavigationStart);

    });


  }

  get name(): string {
    return this.localizationService.translate('banner.world');
  }

  getNewRiderLink() {
    if (this.auth.currentUserValue.role == 'club') {
      return `registration/registerRider/${this.auth.currentUserValue.clubId}/${this.auth.currentUserValue.role}/${this.auth.currentUserValue.name}`;
    } else {
      return `registration/registerRider/${this.auth.currentUserValue.teamId}/${this.auth.currentUserValue.role}/${this.auth.currentUserValue.name}`;
    }
  }

  async ngOnInit() {
    // Load National Teams
    this.initLang();
    this.loadNationalTeams();
    this.user = JSON.parse(localStorage.getItem('currentUser'));

    // Get Regulations
    this.backendService.regulations().subscribe(
      (response: HttpResponse<any>) => {
        this.regulations = response["data"];
      },
      (error) => {
        this._snackBar.open("Regulations  API", "Error", {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: 5000,
        });
      }
    );

    // Get Primary Law
    this.backendService.primaryLaw().subscribe(
      (response: HttpResponse<any>) => {
        this.primaryLaw = response["data"];
      },
      (error) => {
        this._snackBar.open("Primary Law  API", "Error", {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );

    // Get Primary Law
    this.backendService.racingLicense().subscribe(
      (response: HttpResponse<any>) => {
        this.racingLicense = response["data"];
      },
      (error) => {
        this._snackBar.open("Racing License  API", "Error", {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );
  }
  async initLang() {



    this.language = await localStorage.getItem('language') || 'ar_001';
    this.dir = await localStorage.getItem('dir') || 'rtl';

    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    // console.log({ htmlTag }, htmlTag.getAttribute('dir'), this.dir);
    htmlTag.setAttribute('dir', this.dir);
    htmlTag.setAttribute('lang', this.language);
    this.localizationService.useLanguage(this.language);
    // if (this.dir == "rtl" && this.language != 'ar' && htmlTag.getAttribute('lang')=='en') {

    //   this.localizationService.useLanguage(this.language);
    //   this.changeLang(this.language);
    //   }

    //   if (this.dir == "ltr" && this.language != 'en') {

    //     this.localizationService.useLanguage(this.language);
    //     this.changeLang(this.language);
    //     }
  }
  async changeLang(lang) {
    // console.log("from lang", lang);

    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.setAttribute('dir', lang === "ar_001" ? "rtl" : "ltr");
    htmlTag.setAttribute('lang', lang);
    htmlTag.lang = lang;
    await localStorage.setItem('language', lang);
    await localStorage.setItem('dir', lang === "ar_001" ? "rtl" : "ltr");
    this.localizationService.useLanguage(lang);
    // console.log("await localStorage.getItem('language');");

    // console.log(await localStorage.getItem('language'), lang);

    this.language = lang;
    window.location.reload();

  }
  loadNationalTeams() {
    this.nationalTeamService.loadNationalTeam();
  }

  OpenTechnicalRegulation() {
    this.footerService.loadTechnicalRegulation();
    this.footerService.technicalRegulations$.subscribe(data => {
      if (data.length > 0) {
        window.open(`${environment.API_URL}${data[0].technical_url}`, "_blank");
      }
      // else {
      //   window.open(`${environment.API_URL}`, "_blank");
      // }
    });
  }

  OpenPrimarilylaws() {
    this.footerService.loadPrimarilyLaws();
    this.footerService.primarilyLaws$.subscribe(data => {

      if (data.length > 0) {
        window.open(`${environment.API_URL}${data[0].primary_url}`, "_blank");

      }
    });
  }

  OpenRacingLicense() {
    this.footerService.loadRacingLicense();
    this.footerService.racingLicenses$.subscribe(data => {
      // console.log("racingLicenses", data);

      if (data.length > 0) {
        window.open(`${environment.API_URL}${data[0].racing_license_url}`, "_blank");
      }
    });
  }

  toggleMenu(currentElement: string) {
    this.shownMenuId = currentElement;
  }

  closeDrawer() {
    // console.log("clicked")
  }
}

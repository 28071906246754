<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "Footer.ActivityAndCalender.Complaints.Header" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "Footer.ActivityAndCalender.Complaints.Header" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="complaintForm"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="form-group"
        *ngIf="uaecfServices.raceTypes$ | async as raceTypes"
      >
        <label class="form-label">{{
          "Footer.ActivityAndCalender.Complaints.RaceTypeLable" | translate
        }}</label>
        <select class="form-select" formControlName="raceType">
          <option value="" disabled>
            {{
              "Footer.ActivityAndCalender.Complaints.ChooseRaceTypeLable"
                | translate
            }}
          </option>
          <option *ngFor="let raceType of raceTypes" [ngValue]="raceType.id">
            {{ raceType.name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.raceType.errors" class="invalid-feedback">
          <div *ngIf="f.raceType.errors.required">
            {{
              "Footer.ActivityAndCalender.Complaints.Errors.RaceTypeRequired"
                | translate
            }}
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="uaecfServices.riders$ | async as riders">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.Complaints.RidersLable" | translate
        }}</label>
        <select
          class="form-select"
          (change)="getName(rider.id)"
          formControlName="rider"
        >
          <option value="" disabled>
            {{
              "Footer.ActivityAndCalender.Complaints.ChooseRiderLable"
                | translate
            }}
          </option>
          <option *ngFor="let rider of riders" [ngValue]="rider.id">
            {{ rider.full_name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.rider.errors" class="invalid-feedback">
          <div *ngIf="f.rider.errors.required">
            {{
              "Footer.ActivityAndCalender.Complaints.Errors.RiderRequired"
                | translate
            }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.Complaints.ComplaintTypeLable" | translate
        }}</label>
        <select
          class="form-select"
          formControlName="complaintType"
          (change)="changeComplaintType()"
        >
          <option value="" disabled>
            {{
              "Footer.ActivityAndCalender.Complaints.ChooseCompliantTypeLable"
                | translate
            }}
          </option>
          <option
            *ngFor="let complaintType of complaintTypes"
            [ngValue]="complaintType"
          >
            {{ complaintType }}
          </option>
        </select>
        <!-- error block -->
        <div
          *ngIf="submitted && f.complaintType.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.complaintType.errors.required">
            {{
              "Footer.ActivityAndCalender.Complaints.Errors.ComplaintTypeRequired"
                | translate
            }}
          </div>
        </div>
      </div>
      <div
        class="form-group"
        *ngIf="eventsData.length > 0 && complaint == 'race'"
      >
        <label class="form-label">{{
          "Footer.ActivityAndCalender.Complaints.EventLable" | translate
        }}</label>
        <select class="form-select" formControlName="event">
          <option value="" disabled>
            {{
              "Footer.ActivityAndCalender.Complaints.ChooseEventLable"
                | translate
            }}
          </option>
          <option *ngFor="let event of eventsData" [ngValue]="event.id">
            {{ event.event }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.event.errors" class="invalid-feedback">
          <div *ngIf="f.event.errors.required">
            {{
              "Footer.ActivityAndCalender.Complaints.Errors.EventRequired"
                | translate
            }}
          </div>
        </div>
      </div>
      <div class="form-group" formGroupName="attchment">
        <div class="form-group">
          <label class="form-label">{{
            "Footer.ActivityAndCalender.Complaints.UserAttchmentLable"
              | translate
          }}</label>
          <input
            type="file"
            class="form-control"
            formControlName="file"
            (change)="onFileChange($event, 'attchment')"
          />
        </div>
      </div>
      <div class="form-group registration-form__text-area">
        <label class="form-label">{{
          "Footer.ActivityAndCalender.Complaints.ComplaintLable" | translate
        }}</label>
        <textarea
          class="form-control"
          rows="7"
          formControlName="complaint"
          [ngClass]="{ 'is-invalid': submitted && f.complaint.errors }"
        ></textarea>
        <div *ngIf="submitted && f.complaint.errors" class="invalid-feedback">
          <div *ngIf="f.complaint.errors.required">
            {{
              "Footer.ActivityAndCalender.Complaints.ComplaintRequired"
                | translate
            }}
          </div>
        </div>
      </div>
      <div *ngIf="complaint == 'race'" class="registration-form__terms">
        <h5>
          {{ "Footer.ActivityAndCalender.Complaints.Terms.Notes" | translate }}:
        </h5>
        <div class="registration-form__terms-inner">
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.PaymentConfirmation'
                | translate
            "
          ></p>
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.RefundAndCancellationPolicy'
                | translate
            "
          ></p>
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.ServicePricing'
                | translate
            "
          >
            :<strong
              [innerHTML]="
                calculatePrice() +
                  ' Footer.ActivityAndCalender.Complaints.Terms.Currency'
                  | translate
              "
            ></strong>
          </p>
        </div>
      </div>
      <mat-checkbox
        unchecked="agreedTerms"
        (click)="changeAgreedTerm()"
        class="form-group registration-form__agreement"
      >
        {{ "Footer.ActivityAndCalender.Complaints.IHaveReadAll" | translate }}
        <strong (click)="openDialog(1)"
          >{{
            "Footer.ActivityAndCalender.Complaints.TermsLable" | translate
          }} </strong
        >,<strong (click)="openDialog(2)">
          {{
            "Footer.ActivityAndCalender.Complaints.PolicyLable" | translate
          }}</strong
        >{{ "Footer.ActivityAndCalender.Complaints.AgreeLable" | translate }}
      </mat-checkbox>
      <div class="form-group registration-form__submit-wrap">
        <button
          type="submit"
          class="button button_theme button_md"
          [disabled]="!agreedTerms && complaint == 'race'"
        >
          {{
            "Footer.ActivityAndCalender.Complaints.SubmitButtonLable"
              | translate
          }}
        </button>
      </div>
    </form>
  </section>
</div>

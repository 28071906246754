<div id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "Videos.Videos" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "Videos.Videos" | translate }}</li>
    </ul>
  </div>
  <div class="page-body">
    <!-- <div class="filters filters_floating">
      <select
        class="form-select year-filter"
        [(ngModel)]="selectedYear"
        (change)="filterVideos()"
      >
        <option *ngFor="let year of years">{{ year }}</option>
      </select>
    </div> -->
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Road">
        <div class="tab-body">
          <ul class="media-list" *ngIf="videos.length >= 1">
            <li class="media-list__li" *ngFor="let video of videos">
              <a class="media-card" href={{video.video_url}} target="_blank" *ngIf="video.category_name == 'Road'">
                <div class="media-card__top-wrap">
                  <img
                    class="media-card__media"
                    src={{video.image}}
                  />
                  <img class="media-card__play-btn" src="../../../assets/images/play.png" />
                </div>
                <div class="media-card__text-wrap">
                  <h5 class="h6 media-card__title">{{video.category_name}}</h5>
                </div>
              </a>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
          <!-- <div class="button-wrap">
              <button class="button_custom">{{"CommonText.LoadMore" | translate}}</button>
          </div> -->
        </div>
      </mat-tab>
      <mat-tab label="Track">
        <div class="tab-body">
          <ul class="media-list" *ngIf="videos.length >= 1">
            <li class="media-list__li" *ngFor="let video of videos">
              <a class="media-card" href={{video.video_url}} target="_blank" *ngIf="video.category_name == 'Track'">
                <div class="media-card__top-wrap">
                  <img
                    class="media-card__media"
                    src={{video.image}}
                  />
                  <img class="media-card__play-btn" src="../../../assets/images/play.png" />
                </div>
                <div class="media-card__text-wrap">
                  <h5 class="h6 media-card__title">{{video.category_name}}</h5>
                </div>
              </a>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
          <!-- <div class="button-wrap">
              <button class="button_custom">{{"CommonText.LoadMore" | translate}}</button>
          </div> -->
        </div>
      </mat-tab>
      <mat-tab label="MTB">
        <div class="tab-body">
          <ul class="media-list" *ngIf="videos.length >= 1">
            <li class="media-list__li" *ngFor="let video of videos">
              <a class="media-card" href={{video.video_url}} target="_blank" *ngIf="video.category_name == 'MTB'">
                <div class="media-card__top-wrap">
                  <img
                    class="media-card__media"
                    src={{video.image}}
                  />
                  <img class="media-card__play-btn" src="../../../assets/images/play.png" />
                </div>
                <div class="media-card__text-wrap">
                  <h5 class="h6 media-card__title">{{video.category_name}}</h5>
                </div>
              </a>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
          <!-- <div class="button-wrap">
              <button class="button_custom">{{"CommonText.LoadMore" | translate}}</button>
          </div> -->
        </div>
      </mat-tab>
      <mat-tab label="Tour">
        <div class="tab-body">
          <ul class="media-list" *ngIf="videos.length >= 1">
            <li class="media-list__li" *ngFor="let video of videos">
              <a class="media-card" href={{video.video_url}} target="_blank" *ngIf="video.category_name == 'Tour'">
                <div class="media-card__top-wrap">
                  <img
                    class="media-card__media"
                    src={{video.image}}
                  />
                  <img class="media-card__play-btn" src="../../../assets/images/play.png" />
                </div>
                <div class="media-card__text-wrap">
                  <h5 class="h6 media-card__title">{{video.category_name}}</h5>
                </div>
              </a>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
          <!-- <div class="button-wrap">
              <button class="button_custom">{{"CommonText.LoadMore" | translate}}</button>
          </div> -->
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title en-only">
      {{raceName}}
    </h1>
    <h1 class="page-header__title ar-only">
      {{raceNameAr}}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "CommonText.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li class="en-only">{{raceName}}</li>
      <li class="ar-only">{{raceNameAr}}</li>
    </ul>
  </div>
  <section class="page-body" *ngIf="api.raceHomeData$ | async as homeData">
    <mat-tab-group
      class="mat-tab-secondary vertical-tabs-lg"
      mat-align-tabs="start"
    >
      <mat-tab label='{{"CommonText.News" | translate}}'>
        <div class="tab-body">
          <app-news></app-news>
        </div>
      </mat-tab>
      <mat-tab label='{{"CommonText.Events" | translate}}'>
        <div class="tab-body">
          <ul class="card-list" *ngIf="homeData.calendar.length > 0">
            <li
              class="card"
              *ngFor="let type of homeData.calendar.slice().reverse()"
            >
              <a routerLink="/event/{{ type.id }}">
                <!-- <div *ngIf="env.API_URL + eventData.img_url" class="card__image-wrap" data-src-href="#">
                  <img class="card__image" [src]="env.API_URL + eventData.img_url" />
                </div> -->
                <div class="card__image-wrap" data-src-href="#">
                  <img class="card__image" src="../../assets/images/logo.jpg" />
                </div>

                <div class="card__body">
                  <div class="card__body-tag-wrap">
                    <span class="card__date">{{
                      type.date | date: "mediumDate"
                    }}</span>
                    <span class="category category_sm category_secondary">
                      {{ type.state }}</span
                    >
                  </div>
                  <span class="category">{{
                    type.race_type[0].race_type[1] | titlecase
                  }}</span>
                  <h4 class="h6 card__title">
                    {{ type.event_name | titlecase }}
                  </h4>
                </div>
              </a>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
          <p *ngIf="!homeData.calendar.length > 0">
            {{ "Road.NoData" | translate }}
          </p>
        </div>
      </mat-tab>
      <mat-tab label='{{"CommonText.Photos" | translate}}'>
        <div class="tab-body">
          <app-photos></app-photos>
        </div>
      </mat-tab>
      <mat-tab label='{{"CommonText.Ranking" | translate}}'>
        <div class="tab-body">
          <app-race-type-rankings></app-race-type-rankings>
        </div>
      </mat-tab>
      <mat-tab label='{{"CommonText.Videos" | translate}}'>
        <div class="tab-body">
          <app-videos></app-videos>
        </div>
      </mat-tab>
      <mat-tab label='{{"CommonText.ResultsPastEvents" | translate}}'>
        <div class="tab-body">
          <app-result></app-result>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

<div>
  <div class="filter-wrap">
    <div class="filters">
      <label class="form-label">{{ "Rankings.Year" | translate }}</label>
      <select
        class="form-select"
        [(ngModel)]="selectedYear"
        (change)="UpdateData()"
      >
        <option *ngFor="let year of years">{{ year }}</option>
      </select>
    </div>
    <div class="filters" *ngIf="categories">
      <label class="form-label">{{ "Rankings.Categories" | translate }}</label>
      <select
        class="form-select"
        [(ngModel)]="selectedCategory"
        (change)="UpdateData()"
      >
        <option *ngFor="let category of categories" [ngValue]="category.id">
          {{ category.code }}
        </option>
      </select>
    </div>
  </div>

  <div *ngIf="rankingData" class="card-list_ranking">
    <button
      class="card card-ranking"
      *ngFor="let individual of rankingData.individule_ranking"
      (click)="NavigateToRankingDetails(0)"
    >
      <div class="card-ranking__header">
        <h3 class="h5">{{ "Rankings.IndividualRanking" | translate }}</h3>
      </div>
      <div class="card-ranking__body">
        <p>
          {{ "Rankings.Year" | translate }}
          <br />
          <strong>{{ individual.individule[0].year }}</strong>
        </p>
        <p>
          {{ "Rankings.LastUpdated" | translate }}
          <br />
          <strong>{{ individual.individule[0].last_update | date: "mediumDate" }}</strong>
        </p>
      </div>
      <div class="card-ranking__footer">
        {{ "Rankings.ViewDetails" | translate }}
        <img src="../../../assets/images/Down_Arrow_3_.svg" />
      </div>
    </button>
    <button
      class="card card-ranking"
      *ngFor="let team of rankingData.team_ranking"
      (click)="NavigateToRankingDetails(1)"
    >
      <div class="card-ranking__header">
        <h3 class="h5">{{ "Rankings.TeamRanking" | translate }}</h3>
      </div>
      <div class="card-ranking__body">
        <p>
          {{ "Rankings.Year" | translate }}
          <br />
          <strong>{{ team.team[0].year }}</strong>
        </p>
        <p>
          {{ "Rankings.LastUpdated" | translate }}
          <br />
          <strong>{{ team.team[0].last_update | date: "mediumDate" }}</strong>
        </p>
      </div>
      <div class="card-ranking__footer">
        {{ "Rankings.ViewDetails" | translate }}
        <img src="../../../assets/images/Down_Arrow_3_.svg" />
      </div>
    </button>
    <button
      class="card card-ranking"
      *ngFor="let general of rankingData.general_ranking"
      (click)="NavigateToRankingDetails(2)"
    >
      <div class="card-ranking__header">
        <h3 class="h5">{{ "Rankings.GeneralRanking" | translate }}</h3>
      </div>
      <div class="card-ranking__body">
        <p>
          {{ "Rankings.Year" | translate }}
          <br />
          <strong>{{ general.general[0].year }}</strong>
        </p>
        <p>
          {{ "Rankings.LastUpdated" | translate }}
          <br />
          <strong>{{ general.general[0].last_update | date: "mediumDate" }}</strong>
        </p>
      </div>
      <div class="card-ranking__footer">
        {{ "Rankings.ViewDetails" | translate }}
        <img src="../../../assets/images/Down_Arrow_3_.svg" />
      </div>
    </button>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {BackendApiService} from "../backend-api.service";
import {HomeService} from "../services/home.service";
import {Router} from "@angular/router";
import {environment} from "./../../environments/environment";

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.css']
})
export class TrackComponent implements OnInit {
  environment = environment;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  last_news: any;
  calendar: any;
  last_event: any;
  photos: any;

  constructor(
    private backendService: BackendApiService,
    public api: HomeService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
  }

  loadHomePageData() {
    this.api.HomePageForSpecificRaceType(2);
  }

  ngOnInit() {
    this.loadHomePageData();

    // fixme:/// deprecated and already removed
    // this.backendService.homeNews().subscribe(
    //   (response: HttpResponse<any>) => {
    //     // console.log(JSON.stringify(response["last_news"]));
    //     this.last_news = response["last_news"];
    //     this.calendar = response["calendar"];
    //     this.last_event = response["last_event"];
    //     this.photos = response["photos"];
    //   },
    //   (error) => {
    //     this._snackBar.open("Fecth  Home API Failed", "Error", {
    //       horizontalPosition: this.horizontalPosition,
    //       verticalPosition: this.verticalPosition,
    //     });
    //   }
    // );
  }
}

import {Component, OnInit} from '@angular/core';
import {AboutService} from "../../services/about.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  public env = environment;

  constructor(public aboutService: AboutService) {
  }

  ngOnInit() {
    this.aboutService.loadPartnersData();
  }

}

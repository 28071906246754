import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {NationalTeamService} from "../../../services/national-team.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from '../../../../environments/environment';
import {ITeamAdmin} from "../../../model/official.model";

@Component({
  selector: 'app-team-official-details',
  templateUrl: './team-official-details.component.html',
  styleUrls: ['./team-official-details.component.css']
})
export class TeamOfficialDetailsComponent implements OnInit {

  environment = environment;
  public teamAdmin: ITeamAdmin = {} as ITeamAdmin;
  private routeDataSubscription: Subscription;
  private teamId: number;

  constructor(public nationalTeamService: NationalTeamService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.teamId = +data.get('id');
      this.loadingInitializationData();
    });
  }

  private loadingInitializationData() {
    this.nationalTeamService.loadTeamAdminProfile(this.teamId).subscribe((response: any) => {
      console.log('official team data are ' , response);
      const result = response.data[0];
      this.teamAdmin = {
        ...result,
        nationality: result.nationality[0].nationality[1],
        team_name: result.team_name[0].team_name[1]
      };
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { IContactUs } from "../../model/footer.models";
import { FooterService } from "../../services/footer.service";

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  ContactUsForm: FormGroup;
  submitted: boolean;
  lines: FormArray;

  disabled: false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private footerService: FooterService
  ) {
    this.contactUsFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.ContactUsForm.controls;
  }


  get attachment() {
    return this.ContactUsForm.get('attachment') as FormGroup;

  }


  ngOnInit(): void {
  }

  contactUsFormInitialization() {
    this.ContactUsForm = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      question: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      // attachment: this.fb.group({
      //   file: [''],
      //   fileSource: [''],
      //   extension: ['']
      // }),
    });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.ContactUsForm.invalid) {
      return;
    }
    // const formattedFile = this.attachment.get('fileSource').value.toString().split('base64,');
    const formData = {
      your_name: this.ContactUsForm.get('name').value,
      phone: this.ContactUsForm.get('phone').value,
      subject: this.ContactUsForm.get('subject').value,
      email: this.ContactUsForm.get('email').value,
      your_question: this.ContactUsForm.get('question').value,

      // attachment: formattedFile[1],
    } as IContactUs;
    // console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    console.log('entry by name data are', formData);

    // last step
    this.footerService.addContactUsRequest(formData);
  }

  onReset(): void {
    this.submitted = false;
    this.ContactUsForm.reset();
  }


  // onFileChange(event) {
  //   const reader = new FileReader();

  //   if (event.target.files && event.target.files.length) {
  //     const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
  //     console.log('file extensions', fileExtension);
  //     const [file] = event.target.files;
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       // this.imageSrc = reader.result as string;
  //       this.attachment.patchValue({
  //         fileSource: reader.result,
  //         extension: fileExtension
  //       });

  //     };

  //   }
  // }

  private loadingInitializationData() {

  }

}

<div class="container">
    <div class="top-bar">
        <i class="fa fa-check-circle-o" aria-hidden="true"></i>
    </div>
    <div class="bottom-bar">
        <h1>{{'Payment.PaymentSuccess' | translate}}</h1>

        <button routerLink="/">{{'Payment.Home' | translate}}</button>
    </div>
</div>

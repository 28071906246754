<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "ClubTeamRiderRegistration.Header" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "ClubTeamRiderRegistration.Header" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="freeRiderRegistrationForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-group">
        <input type="text" [value]="CTName" disabled class="form-control" />
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.FirstName" | translate
        }}</label>
        <input
          type="text"
          formControlName="firstName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
        />
        <script>
        function ctrl($scope) {
          $scope.pattern = /[\u0590-\u05FF]+/g;
        }
        </script>
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
          <div *ngIf="f.firstName.errors.required">
            {{ "ClubTeamRiderRegistration.FirstNameRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.MiddleName" | translate
        }}</label>
        <input
          type="text"
          formControlName="middleName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.middleName.errors }"
        />
        <div *ngIf="submitted && f.middleName.errors" class="invalid-feedback">
          <div *ngIf="f.middleName.errors.required">
            {{ "ClubTeamRiderRegistration.MiddleNameRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.LastName" | translate
        }}</label>
        <input
          type="text"
          formControlName="lastName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
        />
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
          <div *ngIf="f.lastName.errors.required">
            {{ "ClubTeamRiderRegistration.LastNameRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label"
          >{{ "ClubTeamRiderRegistration.UCIID" | translate }}
        </label>
        <input type="text" formControlName="uciId" class="form-control" />
      </div>

      <div
        class="form-group"
        *ngIf="api.clubTeamCategories$ | async as clubTeamCategories"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.Category" | translate
        }}</label>
        <select class="form-select form-control" formControlName="freeCategory">
          <option value="" disabled>
            {{ "ClubTeamRiderRegistration.ChooseCategory" | translate }}
          </option>
          <option
            *ngFor="let category of clubTeamCategories"
            [ngValue]="category.id"
          >
            {{ category.code }}
          </option>
        </select>
        <!-- error block -->
        <div
          *ngIf="submitted && f.freeCategory.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.freeCategory.errors.required">
            {{ "ClubTeamRiderRegistration.CategoryRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.RiderStatus" | translate
        }}</label>

        <select
          class="form-select form-control"
          [(ngModel)]="riderTypeId"
          formControlName="riderType"
        >
          <option value="" disabled>
            {{ "ClubTeamRiderRegistration.ChooseRiderStatus" | translate }}
          </option>
          <option
            *ngFor="let riderStatus of riderStatuses"
            [ngValue]="riderStatus.id"
          >
            {{ "RiderStatuses." + riderStatus.id | translate }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.riderType.errors" class="invalid-feedback">
          <div *ngIf="f.riderType.errors.required">
            {{ "ClubTeamRiderRegistration.RiderStatusRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{
            "ClubTeamRiderRegistration.DateOfBirth" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="dateOfBirthPicker"
            formControlName="dateOfBirth"
            class="form-control"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfBirthPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthPicker></mat-datepicker>
        </mat-form-field>

        <div *ngIf="submitted && f.dateOfBirth.errors" class="invalid-feedback">
          <div *ngIf="f.dateOfBirth.errors.required">
            {{ "ClubTeamRiderRegistration.DateOfBirthRequired" | translate }}
          </div>
        </div>
      </div>

      <div
        class="form-group"
        *ngIf="api.nationalities$ | async as nationalities"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.Nationality" | translate
        }}</label>
        <select class="form-select form-control" formControlName="nationality">
          <option value="" disabled>
            {{ "ClubTeamRiderRegistration.ChooseNationality" | translate }}
          </option>
          <option
            *ngFor="let nationality of nationalities"
            [ngValue]="nationality.id"
          >
            {{ nationality.name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.nationality.errors" class="invalid-feedback">
          <div *ngIf="f.nationality.errors.required">
            {{ "ClubTeamRiderRegistration.NationalityRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-group">
          <label class="form-label">{{
            "ClubTeamRiderRegistration.PlaceOfBirth" | translate
          }}</label>
          <input
            type="text"
            formControlName="birthPlace"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.birthPlace.errors }"
          />
          <div
            *ngIf="submitted && f.birthPlace.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.birthPlace.errors.required">
              {{ "ClubTeamRiderRegistration.PlaceOfBirthRequired" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label"
          >{{ "ClubTeamRiderRegistration.Gender" | translate }}
        </label>

        <select class="form-select form-control" formControlName="gender">
          <option value="" disabled>
            {{ "ClubTeamRiderRegistration.ChooseGender" | translate }}
          </option>
          <option *ngFor="let gender of genders" [ngValue]="gender">
            {{ "Gender." + gender | translate }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
          <div *ngIf="f.gender.errors.required">
            {{ "ClubTeamRiderRegistration.GenderRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.HomeAddress" | translate
        }}</label>
        <input
          type="text"
          formControlName="homeAddress"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.homeAddress.errors }"
        />
        <div *ngIf="submitted && f.homeAddress.errors" class="invalid-feedback">
          <div *ngIf="f.homeAddress.errors.required">
            {{ "ClubTeamRiderRegistration.HomeAddressRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.Phone" | translate
        }}</label>
        <input
          type="text"
          formControlName="phone"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
        />
        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
          <div *ngIf="f.phone.errors.required">
            {{ "ClubTeamRiderRegistration.PhoneRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.RiderEmail" | translate
        }}</label>
        <input
          type="text"
          formControlName="riderEmail"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.riderEmail.errors }"
        />
        <div *ngIf="submitted && f.riderEmail.errors" class="invalid-feedback">
          <div *ngIf="f.riderEmail.errors.required">
            {{ "ClubTeamRiderRegistration.RiderEmailRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.WorkAddress" | translate
        }}</label>
        <input
          type="text"
          formControlName="workAddress"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.workAddress.errors }"
        />
        <div *ngIf="submitted && f.workAddress.errors" class="invalid-feedback">
          <div *ngIf="f.workAddress.errors.required">
            {{ "ClubTeamRiderRegistration.WorkAddressRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.EmiratesID" | translate
        }}</label>
        <input
          type="text"
          formControlName="emiratesId"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.emiratesId.errors }"
        />
        <div *ngIf="submitted && f.emiratesId.errors" class="invalid-feedback">
          <div *ngIf="f.emiratesId.errors.required">
            {{ "ClubTeamRiderRegistration.EmiratesIDRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>{{
            "ClubTeamRiderRegistration.EmiratesIdExpiryDate" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="emiratesIdExpiryPicker"
            formControlName="emiratesIdExpiry"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="emiratesIdExpiryPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #emiratesIdExpiryPicker></mat-datepicker>
        </mat-form-field>
        <div
          *ngIf="submitted && f.emiratesIdExpiry.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.emiratesIdExpiry.errors.required">
            {{
              "ClubTeamRiderRegistration.EmiratesIdExpiryDateRequired"
                | translate
            }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <div formGroupName="image" >
          <label class="form-label">{{
            "ClubTeamRiderRegistration.UserImage" | translate
          }}</label>
          <input
            type="file"
            class="form-control"
            formControlName="file"
            (change)="onFileChange($event, 'image')"
          />
        </div>
        <img
          [src]="imageSrc"
          *ngIf="imageSrc"
          style="height: 150px; width: 150px; object-fit: contain;"
        />
      </div>

      <div class="form-group" formGroupName="identity">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.IDBothSides" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'identity')"
          multiple
        />
      </div>

      <div class="form-group" formGroupName="oGuardian">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.NoObjectionGuardian" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'oGuardian')"
        />
      </div>

      <div class="form-group" formGroupName="medical">
        <label class="form-label">{{
          "ClubTeamRiderRegistration.MedicalFitnessCertificate" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'medical')"
        />
      </div>

      <div
        class="form-group"
        *ngIf="riderTypeId == 'gcc' || riderTypeId == 'uae_passport_holder'"
        formGroupName="passport"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.Passport" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'passport')"
        />
      </div>

      <div
        class="form-group"
        *ngIf="riderTypeId == 'children_citizens'"
        formGroupName="motherPassport"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.IdentityPassportMother" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'motherPassport')"
        />
      </div>

      <div
        class="form-group"
        *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae'"
        formGroupName="birthCert"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.BirthCertificate" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'birthCert')"
        />
      </div>

      <div
        class="form-group"
        *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae'"
        formGroupName="studies"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.ContinuityStudies" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'studies')"
        />
      </div>

      <div
        class="form-group"
        *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae'"
        formGroupName="iGuardian"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.IdentityGuardian" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'iGuardian')"
        />
      </div>

      <div
        class="form-group"
        *ngIf="
          riderTypeId == 'children_citizens' ||
          riderTypeId == 'born_uae' ||
          riderTypeId == 'amateur'
        "
        formGroupName="pResidence"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.PlayerResidence" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'pResidence')"
        />
      </div>

      <div
        class="form-group"
        *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae'"
        formGroupName="gResidence"
      >
        <label class="form-label">{{
          "ClubTeamRiderRegistration.GuardianResidence" | translate
        }}</label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'gResidence')"
        />
      </div>

      <div class="form-group" formGroupName="vaccineCert">
        <label class="form-label"
          >{{
            "ClubTeamRiderRegistration.UserVaccineCertification" | translate
          }}
        </label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'vaccineCert')"
        />
      </div>

      <div class="form-group" formGroupName="insuranceCert">
        <label class="form-label"
          >{{
            "ClubTeamRiderRegistration.UserInsuranceCertification" | translate
          }}
        </label>
        <input
          type="file"
          class="form-control"
          formControlName="file"
          (change)="onFileChange($event, 'insuranceCert')"
        />
      </div>

      <div class="form-group registration-form__submit-wrap">
        <button 
          type="submit"
          class="button button_theme button_md"
        >
          {{ "ClubTeamRiderRegistration.Submit" | translate }}
        </button>
      </div>
    </form>
  </section>
</div>

import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HostUAECFEventService} from "../../../../services/host-uaecf-event.service";
import {IVisaApplicationFormData, IVisaApplicationLineId} from "../../../../model/UAECFEvent.model";
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-visa-application',
  templateUrl: './visa-application.component.html',
  styleUrls: ['./visa-application.component.css']
})
export class VisaApplicationComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  visaApplicationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  genders = ['male', 'female'];
  private uaeEventId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: HostUAECFEventService
  ) {
    this.visaApplicationFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.visaApplicationForm.controls;
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.uaeEventId = +data.get('id');
      this.loadLookups();
    });
  }

  visaApplicationFormInitialization() {
    this.visaApplicationForm = this.fb.group({
      name: ['', [Validators.required]],
      contactPersonName: ['', [Validators.required]],
      workPhone: ['', [Validators.required]],
      mobilePhone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      lines: this.fb.array([]),
    });
    this.lines = this.visaApplicationForm.get('lines') as FormArray;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.visaApplicationForm.invalid) {
      return;
    }

    const formData = {
      event_name: this.uaeEventId,
      name_federation: this.visaApplicationForm.get('name').value,
      contact_person_name: this.visaApplicationForm.get('contactPersonName').value,
      work_phone: this.visaApplicationForm.get('workPhone').value,
      mobile_no: this.visaApplicationForm.get('mobilePhone').value,
      email: this.visaApplicationForm.get('email').value,
      visa_application_id: this.lines.controls.map(item => {
        return {
          name: item.get('name').value,
          family_name: item.get('familyName').value,
          sex: item.get('sex').value,
          date_birth: item.get('dateOfBirth').value,
          nationality: item.get('nationality').value,
          position: item.get('position').value,
          visa_country: item.get('visaApplyingCountry').value,
          passport_no: item.get('passportNo').value,
          date_entry: item.get('dateOfEntry').value,
          date_departure: item.get('dateOfDeparture').value,
        } as IVisaApplicationLineId;
      }),
    } as IVisaApplicationFormData;
    // console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    // console.log('entry by name data are', this.visaApplicationForm.value);

    // last step
      this.api.addVisaApplication(formData);
  }

  onReset(): void {
    this.submitted = false;
    this.visaApplicationForm.reset();
  }

  addLine(): void {
    // this.lines = this.entryByNameForm.get('lines') as FormArray;
    this.lines.push(this.createLine());
  }

  removeLine(i: number) {
    this.lines.removeAt(i);
  }

  createLine(): FormGroup {
    return this.fb.group({
      name: [''],
      familyName: [''],
      sex: [''],
      dateOfBirth: [''],
      nationality: [''],
      passportNo: [''],
      position: [''],
      visaApplyingCountry: [''],
      dateOfEntry: [''],
      dateOfDeparture: [''],
    });
  }

  private loadLookups() {
    this.api.loadNationalities();
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {
  IActivityCalenderItem, IActivityCalenderNewModel,
  IComplaintModel,
  IContactUs,
  IPrimaryLaw,
  IRacingLicense,
  IRegisterActivity,
  ITechnicalRegulation,
  ITrainingPermission
} from "../model/footer.models";

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  calenderActivities = new BehaviorSubject<IActivityCalenderItem[]>([]);
  seminarData = new BehaviorSubject<IActivityCalenderItem>({} as IActivityCalenderItem);
  technicalRegulations = new BehaviorSubject<ITechnicalRegulation[]>([]);
  primarilyLaws = new BehaviorSubject<IPrimaryLaw[]>([]);
  racingLicenses = new BehaviorSubject<IRacingLicense[]>([]);

  constructor(private http: HttpClient) {
  }

  get calenderActivities$(): Observable<IActivityCalenderItem[]> {
    return this.calenderActivities.asObservable();
  }

  get seminarData$(): Observable<IActivityCalenderItem> {
    return this.seminarData.asObservable();
  }

  get technicalRegulations$(): Observable<ITechnicalRegulation[]> {
    return this.technicalRegulations.asObservable();
  }

  get primarilyLaws$(): Observable<IPrimaryLaw[]> {
    return this.primarilyLaws.asObservable();
  }

  get racingLicenses$(): Observable<IRacingLicense[]> {
    return this.racingLicenses.asObservable();
  }

  CreateActivity(data: IActivityCalenderNewModel) {
    const requestModel = {
      params: {
        ...data
      }
    };
    this.http.post(`${environment.API_URL}/registration/activity`, requestModel)
      .subscribe(response => {
        //console.log('&&&& re-register riders for club response are :  &&&& ', response);
      });
  }

  loadSeminarsData(id: number) {
    //console.log('id ', id);

    return this.http.get(`${environment.API_URL}/activities-invitations/${id}`);

    // subscribe((response: any) => {
    //   const data = response.data as IActivityCalenderItem[];
    //   data.map(item => {
    //     const from = new Date(item.date_from);
    //     const to = new Date(item.date_to);
    //     const now = new Date();
    //     if (from > now && now < to) {
    //       item.isHidden = false;
    //     } else {
    //       item.isHidden = true;
    //     }
    //   });
    //   //console.log('datails are', data[0]);
    //
    //   this.seminarData.next(data[0]);
    // });
  }

  loadCalenderActivities() {
    this.http.get(`${environment.API_URL}/activities-invitations`)
      .subscribe((response: any) => {
        //console.log(response);
        const data = response.data as IActivityCalenderItem[];
        data.map(item => {
          const from = new Date(item.date_from);
          const to = new Date(item.date_to);
          const now = new Date();
          if (from > now && now < to) {
            item.isHidden = false;
          } else {
            item.isHidden = true;
          }
        });
        this.calenderActivities.next(data);
      });
  }

  registerActivity(data: IRegisterActivity) {
    this.http.post(`${environment.API_URL}/registration/activity`, data).subscribe((response: any) => {
      //console.log('response are ', response);
    });
  }

  addComplaint(data: IComplaintModel):Observable<any> {
    const requestModel = {
      params: {
        ...data
      }
    };
    return this.http.post(`${environment.API_URL}/complaints`, requestModel);
  }

  loadTechnicalRegulation() {
    this.http.get(`${environment.API_URL}/technical-regulatoin`)
      .subscribe((response: any) => {

        const data = response.data as ITechnicalRegulation[];
        this.technicalRegulations.next(data);
      });
  }

  loadPrimarilyLaws() {
    this.http.get(`${environment.API_URL}/primary-law`)
      .subscribe((response: any) => {

        const data = response.data as IPrimaryLaw[];
        this.primarilyLaws.next(data);
      });
  }

  loadRacingLicense() {
    this.http.get(`${environment.API_URL}/racing-license`)
      .subscribe((response: any) => {

        const data = response.data as IRacingLicense[];
        this.racingLicenses.next(data);
      });
  }

  addTrainingPermissionRequest(data: ITrainingPermission) {
    const requestModel = {
      params: {
        ...data
      }
    };
    this.http.post(`${environment.API_URL}/training_permission`, requestModel).subscribe((response: any) => {
      //console.log('Training Permission response are ', response);
    });
  }

  addContactUsRequest(data: IContactUs) {
    const requestModel = {
      params: {
        ...data
      }
    };
    this.http.post(`${environment.API_URL}/contact-us`, requestModel,).subscribe((response: any) => {
      //console.log('Contact us Form Data Response are  ', response);
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HostUAECFEventService} from "../../../../services/host-uaecf-event.service";
import {IEntryByNameFormData, ILineInformation, IRaceClassificationType} from "../../../../model/UAECFEvent.model";
import * as moment from 'moment';
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-entry-by-name',
  templateUrl: './entry-by-name.component.html',
  styleUrls: ['./entry-by-name.component.css']
})
export class EntryByNameComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  entryByNameForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  raceClassification: IRaceClassificationType [] = [];


  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  private uaeEventId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: HostUAECFEventService
  ) {
    this.entryByNameFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.entryByNameForm.controls;
  }

  // get fa(): { [key: string]: AbstractControl } {
  //   return this.entryByNameForm['lines'].controls;
  // }

// Getter method to access formcontrols
  get categoryId() {
    return this.entryByNameForm.get('category');
  }

  get stamp() {
    return this.entryByNameForm.get('stamp') as FormGroup;

  }

// Getter method to access formcontrols
  get raceTypeId() {
    return this.entryByNameForm.get('raceType');
  }


  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.uaeEventId = +data.get('id');
      this.loadingInitializationData();
    });
  }

  entryByNameFormInitialization() {
    this.entryByNameForm = this.fb.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      teamName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      fax: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      category: ['', [Validators.required]],
      raceType: ['', [Validators.required]],
      presidentName: [''],
      place: [''],
      lines: this.fb.array([]),
      stamp: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['' , [Validators.required]]
      }),
      dateTime: ['', [Validators.required]]
    });
    this.lines = this.entryByNameForm.get('lines') as FormArray;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.entryByNameForm.invalid) {
      return;
    }

    const formData = {
      name_event: this.uaeEventId,
      name: this.entryByNameForm.get('name').value,
      address: this.entryByNameForm.get('address').value,
      team_name: this.entryByNameForm.get('teamName').value,
      tel: this.entryByNameForm.get('phone').value,
      fax: this.entryByNameForm.get('fax').value,
      email: this.entryByNameForm.get('email').value,
      category: this.entryByNameForm.get('category').value,
      race_type: this.entryByNameForm.get('raceType').value,
      name_preseident: this.entryByNameForm.get('presidentName').value,
      place: this.entryByNameForm.get('place').value,
      entry_table_id: this.lines.controls.map(item => {
        return {
          first_name: item.get('firstName').value,
          last_name: item.get('lastName').value,
          race_type_classifications: item.get('classification').value,
          race_type: item.get('raceType').value,
          uci_code: item.get('uciCode').value,
        } as ILineInformation;
      }),
      dateTime: this.entryByNameForm.get('dateTime').value,
      image: this.stamp.get('fileSource').value.toString(),
    } as IEntryByNameFormData;
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    //console.log('entry by name data are', this.entryByNameForm.value);

    // last step
     this.api.addEntryByName(formData);
  }

  onReset(): void {
    this.submitted = false;
    this.entryByNameForm.reset();
  }

  addLine(): void {
    // this.lines = this.entryByNameForm.get('lines') as FormArray;
    this.lines.push(this.createLine());
  }

  removeLine(i: number) {
    this.lines.removeAt(i);
  }

  createLine(): FormGroup {
    return this.fb.group({
      firstName: [''],
      lastName: [''],
      uciCode: [''],
      raceType: [''],
      classification: [''],
    });
  }

  // Choose category using select dropdown
  changeCategory(e) {
    this.categoryId.setValue(e.target.value, {
      onlySelf: true
    });
  }

  // Choose category using select dropdown
  changeRaceType(e) {
    this.raceTypeId.setValue(e.target.value, {
      onlySelf: true
    });
  }

  // Choose category using select dropdown
  changeInternalRaceType(e, i: number) {
    this.raceTypeId.setValue(e.target.value, {
      onlySelf: true
    });
    this.api.raceTypes$.subscribe(raceTypes => {

      raceTypes.forEach(item => {
        if (item.id == this.lines.controls[i].get('raceType').value) {
          this.raceClassification = item.classification_type;
        }
      });
    });
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
        const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      //console.log('file extensions' , fileExtension);
      const [file] = event.target.files;
        reader.readAsDataURL(file);
      reader.onload = () => {
        // this.imageSrc = reader.result as string;
        this.stamp.patchValue({
          fileSource: reader.result,
          extension: fileExtension
        });

      };

    }
  }

  private loadingInitializationData() {
    this.api.loadClubTeamCategory();
    this.api.loadRaceType();
  }

}

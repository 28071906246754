import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IRaceClassificationType} from "../../model/UAECFEvent.model";
import * as moment from "moment";
import {ThemePalette} from "@angular/material/core";
import {ActivatedRoute, Router} from "@angular/router";
import {HostUAECFEventService} from "../../services/host-uaecf-event.service";
import {environment} from '../../../environments/environment';
import {MatSelectChange} from "@angular/material/select";
import {ITrainingPermission, TrainingInformationID} from "../../model/footer.models";
import {FooterService} from "../../services/footer.service";

@Component({
  selector: 'app-training-permission',
  templateUrl: './training-permission.component.html',
  styleUrls: ['./training-permission.component.css']
})
export class TrainingPermissionComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  trainingPermissionRequestForm: FormGroup;
  submitted: boolean;
  training_information_ids: FormArray;
  selectedSubblicant = 'company';
  raceClassification: IRaceClassificationType [] = [];
  sublicants = [
    {
      key: 'company',
      value: 'Company'
    },
    {
      key: 'group_riders',
      value: 'Groups of Riders'
    },
    {
      key: 'club',
      value: 'Club'
    },
    {
      key: 'team',
      value: 'Team'
    },
  ];
  frequncies = [
    {
      key: 'one_week',
      value: 'One Week'
    },
    {
      key: 'twice_week',
      value: 'Twice Week'
    },
    {
      key: '3_day_week',
      value: '3 Days of Week'
    },
    {
      key: 'every_day',
      value: 'Every Day'
    },
  ];


  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  private uaeEventId: number;
  agreedTerms: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: HostUAECFEventService,
              private footerService: FooterService
  ) {
    this.trainingPermissionRequestFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.trainingPermissionRequestForm.controls;
  }
  
  changeAgreedTerm() {
    this.agreedTerms = !this.agreedTerms;
    //console.log(this.agreedTerms);

  }

  // get fa(): { [key: string]: AbstractControl } {
  //   return this.entryByNameForm['training_information_ids'].controls;
  // }

// Getter method to access formcontrols
  get categoryId() {
    return this.trainingPermissionRequestForm.get('category');
  }

  get stamp() {
    return this.trainingPermissionRequestForm.get('stamp') as FormGroup;

  }

  get groupContactPersonId() {
    return this.trainingPermissionRequestForm.get('groupContactPersonId') as FormGroup;

  }

  get noc() {
    return this.trainingPermissionRequestForm.get('noc') as FormGroup;
  }

  get license() {
    return this.trainingPermissionRequestForm.get('license') as FormGroup;

  }

  get safteyLicense() {
    return this.trainingPermissionRequestForm.get('saftey_license') as FormGroup;
  }

  get driverLicense() {
    return this.trainingPermissionRequestForm.get('driverLicense') as FormGroup;
  }

  get insurance() {
    return this.trainingPermissionRequestForm.get('insurance') as FormGroup;

  }


  ngOnInit(): void {
    // this.routeDataSubscription = this.route.paramMap.subscribe(data => {
    //   this.uaeEventId = +data.get('id');
    // });
    this.api.loadNationalities();
  }

  trainingPermissionRequestFormInitialization() {
    this.trainingPermissionRequestForm = this.fb.group({

      requestedDate: ['', [Validators.required]],

      sublicant: ['', [Validators.required]],
      address: ['', [Validators.required]],
      companyName: [''],
      clubName: [''],
      teamName: [''],
      phone: ['', [Validators.required]],

      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneInCharge: ['', [Validators.required]],
      addressInCharge: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      nationality: ['', [Validators.required]],
      groupContactPersonId: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),

      NumberOfParticipants: ['', [Validators.required]],
      startingPoint: ['', [Validators.required]],
      finishingPoint: ['', [Validators.required]],
      totalDistance: ['', [Validators.required]],
      startingTime: ['', [Validators.required]],
      finishingTime: ['', [Validators.required]],
      frequency: ['', [Validators.required]],
      categoryFrom: ['', [Validators.required]],
      categoryTo: ['', [Validators.required]],

      brakes: ['', [Validators.required]],
      reflectiveJerseys: ['', [Validators.required]],
      spare: ['', [Validators.required]],
      reflectionsBike: ['', [Validators.required]],
      safetyVehiclesLighting: ['', [Validators.required]],
      helmets: ['', [Validators.required]],

      noc: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      license: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      safetyLicense: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      driverLicense: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      insurance: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),

      training_information_ids: this.fb.array([]),

      accept: ['', [Validators.required]],

    });


    // noc_document: string;
    // saftey_license: string;
    // drivers_licenses: string;
    this.training_information_ids = this.trainingPermissionRequestForm.get('training_information_ids') as FormArray;
  }

  onSubmit(): void {
    this.submitted = true;

    // if (this.trainingPermissionRequestForm.invalid) {
    //   return;
    // }

    const formData = {
      requested_date: moment(this.trainingPermissionRequestForm.get('requestedDate').value).format('YYYY-MM-DD'),
      accept_agreement: this.trainingPermissionRequestForm.get('accept').value,
      requester: this.trainingPermissionRequestForm.get('sublicant').value,
      // should have some solution **********************************
      trade_license: null,
      company_name: this.trainingPermissionRequestForm.get('companyName').value,
      address: this.trainingPermissionRequestForm.get('address').value,
      tel_no: this.trainingPermissionRequestForm.get('phone').value,
      club_name: this.trainingPermissionRequestForm.get('clubName').value,
      team_name: this.trainingPermissionRequestForm.get('teamName').value,
      first_name: this.trainingPermissionRequestForm.get('firstName').value,
      last_name: this.trainingPermissionRequestForm.get('lastName').value,
      address_incharge: this.trainingPermissionRequestForm.get('addressInCharge').value,
      tel_no_incharge: this.trainingPermissionRequestForm.get('phoneInCharge').value,
      email: this.trainingPermissionRequestForm.get('email').value,
      name_preseident: this.trainingPermissionRequestForm.get('nationality').value,
      nationality: this.trainingPermissionRequestForm.get('nationality').value,
      group_incharge: this.groupContactPersonId.get('fileSource').value.toString(),
      num_participation: this.trainingPermissionRequestForm.get('NumberOfParticipants').value,
      starting_point: this.trainingPermissionRequestForm.get('startingPoint').value,
      finishing_point: this.trainingPermissionRequestForm.get('finishingPoint').value,
      total_distance: this.trainingPermissionRequestForm.get('totalDistance').value,
      starting_time: moment(this.trainingPermissionRequestForm.get('startingTime').value).format('YYYY-MM-DD HH:mm:ss'),
      finishing_time: moment(this.trainingPermissionRequestForm.get('finishingTime').value).format('YYYY-MM-DD HH:mm:ss'),
      frequency_training: this.trainingPermissionRequestForm.get('frequency').value,
      category_from: this.trainingPermissionRequestForm.get('categoryFrom').value,
      category_to: this.trainingPermissionRequestForm.get('categoryTo').value,
      brakes: this.trainingPermissionRequestForm.get('brakes').value,
      reflective_jerseys: this.trainingPermissionRequestForm.get('reflectiveJerseys').value,
      spare: this.trainingPermissionRequestForm.get('spare').value,
      reflections_bike: this.trainingPermissionRequestForm.get('reflectionsBike').value,
      safety_vehicles_lighting: this.trainingPermissionRequestForm.get('safetyVehiclesLighting').value,
      helmets: this.trainingPermissionRequestForm.get('helmets').value,
      noc_document: this.noc.get('fileSource').value.toString(),
      saftey_license: this.noc.get('fileSource').value.toString(),
      drivers_licenses: this.noc.get('fileSource').value.toString(),


      training_information_ids: this.training_information_ids.controls.map(item => {
        return {
          rider_first_name: item.get('firstName').value,
          rider_last_name: item.get('lastName').value,
          id_number: item.get('emiratedId').value,
          rider_nationality: item.get('nationality').value,
          telphone_no: item.get('phone').value,
          email_rider: item.get('email').value,
        } as TrainingInformationID;
      })
    } as ITrainingPermission;
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    //console.log('entry by ITrainingPermission data are', this.trainingPermissionRequestForm.value,formData);
    // this.onReset();

    // last step
    //console.log('sandeep form values', formData)
    this.footerService.addTrainingPermissionRequest(formData);
  }

  onReset(): void {
    this.submitted = false;
    this.trainingPermissionRequestForm.reset();
    //console.log("reset")
  }

  addLine(e): void {
    e.preventDefault();
    // this.training_information_ids = this.entryByNameForm.get('training_information_ids') as FormArray;
    this.training_information_ids.push(this.createLine());
  }

  removeLine(i: number) {
    this.training_information_ids.removeAt(i);
  }

  createLine(): FormGroup {
    return this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emiratedId: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      phone: [''],
      email: [''],
    });
  }


  onFileChange(event, documentName: string) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      //console.log('file extensions', fileExtension);
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.imageSrc = reader.result as string;
        if (documentName === 'groupId') {
          this.groupContactPersonId.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        if (documentName === 'noc') {
          this.noc.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        if (documentName === 'license') {
          this.license.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        if (documentName === 'safety') {
          this.safteyLicense.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        if (documentName === 'driverLicense') {
          this.driverLicense.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        if (documentName === 'insurance') {
          this.insurance.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
      };

    }
  }

  getSelectedSubblicant($event: MatSelectChange) {
    //console.log('subblicant are ', $event.value);
  }
}

<div id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "Home.News" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "Home.News" | translate }}</li>
    </ul>
  </div>
  <div class="page-body">
    <ul class="card-list">
      <li class="card" *ngFor="let news of newsData">
        <!-- <li class="card" *ngFor="let news of newsData.slice().reverse()"> -->
        <a (click)="loadEventDetails(news.id)" *ngIf="news.isHidden == true">
          <div class="card__image-wrap">
            <img
              class="card__image"
              src="{{ environment.API_URL + news.img_url }}"
              alt=""
            />
          </div>
          <div class="card__body">
            <div class="category-wrap">
              <span class="category category_sm">{{
                news.category[0].category[1] ? news.category[0].category[1] : "Home.GeneralNews" | translate
              }}</span>
            </div>
            <h4 class="h6">{{ news.name }}</h4>
            <p class="card__description" *ngIf="news.subtitle">
              {{ news.subtitle }}
            </p>
            <span class="card__date">{{ news.date | date: "mediumDate" }}</span>
          </div>
        </a>
      </li>
      <li class="barricade"></li>
      <li class="barricade"></li>
      <li class="barricade"></li>
    </ul>
  </div>
</div>

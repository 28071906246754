import { TermsPolicyComponent } from 'src/app/components/terms-policy/terms-policy.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IRaceClassificationType } from "../../model/UAECFEvent.model";
import * as moment from "moment";
import { ThemePalette } from "@angular/material/core";
import { HostUAECFEventService } from "../../services/host-uaecf-event.service";

import { environment } from '../../../environments/environment';
import { IRiderRegistrationModel, IStringifiesIdLookup } from "../../model/registration.model";
import { RegistrationsService } from "../../services/registrations.service";
import * as localforage from 'localforage';
import * as Constants from '../../shared/AppConstant.constant';


@Component({
  selector: 'app-riders',
  templateUrl: './riders.component.html',
  styleUrls: ['./riders.component.css']
})
export class RidersComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  freeRiderRegistrationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  raceClassification: IRaceClassificationType[] = [];
  riderTypeId;

  imageSrc: string;
  attachmentSrc: string;

  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  genders = ['male', 'female'];
  riderStatuses: IStringifiesIdLookup[] = [
    {
      id: 'citizen_rider',
      name: 'Local Emirati'
    },
    {
      id: 'children_citizens',
      name: 'Children of citizens'
    },
    {
      id: 'gcc',
      name: 'GCC'
    },
    {
      id: 'born_uae',
      name: 'Born in UAE'
    },
    {
      id: 'resident_rider',
      name: 'Resident Rider'
    },
    {
      id: 'uae_passport_holder',
      name: 'UAE Passport Holders'
    },
    {
      id: 'amateur',
      name: 'foreign resident'
    },

  ];
  private uaeEventId: number;
  price = Constants.RiderRegisterPrice;
  localPrice = Constants.LocalRiderRegisterPrice;
  agreedTerms: boolean;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public api: HostUAECFEventService,
    public dialog: MatDialog,
    private registrationApi: RegistrationsService
  ) {
    this.entryByNameFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.freeRiderRegistrationForm.controls;
  }

  // get fa(): { [key: string]: AbstractControl } {
  //   return this.entryByNameForm['lines'].controls;
  // }

  // Getter method to access formcontrols
  get categoryId() {
    return this.freeRiderRegistrationForm.get('freeCategory');
  }

  get attachment() {
    return this.freeRiderRegistrationForm.get('attachment') as FormGroup;

  }

  get image() {
    return this.freeRiderRegistrationForm.get('image') as FormGroup;
  }

  getFormField(name) {
    return this.freeRiderRegistrationForm.get(name) as FormGroup;

  }
  ngOnInit(): void {
    this.loadingInitializationData();
  }

  entryByNameFormInitialization() {
    this.freeRiderRegistrationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      middleName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      validUntil: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      birthPlace: ['', [Validators.required]],
      homeAddress: ['', [Validators.required]],
      workAddress: ['', [Validators.required]],
      riderType: ['', [Validators.required]],
      emiratesId: ['', [Validators.required]],
      freeCategory: ['', [Validators.required]],
      emiratesIdExpiry: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      uciId: ['', [Validators.required]],
      riderEmail: ['', [Validators.required]],
      image: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      identity: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      oGuardian: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      medical: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      passport: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      motherPassport: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      birthCert: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      studies: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      iGuardian: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      pResidence: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      gResidence: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      vaccineCert: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      insuranceCert: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
    });
  }

  getFormValidationErrors(form: FormGroup) {
    const result = [];
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    //console.log('result is : ', result);
    // return result;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.freeRiderRegistrationForm.invalid) {
      this.getFormValidationErrors(this.freeRiderRegistrationForm);
      return;
    }
    //console.log('goood');

    const formData = {
      register_type: 'free_rider',
      first_name: this.freeRiderRegistrationForm.get('firstName').value,
      // date: this.freeRiderRegistrationForm.get('date').value,
      middle_name: this.freeRiderRegistrationForm.get('middleName').value,
      last_name: this.freeRiderRegistrationForm.get('lastName').value,
      phone: this.freeRiderRegistrationForm.get('phone').value,
      date_birth: this.formatDate(this.freeRiderRegistrationForm.get('dateOfBirth').value),
      gender: this.freeRiderRegistrationForm.get('gender').value,
      place_birth: this.freeRiderRegistrationForm.get('birthPlace').value,
      home_address: this.freeRiderRegistrationForm.get('homeAddress').value,
      work_address: this.freeRiderRegistrationForm.get('workAddress').value,
      rider_type: this.freeRiderRegistrationForm.get('riderType').value,
      emirates_id: this.freeRiderRegistrationForm.get('emiratesId').value,
      free_category: this.freeRiderRegistrationForm.get('freeCategory').value,
      emirates_id_expiry: this.formatDate(this.freeRiderRegistrationForm.get('emiratesIdExpiry').value),
      nationality: this.freeRiderRegistrationForm.get('nationality').value,
      rider_email: this.freeRiderRegistrationForm.get('riderEmail').value,
      //image: this.getFormField('image').get('fileSource').value.toString(),
      identity_both_sides: this.getFormField('identity').get('fileSource').value.toString(),
      photo_with_white_backround: this.getFormField('image').get('fileSource').value.toString(),
      no_objection_from_guardian: this.getFormField('oGuardian').get('fileSource').value.toString(),
      medical_fitness_certificate: this.getFormField('medical').get('fileSource').value.toString(),
      valid_passport: this.getFormField('passport').get('fileSource').value.toString(),
      identity_passport_mother: this.getFormField('motherPassport').get('fileSource').value.toString(),
      player_birth_certificate: this.getFormField('birthCert').get('fileSource').value.toString(),
      continuity_studies: this.getFormField('studies').get('fileSource').value.toString(),
      identity_guardian: this.getFormField('iGuardian').get('fileSource').value.toString(),
      player_residence: this.getFormField('pResidence').get('fileSource').value.toString(),
      guardian_residence: this.getFormField('gResidence').get('fileSource').value.toString(),
      vaccine_certification: this.getFormField('vaccineCert').get('fileSource').value.toString(),
      insurance_certificate: this.getFormField('insuranceCert').get('fileSource').value.toString(),
    } as IRiderRegistrationModel;
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    //console.log('entry by name data are', this.freeRiderRegistrationForm.value);

    // last step
    this.registrationApi.registerRider(formData).subscribe(async (data) => {
      //console.log({ data });
      if (data.result.success && this.price > 0) {
        const reference = data.result.payment_resopnse.order.ref;
        const paymentUrl = data.result.payment_resopnse.order.url;

        await localforage.setItem('reference', reference, function (err) {
          // if err is non-null, we got an error
          // localforage.getItem('key', function (err, value) {
          //   // if err is non-null, we got an error. otherwise, value is the value
          // });
        });
        window.location.href = paymentUrl;
      } else if (data.result.success) {
        //console.log("ssuccess toaster");
        window.location.href = "/";
      }
    });
  }

  onReset(): void {
    this.submitted = false;
    this.freeRiderRegistrationForm.reset();
  }

  // Choose category using select dropdown
  changeCategory(e) {
    // this.categoryId.setValue(e.target.value, {
    //   onlySelf: true
    // });
  }

  formatDate(date): string {
    const nowDate = new Date(date);
    const formattedDate = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate();
    return formattedDate.toString();
  }
  //imageSrc="./asset/a.jpg";

  onFileChange(event, fileType: string) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      //console.log('file extensions', reader);
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (fileType == 'image') {
          this.imageSrc = reader.result as string;
          this.getFormField('image').patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        this.getFormField(fileType).patchValue({
          fileSource: reader.result,
          extension: fileExtension
        });
      };
    }
  }

  private loadingInitializationData() {
    this.api.loadClubTeamCategory();
    this.api.loadNationalities();
  }

  changeAgreedTerm() {
    this.agreedTerms = !this.agreedTerms;
    //console.log(this.agreedTerms);

  }

  calculatePrice() {
    if (this.riderTypeId == 'citizen_rider') {
      return this.price;
    } else {
      return this.price;
    }
  }

  openDialog(type) {
    //console.log('fromdialog');
    const dialogRef = this.dialog.open(TermsPolicyComponent, {
      width: '750px',
      height: '550px',
      data: { type: type }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
    });
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {IHomeModel} from "../model/home.model";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  homePageData = new BehaviorSubject<IHomeModel>({
    calendar: [],
    photos: [],
    last_news: [],
    general_news: [],
    last_event: []
  });
  // race types home pages
  raceHomeData = new BehaviorSubject<IHomeModel>({
    calendar: [],
    photos: [],
    last_news: [],
    general_news: [],
    last_event: []
  });
  mtbHomeData = new BehaviorSubject<IHomeModel>({
    calendar: [],
    photos: [],
    last_news: [],
    general_news: [],
    last_event: []
  });
  trackHomeData = new BehaviorSubject<IHomeModel>({
    calendar: [],
    photos: [],
    last_news: [],
    general_news: [],
    last_event: []
  });
  tourHomeData = new BehaviorSubject<IHomeModel>({
    calendar: [],
    photos: [],
    last_news: [],
    general_news: [],
    last_event: []
  });

  constructor(private http: HttpClient) {
  }

  get homePageData$(): Observable<IHomeModel> {
    return this.homePageData.asObservable();
  }

  get raceHomeData$(): Observable<IHomeModel> {
    return this.raceHomeData.asObservable();
  }

  get mtbHomeData$(): Observable<IHomeModel> {
    return this.mtbHomeData.asObservable();
  }

  get trackHomeData$(): Observable<IHomeModel> {
    return this.trackHomeData.asObservable();
  }

  get tourHomeData$(): Observable<IHomeModel> {
    return this.tourHomeData.asObservable();
  }

  loadHomePageData() {
    this.http.get<IHomeModel>(`${environment.API_URL}/home`)
      .subscribe((response) => {
        console.warn('response are ', response);
        response.last_news.map((item, index) => {
          if (index === 0) {
            item.isLastEvent = true;
          } else {
            item.isLastEvent = false;
          }
          return item;
        });
        this.homePageData.next(response);
      });
  }

  loadHomePageNewsData() {
    this.http.get<any>(`${environment.API_URL}/general_news`)
      .subscribe((response) => {
        console.warn('response general_news are ', response);
        // response.last_news.map((item, index) => {
        //   if (index === 0) {
        //     item.isLastEvent = true;
        //   } else {
        //     item.isLastEvent = false;
        //   }
        //   return item;
        // });
        // this.homePageData.next(response);
      });
  }

  HomePageForSpecificRaceType(raceTypeId: number) {
    this.http.get<IHomeModel>(`${environment.API_URL}/home/race-type/${raceTypeId}`)
      .subscribe((response) => {
        console.warn({response});

        response.last_news.map((item, index) => {
          if (response.last_news.length == 1) {
            item.isLastEvent = true;
          } else {
            if (index === 0) {
              item.isLastEvent = true;
            } else {
              item.isLastEvent = false;
            }
          }
          return item;
        });

          this.raceHomeData.next(response);
          // case 1 :
          //   this.roadHomeData.next(response);
          //   break;
          // case 2 :
          //   this.trackHomeData.next(response);
          //   break;
          // case 3 :
          //   this.mtbHomeData.next(response);
          //   break;
          // case 4 :
          //   this.tourHomeData.next(response);
          //   break;

      });
  }
}

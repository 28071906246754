import { ClubTeamRiderRegistrationComponent } from './../registration/club-team-rider-registration/club-team-rider-registration.component';
import { AuthGuard } from './../auth/auth.guard';
import { LoginComponent } from './../auth/login/login.component';
import { AdminRegisterComponent } from './../registration/admin-register/admin-register.component';
import { CommitteesComponent } from './../components/committees/committees.component';
import { RiderTransferComponent } from './../registration/rider-transfer/rider-transfer.component';
import { MorenewsComponent } from './../components/morenews/morenews.component';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./../home/home.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NewsdetailsComponent} from "../newsdetails/newsdetails.component";
import {UaecfComponent} from "../uaecf/uaecf.component";
import {AboutComponent} from "../uaecf/about/about.component";
import {CommisionsComponent} from "../uaecf/commisions/commisions.component";
import {ConstitutionComponent} from "../uaecf/constitution/constitution.component";
import {FederationsComponent} from "../uaecf/federations/federations.component";
import {HallOfFameComponent} from "../uaecf/hall-of-fame/hall-of-fame.component";
import {HostUaecfEventComponent} from "../uaecf/hostUAECF_Event/host-uaecf-event/host-uaecf-event.component";
import {InternationOrganizationComponent} from "../uaecf/internation-organization/internation-organization.component";
import {ManagementBoardComponent} from "../uaecf/management-board/management-board.component";
import {PartnersComponent} from "../uaecf/partners/partners.component";
import {UciComponent} from "../uaecf/uci/uci.component";
import {CalendarComponent} from "../calendar/calendar.component";
import {RoadComponent} from "../road/road.component";
import {RegistrationComponent} from "../registration/registration.component";
import {RidersComponent} from "../registration/riders/riders.component";
import {AdministrativeComponent} from "../registration/administrative/administrative.component";
import {EventsRegisterComponent} from "../registration/events/events.component";
// import {SigninComponent} from "../components/signin/signin.component";
// import {RegisterComponent} from "../components/register/register.component";
import {PhotosComponent} from "../road/photos/photos.component";
import {NewsComponent} from "../road/news/news.component";
import {EventsComponent} from "../road/events/events.component";
import {VideosComponent} from "../road/videos/videos.component";
import {ResultComponent} from "../road/result/result.component";
import {EventdetailsComponent} from "../eventdetails/eventdetails.component";
import {NationalTeamComponent} from "../uaecf/nationalTeam/national-team/national-team.component";
import {NationalTeamDetailsComponent} from "../uaecf/nationalTeam/national-team-details/national-team-details.component";
import {TeamsClubsComponent} from "../teamsClubs/teams-clubs/teams-clubs.component";
import {TeamsClubsDetailsComponent} from "../teamsClubs/teams-clubs-details/teams-clubs-details.component";
import {ClubsDetailsComponent} from "../teamsClubs/clubs-details/clubs-details.component";
import {EntryByNameComponent} from "../uaecf/hostUAECF_Event/forms/entry-by-name/entry-by-name.component";
import {DelegationComponent} from "../uaecf/hostUAECF_Event/forms/delegation/delegation.component";
import {AccommodationComponent} from "../uaecf/hostUAECF_Event/forms/accommodation/accommodation.component";
import {VisaApplicationComponent} from "../uaecf/hostUAECF_Event/forms/visa-application/visa-application.component";
import {TrackComponent} from "../track/track.component";
import {MtbComponent} from "../mtb/mtb.component";
import {TourComponent} from "../tour/tour.component";
import {CalenderComponent} from "../footer/ActivityAndCalender/calender/calender.component";
import {ActivityRegistrationComponent} from "../footer/ActivityAndCalender/activity-registration/activity-registration.component";
import {ComplaintsComponent} from "../footer/ActivityAndCalender/complaints/complaints.component";
import {RaceTypeRankingsComponent} from "../ranking/race-type-rankings/race-type-rankings.component";
import {RaceTypeRankingsDetailsComponent} from "../ranking/race-type-rankings-details/race-type-rankings-details.component";
import {ClubRiderRegistrationComponent} from "../registration/club-rider-registration/club-rider-registration.component";
import {TeamRiderRegistrationComponent} from "../registration/team-rider-registration/team-rider-registration.component";
import {CorusesSemenarsRegistrationComponent} from "../footer/ActivityAndCalender/coruses-semenars-registration/coruses-semenars-registration.component";
import {CommitiesrefernceComponent} from "../footer/commitiesrefernce/commitiesrefernce.component";
import {TrainingPermissionComponent} from "../footer/training-permission/training-permission.component";
import {RiderDetailsComponent} from "../uaecf/nationalTeam/rider-details/rider-details.component";
import {TeamOfficialDetailsComponent} from "../uaecf/nationalTeam/team-official-details/team-official-details.component";
import {ClubOfficialDetailsComponent} from "../uaecf/nationalTeam/club-official-details/club-official-details.component";
import {ContactusComponent} from "../footer/contactus/contactus.component";
import {FlightScheduleComponent} from "../uaecf/hostUAECF_Event/forms/flight-schedule/flight-schedule.component";
import { PaycanceledComponent } from '../payment/paycanceled/paycanceled.component';
import { PayfailedComponent } from '../payment/payfailed/payfailed.component';
import { PaysuccessComponent } from '../payment/paysuccess/paysuccess.component';
import { Role } from '../auth/role';

export const routes: Routes = [
  {path: "", redirectTo: "/home", pathMatch: "full"},
  // { path: 'register', component: RegistrationComponentComponent },
  // { path: 'list', component: ListComponentComponent },
  {path: "home", component: HomeComponent},
  {path: "signin", component: LoginComponent},
  {path: "signin/:returnUrl", component: LoginComponent},
  {path: "uaecf", component: UaecfComponent},
  {path: "about", component: AboutComponent},
  {path: "commisions", component: CommisionsComponent},
  {path: "constitution", component: ConstitutionComponent},
  {path: "federations", component: FederationsComponent},
  {path: "halloffame", component: HallOfFameComponent},
  {path: "hostevent", component: HostUaecfEventComponent},
  {path: "hostuaevent/entrybyName/:id", component: EntryByNameComponent},
  {path: "hostuaevent/delegation/:id", component: DelegationComponent},
  {path: "hostuaevent/accommodation/:id", component: AccommodationComponent},
  {path: "hostuaevent/visa/:id", component: VisaApplicationComponent},
  {path: "hostuaevent/flight-schedule/:id", component: FlightScheduleComponent},
  {path: "organizations", component: InternationOrganizationComponent},
  {path: "board", component: ManagementBoardComponent},
  {path: "partners", component: PartnersComponent},
  {path: "uci", component: UciComponent},
  {path: "calendar", component: CalendarComponent},
  {path: "race-type/:id", component: RoadComponent},
  {path: "mtb", component: RoadComponent},
  {path: "mtb/home", component: MtbComponent},
  {path: "track/home", component: TrackComponent},
  {path: "tour", component: RoadComponent},
  {path: "tour/home", component: TourComponent},
  {path: "registration", component: RegistrationComponent, canActivate: [AuthGuard]},
  {path: "registration/registerRider", component: RidersComponent, canActivate: [AuthGuard], data: { roles: [Role.Rider] }},
  {path: "registration/registerAdmin", component: AdminRegisterComponent, canActivate: [AuthGuard],data: { roles: [Role.Club, Role.Team] }},
  {path: "jointEvent/:id/:registerType/:date/:price/:ctId", component: AdministrativeComponent, canActivate: [AuthGuard]},
  {path: "registration/registerRider/:id/:clubOrTeam/:name", component: ClubTeamRiderRegistrationComponent, canActivate: [AuthGuard], data: { roles: [Role.Club, Role.Team] }},
  {path: "registration/registerEvent", component: EventsRegisterComponent, canActivate: [AuthGuard],data: { roles: [Role.Club, Role.Team] }},
  {path: "registration/club-rider-registration", component: ClubRiderRegistrationComponent, canActivate: [AuthGuard],data: { roles: [Role.Club] }},
  {path: "registration/team-rider-registration", component: TeamRiderRegistrationComponent, canActivate: [AuthGuard],data: { roles: [Role.Team] }},
  // {path: "signin", component: SigninComponent, canActivate: [AuthGuard]},
  // {path: "register", component: RegisterComponent, canActivate: [AuthGuard]},
  {path: "photos", component: PhotosComponent},
  {path: "photos/:id", component: PhotosComponent},
  {path: "photos/:id/:date", component: PhotosComponent},
  {path: "news", component: NewsComponent},
  {path: "news/:id", component: NewsComponent},
  {path: "news-details/:id", component: NewsdetailsComponent},
  {path: "events", component: EventsComponent},
  {path: "events/:id", component: EventsComponent},
  {path: "event/:id", component: EventdetailsComponent},
  {path: "videos", component: VideosComponent},
  {path: "videos/:id", component: VideosComponent},
  {path: "videos/:id/:date", component: VideosComponent},
  {path: "eventresult/:id", component: ResultComponent},
  {path: "national-teams", component: NationalTeamComponent},
  {path: "national-teams/:id", component: NationalTeamDetailsComponent},
  {path: "national-teams/riders/:id", component: RiderDetailsComponent},
  {path: "officials/team/:id", component: TeamOfficialDetailsComponent},
  {path: "officials/club/:id", component: ClubOfficialDetailsComponent},
  {path: "clubs-teams", component: TeamsClubsComponent},
  {path: "teams-details/:id", component: TeamsClubsDetailsComponent},
  {path: "clubs-details/:id", component: ClubsDetailsComponent},
  {path: "footer/calender", component: CalenderComponent},
  {path: "footer/registerActivity", component: ActivityRegistrationComponent},
  {path: "footer/add-activity/:id", component: CorusesSemenarsRegistrationComponent},
  {path: "footer/complaint", component: ComplaintsComponent, canActivate: [AuthGuard]},
  {path: "footer/referees", component: CommitiesrefernceComponent},
  {path: "footer/training", component: TrainingPermissionComponent, canActivate: [AuthGuard]},
  {path: "footer/contactUs", component: ContactusComponent},
  {path: "rankings/:id", component: RaceTypeRankingsComponent},
  {path: "paysuccess", component: PaysuccessComponent, canActivate: [AuthGuard]},
  {path: "payfailed", component: PayfailedComponent, canActivate: [AuthGuard]},
  {path: "paycanceled", component: PaycanceledComponent, canActivate: [AuthGuard]},
  {path: "ridertransfer", component: RiderTransferComponent, canActivate: [AuthGuard], data: { roles: [Role.Club] }},
  {path: "committees/:url", component: CommitteesComponent},
  {path: "rankings-details", component: RaceTypeRankingsDetailsComponent},
  {path: "morenews", component: MorenewsComponent}
];

@NgModule({
  imports: [CommonModule,
    RouterModule.forRoot(routes, {relativeLinkResolution: "legacy", scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
}

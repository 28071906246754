import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {BackendApiService} from "../../backend-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {NewsServices} from "../../services/news-service";
import {Subscription} from "rxjs";
import {INewsModel} from "../../model/news.model";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, OnChanges {
  environment = environment;
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  selectedYear = new Date().getFullYear();
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  last_news: any;
  calendar: any;
  last_event: any;
  photos: any;
  newsData: INewsModel[];
  private routeDataSubscription: Subscription;
  private raceTypeId: number;

  constructor(
    private backendService: BackendApiService,
    public api: NewsServices,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
  }

  loadNewsData() {
    this.api.getNews(this.raceTypeId).subscribe((data: any) => {
      this.newsData = data.new as INewsModel[];
      this.updatedNewsAppearance();
    });
  }

  updatedNewsAppearance() {
    this.newsData.forEach(item => {
      item.isHidden = item.year == this.selectedYear;
      return item;
    });
  }

  ngOnInit() {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.raceTypeId = data.get('id') != null ? +data.get('id') : null;
      this.loadNewsData();
    });
  }

  selectedYearChanged() {
    this.updatedNewsAppearance();
  }

  loadEventDetails(id: number) {
    this.router.navigate([`/news-details/${id}`]);
  }

}

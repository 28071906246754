import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BackendApiService } from './../backend-api.service';

@Component({
  selector: 'app-list-component',
  templateUrl: './list-component.component.html',
  styleUrls: ['./list-component.component.css']
})
export class ListComponentComponent implements OnInit {

  cyclist_list;
  heroesUrl = 'https://web.uaecf.ae/boarders';

  constructor(private backendService: BackendApiService) { }

  ngOnInit() {

    this.backendService.getList().subscribe(
      (response: HttpResponse<any>) => {
        //console.log(response['data']);
        this.cyclist_list = response['data'];
      },
      (error) => {
        //console.log(error);
      }
    );

    // return this.http.get(this.heroesUrl, requestOptions)


    // this.http.get<any>('https://web.uaecf.ae/boarders').subscribe(data => {
    //   this.cyclist_list = data.total;
    // })
  }



}

<div class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "App.ContactUs" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "App.ContactUs" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <div class="contactus__grid">
      <div class="contactus__card">
        <img
          class="contactus__icon"
          src="../../../assets/images/location1.svg"
        />
        <h5 class="contactus__title">
          {{ "Footer.ContactUs.UAECFAdressLabel" | translate }}
        </h5>
        <p class="contactus__text">
          {{ "Footer.ContactUs.UAECFAdress1" | translate }}<br />
          {{ "Footer.ContactUs.UAECFAdress2" | translate }}<br />
          {{ "Footer.ContactUs.UAECFAdress3" | translate }}
        </p>
      </div>
      <div class="contactus__card">
        <img class="contactus__icon" src="../../../assets/images/phone1.svg" />
        <h5 class="contactus__title">
          {{ "Footer.ContactUs.UAECFPhoneLabel" | translate }}
        </h5>
        <p class="contactus__text">
          {{ "Footer.ContactUs.UAECFPhone" | translate }}
        </p>
      </div>
      <div class="contactus__card">
        <img class="contactus__icon" src="../../../assets/images/mail1.svg" />
        <h5 class="contactus__title">
          {{ "Footer.ContactUs.UAECFEmailLabel" | translate }}
        </h5>
        <p class="contactus__text">
          {{ "Footer.ContactUs.UAECFEmail" | translate }}
        </p>
      </div>
    </div>
    <p class="contactus__message">
      {{ "Footer.ContactUs.ContactUsText1" | translate }} 
      {{ "Footer.ContactUs.ContactUsText2" | translate }}
    </p>
    <form
      class="form contactus__form"
      [formGroup]="ContactUsForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-group">
        <label for="name" class="form-label">{{
          "Footer.ContactUs.YourName" | translate
        }}</label>
        <input
          type="text"
          class="form-control"
          id="name"
          formControlName="name"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
        />
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required">
            {{ "Footer.ContactUs.YourNameRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="phone" class="form-label">{{
          "Footer.ContactUs.PhoneNumber" | translate
        }}</label>
        <input
          type="tel"
          formControlName="phone"
          id="phone"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
        />
        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
          <div *ngIf="f.phone.errors.required">
            {{ "Footer.ContactUs.PhoneNumberRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="email" class="form-label">{{
          "Footer.ContactUs.Email" | translate
        }}</label>
        <input
          type="email"
          id="email"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">
            {{ "Footer.ContactUs.EmailRequired" | translate }}
          </div>
          <div *ngIf="f.email.errors.email">
            {{ "Footer.ContactUs.EmailInvalid" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="subject" class="form-label">{{
          "Footer.ContactUs.YourSubject" | translate
        }}</label>
        <input
          type="text"
          id="subject"
          formControlName="subject"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"
        />
        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
          <div *ngIf="f.subject.errors.required">
            {{ "Footer.ContactUs.SubjectRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group form-group_textarea">
        <label for="question" class="form-label">{{
          "Footer.ContactUs.YourQuestion" | translate
        }}</label>
        <textarea
          class="form-control"
          rows="6"
          id="question"
          formControlName="question"
          [ngClass]="{ 'is-invalid': submitted && f.question.errors }"
        ></textarea>
        <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
          <div *ngIf="f.question.errors.required">
            {{ "Footer.ContactUs.QuestionRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group form-group_button-center">
        <button
          type="button"
          class="button button_theme button_md"
          (click)="onSubmit()"
        >
          {{ "Footer.ContactUs.SendButton" | translate }}
        </button>
      </div>
    </form>
  </section>
</div>

import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as localforage from 'localforage';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.authenticationService.currentUserValue;
    let Activated = false;



    if (user) {
      // check if route is restricted by role
      // console.log("us from guard", route.data);

      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
        // role not authorised so redirect to home page
        // this.router.navigate(['/']);
        Activated = false;
      } else {
        Activated = true;

      }

    } else {

      //   // not logged in so redirect to login page with the return url
      // this.router.navigate(['/signin'], { queryParams: { returnUrl: state.url } });

    }


    return Activated;
  }
}

import {Component, OnInit} from '@angular/core';
import {ManagementBaordService} from "../../services/management-baord.service";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-management-board',
  templateUrl: './management-board.component.html',
  styleUrls: ['./management-board.component.css']
})
export class ManagementBoardComponent implements OnInit {

  environment = environment;

  constructor(public baordManagementService: ManagementBaordService) {
  }

  ngOnInit() {
    this.baordManagementService.loadManagementBoardData();
  }

}

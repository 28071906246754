import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internation-organization',
  templateUrl: './internation-organization.component.html',
  styleUrls: ['./internation-organization.component.css']
})
export class InternationOrganizationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

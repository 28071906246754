<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "TeamRiderRegistration.Header" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "TeamRiderRegistration.Header" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <form
      class="form registration-form rider-registration-form"
      [formGroup]="teamRiderRegistrationForm"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="form-group"
        *ngIf="clubTeamService.teamsData$ | async as teams"
      >
        <select
          class="form-select"
          (change)="getTeamDetails()"
          formControlName="team"
        >
          <option value="">
            {{ "TeamRiderRegistration.ChooseTeam" | translate }}
          </option>
          <option *ngFor="let team of teams" [ngValue]="team.id">
            {{ team.name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.team.errors" class="invalid-feedback">
          <div *ngIf="f.club.errors.required">
            {{ "TeamRiderRegistration.TeamRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field appearance="standard">
          <mat-label>{{
            "TeamRiderRegistration.ChooseDate" | translate
          }}</mat-label>
          <input matInput [matDatepicker]="datePicker" formControlName="date" />
          <mat-datepicker-toggle
            matSuffix
            [for]="datePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
          <div *ngIf="f.date.errors.required">
            {{ "TeamRiderRegistration.DateRequired" | translate }}
          </div>
        </div>
      </div>

      <!--    Add Lines &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->

      <div class="table-responsive registration-form__table">
        <table class="table table_default">
          <thead>
            <tr>
              <th scope="col">
                {{ "TeamRiderRegistration.FederationID" | translate }}
              </th>
              <th scope="col">
                {{ "TeamRiderRegistration.FullName" | translate }}
              </th>
              <th scope="col">
                {{ "TeamRiderRegistration.DateOfBirth" | translate }}
              </th>
              <th scope="col">
                {{ "TeamRiderRegistration.OldNew" | translate }}
              </th>
              <th scope="col">
                {{ "TeamRiderRegistration.Nationality" | translate }}
              </th>
              <th scope="col">
                {{ "TeamRiderRegistration.Category" | translate }}
              </th>
              <th scope="col">
                {{ "TeamRiderRegistration.HealthFitness" | translate }}
              </th>
              <th scope="col">*</th>
            </tr>
          </thead>
          <tbody formArrayName="lines">
            <tr
              *ngFor="let line of lines.controls; let i = index; let l = last"
              [formGroupName]="i"
            >
              <td>
                <div *ngIf="teamRidersLicenses">
                  <div *ngIf="teamRidersLicenses">
                    <mat-form-field appearance="standard">
                      <mat-select
                        formControlName="federation_id"
                        (selectionChange)="ChangeRiderLicense($event, i)"
                      >
                        <!-- <mat-option>
<button mat-button (click)="OpenRiderRegistrationForm()">Create New Rider</button>
</mat-option> -->
                        <ng-container
                          *ngFor="let license of teamRidersLicenses"
                        >
                          <mat-option *ngIf="license" [value]="license.id">{{
                            license.federation_id
                          }}</mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                    <a
                      routerLink="/national-teams/riders/{{
                        lines.controls[i].get('federation_id').value
                      }}"
                      target="_blank"
                      class="fas fa-external-link-alt"
                      >{{ "TeamRiderRegistration.Rider" | translate }}
                      <i class="fa fa-link"> </i
                    ></a>
                  </div>
                </div>
              </td>

              <td>
                <div *ngIf="teamRidersNames">
                  <mat-form-field appearance="standard">
                    <mat-select
                      formControlName="fullName"
                      (selectionChange)="ChangeRiderName($event, i)"
                    >
                      <!--                                        <mat-option>-->
                      <!--                                            <button mat-button (click)="OpenRiderRegistrationForm()">{{'TeamRiderRegistration.CreateRider' | translate}}</button>-->
                      <!--                                        </mat-option>-->
                      <ng-container *ngFor="let name of teamRidersNames">
                        <mat-option *ngIf="name" [value]="name.id">{{
                          name.name
                        }}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <mat-form-field appearance="standard">
                  <mat-label>{{
                    "TeamRiderRegistration.DateOfBirth" | translate
                  }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="dateOfBirthPicker"
                    formControlName="dateOfBirth"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateOfBirthPicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateOfBirthPicker></mat-datepicker>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="standard">
                  <input matInput formControlName="isOld" />
                </mat-form-field>
              </td>

              <td>
                <div *ngIf="api.nationalities$ | async as nationalities">
                  <mat-form-field appearance="standard">
                    <mat-label>{{
                      "TeamRiderRegistration.ChooseNationality" | translate
                    }}</mat-label>
                    <mat-select formControlName="nationality">
                      <mat-option
                        *ngFor="let nationality of nationalities"
                        [value]="nationality.id"
                        >{{ nationality.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>

              <td>
                <div
                  *ngIf="api.clubTeamCategories$ | async as clubTeamCategories"
                >
                  <mat-form-field appearance="standard">
                    <mat-label>{{
                      "TeamRiderRegistration.ChooseCategory" | translate
                    }}</mat-label>
                    <mat-select formControlName="category">
                      <mat-option
                        *ngFor="let category of clubTeamCategories"
                        [value]="category.id"
                        >{{ category.code }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <mat-checkbox
                  formControlName="healthFitness"
                  class="mt-5"
                  style="margin-left: 14px"
                ></mat-checkbox>
              </td>
              <td>
                <button mat-button class="mt-4" (click)="removeLine(i)">
                  {{ "TeamRiderRegistration.RemoveLine" | translate }}
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <button mat-button (click)="addLine($event)">
                  {{ "TeamRiderRegistration.AddLine" | translate }}
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--    Add Lines &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->

      <div class="form-group" *ngIf="doctors">
        <!--        <div class="row"> {{clubs | json}}</div>-->
        <label class="form-label">
          {{ "TeamRiderRegistration.DoctorName" | translate }}</label
        >
        <select class="form-select" formControlName="doctorName">
          <option value="" disabled>
            {{ "TeamRiderRegistration.ChooseDoctor" | translate }}
          </option>
          <option *ngFor="let doctor of doctors" [ngValue]="doctor.id">
            {{ doctor.full_name[0].full_name }}
          </option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.doctorName.errors" class="invalid-feedback">
          <div *ngIf="f.doctorName.errors.required">
            {{ "TeamRiderRegistration.DoctorRequired" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "TeamRiderRegistration.TeamManager" | translate
        }}</label>
        <input
          type="text"
          formControlName="secretary"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.secretary.errors }"
        />
        <div *ngIf="submitted && f.secretary.errors" class="invalid-feedback">
          <div *ngIf="f.secretary.errors.required">
            {{ "TeamRiderRegistration.TeamManagerRequired" | translate }}
          </div>
        </div>
      </div>

      <div class="registration-form__terms">
        <h5>{{ "TeamRiderRegistration.Notes" | translate }}:</h5>
        <div class="registration-form__terms-inner">
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.PaymentConfirmation'
                | translate
            "
          ></p>
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.RefundAndCancellationPolicy'
                | translate
            "
          ></p>
          <p
            [innerHTML]="
              'Footer.ActivityAndCalender.Complaints.Terms.ServicePricing'
                | translate
            "
          >
            :<strong
              [innerHTML]="
                calculatePrice() +
                  ' Footer.ActivityAndCalender.Complaints.Terms.Currency'
                  | translate
              "
            ></strong>
          </p>
        </div>
      </div>
      <mat-checkbox
        unchecked="agreedTerms"
        (click)="changeAgreedTerm()"
        class="form-group registration-form__agreement"
      >
        {{ "TeamRiderRegistration.IHaveReadAll" | translate }}&nbsp;<a
          (click)="openDialog(1)"
          >{{ "TeamRiderRegistration.TermsLable" | translate }} </a
        >,<a (click)="openDialog(2)">
          {{ "TeamRiderRegistration.PolicyLable" | translate }}</a
        >
        {{ "TeamRiderRegistration.AgreeLable" | translate }}
      </mat-checkbox>

      <div class="form-group registration-form__submit-wrap">
        <button
          type="submit"
          class="button button_theme button_md"
          [disabled]="!agreedTerms"
        >
          {{ "TeamRiderRegistration.SubmitButtonLable" | translate }}
        </button>
      </div>
    </form>
  </section>
</div>

import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NewsServices} from "../services/news-service";
import {Subscription} from "rxjs";

@Component({
  selector: "app-newsdetails",
  templateUrl: "./newsdetails.component.html",
  styleUrls: ["./newsdetails.component.css"],
})
export class NewsdetailsComponent implements OnInit , OnDestroy{
  routeDataSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public api: NewsServices
  ) {
  }

  ngOnInit() {
   this.routeDataSubscription =  this.route.paramMap.subscribe(data => {
      const newsId = +data.get('id');
      this.loadSpecificNewsDetails(newsId);
    });
    // const newsId = +this.route.snapshot.paramMap.get("id");
  }

  loadSpecificNewsDetails(newsId: number) {
    this.api.getSpecificNewsDetails(newsId);
  }

  ngOnDestroy(): void {
    this.routeDataSubscription.unsubscribe();
  }
}

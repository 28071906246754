<div id="mainContent" class="container container__page" *ngIf="List">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "UAECF.Constitution.Constitution" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "UAECF.Constitution.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "UAECF.Constitution.Constitution" | translate }}</li>
    </ul>
  </div>
  <div class="page-body constitution">
    <div class="constitution__image-wrap">
      <img src="../../../assets/images/illustration.png" />
    </div>
    <div class="constitution__text-wrap">
      <h1 class="h2 mb1">
        {{ "UAECF.Constitution.ConstitutionUEACF" | translate }}
      </h1>
      <p class="mb1">
        {{ "UAECF.Constitution.UAECFCON" | translate }}
      </p>
      <h5 class="mb1">
        {{ "UAECF.Constitution.UAECFPresident" | translate }}
      </h5>
      <p class="mb1">
        <strong
          >{{
            "UAECF.Constitution.ConstitutionUEACF" | translate
          }}:&nbsp;</strong
        ><a href="{{ environment.API_URL + List[0].arabic }}"
          ><em>{{ "UAECF.Constitution.ArabicVersion" | translate }}</em></a
        >&nbsp;/&nbsp;<a href="{{ environment.API_URL + List[0].english }}"
          ><em>{{ "UAECF.Constitution.EnglishVersion" | translate }}</em>
        </a>
      </p>
    </div>
  </div>
</div>

<div
  *ngIf="riderData as riderDetails"
  id="mainContent"
  class="container container__page"
>
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "CommonText.Riders" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li *ngIf="riderDetails.register_type == 'team'">
        <a routerLink="/national-teams/1" class="link"
          >{{ "App.NationalTeams" | translate }} >&nbsp;</a
        >
      </li>
      <li *ngIf="riderDetails.register_type == 'club'">
        <a routerLink="/clubs-teams" class="link"
          >{{ "UAECF.ClubsAndTeams.ClubsAndTeams" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "CommonText.Riders" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <section class="club-info">
      <div class="club-info__photo-block">
        <img
          [src]="environment.API_URL + riderDetails.img_url"
          class="club-info__image"
          alt=""
        />
        <h1 class="h5 club-info__title">{{ riderDetails.full_name }}</h1>
      </div>
      <div class="club-info__table-block">
        <div
          class="club-info__club-name"
          *ngIf="environment.API_URL + riderDetails.club_img"
        >
          <img [src]="environment.API_URL + riderDetails.club_img" />
          <h6 class="club-info__club-name-text" *ngIf="riderDetails">
            {{
              riderDetails.club?.club_name == false
                ? riderDetails.team?.team_name[1]
                : riderDetails.club?.club_name[1]
            }}
          </h6>
        </div>
        <div class="club-info__table-block-inner">
          <h5 class="club-into__table-title">
            {{ "UAECF.RiderDetails.PersonalDetails" | translate }}
          </h5>
          <table class="table table-varient1">
            <tbody>
              <tr *ngIf="riderDetails.nationality">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.Nationality" | translate }}
                  </span>
                </th>
                <td>
                  {{ riderDetails.nationality.nationality[1] }}
                </td>
              </tr>
              <!-- <tr *ngIf="riderDetails.license_no">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.BibNo" | translate }}
                  </span>
                </th>
                <td>
                  {{ riderDetails.license_no }}
                </td>
              </tr> -->
              <tr *ngIf="riderDetails.date_birth">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.DateOfBirth" | translate }}
                  </span>
                </th>
                <td>
                  {{ riderDetails.date_birth }}
                </td>
              </tr>
              <tr
                *ngIf="
                  nationalTeamService.getRiderStatus(riderDetails.rider_type)
                "
              >
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.RiderType" | translate }}
                  </span>
                </th>
                <td>
                  {{
                    "RiderStatuses." +
                      nationalTeamService.getRiderStatus(
                        riderDetails.rider_type
                      ) | translate
                  }}
                </td>
              </tr>
              <tr *ngIf="riderDetails.category">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.Category" | translate }}
                  </span>
                </th>
                <td>
                  {{ riderDetails.category }}
                </td>
              </tr>
              <tr *ngIf="riderDetails.gender">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.Gender" | translate }}
                  </span>
                </th>
                <td>
                  {{ riderDetails.gender }}
                </td>
              </tr>
              <tr *ngIf="riderDetails.individule_point">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.IndividualPoints" | translate }}
                  </span>
                </th>
                <td>
                  {{ riderDetails.individule_point }}
                </td>
              </tr>
              <tr *ngIf="riderDetails.uci_id">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.UCIID" | translate }}
                  </span>
                </th>
                <td>
                  {{ riderDetails.uci_id }}
                </td>
              </tr>
              <tr *ngIf="riderDetails.federation_id">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.RiderDetails.FederationId" | translate }}
                  </span>
                </th>
                <td>
                  {{ riderDetails.federation_id }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </section>
</div>

<div class="container pt-5 pb-5 entryByName-form">
  <form class="row g-3" [formGroup]="eventClubRiderRegistrationForm" (ngSubmit)="onSubmit()">
<!--    <h2 class="h1 text-danger fw-bold">{{ "EventClubRegister.Header" | translate}}</h2>-->

    <div class="col-md-12" *ngIf="(eventService.eventDetails$ | async) as eventDetailsData">
      <!--        <div class="row"> {{clubs | json}}</div>-->
      <div class="h1 text-danger fw-bold" *ngFor="let detail of eventDetailsData.details">
        <label *ngIf="isClubRegistration"> {{detail.event}} {{'EventClubRegister.ClubRegistration' | translate}}</label>
        <label *ngIf="isTeamRegistration"> {{detail.event}} {{'EventClubRegister.TeamRegistration' | translate}}</label>
        <p></p>
      </div>
    </div>

    <div class="row mt-4 mb-4">
      <div class="col-md-6" *ngIf="isClubRegistration && (clubTeamService.clubsData$ | async) as clubs">
        <!--        <div class="row"> {{clubs | json}}</div>-->
<!--        {{clubName}}-->
        <label> {{'EventClubRegister.ClubName' | translate}}</label>
        <ng-container *ngIf="clubName">
        <select class="form-select" (change)="getClubDetails()"  [value]='teamCLubValue' formControlName="club" disabled >
          <option value="" disabled>{{'EventClubRegister.ChooseClub' | translate}}</option>

          <option *ngFor="let club of clubs" [ngValue]="club.id">{{club.name}}</option>
        </select>
        </ng-container>
        <!-- error block -->
        <!-- <div *ngIf="submitted && f.club.errors" class="invalid-feedback">
  <div *ngIf="f.club.errors.required">Please Select Club</div>
</div> -->
      </div>

      <div class="col-md-6" *ngIf="isTeamRegistration && (clubTeamService.teamsData$ | async) as teams" >
        <!--        <div class="row"> {{clubs | json}}</div>-->
        <label> {{'EventClubRegister.TeamName' | translate}}</label>
        <select class="form-select" (change)="getTeamDetails()" formControlName="team" disabled >
          <option value="" disabled>{{'TeamRiderRegistration.ChooseTeam' | translate}}</option>
          <option *ngFor="let team of teams" [ngValue]="team.id">{{team.name}}</option>
        </select>
        <!-- error block -->
        <!-- <div *ngIf="submitted && f.club.errors" class="invalid-feedback">
  <div *ngIf="f.team.errors.required">Please Select team</div>
</div> -->
      </div>

      <div class="col-md-6" *ngIf="(eventService.eventDetails$ | async) as eventDetailsData">
        <!--        <div class="row"> {{clubs | json}}</div>-->
        <div *ngFor="let detail of eventDetailsData.details">
          <label class="date-text"> {{'EventClubRegister.Date' | translate}} <Span>{{detail.date}}</Span></label>
        </div>
      </div>


    </div>


    <!--    Add Lines &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->


    <div class="row mt-4 mb-4">
      <table class="table .table-bordered .table-sm  table-hover">
        <thead>
        <tr>
          <th scope="col">{{'EventClubRegister.FederationID' | translate}}</th>
          <th scope="col">{{'EventClubRegister.FullName' | translate}}</th>
          <th scope="col">{{'EventClubRegister.UCIID' | translate}}</th>
          <th scope="col">{{'EventClubRegister.BIBNO' | translate}}</th>
          <th scope="col">{{'EventClubRegister.Nationality' | translate}}</th>
          <th scope="col">{{'EventClubRegister.Category' | translate}}</th>
        </tr>
        </thead>
        <tbody formArrayName="lines" style="justify-content: center">

        <tr *ngFor="let line of lines.controls; let i=index;let l=last" [formGroupName]="i">
          <td>
            <div *ngIf="clubRidersLicenses">
              <mat-form-field appearance="standard">
                <mat-select formControlName="federation_id" (selectionChange)="ChangeRiderLicense($event , i)">
                  <ng-container *ngFor="let license of clubRidersLicenses">
                    <mat-option *ngIf="license" [value]="license.id">{{license.federation_id}}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </td>

          <td>

            <div *ngIf="clubRidersNames">
              <mat-form-field appearance="standard">
                <mat-select formControlName="fullName" (selectionChange)="ChangeRiderName($event , i)">
                  <ng-container *ngFor="let name of clubRidersNames">
                    <mat-option *ngIf="name" [value]="name.id">{{name.name}}</mat-option>
                  </ng-container>

                </mat-select>
              </mat-form-field>
            </div>
          <td>
            <mat-form-field appearance="standard">
              <mat-label>{{'EventClubRegister.UCIID' | translate}}</mat-label>
              <input matInput formControlName="UCI">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="standard">
              <mat-label>{{'EventClubRegister.BIBNO' | translate}}</mat-label>
              <input matInput formControlName="bib_no">
            </mat-form-field>
          </td>

          <td>
            <div *ngIf="(api.nationalities$ | async) as nationalities">
              <mat-form-field appearance="standard">
                <mat-label>{{'EventClubRegister.ChooseNationality' | translate}}</mat-label>
                <mat-select formControlName="nationality">
                  <mat-option *ngFor="let nationality of nationalities"
                              [value]="nationality.id">{{nationality.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </td>

          <td>
            <div *ngIf="(api.clubTeamCategories$ | async) as clubTeamCategories">
              <mat-form-field appearance="standard">
                <mat-label>{{'EventClubRegister.ChooseCategory' | translate}}</mat-label>
                <mat-select formControlName="category">
                  <mat-option *ngFor="let category of clubTeamCategories"
                              [value]="category.id">{{category.code}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </td>

          <td>
            <button mat-button class="mt-4" (click)="removeLine(i)">{{'EventClubRegister.RemoveLine' | translate}}
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <span mat-button (click)="addLine()">{{'EventClubRegister.AddLine' | translate}}</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>


    <!--    Add Lines &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
    <div class="registration-form__terms" *ngIf="!isClubRegistration">
      <h3>{{'EventClubRegister.Notes' | translate}}:</h3>
      <p [innerHTML]="'Footer.ActivityAndCalender.Complaints.Terms.PaymentConfirmation' | translate"></p>
      <p [innerHTML]="'Footer.ActivityAndCalender.Complaints.Terms.RefundAndCancellationPolicy' | translate"></p>
      <p
        [innerHTML]="'Footer.ActivityAndCalender.Complaints.Terms.ServicePricing' | translate">
        :<strong
        [innerHTML]="calculatePrice() + ' Footer.ActivityAndCalender.Complaints.Terms.Currency' | translate"></strong>
      </p>
      <p>
        <mat-checkbox unchecked="agreedTerms" (click)="changeAgreedTerm()"></mat-checkbox>
        {{'EventClubRegister.IHaveReadAll' | translate}}<strong
        (click)="openDialog(1)">{{'EventClubRegister.TermsLable' | translate}} </strong>,<strong
        (click)="openDialog(2)"> {{'EventClubRegister.PolicyLable' | translate}}</strong>                {{'EventClubRegister.AgreeLable' | translate}}
      </p>
    </div>


    <div class="d-flex justify-content-center">
      <button type="submit" class="btn btn-lg host-btn submit-btn"
              [disabled]="!agreedTerms && !isClubRegistration">{{'EventClubRegister.SubmitButtonLable' | translate}}</button>
    </div>
  </form>
</div>

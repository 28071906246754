import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {NationalTeamService} from "../../../services/national-team.service";
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-national-team-details',
  templateUrl: './national-team-details.component.html',
  styleUrls: ['./national-team-details.component.css']
})
export class NationalTeamDetailsComponent implements OnInit, OnDestroy {
  routeDataSubscription: Subscription;
  environment = environment;

  constructor(public api: NationalTeamService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      const nationalTeamId = +data.get('id');
      this.api.LoadSpecificNationalTeamInformation(nationalTeamId);
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription.unsubscribe();
  }

  OpenAchivment(achivement: string) {
    if (achivement) {
      window.open(`${environment.API_URL}${achivement}`, "_blank");
    } else {
      window.open(`${environment.API_URL}`, "_blank");
    }
  }

}

import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {AboutService} from "../../services/about.service";

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  public env = environment;

  constructor(public aboutService: AboutService) {
  }

  ngOnInit() {
    this.aboutService.loadPartnersData();
  }

}

import { IVideoDataModel, IVideoResponseDataModel } from './../model/photo.model';
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {IPhotoDataModel} from "../model/photo.model";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PhotosService {

  racePhotosData = new BehaviorSubject<IPhotoDataModel>({
    photo: [],
    event: []
  });

  raceVideosData = new BehaviorSubject<IVideoResponseDataModel>({
    videos: [],
    event: []
  });


  eventId = new BehaviorSubject<number>(0);
  selectedYear = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {
  }

  get racePhotosData$(): Observable<IPhotoDataModel> {
    return this.racePhotosData.asObservable();
  }

  get raceVideosData$(): Observable<IVideoResponseDataModel> {
    return this.raceVideosData.asObservable();
  }

  get eventId$(): Observable<number> {
    return this.eventId.asObservable();
  }

  get selectedYear$(): Observable<number> {
    return this.selectedYear.asObservable();
  }

  resetPhotosData() {
    //console.log('reset photos data');
    this.racePhotosData.next({
      photo: [],
      event: []
    });
  }

  updateEventIdLocally(eventId: number) {
    //console.log('eventId is  changes to ', eventId);
    this.eventId.next(eventId);
  }

  updateYearLocally(year: number) {
    //console.log('eventId is  changes to ', year);
    this.selectedYear.next(year);
  }

  loadRaceImageData(id?: number) {
    //console.log('race id is ', id);
    let url = '';
    if (id) {
      url = `${environment.API_URL}/photos/${id}`;
    } else {
      url = `${environment.API_URL}/photos`;
    }
    this.http.get<IPhotoDataModel>(url)
      .subscribe(response => {
        console.warn('service result are   : ', response);
        response.photo.map(item => {
          item.img_url = environment.API_URL + item.img_url;
          return item;
        });
        this.racePhotosData.next(response);
      });
  }

  loadRaceVideoData(id?: number) {
    //console.log('race id is ', id);
    let url = '';
    if (id) {
      url = `${environment.API_URL}/videos-event/${id}`;
    } else {
      url = `${environment.API_URL}/videos-event`;
    }
    this.http.get<IVideoResponseDataModel>(url)
      .subscribe(response => {
        console.warn('service result are   : ', response);
        this.raceVideosData.next(response);
      });
  }
}

<div id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "Home.GeneralNews" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "Home.GeneralNews" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <ul class="card-list" *ngIf="generalNews">
        <li class="card" *ngFor="let news of generalNews">
          <a routerLink="/news-details/{{ news.id }}" class="">
            <div class="card__image-wrap">
              <img
                class="card__image"
                src="{{ news.img_url }}"
                alt=""
              />
            </div>
            <div class="card__body">
              <span class="category" *ngIf="news.category">{{news.category}}</span>
              <span class="category" *ngIf="!news.category">{{"Home.GeneralNews" | translate}}</span>
              <h4 class="h6">{{ news.name }}</h4>
              <p class="card__description">
                {{ news.subtitle }}
              </p>
              <span class="card__date">{{ news.date  | date: 'mediumDate' }}</span>
            </div>
          </a>
        </li>
    </ul>
  </section>
</div>

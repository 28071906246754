<div id="mainContent">
  <div *ngIf="api.homePageData$ | async as homeData">
    <section class="container container_section container_home-header">
      <div class="section-title-block section-title-block_header">
        <img
          class="section-title-block__image"
          src="../../assets/images/logo.svg"
        />
        <h2 class="section-title-block__title">
          {{ "Home.News" | translate }}
        </h2>
        <button
          class="section-title-block__button button_icon"
          routerLink="/news"
        >
          <img class="button__icon" src="../../assets/images/witness.svg" />
          {{ "Home.ViewAll" | translate }}
        </button>
      </div>
      <ul class="header-grid">
        <ng-container *ngFor="let news of homeData.last_news">
          <li class="header-grid__highlight" *ngIf="news.isLastEvent">
            <a
              routerLink="/news-details/{{ news.id }}"
              class="link contentImage"
              style="width: 100%; height: 100%"
            >
              <div class="header-grid__highlight-inner" data-src-href="#">
                <span class="info-icon info-icon_red info-icon_lg">
                  <img
                    class="info-icon__icon info-icon__icon_lg"
                    src="../../assets/images/newspaper.svg"
                  />
                </span>
                <img
                  class="header-grid__highlight-image"
                  src="{{ environment.API_URL + news.img_url }}"
                  alt=""
                />
                <div class="header-grid__highlight-text">
                  <div
                    class="contentDateType"
                    title="UAECF"
                    *ngFor="let category of news.category"
                  >
                    <span class="category category_primary">{{
                      category.category[1]
                    }}</span>
                    <h3>{{ news.name }}</h3>
                    <span class="date">{{ news.date }}</span>
                  </div>
                </div>
              </div>
            </a>
          </li>

          <li class="card header-grid__card" *ngIf="!news.isLastEvent">
            <a routerLink="/news-details/{{ news.id }}" class="">
              <div class="card__image-wrap" data-src-href="#">
                <img
                  class="card__image card__image_blur"
                  src="{{ environment.API_URL + news.img_url }}"
                  alt=""
                />
                <img
                  class="card__image"
                  src="{{ environment.API_URL + news.img_url }}"
                  alt=""
                />
              </div>
              <span class="info-icon">
                <img
                  class="info-icon__icon"
                  src="../../assets/images/event.svg"
                />
              </span>
              <div class="card__body">
                <div
                  class=""
                  title="UAECF"
                  *ngFor="let category of news.category"
                >
                  <span class="category">{{ category.category[1] }}</span>
                  <h4>
                    <span>{{ news.name }}</span>
                  </h4>
                  <span class="card__date">{{
                    news.date | date: "mediumDate"
                  }}</span>
                </div>
              </div>
            </a>
          </li>
        </ng-container>
      </ul>
    </section>
    <section class="alt-bg">
      <div class="container container_section container_calendar-events">
        <div class="events events_home">
          <div class="section-title-block">
            <img
              class="section-title-block__image"
              src="../../assets/images/logo.svg"
            />
            <h2 class="section-title-block__title">
              {{ "Home.Calendar" | translate }}
            </h2>
          </div>
          <div class="">
            <ul class="event-list">
              <li
                class="event-list__li"
                *ngFor="
                  let type of homeData.calendar
                    | paginate: { itemsPerPage: 5, currentPage: page }
                "
              >
                <a class="event-list__a" routerLink="/event/{{ type.id }}">
                  <div class="event-list__date">
                    <span class="event-list__date-text">
                      {{ type.date | date: "mediumDate" }}
                    </span>
                  </div>
                  <div class="event-list__info-block">
                    <span class="category">
                      {{ type.race_type[0].race_type[1] }}
                    </span>
                    <h3 class="h4 event-list__title">
                      {{ type.event_name }}
                    </h3>
                  </div>
                </a>
              </li>
            </ul>
            <pagination-controls
              (pageChange)="page = $event"
            ></pagination-controls>
          </div>
        </div>
        <div class="categories categories_home">
          <div class="section-title-block">
            <img
              class="section-title-block__image"
              src="../../assets/images/logo.svg"
            />
            <h2 class="section-title-block__title">{{ "CommonText.OurCategories" | translate }}</h2>
          </div>
          <app-cat-list></app-cat-list>
        </div>
      </div>
    </section>
    <section *ngIf="homeData.general_news" class="container container_section">
      <div>
        <div class="section-title-block">
          <img
            class="section-title-block__image"
            src="../../assets/images/logo.svg"
          />
          <h2 class="section-title-block__title">
            {{ "Home.GeneralNews" | translate }}
          </h2>
          <button
            class="section-title-block__button button_icon"
            routerLink="/morenews"
          >
            <img class="button__icon" src="../../assets/images/witness.svg" />
            {{ "Home.ViewAll" | translate }}
          </button>
        </div>
        <ul class="card-list" *ngIf="homeData.general_news">
          <li
            class="card"
            *ngFor="
              let news of homeData.general_news | slice: 0:3;
              let i = index
            "
          >
            <a routerLink="/news-details/{{ news.id }}" class="">
              <div class="card__image-wrap">
                <img
                  class="card__image"
                  src="{{ environment.API_URL + news.img_url }}"
                  alt=""
                />
              </div>
              <div class="card__body">
                <span class="category" *ngIf="news.category">{{
                  news.category
                }}</span>
                <span class="category" *ngIf="!news.category">{{
                  "Home.GeneralNews" | translate
                }}</span>
                <h4 class="h6">{{ news.name }}</h4>
                <p class="card__description">
                  {{ news.subtitle }}
                </p>
                <span class="card__date">{{
                  news.date | date: "mediumDate"
                }}</span>
              </div>
            </a>
          </li>
        </ul>
        <div
          class="contentSlidesEvenements custom-slider hidden"
          style="width: 100%"
        >
          <ng-image-slider
            (imageClick)="imageNewsSelected($event)"
            [images]="newsImages"
            #nav
          ></ng-image-slider>
        </div>
      </div>
      <div class="triple-card-grid">
        <div
          class="triple-card-grid__col"
          *ngFor="let photo of homeData.photos"
        >
          <div
            class="section-title-block"
            *ngFor="let eventData of photo.event"
          >
            <img
              class="section-title-block__image"
              src="../../assets/images/logo.svg"
            />
            <h2 class="section-title-block__title">
              {{ "Home.Photos" | translate }}
            </h2>
          </div>
          <!-- routerLink="/photos/{{ photo.race_type[0].category[0] }}/{{
            photo.date
          }}" -->
          <a
            *ngFor="let imgData of photo.img_urls"
            routerLink="/photos"
            class="card-image"
          >
            <img
              src="{{ environment.API_URL + imgData.img_url }}"
              class="card-image__image"
            />
            <span class="h3 card-image__overlay">{{
              "Home.Photos" | translate
            }}</span>
          </a>
        </div>

        <div
          class="triple-card-grid__col"
          *ngFor="let lastEvent of homeData.last_event"
        >
          <div class="section-title-block">
            <img
              class="section-title-block__image"
              src="../../assets/images/logo.svg"
            />
            <h2 class="section-title-block__title">
              {{ "Home.Events" | translate }}
            </h2>
          </div>
          <a routerLink="/events/" class="card-image">
            <img
              src="{{ environment.API_URL + lastEvent.img_url[0].img_url }}"
              class="card-image__image"
            />
            <span class="h3 card-image__overlay">{{
              "Home.Events" | translate
            }}</span>
          </a>
        </div>

        <div
          class="triple-card-grid__col"
          *ngFor="let video of homeData.last_video"
        >
          <div class="section-title-block">
            <img
              class="section-title-block__image"
              src="../../assets/images/logo.svg"
            />
            <h2 class="section-title-block__title">
              {{ "Home.Videos" | translate }}
            </h2>
          </div>
          <div *ngFor="let videoData of video.video_urls">
            <!-- routerLink="/videos/{{ video.category[0].id }}/{{ video.date }}" -->
            <a
              routerLink="/videos/"
              class="card-image"
            >
              <img
                [src]="getThumbnailPhotoFrom(videoData.video_url) | safeHtml"
                class="card-image__image"
              />
              <span class="h3 card-image__overlay">{{
                "Home.Videos" | translate
              }}</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

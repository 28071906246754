<div class="registration-form__terms" *ngIf="type == 1" style="">
  <h2 class="h1">Terms and Conditions</h2>
  <p class="mt-4">
    Emirates Cycling Federation maintains the Uaecf.ae Website ("Site"). The following are the terms of use that govern
    use of theSite ("Terms of Use"). By using the Site, you expressly agree to be bound by these Terms of Use and the
    Uaecf.ae privacy policy and to follow these Terms of Use and all applicable laws and regulations governing use of the
    Site. EMIRATES CYCLING FEDERATION reserves the right to change these Terms of Use at any time, effective immediately
    upon posting on the Site. Please check this page of the Site periodically. We will note when there are updates to the
    Terms of Use at the bottom of the Terms ofUse. lf you violate these Terms of Use, EMIRATES CYCLING FEDERATION may
    terminate your use of the Site, bar you from future use of the Site, and/or take appropriate legal action against
    you.
  </p>

  <p class="mt-4">
    <strong>LIMITED LICENSE.</strong> You are granted a limited, non-exclusive, revocable and non-transferable license to utilize and
    access the Site pursuant to the requirements and restrictions of these Terms of Use. Emirates Cycling Federation  may change, suspend, or
    discontinue any aspect of the Site at any time. Emirates Cycling Federation  may also, without notice or liability, impose limits on
    certain features and services or restrict your access to all or portions of the Site. You shall have no rights to
    the proprietary software and related documentation, if any, provided to you in order to access the Site. Except as
    provided in the Terms of Use, you shall have no right to directly or indirectly, own, use, loan, sell, rent, lease,
    license, sublicense, assign, copy, translate, modify, adapt, improve, or create any new or derivative works from, or
    display, distribute, perform, or in any way exploit the Site, or any of its contents (including software) in whole
    or in part.
  </p>

  <ul class="mt-4 px-4">
    <li>
      <strong>1. SITE OPERATION:</strong> United Arab Emirates is our Country of Domicile. Emirates Cycling Federation  controls this Site
      from the U.A.E.
      Emirates Cycling Federation  makes no representation that this Site is appropriate for use in other locations. If you use this Site
      from other locations you are responsible for ensuring compliance with local laws. You may not use, export or
      re-export any materials from this Site in violation of any applicable laws or regulations, including, but not
      limited to any U.A.E export laws and regulations.
    </li>
    <li class="mt-3">
      <strong>2. APPLICABLE LAW. </strong>The Laws of the United Arab Emirates shall govern the use
      of the Site and the Terms of Use, without regards to conflict of laws principals. All disputes arising in
      connection therewith shall be heard only by a court of competent jurisdiction in U.A.E.
    </li>
    <li class="mt-3">
      <strong>3. MULTI-CURRENCY PRICED TRANSACTION</strong> The displayed price and currency
      selected by you, will be the same price and currency charged to the Card and printed on the Transaction Receipt.
    </li>
    <li class="mt-3">
      <strong>4. PURCHASES. </strong>Emirates Cycling Federation  accepts payment by Visa or Mastercard debit and
      credit cards in AED for its products and services. All online purchases are also governed by the terms and
      conditions of respective merchant service providers. Please review respective merchant service provider’s user
      agreement and privacy policy before entering any transaction. Interpretation. These Terms of Use supersede all
      other written and oral communications or agreements with regard to the subject matters discussed in these Terms of
      Use. A waiver or modification of these Terms of Use will only be effective if made in writing signed by an
      authorized officer of Emirates Cycling Federation . All refunds will be made onto the original mode of payment.
    </li>
    <li class="mt-3">
      <strong>5. OFFICE FOREIGN ASSETS CONTROL (OFAC) SANCTIONED COUNTRIES.</strong> Emirates Cycling Federation  will not
      trade with or provide any services to individuals and companies owned or controlled by, or acting for or on behalf
      of, OFAC targeted countries AND individuals, groups, and entities, such as terrorists and narcotics traffickers
      designated under the OFAC programs that are not country specific
    </li>
    <li class="mt-3">
      <strong>6. REPRESENTATIONS BY YOU.</strong>By visiting the Site, you represent, warrant and covenant that (a) you
      are at least 18 years old; (b) that all
      materials of any kind submitted by you to Emirates Cycling Federation  through the Site or for inclusion on the Site will not
      plagiarize, violate or infringe upon the rights of any third-party including trade secret, copyright, trademark,
      trade dress, privacy, patent, or other personal or proprietary rights. The customer using the website who are
      Minor /under the age of 18 shall not register as a User of the website and shall not transact on or use the
      website.
    </li>
    <li class="mt-3">
      <strong>7. PERMITTED USE.</strong> You agree that you are only authorized to visit, view
      and to retain a copy of pages of this Site for your own personal use, that you shall not duplicate, download,
      publish, modify or otherwise distribute the material on this Site for any purpose other than for personal use,
      unless otherwise specifically authorized by Emirates Cycling Federation  to do so. You also agree not to deep-link to the site for any
      purpose, unless specifically authorized by Emirates Cycling Federation  to do so. The content and software on this Site is the property
      of Emirates Cycling Federation . The cardholder must retain a copy of transaction records and Merchant policies and rules.
    </li>
    <li class="mt-3">
      <strong>8. YOUR ACCOUNT.</strong> If you use Emirates Cycling Federation  Site, you are responsible for maintaining
      the confidentiality of your account and password and for restricting access to your account from any devices, and
      you agree to accept responsibility for all activities that occur under your account or password. The Site shall
      not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a
      result of, or in connection with, your failure to comply with this section.
    </li>
  </ul>


  <p class="mt-4">
    <strong>NO COMMERCIAL USE.</strong> This Site may not be used by you for any commercial purposes such as to conduct
    sales of
    merchandise or services of any kind. You must obtain Emirates Cycling Federation ’s prior written consent to make commercial offers of
    any kind on the Site, whether by advertising, solicitations, links, or any other form of communication. Emirates Cycling Federation  will
    investigate and take appropriate legal action against anyone who violates this provision, including without
    limitation, removing the offending communication from the Site and barring such violators from use of the Site.
  </p>


  <p class="mt-4">
    <strong>LINKS AND SEARCH RESULTS.</strong> The Site may automatically produce search results that reference and/or
    link to third
    party sites throughout the World Wide Web. Emirates Cycling Federation  has no control over these sites or the content within them.
    Emirates Cycling Federation  does not guarantee, represent or warrant that the content contained in the sites is accurate, legal and/or
    inoffensive. Emirates Cycling Federation  does not endorse the content of any third party site, nor does it make any representation or
    warranty about these sites, including that they will not contain viruses or otherwise impact your computer. By using
    the Site to search for or link to another site, you agree and understand that you may not make any claim against
    Emirates Cycling Federation  for any damages or losses, whatsoever, resulting from your use of the Site to obtain search results or to
    link to another site. If you have a problem with a link from the Site, you may notify us at
    support@Emirates Cycling Federation WEBSITE.com.
  </p>

  <p class="mt-4">
    <strong>COPYRIGHT POLICY. </strong> Emirates Cycling Federation  may terminate the privileges of any user who uses this Site to
    unlawfully transmit copyrighted material without a license, express consent, valid defense or fair use exemption to
    do so. If you submit information to this Site, you warrant that the information does not infringe the copyrights or
    other rights of third parties.
  </p>

  <p class="mt-4">
    <strong>INTELLECTUAL PROPERTY.</strong> Although Emirates Cycling Federation  is not responsible for the content,
    quality or accuracy of data provided by users, compilations of such data, text, information and other materials made
    available to users through Emirates Cycling Federation ’s system. The On-line Materials are Emirates Cycling Federation ’s intellectual property, and are
    protected by U.S. and international intellectual property laws. The On-line Materials may not be copied or
    redistributed either in whole or in part without prior written consent of Emirates Cycling Federation , except as expressly and
    specifically permitted under these Terms of Use.
  </p>

  <p class="mt-4">
    <strong>The On-line Materials</strong> Are and will remain the exclusive property of Emirates Cycling Federation .
    All rights, titles and interests in and to the On-line Materials will be and remain vested solely in Emirates Cycling Federation . Under
    no circumstances will you have any right, directly or indirectly, to own, use, copy, loan, sell, rent, lease,
    license, sublicense, redistribute, assign or otherwise convey the On- line Materials, or any rights thereto, except
    as expressly and specifically provided in the Terms of Use. Nothing in these Terms of Use will convey to you any
    right, title or interest, except that of a license with the express rights and subject to all limitations herein.
    Nothing in these Terms of Use grants you the right, directly or indirectly, to use the On-line Materials to create a
    product for resale or to use the On-line Materials in any way that competes with Emirates Cycling Federation .
  </p>


  <p class="mt-4">
    You acknowledge and agree that Emirates Cycling Federation  will own all rights, titles and interests in and to any copy, translation,
    modification, adaptation, derivative work or improvement of the On- line Materials made by or for you. At Emirates Cycling Federation ’s
    request, you must execute, or obtain the execution of, any instrument that may be necessary to assign these rights,
    titles or interests to Emirates Cycling Federation  or perfect these rights, titles or interests in Emirates Cycling Federation ’s name. DISCLAIMER OF
    WARRANTY, LIMITATION OF DAMAGES. Emirates Cycling Federation  MAKES NO WARRANTY OR REPRESENTATION OF ANY KIND, EITHER EXPRESS OR IMPLIED,
    INCLUDING BUT NOT LIMITED TO WARRANTY OF TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS
    FOR A PARTICULAR PURPOSE,NON-INFRINGEMENT OR OTHER VIOLATION OF RIGHTS IN RELATION TO THE AVAILABILITY, ACCURACY,
    VALIDITY, RELIABILITY OR CONTENT OF THESE PAGES AND/OR THE SITE. Emirates Cycling Federation  ALSO DOES NOT MAKE ANY REPRESENTATION OR
    WARRANTY REGARDING THE ACCURACY OR RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION THAT IS
    SUBMITTED, DISPLAYED OR UPLOADED THROUGH THE SITE BY ANY USER. Emirates Cycling Federation  SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
    INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS OR FOR BUSINESS INTERRUPTION ARISING OUT OF THE USE OF OR
    INABILITY TO USE THIS SITE, EVEN IF Emirates Cycling Federation  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS
    DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS OF LIABILITY, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY
    NOT APPLY TO YOU. THE LIABILITY OF Emirates Cycling Federation  WOULD IN SUCH CASE BE LIMITED TO THE GREATEST EXTENT OF LIABILITY
    PERMITTED BY LAW.
  </p>

  <p class="mt-4">
    <strong>VIOLATION OF TERMS OF USE.</strong> You understand and agree that in Emirates Cycling Federation ’s sole discretion, and without
    prior notice,
    Emirates Cycling Federation  may terminate your access to the Site, or exercise any other remedy available and remove any unauthorized
    user information, if Emirates Cycling Federation  believes that the information you provide has violated or is inconsistent with these
    Terms of Use, or violates the rights of Emirates Cycling Federation , or any third party, or violates the law. You agree that monetary
    damages may not provide a sufficient remedy to Emirates Cycling Federation  for violations of these Terms of Use and you consent to
    injunctive or other equitable relief for such violations. Emirates Cycling Federation  may release user information about you if required
    by law or subpoena.
  </p>

  <p class="mt-4">
    <strong>INDEMNITY.</strong> You agree to indemnify and hold Emirates Cycling Federation , its subsidiaries,
    affiliates, officers, agents and other partners and employees, harmless from any loss, liability, claim or demand,
    including reasonable attorneys’ fees, made by any third party due to or arising out of or relating to your use of
    the Site, including also your use of the Site to provide a link to another site or to upload content or other
    information to the Site, or your breach of the Terms of Use.
  </p>


  <p class="mt-4">
    <strong>LICENCE GRANTED TO YOU.</strong> By providing materials to Emirates Cycling Federation , including by submitting or
    uploading content or materials for use on the Site you represent and warrant that you or the owner of all rights to
    such content or materials has expressly granted Emirates Cycling Federation  an irrevocable world-wide right in all languages and in
    perpetuity to use and exploit all or any part of the content and materials provided by you. Emirates Cycling Federation  may publish and
    distribute any such submitted content or materials at its sole discretion by any method now existing or later
    developed. You agree that you shall waive all claims and have no recourse against Emirates Cycling Federation  for any alleged or actual
    infringement or misappropriation of any proprietary rights in any communication, content or material submitted to
    Emirates Cycling Federation . Any communication or materials you send to Emirates Cycling Federation  will be treated as non- confidential and non-proprietary
    and may be disseminated or used by Emirates Cycling Federation  for any purpose, including, but not limited to, developing, creating,
    manufacturing or marketing products or services.
  </p>

  <p class="mt-4">
    <strong>ADVERTISING.</strong> The Site may contain advertisements and/or sponsorships. The
    advertisers and/or sponsors that provide these advertisements and sponsorships are solely responsible for insuring
    that the materials submitted for inclusion on the Site are accurate and comply with all applicable laws. Emirates Cycling Federation  is
    not responsible for the acts or omissions of any advertiser or sponsor.
  </p>

  <p class="mt-4">
    <strong>SEVERABILITY.</strong> If any provision of the Terms of Use is held to be invalid, void or
    unenforceable, the remaining provisions shall nevertheless continue in full force and effect.
  </p>

  <p class="mt-4">
    Headings & section titles in this Agreement are for convenience and do not define,
    limit, or extend any provision of this Agreement.
  </p>
</div>
<div class="policy" *ngIf="type == 2" style="">
  <h2 class="h1">Privacy and Policy</h2>
  <p class="mt-4">
    INFORMATION GATHERED BY UAECF.AE This is UAECF.AE's("UAECF.AE") online privacy policy ("Policy"). This policy
    applies only to activities UAECF.AE engages in onits website and does not apply to UAECF.AE activities that are
    "offline" or unrelated to the website.
  </p>

  <p class="mt-4">
    UAECF.AE collects certain anonymous data regarding the usage of the website. This information does not personally
    identify users, by itself or in combination with other information, and is gathered to improvethe performance of the
    website. The anonymous data collected by the UAECF.AE website can include information such as the type of browser you
    are using, and the length of the visit to the website. You may also be asked to provide personally identifiable
    information on the UAECF.AE website, which may include your name, address, telephone number and e-mail address. This
    information can be gathered when feedback or e-mails are sent to UAECF.AE, when you register for services, or make
    purchases via the website. ln all such cases you have the option of providing us with personally identifiable
    information.
  </p>

  <ul class="mt-4 px-4">
    <li><strong>1. USE AND DISCLOSURE OF INFORMATION.</strong> Except as otherwise stated below, we do not sell, trade or
      rent your
      personally identifiable information collected on the site to others. The information collected by our site is used
      to process orders, to keep you informed about your order status, to notify you of products or special offers that
      may be of interest to you, and for statistical purposes for improving our site. We will disclose your Delivery
      information to third parties for older tracking purposes or process your check or money order, as appropriate, fill
      your order, improve the functionality of our site, perform statistical and data analyses deliver your order and
      deliver promotional emails to you from us. For example, we must release your mailing address information to the
      delivery service to deliver products that you ordered.</li>

    <li class="mt-3">
      <strong>2. All credit/debit cards'</strong> details and personally identifiable information will
      NOT be stored, sold,shared, rented or leased to any third parties
    </li>
  </ul>

  <p class="mt-4">
    COOKIES. Cookies are small bits of data cached in a user's browser. UAECF.AE utilizes cookies to determine whether or
    not you have visited the home page in the past. However, no other user information is gathered.
  </p>


  <p class="mt-4">
    UAECF.AE may use non-personal "aggregated data" to enhance the operation of our website, or analyze interest in the
    areas of our website. Additionally, if you provide UAECF.AE with content for publishing or feedback, we may publish
    your username or other identifying data with your permission.
  </p>

  <p class="mt-4">
    UAECF.AE may also disclose personally identifiable information in order to respond to a subpoena, court order or
    other such request. UAECF.AE may also provide such personally identifiable information in response to law enforcement
    agencies request or as otherwise required by law. Your personally identifiable information may be provided to an
    authorized party if UAECF.AE files for bankruptcy, or there is a transfer of the assets or ownership of UAECF.AE in
    connection with proposed or consummated corporate reorganizations, such as mergers or acquisitions under court order
    as admissible by applicable laws in force.
  </p>

  <ul class="mt-4 px-4">
    <li><strong>3. SECURITY. UAECF.AE</strong> takes appropriate steps to ensure data
      privacy and security including through various hardware and software methodologies. However, UAECF.AE cannot
      guarantee the security of any information that is disclosed online.</li>

    <li class="mt-3">
      <strong>4. OTHER WEBSITES. UAECF.AE</strong> is not responsible for the privacy
      policies of websites to which it links.lf you provide any information to such third parties different rules
      regarding the collection and use of your personal information may apply. We strongly suggest you review such third
      party's privacy policies before providing any data to them. We are not responsible for the policies or practices of
      third parties. Please be aware that our sites may contain links to other sites on the lnternet that are owned and
      operated by third parties. The information practices of those Web sites linked to our site is not covered by this
      Policy. These other sites may send their own cookies or clear GlFs to users, collect data or solicit personally
      identifiable information. We cannot control this collection of information. You should contact these entities
      directly if you have any questions about their use of the information that they collect.
    </li>
  </ul>

  <p class="mt-3">
    MINORS. UAECF.AE does not knowingly collect personal information from minors under the age of 18.Minors are not
    permitted to use the UAECF.AE website or services, and UAECF.AE requests that minors under the age of 18 not submit
    any personal information to the website. Since information regarding minors under the age of 18 is not collected,
    UAECF.AE does not knowingly distribute personal information regarding minors under the age of 18.
  </p>

  <p class="mt-4">
    CORRECTIONS AND UPDATES. lf you wish to modify or update any information UAECF.AE has received,please contact info@
    UAECF.AE
  </p>

  <ul class="mt-4 px-4">
    <li><strong>5. MODIFICATIONS OF THE PRIVACY POLICY.</strong> UAECF.AE The Website Policies and Terms & Conditions
      would be changed or
      updated occasionally to meet the requirements and standards. Therefore, theCustomers are encouraged to frequently
      visit these sections in order to be updated about the changes on the website. Modifications will be effective on
      the day they are posted".</li>
  </ul>

</div>
<div class="mt-4 d-flex justify-content-center">
  <button type="submit" class="button button_theme button_md" (click)="onNoClick()">Close</button>
</div>

<div
  *ngIf="eventDetailsData as eventDetailsData"
  id="mainContent"
  class="container container__page"
>
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "CommonText.EventDetail" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>
        <a routerLink="/events" class="link"
          >{{ "CommonText.Events" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "CommonText.EventDetail" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <section class="event-info" *ngFor="let detail of eventDetailsData.details">
      <div class="event-info__intro">
        <h1>{{ detail.event }}</h1>
        <img
          class="event-info__image"
          src="{{ environment.API_URL + detail.img }}"
        />
      </div>
      <div class="event-info__table-block">
        <div class="event-info__table-block-inner">
          <h5 class="event-info__table-title">
            {{ "CommonText.Details" | translate }}
          </h5>
          <table class="table table-varient1">
            <tbody>
              <tr>
                <th>
                  <img src="../../../assets/images/location.svg" />
                  {{ "EventDetails.LocationLable" | translate }}
                </th>
                <td>
                  <a href="{{ detail.location }}" target="_blank">
                    {{ detail.location }}
                  </a>
                </td>
              </tr>
              <tr>
                <th>
                  <img src="../../../assets/images/calendar.svg" />
                  {{ "EventDetails.DateLable" | translate }}
                </th>
                <td>{{ detail.date | date: "mediumDate" }}</td>
              </tr>
              <tr>
                <th>
                  <img src="../../../assets/images/teamwork.svg" />{{
                    "EventDetails.OrganizerLable" | translate
                  }}
                </th>
                <td>{{ detail.organisers }}</td>
              </tr>
              <tr>
                <th>
                  <img src="../../../assets/images/finish-flag.svg" />{{
                    "EventDetails.RaceTypeLable" | translate
                  }}
                </th>
                <td>{{ detail.race_type[0].race_type[1] }}</td>
              </tr>
              <tr>
                <th>
                  <img src="../../../assets/images/location.svg" />{{
                    "EventDetails.RaceDistanceLable" | translate
                  }}
                </th>
                <td>
                  {{ detail.race_distance }}
                </td>
              </tr>
              <tr>
                <th>
                  <img src="../../../assets/images/online-registration.svg" />{{
                    "EventDetails.RegistrationOpenLable" | translate
                  }}
                </th>
                <td>
                  {{ detail.registration_open | date: "mediumDate" }},&nbsp;{{
                    detail.registration_open | date: "shortTime"
                  }}
                </td>
              </tr>
              <tr>
                <th>
                  <img
                    src="../../../assets/images/online-registration-close.svg"
                  />{{ "EventDetails.RegistrationCloseLable" | translate }}
                </th>
                <td>
                  {{ detail.registration_close | date: "mediumDate" }},&nbsp;{{
                    detail.registration_close | date: "shortTime"
                  }}
                </td>
              </tr>
              <tr>
                <th>
                  <img src="../../../assets/images/check-in.svg" />{{
                    "EventDetails.StateLable" | translate
                  }}
                </th>
                <td>{{ detail.state }}</td>
              </tr>
              <tr>
                <th>
                  <img src="../../../assets/images/money.svg" />{{
                    "EventDetails.PriceLable" | translate
                  }}
                </th>
                <td>
                  {{ detail.event_fess }}
                  {{ "EventDetails.PriceCurrencyLable" | translate }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  class="text-center"
                  *ngIf="!detail.isHidden && user.role == 'free_rider'"
                >
                  <p class="italic">
                    {{ "EventDetails.ParticipateMessage" | translate }}
                  </p>
                  <a
                    class="button_theme button_md inline-block"
                    routerLink="/jointEvent/{{ detail.id }}/{{ 1 }}/{{
                      detail.date
                    }}/{{ detail.event_fess }}/{{ user.riderId }}"
                    target="_blank"
                  >
                    {{ "EventDetails.RegisterNow" | translate }}
                  </a>
                </td>
                <td
                  colspan="2"
                  class="text-center"
                  *ngIf="!detail.isHidden && user.role == 'club'"
                >
                  <p class="italic">
                    {{ "EventDetails.ParticipateMessage" | translate }}
                  </p>
                  <a
                    class="button_theme button_md inline-block"
                    routerLink="/jointEvent/{{ detail.id }}/{{ 2 }}/{{
                      detail.date
                    }}/{{ detail.event_fess }}/{{ user.clubId }}"
                    target="_blank"
                  >
                    {{ "EventDetails.RegisterNow" | translate }}
                  </a>
                </td>
                <td
                  colspan="2"
                  class="text-center"
                  *ngIf="!detail.isHidden && user.role == 'team'"
                >
                  <p class="italic">
                    {{ "EventDetails.ParticipateMessage" | translate }}
                  </p>
                  <a
                    class="button_theme button_md inline-block"
                    routerLink="/jointEvent/{{ detail.id }}/{{ 1 }}/{{
                      detail.date
                    }}/{{ detail.event_fess }}/{{ user.teamId }}"
                    target="_blank"
                  >
                    {{ "EventDetails.RegisterNow" | translate }}
                  </a>
                </td>
              </tr>

              <tr *ngIf="detail.organisers == 'other'">
                <th>{{ "EventDetails.CompanyNameLable" | translate }}</th>
                <td>
                  {{ detail.company_name }}
                </td>
              </tr>
              <tr *ngIf="detail.organisers == 'other'">
                <th>{{ "EventDetails.CompanyEmailLable" | translate }}</th>
                <td>
                  {{ detail.comp_email }}
                </td>
              </tr>
              <tr *ngIf="detail.organisers == 'other'">
                <th>{{ "EventDetails.CompanyAdressLable" | translate }}</th>
                <td>
                  {{ detail.comp_address }}
                </td>
              </tr>
              <tr *ngIf="detail.organisers == 'other'">
                <th>{{ "EventDetails.CompanyWebsiteLable" | translate }}</th>
                <td>
                  <a href="{{ detail.company_website }}" target="_blank">
                    {{ detail.company_website }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section>
      <mat-tab-group
        class="mat-tab-secondary"
        mat-align-tabs="center"
        dynamicHeight
      >
        <mat-tab
          label="{{ 'EventDetails.StartingListTableLable' | translate }}"
          class="text-center"
        >
          <div class="table-responsive">
            <table class="table table_default table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.BIBNOLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.FederationIDLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.FullNameLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.UCIIDLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.TeamLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.ClubLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.IdLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.CategoryLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.RiderStatusLable" | translate }}
                  </th>
                </tr>
              </thead>

              <tbody class="text-center">
                <tr>
                  <td colspan="9" class="table__filter">
                    <div
                      style="display: flex"
                      *ngIf="eventDetailsData.starting_list"
                    >
                      <ng-container
                        *ngIf="eventDetailsData.starting_list[0].category"
                      >
                        <label>{{
                          "EventDetails.CategoryLable" | translate
                        }}</label>
                        <select [(ngModel)]="selectedStartingListCategory">
                          <option [value]="0">
                            {{ "EventDetails.AllResultLable" | translate }}
                          </option>
                          <option
                            *ngFor="
                              let category of eventDetailsData.starting_list[0]
                                .category
                            "
                            [value]="category.id"
                          >
                            {{ category.code }}
                          </option>
                        </select>
                      </ng-container>
                    </div>
                  </td>
                </tr>
                <ng-container *ngIf="eventDetailsData.starting_list">
                  <tr
                    *ngFor="
                      let startingItem of eventDetailsData.starting_list[0]
                        .lines
                    "
                  >
                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{ startingItem.bib_no }}
                    </td>
                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{ startingItem.license_no[0].license_no[1] }}
                    </td>

                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{ startingItem.name[0].full_name }}
                    </td>
                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{ startingItem.uci_id ? startingItem.uci_id : "-" }}
                    </td>
                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{ startingItem.team[0].team[1] }}
                    </td>
                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{ startingItem.club[0].club[1] }}
                    </td>
                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{ startingItem.nationality[0].nationality[1] }}
                    </td>
                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{ startingItem.category[0].category[1] }}
                    </td>
                    <td
                      *ngIf="
                        selectedStartingListCategory ==
                          startingItem.category[0].category[0] ||
                        selectedStartingListCategory == 0
                      "
                    >
                      {{
                        startingItem.rider_type ? startingItem.rider_type : "-"
                      }}
                    </td>
                    <!-- <td
                  *ngIf="
                    selectedStartingListCategory ==
                      startingItem.category[0].category[0] ||
                    selectedStartingListCategory == 0
                  "
                >
                  {{ startingItem.exception }}
                </td> -->
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </mat-tab>

        <mat-tab
          label="{{ 'EventDetails.ResultsTableLable' | translate }}"
          class="text-center"
        >
          <div class="table-responsive">
            <table class="table table_default table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.ResultLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.BIBNOLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.FederationIDLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.FullNameLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.UCIIDLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.TeamLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.ClubLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.NationalityLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.CategoryLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.RiderStatusLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.ExceptionLable" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr>
                  <td colspan="11" class="table__filter text-left">
                    <label>{{
                      "EventDetails.CategoryLable" | translate
                    }}</label>
                    <select [(ngModel)]="selectedCategory">
                      <option [value]="0">
                        {{ "EventDetails.AllResultLable" | translate }}
                      </option>
                      <option
                        *ngFor="
                          let category of eventDetailsData.results[0].category
                        "
                        [value]="category.id"
                      >
                        {{ category.code }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr *ngFor="let result of eventDetailsData.results[0].lines">
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.result }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.bib_no }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.license_no[0].license_no[1] }}
                  </td>

                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.name[0].full_name[1] }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.uci_id ? result.uci_id : "-" }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.team[0].team[1] }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.club[0].club[1] }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.nationality[0].nationality[1] }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.category[0].category[1] }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.rider_type }}
                  </td>
                  <td
                    *ngIf="
                      selectedCategory == result.category[0].category[0] ||
                      selectedCategory == 0
                    "
                  >
                    {{ result.exception }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>

        <mat-tab
          label="{{ 'EventDetails.IrregularitiesTableLable' | translate }}"
        >
          <div class="table-responsive">
            <table class="table table_default table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.CommissariesLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.PenaltyTypeLable" | translate }}
                  </th>

                  <th scope="col" class="text-center">
                    {{ "EventDetails.TheSanctionLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.TeamLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.ClubLable" | translate }}
                  </th>
                  <!--            <th scope="col" class="text-center">Violation</th>-->
                  <th scope="col" class="text-center">
                    {{ "EventDetails.BIBNOLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.FullNameLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.CategoryLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.LocationLable" | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ "EventDetails.DateLable" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr
                  *ngFor="let irregularity of eventDetailsData.irregularities"
                >
                  <td class="text-center">
                    {{
                      irregularity.commissaries_committee[0]
                        .commissaries_committee[1]
                    }}
                  </td>
                  <td class="text-center">{{ irregularity.type_violation }}</td>
                  <td class="text-center">
                    {{ irregularity.punishment_race }}
                  </td>
                  <td class="text-center">
                    {{ irregularity.team[0].team[1] }}
                  </td>
                  <td class="text-center">
                    {{ irregularity.club[0].club[1] }}
                  </td>
                  <td class="text-center">
                    {{ irregularity.license_no[0].license_no[1] }}
                  </td>
                  <td class="text-center">
                    {{ irregularity.full_name[0].full_name[1] }}
                  </td>
                  <td>{{ irregularity.category[0].category[1] }}</td>
                  <!--            <td class="text-center">{{irregularity.type_violation[0].type_violation[1]}}</td>-->
                  <td class="text-center">{{ irregularity.race_location }}</td>
                  <td class="text-center">{{ irregularity.date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>

        <mat-tab
          label="{{ 'EventDetails.IndividualPointsTableLable' | translate }}"
          class="text-center"
        >
          <div class="accordion" id="accordionExample">
            <!--ngfor loop started for dynamic data -->
            <div
              class="accordion-item"
              *ngFor="
                let individulePoint of eventDetailsData.individule_points;
                let i = index
              "
            >
              <h2 [id]="'heading' + i">
                <button
                  class="accordion-button"
                  style="font-size: 1.5rem; background-color: #f7f7f7"
                  [ngClass]="{ collapsed: i != 0 }"
                  type="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse' + i"
                  aria-expanded="true"
                  [attr.aria-controls]="'collapse' + i"
                >
                  <h2 class="h5 accordion-header">
                    #{{ individulePoint.category[0].code }}
                  </h2>
                </button>
              </h2>
              <div
                [id]="'collapse' + i"
                class="accordion-collapse collapse"
                [ngClass]="{ show: i == 0 }"
                [attr.aria-labelledby]="'heading' + i"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="table-responsive">
                    <table
                      class="table table_default table-hover table-striped"
                    >
                      <thead>
                        <tr>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.RankingLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.NameLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.BIBNOLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.UCIIDLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.CategoryLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.TeamOrClubLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.NationalityLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.PointLable" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let line of individulePoint.lines">
                          <td class="text-center">{{ line.ranking }}</td>
                          <td class="text-center">
                            {{ line.full_name[0].full_name[1] }}
                          </td>
                          <td class="text-center">
                            {{ line.license_no[0].license_no[1] }}
                          </td>
                          <td class="text-center">
                            {{ line.uci_id ? line.uci_id : "-" }}
                          </td>
                          <td class="text-center">
                            {{ line.category_id[0].category_id[1] }}
                          </td>
                          <td class="text-center">
                            {{
                              line.team[0].team[1] == false
                                ? line.club[0].club[1]
                                : line.team[0].team[1]
                            }}
                          </td>
                          <td class="text-center">
                            {{ line.nationality[0].nationality[1] }}
                          </td>
                          <td class="text-center">{{ line.total_point }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{ 'EventDetails.TeamPointsTableLable' | translate }}">
          <div class="accordion" id="teamPointsId">
            <!--ngfor loop started for dynamic data -->
            <div
              class="accordion-item"
              *ngFor="
                let teamPoint of eventDetailsData.team_points;
                let i = index
              "
            >
              <h2 [id]="'pointHeading' + i">
                <button
                  class="accordion-button"
                  style="font-size: 1.5rem; background-color: #f7f7f7"
                  [ngClass]="{ collapsed: i != 0 }"
                  type="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#pointCollapse' + i"
                  aria-expanded="true"
                  [attr.aria-controls]="'pointCollapse' + i"
                >
                  <h2 class="h5 accordion-header">
                    {{ teamPoint.category[0].code }}
                  </h2>
                </button>
              </h2>
              <div
                [id]="'pointCollapse' + i"
                class="accordion-collapse collapse"
                [ngClass]="{ show: i == 0 }"
                [attr.aria-labelledby]="'pointHeading' + i"
                data-bs-parent="#teamPointsId"
              >
                <div class="accordion-body">
                  <div class="table-responsive">
                    <table
                      class="table table_default table-hover table-striped"
                    >
                      <thead style="text-align: center">
                        <tr style="text-align: center">
                          <th scope="col" class="text-center">
                            {{ "EventDetails.TeamOrClubLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{
                              "EventDetails.RankingFirstPlayerLable" | translate
                            }}
                          </th>
                          <th scope="col" class="text-center">
                            {{
                              "EventDetails.RankingSecondPlayerLable"
                                | translate
                            }}
                          </th>
                          <th scope="col" class="text-center">
                            {{
                              "EventDetails.RankingThirdPlayerLable" | translate
                            }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.TotalEntryLable" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "EventDetails.PointLable" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let team of teamPoint.lines">
                          <td class="text-center">
                            {{
                              team.team_name[0].team_name == false
                                ? team.club_name[0].club_name[1]
                                : team.team_name[0].team_name
                            }}
                          </td>
                          <td class="text-center">
                            {{ team.ranking_first_player }}
                          </td>
                          <td class="text-center">
                            {{ team.ranking_second_player }}
                          </td>
                          <td class="text-center">
                            {{ team.ranking_third_player }}
                          </td>
                          <td class="text-center">{{ team.total_entry }}</td>
                          <td class="text-center">{{ team.points }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </section>
  </section>
</div>

<div
  class="container mt-5 mb-5 hidden"
  *ngIf="eventDetailsData as eventDetailsData"
>
  <div style="margin-top: 30px">
    <h2 style="margin: 20px; color: black">
      {{ "EventDetails.SponsorsLable" | translate }}
    </h2>

    <div *ngFor="let sponsorData of eventDetailsData.sponsors">
      <span *ngFor="let sponsor of sponsorData.event_sponsors">
        <img
          style="width: 300px; height: 300px; border-radius: 50%"
          class="sponsers"
          src="{{ environment.API_URL }}{{ sponsor.img_url }}"
          alt="Partners"
          title="Partners"
        />
      </span>
    </div>
  </div>
  <!--  <pre>{{eventDetailsData.individule_points | json}}</pre>-->
  <div class="more-info">
    <button
      class="more-info-btn"
      matRipple
      *ngIf="!showMoreInfo"
      (click)="showMoreInfoState()"
    >
      {{ "EventDetails.MoreInfoLable" | translate }}
    </button>
    <button
      class="more-info-btn"
      matRipple
      *ngIf="showMoreInfo"
      (click)="showMoreInfoState()"
    >
      {{ "EventDetails.LessInfoLable" | translate }}
    </button>
    <!-- need to refactor to inline condition -->
    <ng-container *ngIf="showMoreInfo">
      <p
        *ngFor="let info of eventDetailsData.more_information"
        [innerHTML]="info.introduction"
      ></p>
    </ng-container>
  </div>
</div>

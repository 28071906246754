<div class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "UAECF.About.AboutUs" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "UAECF.About.AboutUs" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <section class="aboutus-header">
      <div class="aboutus-header__img">
        <img src="../../../assets/images/about-us.svg" />
      </div>
      <div class="aboutus-content">
        <p>{{ "UAECF.About.Intro1" | translate }}</p>
        <p>{{ "UAECF.About.ListTitle" | translate }}</p>
        <ul>
          <li><p>{{ "UAECF.About.ListItem1" | translate }}</p></li>
          <li><p>{{ "UAECF.About.ListItem2" | translate }}</p></li>
        </ul>
      </div>
    </section>
  </section>
</div>
<section class="container_section aboutus-cards__wrap">
  <div class="aboutus-cards container">
    <ul class="card-list aboutus-cards__list">
      <li class="aboutus-cards__li">
        <div class="card aboutus-card">
          <div class="aboutus-card__header">
            <img
              class="aboutus-card__image"
              src="../../../assets/images/vision.svg"
            />
            <h2 class="h4">{{ "UAECF.About.OurVision" | translate }}</h2>
          </div>
          <div class="aboutus-card__body">
            <ul>
              <li>{{ "UAECF.About.OurVision1" | translate }}</li>
              <li>{{ "UAECF.About.OurVision2" | translate }}</li>
            </ul>
          </div>
        </div>
      </li>
      <li class="aboutus-cards__li">
        <div class="card aboutus-card">
          <div class="aboutus-card__header">
            <img
              class="aboutus-card__image"
              src="../../../assets/images/mission.svg"
            />
            <h2 class="h4">{{ "UAECF.About.OurMission" | translate }}</h2>
          </div>
          <div class="aboutus-card__body">
            <p>{{ "UAECF.About.OurMission1" | translate }}</p>
          </div>
        </div>
      </li>
      <li class="aboutus-cards__li">
        <div class="card aboutus-card">
          <div class="aboutus-card__header">
            <img
              class="aboutus-card__image"
              src="../../../assets/images/value.svg"
            />
            <h2 class="h4">{{ "UAECF.About.OurValue" | translate }}</h2>
          </div>
          <div class="aboutus-card__body">
            <ul>
              <li>{{ "UAECF.About.OurValue1" | translate }}</li>
              <li>{{ "UAECF.About.OurValue2" | translate }}</li>
              <li>{{ "UAECF.About.OurValue3" | translate }}</li>
              <li>{{ "UAECF.About.OurValue4" | translate }}</li>
              <li>{{ "UAECF.About.OurValue5" | translate }}</li>
              <li>{{ "UAECF.About.OurValue6" | translate }}</li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</section>
<div class="container">
  <section class="container_section">
    <section
      class="container_section-sub"
      *ngIf="aboutService.partnersData$ | async; let partners"
    >
      <div class="section-title-block">
        <img
          src="../../assets/images/logo.svg"
          class="section-title-block__image"
        />
        <h2 class="section-title-block__title">
          {{ "UAECF.About.StrategicPartners" | translate }}
        </h2>
      </div>
      <div class="icon-card-list">
        <li class="icon-card-list__li" *ngFor="let partner of partners">
          <a
            class="icon-card-list__cards"
            [href]="partner.website"
            target="_blank"
            *ngIf="partner.partners_type == 'strategic'"
          >
            <img
              class="icon-card-list__icon"
              [src]="env.API_URL + partner.logo_url"
              alt="Partners"
              title="Partners"
            />
          </a>
        </li>
      </div>
    </section>
    <section *ngIf="aboutService.partnersData$ | async; let partners">
      <div class="section-title-block">
        <img
          src="../../assets/images/logo.svg"
          class="section-title-block__image"
        />
        <h2 class="section-title-block__title">
          {{ "UAECF.About.GovernmentsPartners" | translate }}
        </h2>
      </div>
      <div class="icon-card-list">
        <li class="icon-card-list__li" *ngFor="let partner of partners">
          <a
            class="icon-card-list__cards"
            [href]="partner.website"
            target="_blank"
            *ngIf="partner.partners_type == 'goverment'"
          >
            <img
              class="icon-card-list__icon"
              [src]="env.API_URL + partner.logo_url"
              alt="Partners"
              title="Partners"
            />
          </a>
        </li>
      </div>
    </section>
  </section>
</div>

import { PaymentService } from '../../services/payment.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payfailed',
  templateUrl: './payfailed.component.html',
  styleUrls: ['./payfailed.component.css']
})
export class PayfailedComponent implements OnInit {

  constructor(public payment: PaymentService) {
    payment.checkTransaction().then((data) => {
      data.subscribe((res) => {
//console.log({res});

      });

    });
   }

  ngOnInit(): void {
  }

}

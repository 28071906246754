<div
  *ngIf="api.currentNewsDetails$ | async as newsDetails"
  id="mainContent"
  class="container container_md container__page"
>
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "Home.News" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>
        <a routerLink="/news" class="link">{{ "Home.News" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "Home.News" | translate }}</li>
    </ul>
  </div>
  <section class="page-body" *ngFor="let info of newsDetails.new">
    <div class="event-info">
      <div class="event-info__intro">
        <h1>
          {{ info.name }}
        </h1>
        <div class="category" *ngFor="let category of info.category">
          {{ info.category[0].category[1] }}
        </div>
        <div class="date-share-block">
          <span class="" *ngFor="let category of info.category">
            {{ info.date }}
          </span>
          <div class="social-media-links-wrap">
            {{"News.Sharethis" | translate}}:
            <div class="social-media-links social-media-links_header">
              <a
                target="_blank"
                href="https://www.facebook.com/mediacyclinguae"
                class="social-media-links_header__a"
                ><img
                  title="facebook"
                  src="../assets/images/facebook.svg"
                  class="social-media-links_header__icon" /></a
              ><a
                target="_blank"
                href="https://www.instagram.com/uaecycf/"
                class="social-media-links_header__a"
                ><img
                  title="instagram"
                  src="../assets/images/instagram.svg"
                  class="social-media-links_header__icon" /></a
              ><a
                target="_blank"
                href="https://www.twitter.com/mediacyclinguae"
                class="social-media-links_header__a"
                ><img
                  title="twitter"
                  src="../assets/images/twitter.svg"
                  class="social-media-links_header__icon" /></a
              ><a
                target="_blank"
                href="https://www.youtube.com/channel/UC6otd4Ycloq-xUtANdZ7Heg/featured"
                class="social-media-links_header__a"
                ><img
                  title="youtube"
                  src="../assets/images/youtube.svg"
                  class="social-media-links_header__icon"
              /></a>
            </div>
          </div>
        </div>
        <img
          [src]="info.img_url"
          class="event-info__image"
          alt="news details"
        />
        <p>
          <strong *ngIf="info.subtitle">
            {{ info.subtitle }}
          </strong>
        </p>
        <div [innerHTML]="info.content"></div>
      </div>
    </div>
    <ul class="card-list">
      <li class="card" *ngFor="let otherInfo of newsDetails.other">
        <a routerLink="/news-details/{{ otherInfo.id }}" class="">
          <div class="card__image-wrap">
            <img class="card__image" src="{{ otherInfo.img_url }}" alt="" />
          </div>
          <div class="card__body">
            <span class="category">{{
              otherInfo.category[0].category[1]
            }}</span>
            <h4 class="h5">{{ otherInfo.name }}</h4>
            <span class="card__date">{{ otherInfo.date  | date: 'mediumDate' }}</span>
          </div>
        </a>
      </li>
      <li class="barricade"></li>
      <li class="barricade"></li>
    </ul>
  </section>
</div>

<div class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "Rankings.RankingDetail" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "Rankings.RankingDetail" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <mat-tab-group class="mat-tab-secondary" mat-stretch-tabs [(selectedIndex)]="tabIndex">
      <mat-tab label="Individual Points">
        <table class="table table_default table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">{{ "Rankings.Ranking" | translate }}</th>
              <th scope="col">{{ "Rankings.FullName" | translate }}</th>
              <th scope="col">{{ "Rankings.LicenceNumber" | translate }}</th>
              <th scope="col">{{ "Rankings.UCINumber" | translate }}</th>
              <th scope="col">{{ "Rankings.Nationality" | translate }}</th>
              <th scope="col">{{ "Rankings.Team" | translate }}</th>
              <th scope="col">{{ "Club" | translate }}</th>
              <th scope="col">{{ "Rankings.TotalPoints" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let individual of individualLines">
              <td>{{ individual.ranking }}</td>
              <td>{{ individual.full_name[0].full_name[1] }}</td>
              <td>{{ individual.license_no[0].license_no[1] }}</td>
              <td>{{ individual.uci_id }}</td>
              <td>{{ individual.nationality[1] }}</td>
              <td>{{ individual.team[0].team[1] }}</td>
              <td>{{ individual.club[0].club[1] }}</td>
              <td>{{ individual.total_point }}</td>
            </tr>
          </tbody>
        </table>
      </mat-tab>

      <mat-tab label="Team Points">
        <table class="table table_default table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">{{ "Rankings.Ranking" | translate }}</th>

              <th scope="col">{{ "Rankings.Team" | translate }}</th>
              <th scope="col">{{ "Rankings.Club" | translate }}</th>
              <th scope="col">{{ "Rankings.TotalPoints" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let teamLine of teamRankings">
              <td>{{ teamLine.ranking }}</td>

              <td>{{ teamLine.team_name[0].team_name[1] }}</td>
              <td>{{ teamLine.club_name[0].club_name[1] }}</td>
              <td>{{ teamLine.total_points }}</td>
            </tr>
          </tbody>
        </table>
      </mat-tab>

      <mat-tab label="General Ranking">
        <table class="table table_default table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">{{ "Rankings.Ranking" | translate }}</th>
              <th scope="col">{{ "Rankings.Team" | translate }}</th>
              <th scope="col">{{ "Rankings.Club" | translate }}</th>
              <th scope="col">
                {{ "Rankings.TotalIndividualSuperiority" | translate }}
              </th>
              <th scope="col">{{ "Rankings.TotalTeamPoints" | translate }}</th>
              <th scope="col">{{ "Rankings.TotalPoints" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let general of generalRankings">
              <td>{{ general.ranking }}</td>
              <td>{{ general.team_name[0].team_name[1] }}</td>
              <td>{{ general.club_name[0].club_name[1] }}</td>
              <td>{{ general.total_individual_superiority }}</td>
              <td>{{ general.total_team_points }}</td>
              <td>{{ general.total }}</td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

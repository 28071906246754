import {Component, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {BackendApiService} from './../../backend-api.service';
import {Router} from '@angular/router';
import {environment} from './../../../environments/environment';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';

@Component({
  selector: 'app-constitution',
  templateUrl: './constitution.component.html',
  styleUrls: ['./constitution.component.css']
})
export class ConstitutionComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  List: any;

  environment = environment;

  constructor(private _snackBar: MatSnackBar, private router: Router, private backendService: BackendApiService) {
  }

  ngOnInit() {

    // Get Hall Of Fame
    this.backendService.constituation().subscribe(
      (response: HttpResponse<any>) => {
        this.List = response['data'];
      },
      (error) => {
        this._snackBar.open('Fecth Constituation API', 'Error', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );

  }
}


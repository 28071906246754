<div *ngIf="teamAdmin">
    <section class="header">
        <div class="profileLabel">
            <h6>{{'UAECF.TeamOfficialDetails.Profile' | translate}}</h6>
        </div>
        <div class=""></div>
    </section>

    <section class="mainProfile">

        <div class="row d-flex justify-content-center">

            <div class="row" style="justify-content: center ; width: 300px; height: 300px; margin-top : 30px">
                <img [src]="environment.API_URL + teamAdmin.img_url" alt="" style="object-fit: cover">
            </div>
            <div class="row d-flex justify-content-center">
                <br>
                <h1 class="offset-md-1 mt-5 col-md-3" style="text-transform: uppercase; margin-right: 60px">
                    {{teamAdmin.full_name}}</h1>
                <br>
            </div>
            <div class="row d-flex justify-content-center">

            </div>

        </div>

    </section>

    <div class="container mb-5">

        <div class="row">
            <div class="col-md-4 rider-club-team">
                <div class="row ">
                    <img [src]="environment.API_URL + teamAdmin.team_image" class="mt-3">
                </div>
                <div class="row">

                    <p class="offset-1 col-md-8" style="">
                        {{teamAdmin.team_name }}
                    </p>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="row mb-3">
                    <p class="personalDetails mt-5 mb-5">{{'UAECF.TeamOfficialDetails.PersonalDetails' | translate}}</p>
                </div>
                <div style="border-left: #ddd solid 1px; padding: 0 20px">
                    <div class="mb-3">
                        <p class="anotherDetail">
                            <strong style="color: #999">
                {{'UAECF.TeamOfficialDetails.Nationality' | translate}} :
              </strong> {{teamAdmin.nationality[0].nationality[1]}}
                        </p>
                    </div>
                    <div>
                        <p class="anotherDetail">
                            <strong style="color: #999">
                {{'UAECF.TeamOfficialDetails.UCIID' | translate}} :
              </strong> {{teamAdmin.uci_id}}
                        </p>
                    </div>
                </div>

            </div>
            <div class="col-md-4" style="margin-top:90px">
                <div class="mt-5" style="border-left: #ddd solid 1px; padding: 0 20px">
                    <div class="mb-3">
                        <p class="anotherDetail">
                            <strong style="color: #999">
                {{'UAECF.TeamOfficialDetails.DateOfBirth' | translate}} :
              </strong> {{teamAdmin.date_birth}}
                        </p>
                    </div>
                    <div>
                        <p class="anotherDetail">
                            <strong style="color: #999">
                {{'UAECF.TeamOfficialDetails.Function' | translate}} :
              </strong> {{teamAdmin.category}}
                        </p>
                    </div>
                </div>

            </div>

        </div>
        <div class="row mt-5">
            <div class="offset-4 col-md-4" style="">
                <div class="mt-5" style="border-left: #ddd solid 1px; padding: 0 20px">
                    <div class="mb-3">
                        <p class="anotherDetail">
                            <strong style="color: #999">
                {{'UAECF.TeamOfficialDetails.Email' | translate}}:
              </strong> {{teamAdmin.email}}
                        </p>
                    </div>
                    <div>
                        <p class="anotherDetail">
                            <strong style="color: #999">
                {{'UAECF.TeamOfficialDetails.TeamClub' | translate}} :
              </strong>
                            <!-- {{teamAdmin.team_club}} -->
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

<div class="container pt-5 pb-5">
    <form class="row g-3" [formGroup]="entryByNameForm" (ngSubmit)="onSubmit()">
      <h2 class="h1 text-danger fw-bold">{{ "UAECF.EntryByName.Header" | translate}}</h2>
        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.EntryByName.EntryName' | translate}}</label>
                    <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">{{'UAECF.EntryByName.EntryNameRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.EntryByName.Address' | translate}}</label>
                    <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                        <div *ngIf="f.address.errors.required">{{'UAECF.EntryByName.AddressRequired' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 mt-4 mb-4">
            <div class="form-group">
                <label>{{'UAECF.EntryByName.TeamName' | translate}}</label>
                <input type="text" formControlName="teamName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.teamName.errors }" />
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                    <div *ngIf="f.teamName.errors.required">{{'UAECF.EntryByName.TeamNameRequired' | translate}}</div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.EntryByName.Phone' | translate}}</label>
                    <input type="tel" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">{{'UAECF.EntryByName.PhoneRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.EntryByName.Fax' | translate}}</label>
                    <input type="tel" formControlName="fax" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fax.errors }" />
                    <div *ngIf="submitted && f.fax.errors" class="invalid-feedback">
                        <div *ngIf="f.fax.errors.required">{{'UAECF.EntryByName.FaxRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.EntryByName.Email' | translate}}</label>
                    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">{{'UAECF.EntryByName.EmailRequired' | translate}}</div>
                        <div *ngIf="f.email.errors.email">{{'UAECF.EntryByName.EmailInvalid' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-md-6" *ngIf="(api.clubTeamCategories$ | async) as clubTeamCategories">

                <select class="form-select" (change)="changeCategory($event)" formControlName="category">
          <option value="" disabled>{{'UAECF.EntryByName.ChooseCategory' | translate}}</option>
          <option *ngFor="let category of clubTeamCategories" [ngValue]="category.id">{{category.code}}</option>
        </select>
                <!-- error block -->
                <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                    <div *ngIf="f.category.errors.required">{{'UAECF.EntryByName.CategoryRequired' | translate}}</div>
                </div>

            </div>

            <div class="col-md-6" *ngIf="(api.raceTypes$ | async) as raceTypes">

                <select class="form-select" (change)="changeRaceType($event)" formControlName="raceType">
          <option value="" disabled>{{'UAECF.EntryByName.ChooseRaceType' | translate}}</option>
          <option *ngFor="let raceType of raceTypes" [ngValue]="raceType.id">{{raceType.name}}</option>
        </select>
                <!-- error block -->
                <div *ngIf="submitted && f.raceType.errors" class="invalid-feedback">
                    <div *ngIf="f.raceType.errors.required">{{'UAECF.EntryByName.RaceTypeRequired' | translate}}</div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <table class="table .table-bordered .table-sm  table-hover">
                <thead>
                    <tr>
                        <th scope="col">{{'UAECF.EntryByName.LastName' | translate}}</th>
                        <th scope="col">{{'UAECF.EntryByName.FirstName' | translate}}</th>
                        <th scope="col">{{'UAECF.EntryByName.UCICode' | translate}}</th>
                        <th scope="col">{{'UAECF.EntryByName.RaceType' | translate}}</th>
                        <th scope="col">{{'UAECF.EntryByName.Classification' | translate}}</th>
                        <th scope="col">*</th>
                    </tr>
                </thead>
                <tbody formArrayName="lines">

                    <tr *ngFor="let line of lines.controls; let i=index;let l=last" [formGroupName]="i">
                        <td>
                            <input type="text" formControlName="lastName" class="form-control" />
                        </td>
                        <td>
                            <input type="text" formControlName="firstName" class="form-control" />
                        </td>
                        <td>
                            <input type="text" formControlName="uciCode" class="form-control" />
                        </td>
                        <td>
                            <div *ngIf="(api.raceTypes$ | async) as raceTypes">
                                <select class="form-select" (change)="changeInternalRaceType($event , i)" formControlName="raceType">
                <option value="" disabled>{{'UAECF.EntryByName.ChooseRaceType' | translate}}</option>
                <option *ngFor="let raceType of raceTypes" [ngValue]="raceType.id">{{raceType.name}}</option>
              </select>
                            </div>
                        </td>
                        <td>
                            <div *ngIf="raceClassification">
                                <select class="form-select" formControlName="classification">
                <option value="" disabled>{{'UAECF.EntryByName.ChooseRaceTypeClassification' | translate}}</option>
                <option *ngFor="let classification of raceClassification"
                        [ngValue]="classification.id">{{classification.name}}</option>
              </select>
                            </div>

                        </td>
                        <td>
                            <button mat-button (click)="removeLine(i)">{{'UAECF.EntryByName.RemoveLine' | translate}}
            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button mat-button (click)="addLine()">{{'UAECF.EntryByName.AddLine' | translate}}</button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.EntryByName.PresidentName' | translate}}</label>
                    <input type="text" formControlName="presidentName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.presidentName.errors }" />
                    <div *ngIf="submitted && f.presidentName.errors" class="invalid-feedback">
                        <div *ngIf="f.presidentName.errors.required">{{'UAECF.EntryByName.PresidentNameRequired' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.EntryByName.Place' | translate}}</label>
                    <input type="text" formControlName="place" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.place.errors }" />
                    <div *ngIf="submitted && f.place.errors" class="invalid-feedback">
                        <div *ngIf="f.place.errors.required">{{'UAECF.EntryByName.PlaceRequired' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-md-6" formGroupName="stamp">
                <div class="form-group">
                    <label>{{'UAECF.EntryByName.PresidentStampAngSig' | translate}}</label>
                    <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event)" />
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field>
                        <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{'UAECF.EntryByName.ChooseDate' | translate}}" formControlName="dateTime" [min]="minDate" [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>

                    <div *ngIf="submitted && f.dateTime.errors" class="invalid-feedback">
                        <div *ngIf="f.dateTime.errors.required">{{'UAECF.EntryByName.DateRequired' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center"> <button type="submit" class="btn btn-lg host-btn">{{'UAECF.EntryByName.Submit' | translate}}</button></div>
    </form>
</div>

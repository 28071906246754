import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BackendApiService } from './../backend-api.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})

export class CalendarComponent implements OnInit {
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  selectedYear = new Date().getFullYear();
  allEvents = [];
  page: number;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  calendarList: any;

  constructor(private _snackBar: MatSnackBar, private router: Router, private backendService: BackendApiService) {
  }

  ngOnInit() {
    this.backendService.calendar().subscribe(
      (response: HttpResponse<any>) => {
        this.calendarList = response['Calender'];
        var matching = this.calendarList.filter(function (events) {
          return events.year == 2023;
        });
        this.allEvents = matching;
      },
      (error) => {
        this._snackBar.open('Calendar API', 'Error', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );
  }

  updatedNewsAppearance() {
    const matching = this.calendarList.filter((item: any) => item.year === this.selectedYear);
    this.allEvents = matching;
  }

  selectedYearChanged() {
    this.updatedNewsAppearance();
  }

}

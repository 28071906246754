'use strict';


export const  RiderRole = "free_rider";
export const  TeamRole = "team";
export const  ClubRole = "club";
export const  RiderRegisterPrice = 250;
export const  LocalRiderRegisterPrice = 150;
export const  AdminRegisterPrice = 250;
export const  CompliantPrice = 100;

import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { BackendApiService } from "../backend-api.service";
import { HomeService } from "../services/home.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "./../../environments/environment";
@Component({
  selector: 'app-road',
  templateUrl: './road.component.html',
  styleUrls: ['./road.component.css']
})
export class RoadComponent implements OnInit {
  environment = environment;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  last_news: any;
  calendar: any;
  last_event: any;
  photos: any;
  raceName: string;
  raceNameAr: string;

  constructor(
    private backendService: BackendApiService,
    public api: HomeService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  loadHomePageData(raceId) {
    this.api.HomePageForSpecificRaceType(raceId);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(data => {
      const raceId = +data.get('id');
      this.loadHomePageData(raceId);
      if (raceId) {
        switch (raceId) {
          case 1:
            ////console.log('road');
            this.raceName = 'Road';
            this.raceNameAr = "الطريق";
            break;
          case 2:
            //console.log('track');
            this.raceName = 'Track';
            this.raceNameAr = "المضمار";
            break;
          case 3:
            this.raceName = 'MTB';
            this.raceNameAr = "الدراجة الجبلية";
            break;
          case 4:
            this.raceName = 'Tour';
            this.raceNameAr = "طواف";
            break;
          default:
            this.raceName = 'All';
            this.raceNameAr = "الكل";
        }
      }
    });
  }
}

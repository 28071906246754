import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HostUAECFEventService} from "../../../../services/host-uaecf-event.service";
import {IArrivalInfo, IDepartureInfo, IFlightSchedule} from "../../../../model/UAECFEvent.model";
import {environment} from '../../../../../environments/environment';
import * as moment from "moment";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-flight-schedule',
  templateUrl: './flight-schedule.component.html',
  styleUrls: ['./flight-schedule.component.css']
})
export class FlightScheduleComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  flightScheduleForm: FormGroup;
  submitted: boolean;
  arrivalInfo: FormArray;
  departureInfo: FormArray;
  roles = ['staff', 'rider'];
  genders = ['male', 'female'];
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  private uaeEventId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: HostUAECFEventService
  ) {
    this.flightScheduleFormInitialization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.flightScheduleForm.controls;
  }

// Getter method to access formcontrols
  get categoryId() {
    return this.flightScheduleForm.get('category');
  }

  get stamp() {
    return this.flightScheduleForm.get('stamp') as FormGroup;

  }

  get logo() {
    return this.flightScheduleForm.get('logo') as FormGroup;

  }

// Getter method to access formcontrols
  get raceTypeId() {
    return this.flightScheduleForm.get('raceType');
  }


  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.uaeEventId = +data.get('id');
    });
  }

  flightScheduleFormInitialization() {
    this.flightScheduleForm = this.fb.group({
      nfName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      teamName: ['', [Validators.required]],
      coordinator: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      website: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      place: [''],
      arrivalInfo: this.fb.array([]),
      departureInfo: this.fb.array([]),

      stamp: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      logo: this.fb.group({
        file: ['', [Validators.required]],
        fileSource: ['', [Validators.required]],
        extension: ['', [Validators.required]]
      }),
      date: ['', [Validators.required]]
    });
    this.arrivalInfo = this.flightScheduleForm.get('arrivalInfo') as FormArray;
    this.departureInfo = this.flightScheduleForm.get('departureInfo') as FormArray;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.flightScheduleForm.invalid) {
      return;
    }

    const formData = {
      event_name: this.uaeEventId,
      nf_name: this.flightScheduleForm.get('nfName').value,
      address: this.flightScheduleForm.get('address').value,
      team_name: this.flightScheduleForm.get('teamName').value,
      telephone: this.flightScheduleForm.get('phone').value,
      website: this.flightScheduleForm.get('website').value,
      email: this.flightScheduleForm.get('email').value,
      place: this.flightScheduleForm.get('place').value,

      arrival_ids: this.arrivalInfo.controls.map(item => {
        return {
          flight_no: item.get('flightNo').value,
          airport: item.get('fromAirPort').value,
          arrivalDateTime: item.get('arrivalDateTime').value,
          terminal: item.get('terminal').value,
          noOfRiders: item.get('noOfRiders').value,
          noOfStaffs: item.get('noOfStaffs').value,
          noOfBikeBoxes: item.get('noOfBikeBoxes').value,
        } as IArrivalInfo;
      }),

      departure_ids: this.departureInfo.controls.map(item => {
        return {
          flight_no: item.get('flightNo').value,
          airport: item.get('fromAirPort').value,
          departureDateTime: item.get('departureDateTime').value,
          terminal: item.get('terminal').value,
          noOfPersons: item.get('noOfPersons').value,
          baggage: item.get('baggage').value,
        } as IDepartureInfo;
      }),
      date: this.flightScheduleForm.get('date').value,
      signature: this.stamp.get('fileSource').value.toString(),
      logo: this.logo.get('fileSource').value.toString(),
    } as IFlightSchedule;
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    //console.log('entry by name data are', this.flightScheduleForm.value);

    // last step
    this.api.addFlightSchedule(formData);
  }

  onReset(): void {
    this.submitted = false;
    this.flightScheduleForm.reset();
  }

  addArriveInfo(): void {
    // this.lines = this.entryByNameForm.get('lines') as FormArray;
    this.arrivalInfo.push(this.createArrivalInfo());
  }

  addDepartureInfo(): void {
    // this.lines = this.entryByNameForm.get('lines') as FormArray;
    this.departureInfo.push(this.createDepartureInfo());
  }

  removeArriveInfo(i: number) {
    this.arrivalInfo.removeAt(i);
  }

  removeDepartureInfo(i: number) {
    this.departureInfo.removeAt(i);
  }

  createArrivalInfo(): FormGroup {
    return this.fb.group({
      flightNo: [''],
      fromAirPort: [''],
      arrivalDateTime: [''],
      terminal: [''],
      noOfRiders: [''],
      noOfBikeBoxes: [''],
      noOfStaffs: [''],
    });
  }

  createDepartureInfo(): FormGroup {
    return this.fb.group({
      flightNo: [''],
      fromAirPort: [''],
      departureDateTime: [''],
      terminal: [''],
      noOfPersons: [''],
      baggage: [''],
    });
  }

  // Choose category using select dropdown
  changeCategory(e) {
    this.categoryId.setValue(e.target.value, {
      onlySelf: true
    });
  }

  // Choose category using select dropdown
  changeRaceType(e) {
    this.raceTypeId.setValue(e.target.value, {
      onlySelf: true
    });
  }

  onFileChange(event, item: string) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      //console.log('file extensions', fileExtension);
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.imageSrc = reader.result as string;
        if (item == 'stamp') {
          this.stamp.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        if (item == 'logo') {
          this.logo.patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
      };
    }
  }
}

import { IAdminRegistrationModel } from './../../model/registration.model';
import { TeamsClubsService } from 'src/app/services/teams-clubs.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl, Validators, ValidationErrors } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import * as localforage from 'localforage';
import { Subscription } from 'rxjs';
import { TermsPolicyComponent } from 'src/app/components/terms-policy/terms-policy.component';
import { IStringifiesIdLookup, IRiderRegistrationModel } from 'src/app/model/registration.model';
import { IRaceClassificationType } from 'src/app/model/UAECFEvent.model';
import { HostUAECFEventService } from 'src/app/services/host-uaecf-event.service';
import { RegistrationsService } from 'src/app/services/registrations.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import * as Constants from '../../shared/AppConstant.constant';

@Component({
  selector: 'app-admin-register',
  templateUrl: './admin-register.component.html',
  styleUrls: ['./admin-register.component.css']
})
export class AdminRegisterComponent implements OnInit {


  Functions: IStringifiesIdLookup[] = [
    {
      id: 't_manager',
      name: 'Team Manager'
    },
    {
      id: 'team_admin',
      name: 'Team Admin'
    },
    {
      id: 't_supervisor',
      name: 'Team Supervisor'
    },
    {
      id: 'administrative_assistant',
      name: 'Administrative Assistant'
    },
    {
      id: 'coach',
      name: 'Coach'
    },
    {
      id: 'coach_assistant',
      name: 'Coach Assistant'
    },
    {
      id: 'doctor',
      name: 'Doctor'
    },
    {
      id: 'masseur',
      name: 'Masseur'
    },
    {
      id: 'mechanic',
      name: 'Mechanic'
    },
    {
      id: 'technical_manager',
      name: 'Technical Manager'
    },

  ];

  routeDataSubscription: Subscription;
  environment = environment;
  adminRegistrationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  raceClassification: IRaceClassificationType[] = [];
  riderTypeId;

  imageSrc: string;
  attachmentSrc: string;

  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  genders = ['male', 'female'];
  isClub: boolean;

  price = Constants.AdminRegisterPrice;
  agreedTerms: boolean;
  clubTeamValue: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public api: HostUAECFEventService,
    public dialog: MatDialog,
    public clubTeamService: TeamsClubsService,
    public auth: AuthService,
    private registrationApi: RegistrationsService
  ) {
    this.isClub = this.auth.currentUserValue.role == "club";
  }

  get f(): { [key: string]: AbstractControl } {
    return this.adminRegistrationForm.controls;
  }

  // get fa(): { [key: string]: AbstractControl } {
  //   return this.entryByNameForm['lines'].controls;
  // }

  // Getter method to access formcontrols


  getFormField(name) {
    return this.adminRegistrationForm.get(name) as FormGroup;

  }
  ngOnInit(): void {
    this.loadingInitializationData();
  }

  async entryByNameFormInitialization() {
    await this.clubTeamService.loadClubs();

    this.adminRegistrationForm = this.fb.group({
      club: [this.clubTeamValue],
      team: [this.clubTeamValue],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      birthPlace: ['',],
      address: ['',],
      function: ['', [Validators.required]],
      emiratesId: ['', [Validators.required]],
      emiratesIdExpiry: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      uciId: ['',],
      adminEmail: ['', [Validators.required]],
      image: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      identity: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      qualifications: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      })
    });
  }

  getFormValidationErrors(form: FormGroup) {
    const result = [];
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    //console.log('result is : ', result);
    // return result;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.adminRegistrationForm.invalid) {
      this.getFormValidationErrors(this.adminRegistrationForm);
      return;
    }
    //console.log('goood', this.adminRegistrationForm.get('club'), this.adminRegistrationForm.get('club').value.toString());

    const formData = {
      club_name: this.isClub ? this.adminRegistrationForm.get('club').value.toString() : '',
      team_name: this.isClub ? '' : this.adminRegistrationForm.get('team').value.toString(),
      team_club: this.isClub ? 'club' : 'team',
      first_name: this.adminRegistrationForm.get('firstName').value,
      last_name: this.adminRegistrationForm.get('lastName').value,
      phone: this.adminRegistrationForm.get('phone').value,
      date_birth: this.formatDate(this.adminRegistrationForm.get('dateOfBirth').value),
      gender: this.adminRegistrationForm.get('gender').value,
      place_birth: this.adminRegistrationForm.get('birthPlace').value,
      address: this.adminRegistrationForm.get('address').value,
      emirates_id: this.adminRegistrationForm.get('emiratesId').value,
      category: this.adminRegistrationForm.get('function').value,
      emirates_id_expiry: this.formatDate(this.adminRegistrationForm.get('emiratesIdExpiry').value),
      nationality: this.adminRegistrationForm.get('nationality').value.toString(),
      email: this.adminRegistrationForm.get('adminEmail').value,
      image: this.getFormField('image').get('fileSource').value.toString(),
      qualifications_ids: this.getFormField('qualifications').get('fileSource').value,
      image_ids: this.getFormField('identity').get('fileSource').value,
    } as IAdminRegistrationModel;
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    //console.log('entry by name data are', this.adminRegistrationForm.value);

    // last step
    this.registrationApi.registerAdmin(formData).subscribe(async (data) => {
      //console.log({ data });
      if (data.result.success && this.price > 0) {
        const reference = data.result.payment_resopnse.order.ref;
        const paymentUrl = data.result.payment_resopnse.order.url;

        await localforage.setItem('reference', reference, function (err) {
          // if err is non-null, we got an error
          // localforage.getItem('key', function (err, value) {
          //   // if err is non-null, we got an error. otherwise, value is the value
          // });
        });
        window.location.href = paymentUrl;
      } else if (data.result.success) {
        //console.log("ssuccess toaster");
        window.location.href = "/";
      }
    });
  }

  onReset(): void {
    this.submitted = false;
    this.adminRegistrationForm.reset();
  }

  // Choose category using select dropdown
  changeCategory(e) {
    // this.categoryId.setValue(e.target.value, {
    //   onlySelf: true
    // });
  }

  formatDate(date): string {
    const nowDate = new Date(date);
    const formattedDate = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate();
    //console.log({ date });
    return formattedDate.toString();
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async tobase64Handler(files: FileList[]): Promise<string[]> {
    const filePathsPromises = [];
    this.toBase64(files[0]);
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      await this.toBase64(element).then(data => {

        const fields = data.toString().split('base64,');
        filePathsPromises.push(fields[1]);



      });
    }
    //console.log(filePathsPromises.toString());
    return filePathsPromises;
  }

  async onFileChange(event, fileType: string) {
    const reader = new FileReader();
    let tempFileExtension: string[];
    let tempFileSource: string[];
    if (event.target.files && event.target.files.length) {
      //       for (let index = 0; index < event.target.files.length; index++) {
      //         const fileData = event.target.files[index];
      //       const fileExtension = event.target.files[index].name.split('.').pop().toLowerCase();
      //         reader.readAsDataURL(fileData);
      // //console.log("reader.result", reader.result);
      //               tempFileSource.concat(reader.result + ',');
      //               tempFileExtension.concat(fileExtension + ',');
      //       }
      tempFileSource = await this.tobase64Handler(event.target.files);
      // //console.log("reader.result", tempFileSource);

      if (fileType == 'image') {
        this.imageSrc = "data:image/webp;base64," + tempFileSource[0] as string;
        this.getFormField('image').patchValue({
          fileSource: tempFileSource[0],
          extension: tempFileSource
        });
      }
      this.getFormField(fileType).patchValue({
        fileSource: tempFileSource,
        extension: tempFileSource
      });
      // //console.log('file extensions', tempFileSource, tempFileSource, event.target.files.length, event.target.files);
    }
  }

  private async loadingInitializationData() {
    this.api.loadNationalities();
    this.entryByNameFormInitialization();

    if (this.isClub) {
      this.clubTeamValue = this.auth.currentUserValue.clubId;
    } else {
      this.clubTeamValue = this.auth.currentUserValue.teamId;

    }
    //console.log("clubTeamValue", this.clubTeamValue);
  }

  changeAgreedTerm() {
    this.agreedTerms = !this.agreedTerms;
    //console.log(this.agreedTerms);
  }
  calculatePrice() {
    return this.price;
  }

  openDialog(type) {
    //console.log('fromdialog');
    const dialogRef = this.dialog.open(TermsPolicyComponent, {
      width: '750px',
      height: '550px',
      data: { type: type }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
    });
  }
}

<div class="container pt-5 pb-5">
    <form class="row g-3" [formGroup]="flightScheduleForm" (ngSubmit)="onSubmit()">
      <h2 class="h1 text-danger fw-bold">{{ "UAECF.FlightSchedule.Header" | translate}}</h2>

        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.NFName' | translate }}</label>
                    <input type="text" formControlName="nfName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nfName.errors }" />
                    <div *ngIf="submitted && f.nfName.errors" class="invalid-feedback">
                        <div *ngIf="f.nfName.errors.required">{{'UAECF.FlightSchedule.NFNameRequired' | translate }}</div>
                    </div>
                </div>
            </div>

        </div>
        <h1 style="color:#c93534">{{'UAECF.FlightSchedule.Arrival' | translate }}</h1>
        <div class="row mt-4 mb-4">
            <table class="table table-bordered table-sm  table-hover">
                <thead>
                    <tr>
                        <th scope="col">{{'UAECF.FlightSchedule.FlightNo' | translate }}</th>
                        <th scope="col">{{'UAECF.FlightSchedule.From' | translate }}</th>
                        <th scope="col">{{'UAECF.FlightSchedule.DateTime' | translate }}</th>
                        <th scope="col">{{'UAECF.FlightSchedule.Terminal' | translate }}</th>
                        <th scope="col"> {{'UAECF.FlightSchedule.NumberOfRiders' | translate }}</th>
                        <th scope="col"> {{'UAECF.FlightSchedule.NumberOfStaff' | translate }}</th>
                        <th scope="col"> {{'UAECF.FlightSchedule.NumberOfBikeBoxes' | translate }}</th>
                        <th scope="col">*</th>
                    </tr>
                </thead>
                <tbody formArrayName="arrivalInfo">

                    <tr *ngFor="let arrive of arrivalInfo.controls; let i=index;let l=last" [formGroupName]="i">
                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="flightNo">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="fromAirPort">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="mt-3">
                                <input matInput [ngxMatDatetimePicker]="ArrivalPicker" formControlName="arrivalDateTime" [min]="minDate" [max]="maxDate">
                                <mat-datepicker-toggle matSuffix [for]="ArrivalPicker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #ArrivalPicker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="terminal">
                            </mat-form-field>
                        </td>

                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="noOfRiders">
                            </mat-form-field>
                        </td>


                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="noOfStaffs">
                            </mat-form-field>
                        </td>

                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="noOfBikeBoxes">
                            </mat-form-field>
                        </td>

                        <td>
                            <button mat-button (click)="removeArriveInfo(i)" class="mt-5">{{'UAECF.FlightSchedule.RemoveArrivalInfo' | translate }}
            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button mat-button (click)="addArriveInfo()">{{'UAECF.FlightSchedule.AddArrivalInfo' | translate }}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h1 style="color:#c93534">{{'UAECF.FlightSchedule.Departure' | translate }}</h1>

        <div class="row mt-4 mb-4">
            <table class="table table-bordered table-sm  table-hover">
                <thead>
                    <tr>
                        <th scope="col">{{'UAECF.FlightSchedule.FlightNo' | translate }}</th>
                        <th scope="col">{{'UAECF.FlightSchedule.From' | translate }}</th>
                        <th scope="col">{{'UAECF.FlightSchedule.DateTime' | translate }}</th>
                        <th scope="col">{{'UAECF.FlightSchedule.Terminal' | translate }}</th>
                        <th scope="col"> {{'UAECF.FlightSchedule.NumberOfPersons' | translate }}</th>
                        <th scope="col"> {{'UAECF.FlightSchedule.Baggage' | translate }}</th>
                        <th scope="col">*</th>
                    </tr>
                </thead>
                <tbody formArrayName="departureInfo">

                    <tr *ngFor="let departure of departureInfo.controls; let i=index;let l=last" [formGroupName]="i">
                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="flightNo">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="fromAirPort">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="mt-3">
                                <input matInput [ngxMatDatetimePicker]="DeparturePicker" formControlName="departureDateTime" [min]="minDate" [max]="maxDate">
                                <mat-datepicker-toggle matSuffix [for]="DeparturePicker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #DeparturePicker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="terminal">
                            </mat-form-field>
                        </td>

                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="noOfPersons">
                            </mat-form-field>
                        </td>


                        <td>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="baggage">
                            </mat-form-field>
                        </td>

                        <td>
                            <button mat-button (click)="removeDepartureInfo(i)" class="mt-5">{{'UAECF.FlightSchedule.RemoveDeparture' | translate }}
            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button mat-button (click)="addDepartureInfo()">{{'UAECF.FlightSchedule.AddDepartureInfo' | translate }}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.FlightCoordinator' | translate }}</label>
                    <input type="text" formControlName="coordinator" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.coordinator.errors }" />
                    <div *ngIf="submitted && f.coordinator.errors" class="invalid-feedback">
                        <div *ngIf="f.coordinator.errors.required">{{'UAECF.FlightSchedule.FlightCoordinatorRequired' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.MobileNumber' | translate }}</label>
                    <input type="text" formControlName="mobile" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                        <div *ngIf="f.mobile.errors.required">{{'UAECF.FlightSchedule.MobileNumberRequired' | translate }}</div>
                    </div>
                </div>
            </div>

        </div>


        <div class="row mt-4 mb-4">

            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'UAECF.FlightSchedule.ChooseDate' | translate }}</mat-label>
                        <input matInput [matDatepicker]="date" formControlName="date">
                        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                    </mat-form-field>

                    <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                        <div *ngIf="f.date.errors.required">{{'UAECF.FlightSchedule.DateRequired' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.Place' | translate }}</label>
                    <input type="text" formControlName="place" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.place.errors }" />
                    <div *ngIf="submitted && f.place.errors" class="invalid-feedback">
                        <div *ngIf="f.place.errors.required">{{'UAECF.FlightSchedule.PlaceRequired' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">

            <div class="col-md-4">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.TeamName' | translate }}</label>
                    <input type="text" formControlName="teamName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.teamName.errors }" />
                    <div *ngIf="submitted && f.teamName.errors" class="invalid-feedback">
                        <div *ngIf="f.teamName.errors.required">{{'UAECF.FlightSchedule.TeamNameRequired' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-4" formGroupName="stamp">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.Signature' | translate }}</label>
                    <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event,'stamp')" />
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-md-6" formGroupName="logo">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.Logo' | translate }}</label>
                    <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event , 'logo')" />
                </div>
            </div>
        </div>

        <h1>{{'UAECF.FlightSchedule.OrganizerInformation' | translate }}</h1>

        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.Address' | translate }}</label>
                    <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                        <div *ngIf="f.address.errors.required">{{'UAECF.FlightSchedule.AddressRequired' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.Website' | translate }}</label>
                    <input type="text" formControlName="website" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.website.errors }" />
                    <div *ngIf="submitted && f.website.errors" class="invalid-feedback">
                        <div *ngIf="f.website.errors.required">{{'UAECF.FlightSchedule.WebsiteRequired' | translate }}</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row ">
            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.Phone' | translate }}</label>
                    <input type="tel" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">{{'UAECF.FlightSchedule.PhoneRequired' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label>{{'UAECF.FlightSchedule.Email' | translate }}</label>
                    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">{{'UAECF.FlightSchedule.EmailRequired' | translate }}</div>
                        <div *ngIf="f.email.errors.email">{{'UAECF.FlightSchedule.EmailInvalid' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-lg host-btn">{{'UAECF.FlightSchedule.Submit' | translate }}</button>
        </div>
    </form>
</div>



<div class="basic-container">
  <gallery id="basic-test"  [items]="items" [itemTemplate]="itemTemplate" [gestures]="true"></gallery>
</div>

<!--<div class="lightbox-example">-->
<!--  <h2>Lightbox Example</h2>-->
<!--  <button mat-raised-button color="primary" (click)="lightbox.open(0)">Open Lightbox</button>-->


<!--  <button mat-raised-button color="accent" (click)="lightbox.open(0, 'lightbox', {panelClass: 'fullscreen'})">Open-->
<!--    Lightbox in fullscreen mode-->
<!--  </button>-->
<!--</div>-->


<!-- create a template to use the imageviewer -->

<ng-template #itemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
  <ng-container *ngIf="type === 'imageViewer' && index === currIndex">
    <ngx-imageviewer [src]="data.src" (click)="lightbox.open(0, 'lightbox', {panelClass: 'fullscreen'})"></ngx-imageviewer>
  </ng-container>
</ng-template>

export class Cookies { // cookies doesn't work with Android default browser / Ionic

  private session_id: string = null;

  delete_sessionId() {
    this.session_id = null;
    document.cookie = "session_id=; expires=Wed, 29 Jun 2016 00:00:00 UTC";
  }

  get_sessionId() :string{
    return document
      .cookie.split("; ")
      .filter(x => {
        return x.indexOf("session_id") === 0;
      })
      .map(x => {
        return x.split("=")[1];
      })
      .pop() || this.session_id || "";
  }

  public set_sessionId(val: string) {

    document.cookie = `session_id=${val}`;
    this.session_id = val;
  }
}

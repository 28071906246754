import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from "./auth.service";
import {Cookies} from "../model/cookies";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService:AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.authService.currentUserValue){
       this.authService.checkSessionValidation();
       let cookies = new Cookies();
       cookies.delete_sessionId();
      cookies.set_sessionId(this.authService.currentUserValue.session_id);
      // console.log("cookies",cookies.get_sessionId())
      const reqHttps = request.clone(
        {
          headers:request.headers.set('session_id',this.authService.currentUserValue.session_id),
          params:request.params.set('cookies',cookies.get_sessionId())

        }
      );
      return  next.handle(reqHttps);
    }else {
      return next.handle(request);

    }
  }
}

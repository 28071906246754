import { IRiderDetails } from './../../../model/nationalTeam/rider.model';
import {Component, OnInit} from '@angular/core';
import {NationalTeamService} from "../../../services/national-team.service";
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-rider-details',
  templateUrl: './rider-details.component.html'
})
export class RiderDetailsComponent implements OnInit {

  environment = environment;
  private routeDataSubscription: Subscription;
  private riderId: number;
  riderData: IRiderDetails;

  constructor(public nationalTeamService: NationalTeamService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.riderId = +data.get('id');
      this.loadingInitializationData();
    });
  }

  private loadingInitializationData() {
    this.nationalTeamService.loadRiderProfile(this.riderId);
    this.nationalTeamService.riderDetails$.subscribe(data => {
      this.riderData = data;

    });

  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IEventModel} from "../model/event.model";
import {environment} from 'src/environments/environment';
import {BehaviorSubject, Observable} from "rxjs";
import {EventDetailsModel} from "../model/eventDetails.model";

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  eventsData = new BehaviorSubject<IEventModel[]>([]);
  eventDetails = new BehaviorSubject<EventDetailsModel>({} as EventDetailsModel);

  constructor(private http: HttpClient) {
  }

  get eventsData$(): Observable<IEventModel[]> {
    return this.eventsData.asObservable();
  }

  get eventDetails$(): Observable<EventDetailsModel> {
    return this.eventDetails.asObservable();
  }

  loadEventData(raceTypeId?: number) {
    const fullUrl = raceTypeId == null ? `${environment.API_URL}/events` : `${environment.API_URL}/events/${raceTypeId}`;
    return this.http.get(fullUrl);
  }

  loadEventDetailsData(id: number) :Observable <any>{
     this.http.get<EventDetailsModel>(`${environment.API_URL}/event_details/${id}`)
      .subscribe((response) => {
        console.warn('events data are  : ', response.results[0].lines);
        response.details.forEach(item => {

          const from = new Date(item.registration_open);
          const to = new Date(item.registration_close);
          const now = new Date();
          item.isHidden = true;
          //console.log('dates are ', from, to, now);
          if (item.organisers === 'federation' && now >= from && now <= to) {
            if ( now >= from && now <= to) {

              item.isHidden = false;
            //console.log("opened1", item.isHidden);

             }
          }
          if (item.organisers === 'other' && item.open_registration == true ) {
           if ( now >= from && now <= to) {

            item.isHidden = false;
          //console.log("opened1", item.isHidden);

           }
          }
          if (item.organisers === 'other' && item.open_registration == false ) {
            item.isHidden = true;
          }
          //console.log("opened", item.isHidden,response.details);

        });


        this.eventDetails.next(response);
      })
      return this.http.get<EventDetailsModel>(`${environment.API_URL}/event_details/${id}`);

  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {TeamsClubsService} from "../../services/teams-clubs.service";

@Component({
  selector: 'app-teams-clubs-details',
  templateUrl: './teams-clubs-details.component.html',
})
export class TeamsClubsDetailsComponent implements OnInit, OnDestroy {
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  selectedYear = new Date().getFullYear();
  routeDataSubscription: Subscription;
  environment = environment;
  private id: number;

  constructor(public api: TeamsClubsService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    // this.route.queryParams
    //   .subscribe(params => {
    //       console.log(params);
    //       this.id = +params.id;
    //       this.api.loadSpecificTeam(this.id);
    //     }
    //   );
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.id = +data.get('id');
      this.api.loadSpecificTeam(this.id);
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription.unsubscribe();
  }
  loadEventDetails(id: number) {
    this.router.navigate([`/event/${id}`]);
  }

  LoadTeamAdminDetails(id: number) {
    this.router.navigate([`/officials/team/${id}`]);

  }
}

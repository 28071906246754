<div *ngIf="clubAdmin" id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "CommonText.Officials" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>
        <a routerLink="/national-teams/1" class="link"
          >{{ "App.NationalTeams" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "CommonText.Officials" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <section class="club-info">
      <div class="club-info__photo-block">
        <img
          [src]="environment.API_URL + clubAdmin.img_url"
          class="club-info__image"
          alt=""
        />
        <h1 class="h5 club-info__title">{{ clubAdmin.full_name }}</h1>
      </div>
      <div class="club-info__table-block">
        <div class="club-info__club-name">
          <img [src]="environment.API_URL + clubAdmin.club_image" />
          <h6 class="club-info__club-name-text" *ngIf="clubAdmin.club_name">
            {{ clubAdmin.club_name }}
          </h6>
        </div>
        <div class="club-info__table-block-inner">
          <h5 class="club-into__table-title">
            {{ "CommonText.PersonalDetails" | translate }}
          </h5>
          <table class="table table-varient1">
            <tbody>
              <tr *ngIf="clubAdmin.nationality">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.ClubOfficialDetails.Nationality" | translate }}
                  </span>
                </th>
                <td>
                  {{ clubAdmin.nationality }}
                </td>
              </tr>
              <tr *ngIf="clubAdmin.uci_id">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.ClubOfficialDetails.UCIID" | translate | translate }}
                  </span>
                </th>
                <td>
                  {{ clubAdmin.uci_id }}
                </td>
              </tr>
              <tr *ngIf="clubAdmin.date_birth">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.ClubOfficialDetails.DateOfBirth" | translate }}
                  </span>
                </th>
                <td>
                  {{ clubAdmin.date_birth }}
                </td>
              </tr>
              <tr *ngIf="nationalTeamService.getFunction(clubAdmin.category)">
                <th>
                  <span class="table__th-inner">
                    {{ "UAECF.ClubOfficialDetails.Function" | translate }}
                  </span>
                </th>
                <td>
                    {{
                      "Functions." +
                        nationalTeamService.getFunction(clubAdmin.category)
                        | translate
                    }}
                </td>
              </tr>
              
              <!-- <strong style="color: #999">
                {{ "UAECF.ClubOfficialDetails.Email" | translate }}:
              </strong>
              {{ clubAdmin.email }} -->
              
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </section>
</div>

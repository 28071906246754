<div class="container mt-5 mb-5">


    <div *ngIf="( api.calenderActivities$ | async) as activities">
        <!--    <div *ngIf="activities" class="d-flex justify-content-end mb-5 mt-5">-->
        <!--      <a routerLink="/footer/add-activity" class="link btn btn-lg smbt-btn">Create Activity</a>-->
        <!--    </div>-->
        <table class="table table_default">
            <thead>
                <tr>
                    <th scope="col" class="text-center">{{'Footer.ActivityAndCalender.Calender.EnglishNameLable' | translate}}</th>
                    <th scope="col" class="text-center">{{'Footer.ActivityAndCalender.Calender.ArabicNameLable' | translate}}</th>
                    <th scope="col" class="text-center">{{'Footer.ActivityAndCalender.Calender.DateFromLable' | translate}}</th>
                    <th scope="col" class="text-center">{{'Footer.ActivityAndCalender.Calender.DateToLable' | translate}}</th>
                    <th scope="col" class="text-center">{{'Footer.ActivityAndCalender.Calender.DurationLable' | translate}}</th>
                    <th scope="col" class="text-center">{{'Footer.ActivityAndCalender.Calender.StateLable' | translate}}</th>
                    <th scope="col" class="text-center">{{'Footer.ActivityAndCalender.Calender.RegisterToTheActivityLable' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let activity of activities" (click)="goToSeminarsDetails(activity.id)">
                    <th scope="row" class="text-center">{{activity.name}}</th>
                    <td class="text-center">{{activity.arabic_name}}</td>
                    <td class="text-center">{{activity.date_from}}</td>
                    <td class="text-center">{{activity.date_to}}</td>
                    <td class="text-center">{{activity.duration}}</td>
                    <td class="text-center"> {{activity.state}}</td>
                    <td class="text-center">
                        <div *ngIf="activity.state != 'close'">
                            <a routerLink="/footer/add-activity/{{activity.id}}" class="link btn btn-sm smbt-btn">{{'Footer.ActivityAndCalender.Calender.RegisterActivityButtonLable' | translate}}</a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

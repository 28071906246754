<div class="container pt-5 pb-5">
  <form class="row g-3 rider-registration-form" [formGroup]="freeRiderRegistrationForm" (ngSubmit)="onSubmit()">
    <h2 class="h1 text-danger fw-bold">{{ "Riders.Header" | translate}}</h2>


    <div class="row mt-4 mb-4">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{'Riders.FirstName' | translate}}</label>
          <input type="text" formControlName="firstName" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"/>
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">{{'Riders.FirstNameRequired' | translate}}</div>
          </div>
        </div>
      </div>


      <div class="col-md-4">
        <div class="form-group">
          <label>{{'Riders.MiddleName' | translate}}</label>
          <input type="text" formControlName="middleName" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.middleName.errors }"/>
          <div *ngIf="submitted && f.middleName.errors" class="invalid-feedback">
            <div *ngIf="f.middleName.errors.required">{{'Riders.MiddleNameRequired' | translate}}</div>
          </div>
        </div>
      </div>


      <div class="col-md-4">
        <div class="form-group">
          <label>{{'Riders.LastName' | translate}}</label>
          <input type="text" formControlName="lastName" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"/>
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">{{'Riders.LastNameRequired' | translate}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row  mt-4 mb-4">


      <div class="col-md-6">
        <div class="form-group">
          <label>{{'Riders.UCIID' | translate}} </label>
          <input type="text" formControlName="uciId" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.uciId.errors }"/>
          <div *ngIf="submitted && f.uciId.errors" class="invalid-feedback">
            <div *ngIf="f.uciId.errors.required">{{'Riders.UCIIDRequired' | translate}}</div>
          </div>
        </div>
      </div>
    </div>


    <div class="row  mt-4 mb-4">

      <div class="col-md-6" *ngIf="(api.clubTeamCategories$ | async) as clubTeamCategories">
        <label>{{'Riders.Category' | translate}}</label>
        <select class="form-select" (change)="changeCategory($event)" formControlName="freeCategory">
          <option value="" disabled>{{'Riders.ChooseCategory' | translate}}</option>
          <option *ngFor="let category of clubTeamCategories" [ngValue]="category.id">{{category.code}}</option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.freeCategory.errors" class="invalid-feedback">
          <div *ngIf="f.freeCategory.errors.required">{{'Riders.CategoryRequired' | translate}}</div>
        </div>

      </div>

    </div>

    <div class="row  mt-4 mb-4">

      <div class="col-md-6">
        <label>{{'Riders.RiderStatusLable' | translate}} </label>

        <select class="form-select" [(ngModel)]="riderTypeId" formControlName="riderType">
          <option value="" disabled>{{'Riders.ChooseRiderStatus' | translate}}</option>
          <option *ngFor="let riderStatus of riderStatuses" [ngValue]="riderStatus.id">{{ 'RiderStatuses.'+riderStatus.id |translate }}</option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.riderType.errors" class="invalid-feedback">
          <div *ngIf="f.riderType.errors.required">{{'Riders.RiderStatusRequired' | translate}}</div>
        </div>

      </div>

    </div>

    <div class="row  mt-4 mb-4">

      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label>{{'Riders.ValidUntil' | translate}}</mat-label>
            <input matInput [matDatepicker]="validUntilPicker" formControlName="validUntil">
            <mat-datepicker-toggle matSuffix [for]="validUntilPicker"></mat-datepicker-toggle>
            <mat-datepicker #validUntilPicker></mat-datepicker>
          </mat-form-field>

          <div *ngIf="submitted && f.validUntil.errors" class="invalid-feedback">
            <div *ngIf="f.validUntil.errors.required">{{'Riders.ValidRequired' | translate}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row  mt-4 mb-4">

      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label>{{'Riders.DateOfBirth' | translate}}</mat-label>
            <input matInput [matDatepicker]="dateOfBirthPicker" formControlName="dateOfBirth">
            <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirthPicker></mat-datepicker>
          </mat-form-field>

          <div *ngIf="submitted && f.dateOfBirth.errors" class="invalid-feedback">
            <div *ngIf="f.dateOfBirth.errors.required">{{'Riders.DateOfBirthRequired' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-md-6" *ngIf="(api.nationalities$ | async) as nationalities">
        <label>{{'Riders.Nationality' | translate}}</label>
        <select class="form-select" formControlName="nationality">
          <option value="" disabled>{{'Riders.ChooseNationality' | translate}}</option>
          <option *ngFor="let nationality of nationalities" [ngValue]="nationality.id">{{nationality.name}}</option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.nationality.errors" class="invalid-feedback">
          <div *ngIf="f.nationality.errors.required">{{'Riders.NationalityRequired' | translate}}</div>
        </div>

      </div>

    </div>


    <div class="row  mt-4 mb-4">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{'Riders.PlaceOfBirth' | translate}}</label>
          <input type="text" formControlName="birthPlace" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.birthPlace.errors }"/>
          <div *ngIf="submitted && f.birthPlace.errors" class="invalid-feedback">
            <div *ngIf="f.birthPlace.errors.required">{{'Riders.PlaceOfBirthRequired' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <label>{{'Riders.Gender' | translate}} </label>

        <select class="form-select" formControlName="gender">
          <option value="" disabled>{{'Riders.ChooseGender' | translate}}</option>
          <option *ngFor="let gender of genders" [ngValue]="gender">{{'Gender.'+gender|translate}}</option>
        </select>
        <!-- error block -->
        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
          <div *ngIf="f.gender.errors.required">{{'Riders.GenderRequired' | translate}}</div>
        </div>
      </div>

    </div>


    <div class="row  mt-4 mb-4">

      <div class="col-md-6">
        <div class="form-group">
          <label>{{'Riders.HomeAddress' | translate}}</label>
          <input type="text" formControlName="homeAddress" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.homeAddress.errors }"/>
          <div *ngIf="submitted && f.homeAddress.errors" class="invalid-feedback">
            <div *ngIf="f.homeAddress.errors.required">{{'Riders.HomeAddressRequired' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>{{'Riders.Phone' | translate}}</label>
          <input type="text" formControlName="phone" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"/>
          <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
            <div *ngIf="f.phone.errors.required">{{'Riders.PhoneRequired' | translate}}</div>
          </div>
        </div>
      </div>


    </div>

    <div class="row  mt-4 mb-4">

      <div class="col-md-6">
        <div class="form-group">
          <label>{{'Riders.RiderEmail' | translate}}</label>
          <input type="text" formControlName="riderEmail" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.riderEmail.errors }"/>
          <div *ngIf="submitted && f.riderEmail.errors" class="invalid-feedback">
            <div *ngIf="f.riderEmail.errors.required">{{'Riders.RiderEmailRequired' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>{{'Riders.WorkAddress' | translate}}</label>
          <input type="text" formControlName="workAddress" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.workAddress.errors }"/>
          <div *ngIf="submitted && f.workAddress.errors" class="invalid-feedback">
            <div *ngIf="f.workAddress.errors.required">{{'Riders.WorkAddressRequired' | translate}}</div>
          </div>
        </div>
      </div>


    </div>
    <div class="row  mt-4 mb-4">

      <div class="col-md-6">
        <div class="form-group">
          <label>{{'Riders.EmiratesID' | translate}}</label>
          <input type="text" formControlName="emiratesId" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.emiratesId.errors }"/>
          <div *ngIf="submitted && f.emiratesId.errors" class="invalid-feedback">
            <div *ngIf="f.emiratesId.errors.required">{{'Riders.EmiratesIDRequired' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">

          <mat-form-field appearance="fill">
            <mat-label>{{'Riders.EmiratesIdExpiryDate' | translate}}</mat-label>
            <input matInput [matDatepicker]="emiratesIdExpiryPicker" formControlName="emiratesIdExpiry">
            <mat-datepicker-toggle matSuffix [for]="emiratesIdExpiryPicker"></mat-datepicker-toggle>
            <mat-datepicker #emiratesIdExpiryPicker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="submitted && f.emiratesIdExpiry.errors" class="invalid-feedback">
            <div *ngIf="f.emiratesIdExpiry.errors.required">{{'Riders.EmiratesIdExpiryDateRequired' | translate}}</div>
          </div>
        </div>
      </div>


    </div>
    <div class="row mt-4 mb-4">

      <div class="col-md-6" formGroupName="image">
        <div class="form-group">
          <label>{{'Riders.UserImage' | translate}}</label>
          <input type="file"  class="form-control" formControlName="file" />
        </div>
      </div>

      <div class="offset-3 col-md-3">
        <img [src]="imageSrc" *ngIf="submitted && f.imageSrc.errors" style="height: 150px; width: 150px; object-fit: contain;">
      </div>

      <div class="col-md-6" formGroupName="identity">
        <div class="form-group">
          <label>{{'Riders.IDBothSides' | translate}}</label>
          <input type="file" multiple class="form-control" formControlName="file" (change)="onFileChange($event , 'imageSrc')"/>
        </div>
      </div>

      <div class="col-md-6" formGroupName="oGuardian">
        <div class="form-group">
          <label>{{'Riders.NoObjectionGuardian' | translate}}</label>
          <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event , 'oGuardian')"/>
        </div>
      </div>

      <div class="col-md-6" formGroupName="medical">
        <div class="form-group">
          <label>{{'Riders.MedicalFitnessCertificate' | translate}}</label>
          <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event , 'medical')"/>
        </div>
      </div>

      <div class="col-md-6" *ngIf="riderTypeId == 'gcc' || riderTypeId == 'uae_passport_holder'"
           formGroupName="passport">
        <div class="form-group">
          <label>{{'Riders.Passport' | translate}}</label>
          <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event , 'passport')"/>
        </div>
      </div>

      <div class="col-md-6" *ngIf="riderTypeId == 'children_citizens' " formGroupName="motherPassport">
        <div class="form-group">
          <label>{{'Riders.IdentityPassportMother' | translate}}</label>
          <input type="file" class="form-control" formControlName="file"
                 (change)="onFileChange($event , 'motherPassport')"/>
        </div>
      </div>

      <div class="col-md-6" *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae' "
           formGroupName="birthCert">
        <div class="form-group">
          <label>{{'Riders.BirthCertificate' | translate}}</label>
          <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event , 'birthCert')"/>
        </div>
      </div>

      <div class="col-md-6" *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae' "
           formGroupName="studies">
        <div class="form-group">
          <label>{{'Riders.ContinuityStudies' | translate}}</label>
          <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event , 'studies')"/>
        </div>
      </div>

      <div class="col-md-6" *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae' "
           formGroupName="iGuardian">
        <div class="form-group">
          <label>{{'Riders.IdentityGuardian' | translate}}</label>
          <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event , 'iGuardian')"/>
        </div>
      </div>

      <div class="col-md-6"
           *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae'  || riderTypeId == 'amateur' "
           formGroupName="pResidence">
        <div class="form-group">
          <label>{{'Riders.PlayerResidence' | translate}}</label>
          <input type="file" class="form-control" formControlName="file"
                 (change)="onFileChange($event , 'pResidence')"/>
        </div>
      </div>

      <div class="col-md-6" *ngIf="riderTypeId == 'children_citizens' || riderTypeId == 'born_uae' "
           formGroupName="gResidence">
        <div class="form-group">
          <label>{{'Riders.GuardianResidence' | translate}}</label>
          <input type="file" class="form-control" formControlName="file"
                 (change)="onFileChange($event , 'gResidence')"/>
        </div>
      </div>

      <div class="col-md-6" formGroupName="vaccineCert">
        <div class="form-group">
          <label>{{'Riders.UserVaccineCertification' | translate}} </label>
          <input type="file" class="form-control" formControlName="file"
                 (change)="onFileChange($event , 'vaccineCert')"/>
        </div>
      </div>
      <div class="col-md-6" formGroupName="insuranceCert">
        <div class="form-group">
          <label>{{'Riders.UserInsuranceCertification' | translate}} </label>
          <input type="file" class="form-control" formControlName="file"
                 (change)="onFileChange($event , 'insuranceCert')"/>
        </div>
      </div>


    </div>


    <div class="d-flex justify-content-center">
      <div class="registration-form__terms">
        <h3>{{'Riders.Notes' | translate}}:</h3>
        <p [innerHTML]="'Footer.ActivityAndCalender.Complaints.Terms.PaymentConfirmation' | translate"></p>
        <p [innerHTML]="'Footer.ActivityAndCalender.Complaints.Terms.RefundAndCancellationPolicy' | translate"></p>
        <p
          [innerHTML]="'Footer.ActivityAndCalender.Complaints.Terms.ServicePricing' | translate">
          :<strong
          [innerHTML]="calculatePrice() + ' Footer.ActivityAndCalender.Complaints.Terms.Currency' | translate"></strong>
        </p>
        <p>
          <mat-checkbox unchecked="agreedTerms" (click)="changeAgreedTerm()"></mat-checkbox>
          {{'Riders.IHaveReadAll' | translate}}<strong
          (click)="openDialog(1)">{{'Riders.TermsLable' | translate}} </strong>,<strong
          (click)="openDialog(2)"> {{'Riders.PolicyLable' | translate}}</strong> {{'Riders.AgreeLable'
          | translate}}</p>
      </div>

    </div>
    <div class="d-flex justify-content-center">
      <button type="submit" class="btn btn-lg submit-btn"
              [disabled]="!agreedTerms">{{'Riders.Submit' | translate}}</button>

    </div>
  </form>
</div>

import {Component, OnInit} from '@angular/core';
import {TeamsClubsService} from "../../services/teams-clubs.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-teams-clubs',
  templateUrl: './teams-clubs.component.html',
})
export class TeamsClubsComponent implements OnInit {

  enviroment = environment;

  constructor(public api: TeamsClubsService) {
  }

  ngOnInit(): void {
    this.api.loadClubs();
    this.api.loadTeams();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footercalendar',
  templateUrl: './footercalendar.component.html',
  styleUrls: ['./footercalendar.component.css']
})
export class FootercalendarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

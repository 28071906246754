import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generalcomplaints',
  templateUrl: './generalcomplaints.component.html',
  styleUrls: ['./generalcomplaints.component.css']
})
export class GeneralcomplaintsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {Component, OnInit} from '@angular/core';
import {NationalTeamService} from "../../../services/national-team.service";
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-national-team',
  templateUrl: './national-team.component.html',
  styleUrls: ['./national-team.component.css']
})
export class NationalTeamComponent implements OnInit {

  environment = environment;

  constructor(public api: NationalTeamService) {
  }

  ngOnInit(): void {
    this.api.loadNationalTeam();
  }

}

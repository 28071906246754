import {INewsDetails, INewsModel} from "../model/news.model";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NewsServices {
  currentNewsDetails = new BehaviorSubject<INewsDetails>({
    new: [] as INewsModel[],
    other: [] as INewsModel[],
  });

  constructor(private http: HttpClient) {
  }

  get currentNewsDetails$(): Observable<INewsDetails> {
    return this.currentNewsDetails.asObservable();
  }

  getSpecificNewsDetails(id: number) {
    this.http
      .get<INewsDetails>(`${environment.API_URL}/news_page/${id}`)
      .subscribe((newsDetails) => {
        //console.log('response is ', newsDetails);
        newsDetails.new.map((item) => {
          if (item) {
            item.img_url = `${environment.API_URL}${item.img_url}`;
          } else {
            //console.log('no item');
          }
          return item;
        });

        newsDetails.other.map((item) => {
          if (item) {
            item.img_url = `${environment.API_URL}${item.img_url}`;
          } else {
            //console.log('no other');
          }
          return item;
        });
        this.currentNewsDetails.next(newsDetails);
      });
  }

  getNews(raceTypeId?: number)  {
    const fullUrl = raceTypeId == null ? `${environment.API_URL}/news` : `${environment.API_URL}/news/${raceTypeId}`;
    return this.http.get(fullUrl);
  }
}

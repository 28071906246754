<div class="container pt-5 pb-5">
    <form class="row g-3" [formGroup]="delegationForm" (ngSubmit)="onSubmit()">
      <h2 class="h1 text-danger fw-bold">{{ "Accommodation.Delegation.Header" | translate}}</h2>

        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Accommodation.Delegation.EntryName' | translate}}</label>
                    <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">{{'Accommodation.Delegation.EntryNameRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Accommodation.Delegation.Address' | translate}}</label>
                    <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                        <div *ngIf="f.address.errors.required">{{'Accommodation.Delegation.AddressRequired' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 mt-4 mb-4">
            <div class="form-group">
                <label>{{'Accommodation.Delegation.TeamName' | translate}}</label>
                <input type="text" formControlName="teamName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.teamName.errors }" />
                <div *ngIf="submitted && f.teamName.errors" class="invalid-feedback">
                    <div *ngIf="f.teamName.errors.required">{{'Accommodation.Delegation.TeamNameRequired' | translate}}</div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-4">
                <div class="form-group">
                    <label>{{'Accommodation.Delegation.Phone' | translate}}</label>
                    <input type="tel" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">{{'Accommodation.Delegation.PhoneRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label>{{'Accommodation.Delegation.Fax' | translate}}</label>
                    <input type="tel" formControlName="fax" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fax.errors }" />
                    <div *ngIf="submitted && f.fax.errors" class="invalid-feedback">
                        <div *ngIf="f.fax.errors.required">{{'Accommodation.Delegation.FaxRequired' | translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label>{{'Accommodation.Delegation.Email' | translate}}</label>
                    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">{{'Accommodation.Delegation.EmailRequired' | translate}}</div>
                        <div *ngIf="f.email.errors.email">{{'Accommodation.Delegation.EmailInvalid' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mt-4 mb-4">
            <table class="table .table-bordered .table-sm  table-hover">
                <thead>
                    <tr>
                        <th scope="col">{{'Accommodation.Delegation.Function' | translate}}</th>
                        <th scope="col">{{'Accommodation.Delegation.LastNameFirstName' | translate}}</th>
                        <th scope="col">{{'Accommodation.Delegation.UCIID' | translate}} </th>
                        <th scope="col">*</th>
                    </tr>
                </thead>
                <tbody formArrayName="lines">

                    <tr *ngFor="let line of lines.controls; let i=index;let l=last" [formGroupName]="i">
                        <td>
                            <input type="text" formControlName="function" class="form-control" />
                        </td>
                        <td>
                            <input type="text" formControlName="lastNameFirstName" class="form-control" />
                        </td>
                        <td>
                            <input type="text" formControlName="license_number" class="form-control" />
                        </td>

                        <td>
                            <button mat-button (click)="removeLine(i)">{{'Accommodation.Delegation.RemoveLine' | translate}}
            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button mat-button (click)="addLine()">{{'Accommodation.Delegation.AddLine' | translate}}</button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Accommodation.Delegation.PresidentName' | translate}}</label>
                    <input type="text" formControlName="presidentName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.presidentName.errors }" />
                    <div *ngIf="submitted && f.presidentName.errors" class="invalid-feedback">
                        <div *ngIf="f.presidentName.errors.required">{{'Accommodation.Delegation.PresidentRequired' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Accommodation.Delegation.Place' | translate}}</label>
                    <input type="text" formControlName="place" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.place.errors }" />
                    <div *ngIf="submitted && f.place.errors" class="invalid-feedback">
                        <div *ngIf="f.place.errors.required">{{'Accommodation.Delegation.PlaceRequired' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-md-6" formGroupName="stamp">
                <div class="form-group">
                    <label>{{'Accommodation.Delegation.PresidentStampAngSig' | translate}}</label>
                    <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event)" />
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field>
                        <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{'Accommodation.Delegation.ChooseDate' | translate}}" formControlName="dateTime" [min]="minDate" [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>

                    <div *ngIf="submitted && f.dateTime.errors" class="invalid-feedback">
                        <div *ngIf="f.dateTime.errors.required">{{'Accommodation.Delegation.DateRequired' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center"> <button type="submit" class="btn btn-lg host-btn">{{'Accommodation.Delegation.Submit' | translate}}</button></div>
    </form>
</div>

<div id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "CommonText.Events" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>
        {{ "CommonText.Events" | translate }}
      </li>
    </ul>
  </div>
  <section class="page-body">
    <select class="form-select year-select" [(ngModel)]="selectedYear" (change)="selectedYearChanged()">
      <option *ngFor="let year of years">{{year}}</option>
    </select>
    <mat-tab-group class="mat-tab-secondary" mat-align-tabs="start">
      <mat-tab label="Road">
        <ul class="card-list">
          <li class="card" *ngFor="let eventData of eventsData">
            <a (click)="loadEventDetails(eventData.id)" *ngIf="eventData.race_type[0].race_type[1] == 'Road'">
              <div class="card__image-wrap" data-src-href="#">
                <img
                  class="card__image"
                  [src]="env.API_URL + eventData.img_url"
                />
              </div>
              <div class="card__body">
                <div class="card__body-tag-wrap">
                  <span class="card__date">{{ eventData.date  | date: 'mediumDate' }}</span>
                  <span class="category category_sm category_secondary">{{
                    eventData.state | titlecase
                  }}</span>
                </div>
                <span class="category">{{
                  eventData.race_type[0].race_type[1] | titlecase
                }}</span>
                <h4 class="h6 card__title">
                  {{ eventData.event | titlecase }}
                </h4>
                <div *ngIf="eventData.categories">
                  <h6 class="card__subtitle">{{"EventComponent.Categories" | translate}}</h6>
                  <ul class="card__description">
                    <li *ngFor="let category of eventData.categories">
                      {{ category.code }}
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </mat-tab>
      <mat-tab label="Track">
        <ul class="card-list">
          <li class="card" *ngFor="let eventData of eventsData">
            <a (click)="loadEventDetails(eventData.id)" *ngIf="eventData.race_type[0].race_type[1] == 'Track'">
              <div class="card__image-wrap" data-src-href="#">
                <img
                  class="card__image"
                  [src]="env.API_URL + eventData.img_url"
                />
              </div>
              <div class="card__body">
                <div class="card__body-tag-wrap">
                  <span class="card__date">{{ eventData.date  | date: 'mediumDate' }}</span>
                  <span class="category category_sm category_secondary">{{
                    eventData.state | titlecase
                  }}</span>
                </div>
                <span class="category">{{
                  eventData.race_type[0].race_type[1] | titlecase
                }}</span>
                <h4 class="h6 card__title">
                  {{ eventData.event | titlecase }}
                </h4>
                <div *ngIf="eventData.categories">
                  <h6>Categories:</h6>
                  <ul class="card__description">
                    <li *ngFor="let category of eventData.categories">
                      {{ category.code }}
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </mat-tab>
      <mat-tab label="MTB">
        <ul class="card-list">
          <li class="card" *ngFor="let eventData of eventsData">
            <a (click)="loadEventDetails(eventData.id)" *ngIf="eventData.race_type[0].race_type[1] == 'MTB'">
              <div class="card__image-wrap" data-src-href="#">
                <img
                  class="card__image"
                  [src]="env.API_URL + eventData.img_url"
                />
              </div>
              <div class="card__body">
                <div class="card__body-tag-wrap">
                  <span class="card__date">{{ eventData.date  | date: 'mediumDate' }}</span>
                  <span class="category category_sm category_secondary">{{
                    eventData.state | titlecase
                  }}</span>
                </div>
                <span class="category">{{
                  eventData.race_type[0].race_type[1] | titlecase
                }}</span>
                <h4 class="h6 card__title">
                  {{ eventData.event | titlecase }}
                </h4>
                <div *ngIf="eventData.categories">
                  <h6>Categories:</h6>
                  <ul class="card__description">
                    <li *ngFor="let category of eventData.categories">
                      {{ category.code }}
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </mat-tab>
      <mat-tab label="Tour">
        <ul class="card-list">
          <li class="card" *ngFor="let eventData of eventsData">
            <a (click)="loadEventDetails(eventData.id)" *ngIf="eventData.race_type[0].race_type[1] == 'Tour'">
              <div class="card__image-wrap" data-src-href="#">
                <img
                  class="card__image"
                  [src]="env.API_URL + eventData.img_url"
                />
              </div>
              <div class="card__body">
                <div class="card__body-tag-wrap">
                  <span class="card__date">{{ eventData.date  | date: 'mediumDate' }}</span>
                  <span class="category category_sm category_secondary">{{
                    eventData.state | titlecase
                  }}</span>
                </div>
                <span class="category">{{
                  eventData.race_type[0].race_type[1] | titlecase
                }}</span>
                <h4 class="h6 card__title">
                  {{ eventData.event | titlecase }}
                </h4>
                <div *ngIf="eventData.categories">
                  <h6>Categories:</h6>
                  <ul class="card__description">
                    <li *ngFor="let category of eventData.categories">
                      {{ category.code }}
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

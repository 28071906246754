
<div id="mainContent" class="container container_md container__page">
    <div class="page-header">
      <h2 class="page-header__title">
        {{ "App.HostUAECFEvent" | translate }}
      </h2>
      <ul class="breadcrumb">
        <li>
          <a routerLink="/" class="link"
            >{{ "CommonText.Home" | translate }} >&nbsp;</a
          >
        </li>
        <li>{{ "App.HostUAECFEvent" | translate }}</li>
      </ul>
    </div>
    <section class="page-body" *ngIf="api.uaecfEvents$ | async as uaecfEvetns">
        <ul class="card-list">
          <li class="card" *ngFor="let uaecfEvent of uaecfEvetns">
            <a routerLink="/events/{{ uaecfEvent.id}}">
              <div class="card__image-wrap" data-src-href="#">
                <img
                  class="card__image"
                  [src]="environment.API_URL+uaecfEvent.image_url"
                />
              </div>
              <div class="card__body">
                <div class="card__body-tag-wrap">
                  <span class="card__date">
                    {{uaecfEvent.date | date: 'mediumDate' }} - {{uaecfEvent.date_to | date: 'mediumDate' }}
                  </span>
                </div>
                <h4 class="h6 card__title">
                    {{uaecfEvent.event_name}}
                </h4>
                <p class="card__subtitle">{{uaecfEvent.description}}</p>
              </div>
            </a>
          </li>
          <li class="barricade"></li>
          <li class="barricade"></li>
        </ul>
    </section>
</div>

<div id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "UAECF.ClubsAndTeams.ClubsAndTeams" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link">{{ "CommonText.Home" | translate }} >&nbsp;</a>
      </li>
      <li>{{ "UAECF.ClubsAndTeams.ClubsAndTeams" | translate }}</li>
    </ul>
  </div>
  <div class="page-body">
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label='{{"CommonText.Clubs" | translate}}'>
        <div
          class="container card-container"
          *ngIf="api.clubsData$ | async as clubsData"
        >
          <ul class="club-card-list">
            <li class="club-card-list__li" *ngFor="let clubData of clubsData">
              <a
                routerLink="/clubs-details/{{ clubData.id }}"
                class="club-card"
              >
                <div class="club-card__header">
                  <div class="club-card__image-wrap">
                    <img
                      [src]="enviroment.API_URL + clubData.img_url"
                      class="club-card__image"
                      alt="{{ clubData.name }}"
                    />
                  </div>
                  <h6 class="club-card__title">{{ clubData.name }}</h6>
                </div>
                <p class="club-card__footer">
                  {{ "UAECF.ClubsAndTeams.ViewClubProfile" | translate }}
                </p>
              </a>
            </li>
          </ul>
        </div>
      </mat-tab>
      <mat-tab label='{{"CommonText.Teams" | translate}}'>
        <div
          class="container card-container"
          *ngIf="api.teamsData$ | async as teamData"
        >
          <ul class="club-card-list">
            <li class="club-card-list__li" *ngFor="let teamData of teamData">
              <a
                routerLink="/clubs-details/{{ teamData.id }}"
                class="club-card"
              >
                <div class="club-card__header">
                  <div class="club-card__image-wrap">
                    <img
                      [src]="enviroment.API_URL + teamData.img_url"
                      class="club-card__image"
                      alt="{{ teamData.name }}"
                    />
                  </div>
                  <h6 class="club-card__title">{{ teamData.name }}</h6>
                </div>
                <p class="club-card__footer">
                  {{ "UAECF.ClubsAndTeams.ViewTeamProfile" | translate }}
                </p>
              </a>
            </li>
          </ul>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

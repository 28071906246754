<div class="widthConteneur marginAuto clearfix">
    <div class="contentBreadcrumb clearfix">
        <ul class="breadcrumb">
            <li><a href="#" class="link">{{'RegistrationComponent.Home' | translate}}</a></li>
            <li><span class="link">{{'RegistrationComponent.Registration' | translate}}</span></li>
        </ul>
    </div>

    <div class="clearfix">
        <ul class="menuBlock fcListElement colonne3" style="position: relative; height: 1030px">
            <li class="block" style="position: absolute; top: 0px; left: 0px">
                <a routerLink="/registration/registerRider" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'RegistrationComponent.RidersRegistration' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 0px; left: 415px">
                <a routerLink="/registration/registerEvent" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'RegistrationComponent.EventRegistrationRequest' | translate}}</span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 0px; left: 830px">
                <a routerLink="/registration/registerAdmin" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'RegistrationComponent.AdminRegister' | translate}} </span>
                </a>
            </li>
            <li class="block" style="position: absolute; top: 0px; left: 830px">
                <a routerLink="/ridertransfer" class="link contentImage">
                    <img src="./../assets/PHOTO-1.jpg" alt="" />
                    <span class="texte">{{'RegistrationComponent.RiderTransfer' | translate}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>

<div id="mainContent">
    <div class="widthConteneur marginAuto clearfix" *ngIf="(api.raceHomeData$ | async) as homeData">

        <ul class="contentActualites clearfix">
            <div *ngFor="let news of homeData.last_news">
                <li class="contentBig" *ngIf="news.isLastEvent">

                    <div class="contentBandeau">
                        <h2 class="titre pt-3">
                            <i class="fa fa-newspaper-o" aria-hidden="true"></i> {{'Track.News' | translate}}
                        </h2>
                        <a href="#" class="link linkMore"><i class="fa fa-plus"></i></a>
                    </div>

                    <div class="contentActualite blockWithLink" data-src-href="#">
                        <a routerLink="/news-details/{{news.id}}" class="link contentImage" style="width: 100%; height: 100%">
                            <img src="{{ environment.API_URL + news.img_url }}" alt="" />
                        </a>
                        <div class="contentText">
                            <div class="contentDateType" title="{{'Track.UAECF' | translate}}" *ngFor="let category of news.category">
                                <span class="type">{{ category.category[1] }}</span>
                                <span class="date">{{ news.date }}</span>
                            </div>
                            <div class="newsTitle">
                                <h3 class="titre" style="overflow: hidden; padding-top: 5px">
                                    <span>{{ news.name }}</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="contentLittle" *ngIf="!news.isLastEvent">
                    <div class="contentActualite blockWithLink" data-src-href="#">
                        <div class="contentImage">
                            <a routerLink="/news-details/{{news.id}}" class="link contentImage" style="width: 100%; height: 100%">
                                <img src="{{ environment.API_URL + news.img_url }}" alt="" />
                            </a>
                        </div>
                        <div class="contentText">
                            <div class="contentDateType" title="{{'Track.UAECF' | translate}}" *ngFor="let category of news.category">
                                <span class="type">{{ category.category[1] }}</span>
                                <span class="date">{{ news.date }}</span>
                            </div>
                            <div class="newsTitle">
                                <h3 class="titre" style="overflow: hidden; padding-top: 5px">
                                    <span>{{ news.name }}</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </li>
            </div>

        </ul>

        <div class="clearfix contentBottom">
            <div class="contentLeft">
                <div class="contentAgenda clearfix">
                    <div class="contentEvenements">
                        <div class="contentBandeau">
                            <h2 class="titre pt-3"><i class="fa fa-calendar"></i> {{'Track.Calendar' | translate}}</h2>
                            <div class="navigationEvenements">
                                <span class="prev btPrevNext disable"><i class="fa fa-angle-left"></i></span>
                                <span class="next btPrevNext"><i class="fa fa-angle-right"></i></span>
                            </div>
                        </div>
                        <div class="contentSlidesEvenements">
                            <ul class="evenements">
                                <li class="evenement" *ngFor="let type of homeData.calendar" routerLink="/events/{{type.id}}" style="width: 200px">
                                    <div class="date">
                                        <span class="jours"></span>

                                        <span class="mois">{{ type.date }}</span>
                                    </div>
                                    <h3 class="titre">{{ type.event_name }}</h3>
                                    <span class="villePays">
                        <span class="ville ligne">
                          {{ type.race_type[0].race_type[1]}}
                        </span>
                                    <span class="pays ligne"> ( {{type.state}} ) </span></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="clearfix">
                    <div class="contentPhoto" *ngFor="let photo of homeData.photos">
                        <div class="contentBandeau" *ngFor="let eventData of photo.event">
                            <h2 class="titre pt-3"><i class="fa fa-image"></i> {{'Track.Photos' | translate}}</h2>
                            <!--              <a routerLink="/photos/{{ eventData.id }}" class="link linkMore"><i class="fa fa-plus"></i></a>-->
                        </div>
                        <div *ngFor="let imgData of photo.img_urls">
                            <a routerLink="/photos/{{ photo.event[0].id }}" class="link contentImage">
                                <img src="{{ environment.API_URL + imgData.img_url }}" alt="" />
                            </a>
                        </div>

                    </div>

                    <div class="contentPhoto" style="margin-left: 35px" *ngFor="let lastEvent of homeData.last_event">
                        <div class="contentBandeau">
                            <h2 class="titre pt-3"><i class="fa fa-calendar"></i> {{'Track.Events' | translate}}</h2>
                            <!--  <a routerLink="/events/{{ lastEvent.id }}" class="link linkMore"><i class="fa fa-plus"></i></a>-->
                        </div>
                        <a routerLink="/events/{{lastEvent.id}}" class="link contentImage">
                            <img src="{{environment.API_URL + lastEvent.img_url[0].img_url}}" alt="" />
                        </a>
                    </div>

                    <!--          <div class="contentVideo">-->
                    <!--            <div class="contentBandeau">-->
                    <!--              <h2 class="titre"><i class="fa fa-play"></i> Videos</h2>-->
                    <!--              <a href="#" class="link linkMore"><i class="fa fa-plus"></i></a>-->
                    <!--            </div>-->
                    <!--            <a href="#" class="link contentImage">-->
                    <!--              <img src="./../assets/5dfa64de2103f.jpg" alt=""/>-->
                    <!--              <span class="pictoPlay"></span>-->
                    <!--            </a>-->
                    <!--          </div>-->


                </div>
            </div>
        </div>
    </div>
</div>

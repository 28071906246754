import { IGeneralNewsModel, IGeneralNewsSliderModel } from './../model/home.model';
import { Component, OnInit, ViewChild } from "@angular/core";
import { BackendApiService } from "../backend-api.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { HomeService } from "../services/home.service";
import { NgImageSliderComponent } from 'ng-image-slider';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  environment = environment;
  @ViewChild('nav') slider: NgImageSliderComponent;


  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  last_news: any;
  calendar: any;
  last_event: any;
  photos: any;

  newsImages: Array<IGeneralNewsSliderModel> = [];
  counter = 0;
  constructor(
    private backendService: BackendApiService,
    public api: HomeService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
  }

  backwardCalender(length) {
    if (this.counter > 0) {
      this.counter--;
    }
    //console.log('this.counter mins',this.counter);

  }
  forwardCalender(length) {
    //console.log('this.counter plus',this.counter);

    if (this.counter < length) {
      this.counter++;
    }
    //console.log('this.counter plus plus',this.counter);

  }
  prevImageClick() {
    this.slider.prev();
  }

  nextImageClick() {
    this.slider.next();
  }
  imageNewsSelected(event) {
    //console.log({ event });
    this.router.navigate(['/news-details' + '/' + this.newsImages[event].id]);
  }
  getThumbnailPhotoFrom(youtubeUrl: string) {
    return 'https://i.ytimg.com/vi_webp/' + youtubeUrl.split("v=")[1] + '/hqdefault.webp';
  }

  loadHomePageData() {
    this.api.loadHomePageData();
    this.api.homePageData$.subscribe((data => {
      this.newsImages = [];
      for (let news of data.general_news) {
        const tempImg: IGeneralNewsSliderModel = {
          image: environment.API_URL + news.img_url,
          thumbImage: environment.API_URL + news.img_url,
          title: news.name,
          alt: news.subtitle,
          id: news.id
        };
        this.newsImages.push(tempImg);
      }

    }));
    // this.api.loadHomePageNewsData();
  }

  ngOnInit() {
    this.loadHomePageData();

  }
}

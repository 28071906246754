import { TermsPolicyComponent } from './../../components/terms-policy/terms-policy.component';
import { NationalTeamService } from './../../services/national-team.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HostUAECFEventService } from "../../services/host-uaecf-event.service";
import { TeamsClubsService } from "../../services/teams-clubs.service";
import { MatDialog } from "@angular/material/dialog";
import { ClubTeamRiderRegistrationComponent } from "../club-team-rider-registration/club-team-rider-registration.component";
import { environment } from '../../../environments/environment';
import { TeamAdmin } from "../../model/nationalTeam/team.detail.model";
import { IFullName, ILicenseNo, ITeamRiderRegistration, ReregistrationID } from "../../model/registration.model";
import { RegistrationsService } from "../../services/registrations.service";
import * as localforage from 'localforage';
import { AuthService } from 'src/app/auth/auth.service';
import * as Constants from '../../shared/AppConstant.constant';

@Component({
  selector: 'app-team-rider-registration',
  templateUrl: './team-rider-registration.component.html',
  styleUrls: ['./team-rider-registration.component.css']
})
export class TeamRiderRegistrationComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  teamRiderRegistrationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  teamRidersLicenses: ILicenseNo[] = [];
  teamRidersNames: IFullName[] = [];
  doctors: TeamAdmin[] = [];
  private uaeEventId: number;
  private teamName: string;
  agreedTerms: boolean;
  price = Constants.RiderRegisterPrice;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public api: HostUAECFEventService,
    private national: NationalTeamService,
    public clubTeamService: TeamsClubsService,
    public dialog: MatDialog,
    private auth: AuthService,
    private registrationService: RegistrationsService
  ) {
    this.RiderClubRegistrationFormInitilization();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.teamRiderRegistrationForm.controls;
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.uaeEventId = +data.get('id');
      this.loadingInitializationData();
      this.getTeamDetails();
    });
  }

  RiderClubRegistrationFormInitilization() {
    this.teamRiderRegistrationForm = this.fb.group({
      team: [this.auth.currentUserValue.teamId, [Validators.required]],
      date: ['', [Validators.required]],
      lines: this.fb.array([]),
      secretary: [''],
      doctorName: [''],
    });
    this.lines = this.teamRiderRegistrationForm.get('lines') as FormArray;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.teamRiderRegistrationForm.invalid) {
      return;
    }

    const formData = {
      team_name: this.teamRiderRegistrationForm.get('team').value.toString(),
      team_manager: this.teamRiderRegistrationForm.get('secretary').value.toString(),
      doctor_name: this.teamRiderRegistrationForm.get('doctorName').value.toString(),
      reregistration_ids: this.lines.controls.map(item => {
        return {
          category: item.get('category').value.toString(),
          license_no: item.get('federation_id').value.toString(),
          full_name: item.get('fullName').value.toString(),
          new_old: item.get('isOld').value.toString().toLowerCase(),
          date_birth: item.get('dateOfBirth').value.toString(),
          health_fitness: item.get('healthFitness').value.toString(),
          nationality: item.get('nationality').value.toString(),
        } as ReregistrationID;
      })
    } as ITeamRiderRegistration;
    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    //console.log('entry by name data are', this.teamRiderRegistrationForm.value);

    // last step
    this.registrationService.registerBulkRidersForTeam(formData).subscribe(async (data) => {
      //console.log({ data });
      if (data.result.success && this.price > 0 && data.result.payment_resopnse) {
        const reference = data.result.payment_resopnse.order.ref;
        const paymentUrl = data.result.payment_resopnse.order.url;

        await localforage.setItem('reference', reference, function (err) {
          // if err is non-null, we got an error
          // localforage.getItem('key', function (err, value) {
          //   // if err is non-null, we got an error. otherwise, value is the value
          // });
        });
        window.location.href = paymentUrl;
      }
      else if (data.result.success) {
        //console.log("ssuccess toaster");
        window.location.href = "/";
      }
    });
  }

  onReset(): void {
    this.submitted = false;
    this.teamRiderRegistrationForm.reset();
  }

  addLine(e): void {
    // this.lines = this.entryByNameForm.get('lines') as FormArray;
    e.preventDefault();
    this.lines.push(this.createLine());
  }

  removeLine(i: number) {
    this.lines.removeAt(i);
  }

  createLine(): FormGroup {
    return this.fb.group({
      federation_id: [''],
      fullName: [''],
      dateOfBirth: [''],
      isOld: [''],
      nationality: [''],
      category: [''],
      healthFitness: [''],
    });
  }

  getTeamDetails() {
    const team = this.teamRiderRegistrationForm.get('team').value;
    //console.log('team id is ', team);
    this.clubTeamService.loadSpecificTeam(team);
    this.clubTeamService.teamDetailsData$.subscribe(data => {
      //console.log('data team are ', data);
      if (data.team.length > 0) {
        this.teamName = data.team[0].name;
        this.doctors = data.team_admin;
      }
    });
    this.clubTeamService.loadRidersForSpecificTeam(team);
    this.clubTeamService.teamRiders$.subscribe(data => {
      this.teamRidersLicenses = data.map(item => item.license_no[0]);
      // Dumpiest solution need refactor in phase 2 :)
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        try {
          this.teamRidersNames.push(element.full_name[0]);
        } catch (error) { }
      }
      //console.log("from club ARRAYS", { data }, this.teamRidersNames, this.teamRidersLicenses);
    });
  }

  ChangeRiderLicense(e, i: number) {
    const riderLicenseNumber = this.lines.controls[i].get('federation_id').value;
    this.clubTeamService.teamRiders$.subscribe(data => {

      const rider = data.find(item => item.license_no[0].id == riderLicenseNumber);
      if (rider) {
        this.lines.controls[i].patchValue({
          fullName: rider.full_name[0].id,
          dateOfBirth: rider.date_birth,
          isOld: 'Old',
          nationality: rider.nationality[0].nationality[0],
          category: rider.team_category[0].team_category[0],
          // healthFitness: null
        });
      }
    });
  }

  ChangeRiderName(e, i: number) {
    const riderLicenseName = this.lines.controls[i].get('fullName').value;
    this.clubTeamService.teamRiders$.subscribe(data => {

      const rider = data.find(item => item.full_name[0].id == riderLicenseName);
      //console.log({ riderLicenseName }, rider, 'from names');

      if (rider) {
        this.lines.controls[i].patchValue({
          federation_id: rider.license_no[0].id,
          fullName: rider.full_name[0].id,
          dateOfBirth: rider.date_birth,
          isOld: 'Old',
          nationality: rider.nationality[0].nationality[0],
          category: rider.team_category[0].team_category[0],
          // healthFitness: null
        });
      }
    });
    //console.log('entry by riderLicenseName are', this.teamRiderRegistrationForm.value);
  }

  OpenRiderRegistrationForm() {
    const dialogRef = this.dialog.open(ClubTeamRiderRegistrationComponent, {
      width: '1200px',
      height: '900px',
      data: {
        id: this.teamRiderRegistrationForm.get('team').value,
        clubOrTeam: 'team',
        name: this.teamName
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(async result => {
      //console.log('dialog result is ', result);
      await this.national.loadNewRiderProfile(result).subscribe(rider => {
        //console.log({ rider }, this.lines.length);
        if (rider.data) {
          this.teamRidersLicenses.push({ id: rider.data[0].id, federation_id: rider.data[0].license_no });
          this.teamRidersNames.push(rider.data[0].full_name[0]);
          this.lines.controls[this.lines.length - 1].patchValue({
            federation_id: rider.data[0].id,
            fullName: rider.data[0].full_name[0].id,
            dateOfBirth: rider.data[0].date_birth,
            isOld: 'New',
            nationality: rider.data[0].nationality[0].nationality[0],
            category: rider.data[0].team_category[0].team_category[0],
            healthFitness: 'null'
          });
          ``
        }
      });
    });
  }

  calculatePrice() {
    let emptyLines = 0;
    for (const line of this.lines.controls) {
      if (line.get('isOld').value && line.get('isOld').value.toLowerCase() == "new") {
        emptyLines++;
      }
    }
    return (this.lines.length - emptyLines) * this.price;
  }
  changeAgreedTerm() {
    this.agreedTerms = !this.agreedTerms;
    //console.log(this.agreedTerms);
  }

  openDialog(type) {
    //console.log('fromdialog');
    const dialogRef = this.dialog.open(TermsPolicyComponent, {
      width: '750px',
      height: '550px',
      data: { type: type }
    });

    dialogRef.afterClosed().subscribe(async result => {
      //console.log('The dialog was closed', result);
    });
  }

  private loadingInitializationData() {
    this.clubTeamService.loadTeams();
    this.api.loadClubTeamCategory();
    this.api.loadNationalities();
  }
}

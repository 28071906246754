import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";

import {HttpClient} from "@angular/common/http";
import {
  HostUAECFEvent,
  IAccommodationFormData,
  IClubTeamCategory,
  IDelegationFormData,
  IEntryByNameFormData,
  IFlightSchedule,
  INationality,
  IRaceType,
  IRiderType,
  IVisaApplicationFormData
} from "../model/UAECFEvent.model";
import {BehaviorSubject, Observable} from "rxjs";
import { IRiderDetails } from '../model/nationalTeam/rider.model';

@Injectable({
  providedIn: 'root'
})
export class HostUAECFEventService {


  uaecfEvents = new BehaviorSubject<HostUAECFEvent[]>([]);
  clubTeamCategories = new BehaviorSubject<IClubTeamCategory[]>([]);
  raceTypes = new BehaviorSubject<IRaceType[]>([]);
  riders = new BehaviorSubject<IRiderType[]>([]);
  nationalities = new BehaviorSubject<INationality[]>([]);
  ridersDetails = new BehaviorSubject<IRiderDetails[]>([]);

  constructor(private http: HttpClient) {
  }

  get uaecfEvents$(): Observable<HostUAECFEvent[]> {
    return this.uaecfEvents.asObservable();
  }

  get clubTeamCategories$(): Observable<IClubTeamCategory[]> {
    return this.clubTeamCategories.asObservable();
  }

  get raceTypes$(): Observable<IRaceType[]> {
    return this.raceTypes.asObservable();
  }

  get riders$(): Observable<IRiderType[]> {
    return this.riders.asObservable();
  }
  get ridersDetails$(): Observable<IRiderDetails[]> {
    return this.ridersDetails.asObservable();
  }

  get nationalities$(): Observable<INationality[]> {
    return this.nationalities.asObservable();
  }

  loadUAECF_Events() {
    this.http.get(`${environment.API_URL}/host-uae`)
      .subscribe(((response: any) => {
        //console.log('internation event data are ', response);
        const dataModel = response.data as HostUAECFEvent[];
        this.uaecfEvents.next(dataModel);
      }));
  }

  loadClubTeamCategory() {
    this.http.get(`${environment.API_URL}/club-team-category`)
      .subscribe(((response: any) => {
        const dataModel = response.data as IClubTeamCategory[];
        this.clubTeamCategories.next(dataModel);
      }));
  }

  loadRaceType() {
    this.http.get(`${environment.API_URL}/race-type`)
      .subscribe(((response: any) => {
        const dataModel = response.data as IRaceType[];
        this.raceTypes.next(dataModel);
      }));
  }

  loadRiders() {
    this.http.get(`${environment.API_URL}/riders_list`)
      .subscribe(((response: any) => {
        const dataModel = response.data as IRiderType[];
        const dataModelDetails = response.data as  IRiderDetails[];
        this.riders.next(dataModel);
        this.ridersDetails.next(dataModelDetails);
      }));
  }



  addEntryByName(data: IEntryByNameFormData) {
    this.http.post(`${environment.API_URL}/entry-name`, data).subscribe(response => {
      //console.log('response are ', response);
    });
  }

  addDelegationHost(data: IDelegationFormData) {
    this.http.post(`${environment.API_URL}/delegation-host`, data).subscribe(response => {
      //console.log('response are ', response);
    });
  }

  addAccommodation(data: IAccommodationFormData) {
    this.http.post(`${environment.API_URL}/accommodation-form`, data).subscribe(response => {
      //console.log('response are ', response);
    });
  }

  addFlightSchedule(data: IFlightSchedule) {
    const requestModel = {
      params: {
        ...data
      }
    };
    this.http.post(`${environment.API_URL}/flight-schedule`, requestModel).subscribe(response => {
      //console.log('response are ', response);
    });
  }

  addVisaApplication(data: IVisaApplicationFormData) {
    this.http.post(`${environment.API_URL}/visa-application`, data).subscribe(response => {
      //console.log('response are ', response);
    });
  }

  loadNationalities() {
    this.http.get(`${environment.API_URL}/nationality`)
      .subscribe(((response: any) => {
        const dataModel = response.data as INationality[];
        //console.log(response.data);

        this.nationalities.next(dataModel);
      }));
  }

}

import {Component, OnInit} from '@angular/core';
import {FooterService} from "../../../services/footer.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css']
})
export class CalenderComponent implements OnInit {

  constructor(public api: FooterService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.api.loadCalenderActivities();
  }

  goToSeminarsDetails(id: number) {
    this.router.navigate([`/footer/add-activity/${id}`]);
  }
}

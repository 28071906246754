<div id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "UAECF.HallOfFame.HallOfFame" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "UAECF.HallOfFame.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "UAECF.HallOfFame.HallOfFame" | translate }}</li>
    </ul>
  </div>
  <div class="page-body">
    <div class="page-intro">
      <p>{{ "UAECF.HallOfFame.HallOfFameText" | translate }}</p>
    </div>
    <ul class="card-list profile-card-list">
      <li *ngFor="let data of List">
        <div class="card profile-card">
          <div class="profile-card__img-wrap">
            <img
              src="{{ environment.API_URL + data.img_url }}"
              class="profile-card__img"
            />
          </div>
          <div class="profile-card__text-wrap">
            <h4 class="h5 profile-card__title">
              {{ data.full_name[0].full_name[1] }}
            </h4>
            <h5 class="h6 profile-card__subtitle">{{ data.subtitle }}</h5>
            <p class="profile-card__description">{{ data.brief }}</p>
            <ng-container *ngIf="data.linkedin_profile">
              <a
                class="profile-card__linkedin-link"
                href="{{ data.linkedin_profile }}"
                target="_blank"
                title="LinkedIn profile of {{ data.full_name[0].full_name[1] }}"
              >
                <img
                  class="profile-card__linkedin-icon"
                  src="../../../assets/images/linedin.svg"
                />
              </a>
            </ng-container>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "Photos.Photos" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "Photos.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "Photos.Photos" | translate }}</li>
    </ul>
  </div>
  <div class="page-body">
    <div class="filters filters_floating">
      <select
        class="form-select year-filter"
        [(ngModel)]="selectedYear"
        (change)="selectedYearChanged()"
      >
        <option *ngFor="let year of years">{{ year }}</option>
      </select>
    </div>
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="this.raceId - 1">
      <mat-tab label="Road">
        <div class="tab-body">
          <ul class="media-list" [photoGalleryGroup]="{ arrowEl: true, indexIndicatorSep: ' - ' }">
            <li 
              class="media-list__li" 
              *ngFor="let photo of racePhotosImages, let i = index"
            >
              <a
                class="media-card"
                *ngIf="photo.race_type[0].category[1] == 'Road'"
              >
                <div class="media-card__top-wrap" [photoGallery]="photo.img_url">
                  <img
                    class="media-card__media media-card__media_blur"
                    [src]="photo.img_url"
                  />
                  <img class="media-card__media" [src]="photo.img_url" />
                </div>
                <div class="media-card__text-wrap">
                  <h5 class="h6 media-card__title">
                    {{ photo.event[0].event[1] }}
                  </h5>
                </div>
              </a>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
          <div class="button-wrap hidden">
            <button class="button_custom">
              {{ "CommonText.LoadMore" | translate }}
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Track">
        <div class="tab-body">
          <ul class="media-list" [photoGalleryGroup]="{ arrowEl: true, indexIndicatorSep: ' - ' }">
            <li 
              class="media-list__li" 
              *ngFor="let photo of racePhotosImages, let i = index"
            >
              <div
                class="media-card"
                *ngIf="photo.race_type[0].category[1] == 'Track'"
              >
                <div class="media-card__top-wrap" [photoGallery]="photo.img_url">
                  <img
                    class="media-card__media media-card__media_blur"
                    [src]="photo.img_url"
                  />
                  <img class="media-card__media" [src]="photo.img_url" />
                </div>
                <div class="media-card__text-wrap">
                  <h5 class="h6 media-card__title">
                    {{ photo.event[0].event[1] }}
                  </h5>
                </div>
              </div>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
        </div>
      </mat-tab>
      <mat-tab label="MTB">
        <div class="tab-body">
          <ul class="media-list" [photoGalleryGroup]="{ arrowEl: true, indexIndicatorSep: ' - ' }">
            <li 
              class="media-list__li" 
              *ngFor="let photo of racePhotosImages, let i = index"
            >
              <div
                class="media-card"
                *ngIf="photo.race_type[0].category[1] == 'MTB'"
              >
                <div class="media-card__top-wrap" [photoGallery]="photo.img_url">
                  <img
                    class="media-card__media media-card__media_blur"
                    [src]="photo.img_url"
                  />
                  <img class="media-card__media" [src]="photo.img_url" />
                </div>
                <div class="media-card__text-wrap">
                  <h5 class="h6 media-card__title">
                    {{ photo.event[0].event[1] }}
                  </h5>
                </div>
              </div>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
        </div>
      </mat-tab>
      <mat-tab label="Tour">
        <div class="tab-body">
          <ul class="media-list" [photoGalleryGroup]="{ arrowEl: true, indexIndicatorSep: ' - ' }">
            <li 
              class="media-list__li" 
              *ngFor="let photo of racePhotosImages, let i = index"
            >
              <div
                class="media-card"
                *ngIf="photo.race_type[0].category[1] == 'Tour'"
              >
                <div class="media-card__top-wrap" [photoGallery]="photo.img_url">
                  <img
                    class="media-card__media media-card__media_blur"
                    [src]="photo.img_url"
                  />
                  <img class="media-card__media" [src]="photo.img_url" />
                </div>
                <div class="media-card__text-wrap">
                  <h5 class="h6 media-card__title">
                    {{ photo.event[0].event[1] }}
                  </h5>
                </div>
              </div>
            </li>
            <li class="barricade"></li>
            <li class="barricade"></li>
            <li class="barricade"></li>
          </ul>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div id="mainContent" class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "Calendar.Links.Calendar" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "CommonText.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "Calendar.Links.Calendar" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <select
      class="form-select year-filter"
      [(ngModel)]="selectedYear"
      (change)="selectedYearChanged()"
    >
      <option *ngFor="let year of years">{{ year }}</option>
    </select>
    <div class="calendar-events">
      <div class="calendar-events__events">
        <ul class="event-list">
          <li
            class="event-list__li"
            *ngFor="
              let calendar of allEvents
                | paginate: { itemsPerPage: 10, currentPage: page }
            "
          >
            <a class="event-list__a" routerLink="/event/{{ calendar.id }}">
              <div class="event-list__date">
                <span class="event-list__date-text">
                  <!-- {{ calendar.date  | date: 'mediumDate' }} -->
                  {{ calendar.date | date: "longDate" }}
                </span>
              </div>
              <div class="event-list__info-block">
                <span class="category">
                  {{ calendar.race_type[0].race_type[1] }}
                </span>
                <h3 class="h4 event-list__title">
                  {{ calendar.event }}
                </h3>
              </div>
            </a>
          </li>
        </ul>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>

      <div class="calendar-events__categories">
        <div class="section-title-block">
          <img
            class="section-title-block__image"
            src="../../assets/images/logo.svg"
          />
          <h2 class="section-title-block__title">
            {{ "CommonText.OurCategories" | translate }}
          </h2>
        </div>
        <app-cat-list></app-cat-list>
      </div>
    </div>
  </section>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {RankingsService} from "../../services/rankings.service";
import {GeneralRankingGeneralRanking, IndividuleLine, TeamLine} from "../../model/rankings.model";

@Component({
  selector: 'app-race-type-rankings-details',
  templateUrl: './race-type-rankings-details.component.html',
  styleUrls: ['./race-type-rankings-details.component.css']
})
export class RaceTypeRankingsDetailsComponent implements OnInit, OnDestroy {
  public individualLines: IndividuleLine[] = [];
  public teamRankings: TeamLine[] = [];
  public generalRankings: GeneralRankingGeneralRanking[] = [];
  tabIndex = 1;
  private sub: Subscription;
  private raceType: number;
  private year: number;
  private category: number;
  private rankingType: number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private api: RankingsService
  ) {
  }

  ngOnInit() {
    this.sub = this.route
      .queryParamMap
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.raceType = +params.get('raceType') || 0;
        this.year = +params.get('year') || 0;
        this.category = +params.get('category') || 0;
        this.loadRaceDetailsData();
        this.tabIndex = +params.get('ranking') || 0;
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private loadRaceDetailsData() {
    this.api.rankingsData$.subscribe(data => {
      data.individule_ranking.forEach(item => {
        if (item.individule[0].year == this.year) {
          this.individualLines.push(...item.individule_lines);
        }
      });

      data.team_ranking.forEach(item => {
        if (item.team[0].year == this.year) {
          this.teamRankings.push(...item.team_lines);
        }
      });

      data.general_ranking.forEach(item => {
        if (item.general[0].year == this.year) {
          this.generalRankings.push(...item.general_ranking);
        }
      });
    });
  }
}

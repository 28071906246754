import {Component, Inject, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {IRaceClassificationType} from "../../model/UAECFEvent.model";
import * as moment from "moment";
import {ThemePalette} from "@angular/material/core";
import {IRiderRegistrationModel, IStringifiesIdLookup} from "../../model/registration.model";
import {ActivatedRoute, Router} from "@angular/router";
import {HostUAECFEventService} from "../../services/host-uaecf-event.service";
import {RegistrationsService} from "../../services/registrations.service";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-club-team-rider-registration',
  templateUrl: './club-team-rider-registration.component.html',
  styleUrls: ['./club-team-rider-registration.component.css']
})
export class ClubTeamRiderRegistrationComponent implements OnInit {
  routeDataSubscription: Subscription;
  environment = environment;
  freeRiderRegistrationForm: FormGroup;
  submitted: boolean;
  lines: FormArray;
  raceClassification: IRaceClassificationType [] = [];
  riderTypeId;
  imageSrc: string;
  identitySrc: string;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  genders = ['male', 'female'];

  riderStatuses: IStringifiesIdLookup [] = [
    {
      id: 'citizen_rider',
      name: 'Local Emirati'
    },
    {
      id: 'children_citizens',
      name: 'Children of citizens'
    },
    {
      id: 'gcc',
      name: 'GCC'
    },
    {
      id: 'born_uae',
      name: 'Born in UAE'
    },
    {
      id: 'resident_rider',
      name: 'Resident Rider'
    },
    {
      id: 'uae_passport_holder',
      name: 'UAE Passport Holders'
    },
    {
      id: 'amateur',
      name: 'foreign resident'
    },

  ];
  public clubOrTeam: string;
  CTName: string;
  CTId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: HostUAECFEventService,
              private registrationApi: RegistrationsService
  ) {

    this.entryByNameFormInitialization();
    this.routeDataSubscription =  this.route.paramMap.subscribe(async par => {
      //console.log('data model are', par,par.get('clubOrTeam').toString());

       this.CTId = await Number(par.get('id'));
      this.clubOrTeam = await par.get('clubOrTeam').toString();
      this.CTName =  await par.get('name').toString();



    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.freeRiderRegistrationForm.controls;
  }

  // get fa(): { [key: string]: AbstractControl } {
  //   return this.entryByNameForm['lines'].controls;
  // }

  closeDialog(id) {
    // this.dialogRef.close(id);
  }
// Getter method to access formcontrols
  get categoryId() {
    return this.freeRiderRegistrationForm.get('freeCategory');
  }

  getFormField(name) {
    return this.freeRiderRegistrationForm.get(name) as FormGroup;

  }



  async ngOnInit(): Promise<void> {
    this.loadingInitializationData();


  }

  entryByNameFormInitialization() {
    this.freeRiderRegistrationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      middleName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      birthPlace: ['', [Validators.required]],
      homeAddress: ['', [Validators.required]],
      workAddress: ['', [Validators.required]],
      riderType: ['', [Validators.required]],
      emiratesId: ['', [Validators.required]],
      freeCategory: ['', [Validators.required]],
      emiratesIdExpiry: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      uciId: [''],
      riderEmail: ['', [Validators.required]],
      image: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      identity: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      oGuardian: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      medical: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      passport: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      motherPassport: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      birthCert: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      studies: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      iGuardian: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      pResidence: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      gResidence: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      vaccineCert: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
      insuranceCert: this.fb.group({
        file: [''],
        fileSource: [''],
        extension: ['']
      }),
    });
  }

  getFormValidationErrors(form: FormGroup) {

    const result = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    //console.log('result is : ', result);
    // return result;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.freeRiderRegistrationForm.invalid) {
      this.getFormValidationErrors(this.freeRiderRegistrationForm);
      return;
    }

    const formData = {
      register_type: this.clubOrTeam,
      first_name: this.freeRiderRegistrationForm.get('firstName').value,
      // date: this.freeRiderRegistrationForm.get('date').value,
      last_name: this.freeRiderRegistrationForm.get('lastName').value,
      middle_name: this.freeRiderRegistrationForm.get('middleName').value,
      phone: this.freeRiderRegistrationForm.get('phone').value,
      date_birth: this.formatDate(this.freeRiderRegistrationForm.get('dateOfBirth').value) ,
      gender: this.freeRiderRegistrationForm.get('gender').value,
      place_birth: this.freeRiderRegistrationForm.get('birthPlace').value,
      home_address: this.freeRiderRegistrationForm.get('homeAddress').value,
      work_address: this.freeRiderRegistrationForm.get('workAddress').value,
      rider_type: this.freeRiderRegistrationForm.get('riderType').value,
      emirates_id: this.freeRiderRegistrationForm.get('emiratesId').value,
      emirates_id_expiry: this.formatDate(this.freeRiderRegistrationForm.get('emiratesIdExpiry').value) ,
      nationality: this.freeRiderRegistrationForm.get('nationality').value,
      rider_email: this.freeRiderRegistrationForm.get('riderEmail').value,
      uci_id: this.freeRiderRegistrationForm.get('uciId').value,
      image: this.formatFileBase64(this.getFormField('image').get('fileSource').value.toString()),
      identity_both_sides: this.formatFileBase64(this.getFormField('identity').get('fileSource').value.toString()),
      photo_with_white_backround: this.formatFileBase64(this.getFormField('image').get('fileSource').value.toString()),
      no_objection_from_guardian:this.formatFileBase64( this.getFormField('oGuardian').get('fileSource').value.toString()),
      medical_fitness_certificate: this.formatFileBase64(this.getFormField('medical').get('fileSource').value.toString()),
      valid_passport:this.formatFileBase64( this.getFormField('passport').get('fileSource').value.toString()),
      identity_passport_mother: this.formatFileBase64(this.getFormField('motherPassport').get('fileSource').value.toString()),
      player_birth_certificate: this.formatFileBase64(this.getFormField('birthCert').get('fileSource').value.toString()),
      continuity_studies: this.formatFileBase64(this.getFormField('studies').get('fileSource').value.toString()),
      identity_guardian: this.formatFileBase64(this.getFormField('iGuardian').get('fileSource').value.toString()),
      player_residence: this.formatFileBase64(this.getFormField('pResidence').get('fileSource').value.toString()),
      guardian_residence: this.formatFileBase64(this.getFormField('gResidence').get('fileSource').value.toString()),
      vaccine_certification: this.formatFileBase64(this.getFormField('vaccineCert').get('fileSource').value.toString()),
      insurance_certificate: this.formatFileBase64(this.getFormField('insuranceCert').get('fileSource').value.toString()),
    } as IRiderRegistrationModel;
    const category = +this.freeRiderRegistrationForm.get('freeCategory').value;
    if (this.clubOrTeam == 'club') {
      formData.club_name = this.CTId;
      formData.club_category = category;
    } else {
      formData.team_name = this.CTId;
      formData.team_category = category;
    }
    // //console.log('form request data', formData);
    // this.dialogRef.close({formData: formData});
    // // last step
    this.registrationApi.registerRider(formData).subscribe((data) => {

      //console.log(data);
      if (data.result.success == true) {
      this.closeDialog(data.result.data);
      this.onReset();
      }
    });
  }

  onReset(): void {
    this.submitted = false;
    this.freeRiderRegistrationForm.reset();
  }

  // Choose category using select dropdown
  changeCategory(e) {
    // this.categoryId.setValue(e.target.value, {
    //   onlySelf: true
    // });
  }
  formatDate(date): string {
    const nowDate = new Date(date);
    const formattedDate = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate();
    //console.log({date});

    return formattedDate.toString();
  }
  onFileChange(event, fileType: string) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      //console.log('file extensions', reader);
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {

        if (fileType == 'image') {
          this.imageSrc = reader.result as string;
          this.getFormField('image').patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }
        this.getFormField(fileType).patchValue({
          fileSource: reader.result,
          extension: fileExtension
        });

      };

    }
  }
formatFileBase64(file64:string):string{
  const formattedFile = file64.split('base64,');
  return formattedFile[1];
}
  private loadingInitializationData() {
    this.api.loadClubTeamCategory();
    this.api.loadNationalities();
  }

}

<div class="container container__page">
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "UAECF.Board.Board" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "UAECF.Board.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "UAECF.Board.Board" | translate }}</li>
    </ul>
  </div>
  <div class="page-body board">
    <ul
      class="card-list profile-card-list profile-card-list_lg"
      *ngIf="
        baordManagementService.firstLevelBoardManagementData$
          | async as boardManagementData
      "
    >
      <li *ngFor="let board of boardManagementData">
        <div class="card profile-card profile-card_lg">
          <div class="profile-card__img-wrap">
            <img
              [src]="environment.API_URL + board.img_url"
              class="profile-card__img"
            />
          </div>
          <div class="profile-card__text-wrap">
            <h4 class="profile-card__title">{{ board.name }}</h4>
            <h5 class="profile-card__subtitle">{{ board.position[0].position[1] }}</h5>
            <p class="profile-card__description">
              <!--Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.-->
            </p>
            <a class="profile-card__linkedin-link" href="{{ board.linkedin_profile }}" target="_blank" title="LinkedIn profile of {{ board.name }}">
              <img class="profile-card__linkedin-icon" src="../../../assets/images/linedin.svg" />
            </a>
          </div>
        </div>
      </li>
    </ul>
    <ul
      class="card-list profile-card-list"
      *ngIf="
        baordManagementService.secondBoardManagementData$
          | async as boardManagementData
      "
    >
      <li *ngFor="let board of boardManagementData">
        <div class="card profile-card">
          <div class="profile-card__img-wrap">
            <img
              [src]="environment.API_URL + board.img_url"
              class="profile-card__img"
            />
          </div>
          <div class="profile-card__text-wrap">
            <h4 class="profile-card__title">{{ board.name }}</h4>
            <h5 class="profile-card__subtitle">{{ board.position[0].position[1] }}</h5>
            <p class="profile-card__description">
              <!--Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.-->
            </p>
            <!-- <a class="profile-card__linkedin-link" href="{{ board.linkedin_profile }}" target="_blank" title="LinkedIn profile of {{ board.name }}">
              <img class="profile-card__linkedin-icon" src="../../../assets/images/linedin.svg" />
            </a> -->
          </div>
        </div>
      </li>
    </ul>
    <ul
      class="card-list profile-card-list board__card-list_long"
      *ngIf="
        baordManagementService.thirdBoardManagementData$
          | async as boardManagementData
      "
    >
      <li *ngFor="let board of boardManagementData">
        <div class="card profile-card">
          <div class="profile-card__img-wrap">
            <img
              [src]="environment.API_URL + board.img_url"
              class="profile-card__img"
            />
          </div>
          <div class="profile-card__text-wrap">
            <h4 class="profile-card__title">{{ board.name }}</h4>
            <h5 class="profile-card__subtitle">{{ board.position[0].position[1] }}</h5>
            <p class="profile-card__description">
              <!--Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.-->
            </p>
            <!-- <a class="profile-card__linkedin-link" href="{{ board.linkedin_profile }}" target="_blank" title="LinkedIn profile of {{ board.name }}">
              <img class="profile-card__linkedin-icon" src="../../../assets/images/linedin.svg" />
            </a> -->
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

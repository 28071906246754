import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uaecf',
  templateUrl: './uaecf.component.html',
  styleUrls: ['./uaecf.component.css']
})
export class UaecfComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

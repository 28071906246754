<div class="container container__page">
  <div class="page-header">
    <h1 class="page-header__title">
      {{ "UAECF.InternationOrganization.Organizations" | translate }}
    </h1>
    <ul class="breadcrumb">
      <li><a routerLink="/" class="link">Home >&nbsp;</a></li>
      <li>{{ "UAECF.InternationOrganization.Organizations" | translate }}</li>
    </ul>
  </div>
  <div class="page-body">
    <div class="section-title-block">
      <img
        class="section-title-block__image"
        src="../../assets/images/logo.svg"
      />
      <h2 class="section-title-block__title">
        {{ "UAECF.InternationOrganization.Organizations" | translate }}
      </h2>
    </div>
    <p style="margin-bottom: 2rem;">
      The UAECF Constitution represents the fundamental charter of the
      institution, governing both its organization and its activities. In
      particular, they define the rights and obligations of the European
      federations, as well as the functioning of the different bodies, including
      the annual congress, the Management Board, and the Commissions.
    </p>
    <ul class="icon-card-list">
      <li class="icon-card-list__li">
        <a
          href="http://www.uci.ch"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/organization/FI-UCI.webp"
            alt="Union Cycliste Internationale"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="https://arabcycling.com/"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/organization/acf.webp"
            alt="Arab Cycling"
            class="icon-card-list__icon"
          />
        </a>
      </li>
      <li class="icon-card-list__li">
        <a
          href="http://accasia.org/"
          target="_blank"
          class="icon-card-list__cards"
        >
          <img
            src="../../../assets/images/organization/asian-confed.jpg"
            alt="Arab Cycling"
            class="icon-card-list__icon"
          />
        </a>
      </li>
    </ul>
  </div>
</div>

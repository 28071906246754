import {ChangeDetectorRef, Component, OnChanges, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import * as moment from "moment";
import {ThemePalette} from "@angular/material/core";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from '../../../../environments/environment';
import {IActivityCalenderItem, IActivityCalenderNewModel} from "../../../model/footer.models";
import {FooterService} from "../../../services/footer.service";

@Component({
  selector: 'app-coruses-semenars-registration',
  templateUrl: './coruses-semenars-registration.component.html',
  styleUrls: ['./coruses-semenars-registration.component.css']
})
export class CorusesSemenarsRegistrationComponent implements OnInit, OnChanges {
  routeDataSubscription: Subscription;
  environment = environment;
  coursesSeminarsFormGroup: FormGroup;
  submitted: boolean;
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  periods = ['AM', 'PM'];

  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  disabled: false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public seminars: IActivityCalenderItem[]=[];
  private uaeEventId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public api: FooterService,
              private ref: ChangeDetectorRef
  ) {
    this.courseSeminirasRegistrationFormsPreparation();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.coursesSeminarsFormGroup.controls;
  }

  // get fa(): { [key: string]: AbstractControl } {
  //   return this.entryByNameForm['lines'].controls;
  // }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      // //console.log({data})
      this.uaeEventId = Number(data.get('id'));
      this.loadingInitializationData();
    });
  }

  ngOnChanges(): void {
    this.loadingInitializationData();
  }

  courseSeminirasRegistrationFormsPreparation() {
    this.coursesSeminarsFormGroup = this.fb.group({
      // first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
      englishName: [{value: '', disabled: true}, [Validators.required]],
      arabicName: [{value: '', disabled: true}, [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      emiratesId: ['', [Validators.required]],
      phone: ['', [Validators.required],Validators.max(10)],
      mobile: ['', [Validators.required,Validators.max(10)]],
      email: ['', [Validators.required]],
      qualifications:  this.fb.group({
      file: [''],
      fileSource: [''],
      extension: ['']
    }),
    });

  }

  onSubmit(): void {
    this.submitted = true;

    if (this.coursesSeminarsFormGroup.invalid) {
      return;
    }
      const formattedFile = this.coursesSeminarsFormGroup.get('qualifications').get('fileSource').value.toString().split('base64,');
    const formData = {
      registers_id: this.uaeEventId.toString(),
      emirates_id: this.coursesSeminarsFormGroup.get('emiratesId').value,
      name: this.coursesSeminarsFormGroup.get('fullName').value,
      date_birth: moment(this.coursesSeminarsFormGroup.get('dateOfBirth').value).format("yyyy-MM-DD"),
      phone: this.coursesSeminarsFormGroup.get('phone').value,
      // mobile: this.coursesSeminarsFormGroup.get('mobile').value,
      email: this.coursesSeminarsFormGroup.get('email').value,
      certification: formattedFile[1],
    } as IActivityCalenderNewModel;

    // //console.log(JSON.stringify(this.entryByNameForm.value, null, 2));
    // //console.log('entry by name data in semionars are', formData);

    // last step
    this.api.CreateActivity(formData);
    this.onReset();
  }

  onReset(): void {
    this.submitted = false;
    this.coursesSeminarsFormGroup.reset();
  }

  private loadingInitializationData() {

    this.api.calenderActivities$.subscribe(response => {
      this.api.loadSeminarsData(this.uaeEventId).subscribe((response: any) => {

      this.seminars = response.data as IActivityCalenderItem[];
      // this.seminars.map(item => {
      //   const from = new Date(item.date_from);
      //   const to = new Date(item.date_to);
      //   const now = new Date();
      //   if (from > now && now < to) {
      //     item.isHidden = false;
      //   } else {
      //     item.isHidden = true;
      //   }
        //console.log('all activity data are   ', this.seminars);

        const data = this.seminars.filter(m => m.id === this.uaeEventId)[0];
        // const data = this.seminars[0];
        //console.log('waawa data are   ' ,data);
        this.coursesSeminarsFormGroup.patchValue({
          englishName: data.name,
          arabicName: data.arabic_name,

        });
      });
      // this.seminars =response;

    });
  }

  onFileChange(event, documentName: string) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      //console.log('file extensions', fileExtension);
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.imageSrc = reader.result as string;
        if (documentName === 'qualifications') {
          this.coursesSeminarsFormGroup.get('qualifications').patchValue({
            fileSource: reader.result,
            extension: fileExtension
          });
        }

      };

    }
  }
}

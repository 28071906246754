<div
  *ngIf="api.nationalTeamDetails$ | async as nationalTeamDetails"
  id="mainContent"
  class="container container__page"
>
  <div class="page-header">
    <h2 class="page-header__title">
      {{ "App.NationalTeams" | translate }}
    </h2>
    <ul class="breadcrumb">
      <li>
        <a routerLink="/" class="link"
          >{{ "CommonText.Home" | translate }} >&nbsp;</a
        >
      </li>
      <li>{{ "App.NationalTeams" | translate }}</li>
    </ul>
  </div>
  <section class="page-body">
    <mat-tab-group mat-align-tabs="center" color="primary">
      <mat-tab label="{{ 'UAECF.NationalTeamDetails.Riders' | translate }}">
        <div class="icon-card-list profile-card-minimal__list">
          <a
            class="profile-card-minimal"
            routerLink="/national-teams/riders/{{ rider.id }}"
            *ngFor="let rider of nationalTeamDetails.riders"
          >
            <img
              [src]="environment.API_URL + rider.img_url[0].img_url"
              class="profile-card-minimal__image"
              alt="{{ rider.full_name[0].full_name[1] }}"
            />
            <div>
              <h4 class="h6 profile-card-minimal__title">
                {{ rider.full_name[0].full_name[1] }}
              </h4>
              <h5 class="profile-card-minimal__subtitle">
                {{
                  rider.team[0].team == false
                    ? rider.club[0].club[1]
                    : rider.team[0].team[1]
                }}
              </h5>
            </div>
          </a>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'UAECF.NationalTeamDetails.Officials' | translate }}">
        <div class="icon-card-list profile-card-minimal__list">
          <a
            class="profile-card-minimal"
            routerLink="/officials/club/{{ official.id }}"
            *ngFor="let official of nationalTeamDetails.officials"
          >
            <img
              [src]="environment.API_URL + official.img_url"
              class="profile-card-minimal__image"
              alt="{{ official.full_name }}"
            />
            <div>
              <h4 class="h6 profile-card-minimal__title">
                {{ official.full_name }}
              </h4>
              <h5 class="profile-card-minimal__subtitle">
                {{
                  "Functions." + api.getFunction(official.category) | translate
                }},&nbsp;
                {{
                  official.team[0].team == false
                    ? official.club[0].club[1]
                    : official.team[0].team[1]
                }}
              </h5>
            </div>
          </a>
        </div>
      </mat-tab>
      <div *ngIf="nationalTeamDetails.achivements.length > 0">
        <mat-tab
          label="{{ 'UAECF.NationalTeamDetails.Achivements' | translate }}"
        >
          <div class="row mt-3">
            <div
              class="offset-3 col-md-4"
              *ngFor="let achivement of nationalTeamDetails.achivements"
            >
              <a
                [href]="environment.API_URL + achivement.achivement_url"
                target="_blank"
                class="link"
              >
                <p style="color: #c93534; font-size: large">
                  Achievements Document
                </p>
              </a>
            </div>
          </div>
        </mat-tab>
      </div>
    </mat-tab-group>
  </section>
</div>

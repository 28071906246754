import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {PastResultsService} from "../../services/past-results.service";
import {IPastResult} from "../../model/pastResult.model";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  env = environment;
  private routeDataSubscription: Subscription;
  public pastResults: IPastResult[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private pastResultService: PastResultsService
  ) {
  }

  ngOnInit() {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      const raceTypeId = +data.get('id');
      this.pastResultService.loadPastResultServiceForRaceType(raceTypeId).subscribe((response: any) => {
        this.pastResults = response.data as IPastResult[];
      });
    });
  }

}

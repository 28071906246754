<main class="content">
  <div class="card">
    <header class="header">
      <img src="./../../../assets/logo-trans.png" alt="logo" />
      <div class="details">
        <h1 style="padding-inline-start: 0.5rem">
          {{ getCommitteeName() }}
        </h1>
        <p style="padding-inline-start: 0.5rem">
          {{ "Components.Committees.UAECyclingFederation" | translate }}
        </p>
      </div>
    </header>

    <main>
      <h3>{{ "Components.Committees.CommitteeMembers" | translate }}</h3>
      <ng-container *ngIf="committees.data">
        <div class="label" *ngFor="let committee of committees.data">
          <span
            style="
              font-size: small;
              border-inline-end: 2px solid #ffffff;
              padding-inline-end: 0.5rem;
            "
            >{{ committee.name }}</span
          >
          <span
            style="
              font-size: small;
              padding-inline-end: 0.5rem;
              padding-inline-start: 0.5rem;
            "
            >{{ committee.position[0].position[1] }}</span
          >
        </div>
      </ng-container>

      <div class="main-image">
        <img src="./../../../assets/logo-trans.png" alt="logo" />
      </div>
    </main>

    <footer>
      <!-- <p>اتحاد اﻻمارات العربية المتحدة للدراجين</p> -->
      <p>{{ "Components.Committees.UAECyclingFederation" | translate }}</p>
    </footer>
  </div>
</main>

import { Component, OnInit } from '@angular/core';
import { EventsService } from "../services/events.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { IEventModel } from "../model/event.model";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-cat-list',
  templateUrl: './cat-list.component.html',
  styleUrls: ['./cat-list.component.css']
})
export class CatListComponent implements OnInit {
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,2023,2024,2025,2026];
  selectedYear = new Date().getFullYear();
  eventsData: IEventModel[];
  env = environment;
  private routeDataSubscription: Subscription;
  private raceTypeId: number;
  allEvents = [];

  constructor(public api: EventsService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.routeDataSubscription = this.route.paramMap.subscribe(data => {
      this.raceTypeId = data.get('id') != null ? +data.get('id') : null;
      this.api.loadEventData(this.raceTypeId).subscribe((response: any) => {
        this.allEvents = response.data;
        this.updatedNewsAppearance();
        var matching = response.data.filter(function (events) {
          return events.year == 2023;
        });
        this.eventsData = matching;
      });
    });
  }

  loadEventDetails(id: number) {
    this.router.navigate([`/event/${id}`]);
  }

  updatedNewsAppearance() {
    const matching = this.allEvents.filter((item: any) => item.year === this.selectedYear);
    this.eventsData = matching;
  }

  GotoNewsDetails(id: number) { }
}
import { PaymentService } from '../../services/payment.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paycanceled',
  templateUrl: './paycanceled.component.html',
  styleUrls: ['./paycanceled.component.css']
})
export class PaycanceledComponent implements OnInit {

  constructor(public payment: PaymentService) {
    payment.checkTransaction().then((data) => {
      data.subscribe((res) => {
console.log({res});

      });

    });
   }

  ngOnInit(): void {
  }

}

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(public http: HttpClient) { }


 public  async checkTransaction(): Promise<Observable<any>> {
   const reference =  await localforage.getItem('reference', function (err, value) {
    //   // if err is non-null, we got an error. otherwise, value is the value
     });
     //console.log('reference request are :  &&&& ', reference);

  const requestModel = {
    params: {
      ref: reference
    }
  };
  //console.log('requestModel request are :  &&&& ', requestModel);

  return this.http.post(`${environment.API_URL}/check_payment`, requestModel);
 }
}



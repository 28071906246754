import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Role } from './role';
import { User } from './UserModel';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   // this.checkSessionValidation();

  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  login(username: string, password: string) {
    const requestModel = {
      params: {
        db: "cycling_federation_live",
        login: username,
        password: password
      }

    };
    // console.log("auth login", requestModel);
    return this.http.post<any>(`${environment.API_URL}/web/session/authenticate`, requestModel)
      .subscribe(async user => {
        // login successful if there's a jwt token in the response
        // console.log("map", {user});
        const tempUser: User = {
          id: 0,
          name: '',
          role: Role.Rider
        };
        tempUser.id = user.result.uid;
        tempUser.name = user.result.name;
        tempUser.clubId = user.result.club_id;
        tempUser.teamId = user.result.team_id;
        tempUser.riderId = user.result.rider_id;
        tempUser.role = user.result.group;
        tempUser.session_id = user.result.session_id;
        tempUser.sessionCreatedAt = new Date();
        console.log("after mapped", tempUser);
        if (tempUser.teamId && tempUser.clubId && tempUser.teamId) {

          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Something went wrong!'
          })
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        await localStorage.setItem('currentUser', JSON.stringify(tempUser));
        this.currentUserSubject.next(tempUser);
        // console.log("this.currentUserSubject", this.currentUserValue);
        window.location.reload();
      });
  }
  async loadCurrentUser() {
    this.currentUser = JSON.parse(  localStorage.getItem('currentUser'));
    console.log("from load user", this.currentUser);
    this.checkSessionValidation();
    return this.currentUser;
  }
  checkSessionValidation() {
      // Get 1 day in milliseconds
  const one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  const date1_ms = new Date().getTime();
  const date2_ms = new Date(this.currentUserValue.sessionCreatedAt).getTime();

  // Calculate the difference in milliseconds
  const difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  if (Math.round(difference_ms / one_day) > 3) {
    // console.log("Session Expired");
    this.logout();
  }
}


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUser = null;
    window.location.reload();
  }


}

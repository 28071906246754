import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ICLubListModel} from "../model/club.model";
import {BehaviorSubject, Observable} from "rxjs";
import {ClubDetails} from "../model/nationalTeam/club.detail.model";
import {TeamDetails} from "../model/nationalTeam/team.detail.model";
import {IClubRider} from "../model/nationalTeam/clubRider.model";

@Injectable({
  providedIn: 'root'
})
export class TeamsClubsService {
  clubRiders = new BehaviorSubject<IClubRider[]>([]);
  teamRiders = new BehaviorSubject<IClubRider[]>([]);
  clubsData = new BehaviorSubject<ICLubListModel[]>([]);
  teamsData = new BehaviorSubject<ICLubListModel[]>([]);
  clubDetails = new BehaviorSubject<ClubDetails>({
    club: [],
    riders: [],
    "club supervisors": [],
    events: [],
    standing_ranking: [],
    club_admin: []
  });
  teamDetails = new BehaviorSubject<TeamDetails>({
    team: [],
    "team supervisors": [],
    riders: [],
    events: [],
    standing_ranking: [],
    team_admin: []
  });

  constructor(private http: HttpClient) {
    this.loadTeams();
  }

  get clubRiders$(): Observable<IClubRider[]> {
    return this.clubRiders.asObservable();
  }

  get teamRiders$(): Observable<IClubRider[]> {
    return this.teamRiders.asObservable();
  }

  get clubsData$(): Observable<ICLubListModel[]> {
    return this.clubsData.asObservable();
  }

  get teamsData$(): Observable<ICLubListModel[]> {
    return this.teamsData.asObservable();
  }

  get teamDetailsData$(): Observable<TeamDetails> {
    return this.teamDetails.asObservable();
  }

  get clubDetailsData$(): Observable<ClubDetails> {
    return this.clubDetails.asObservable();
  }

  loadTeams() {
    this.http.get(`${environment.API_URL}/uaecf-teams`)
      .subscribe(((response: any) => {
        const dataModel = response.data as ICLubListModel[];
        this.teamsData.next(dataModel);
      }));
  }

  loadSpecificTeam(id: number) {
    this.http.get(`${environment.API_URL}/team_details/${id}`)
      .subscribe(((response: TeamDetails) => {
        console.warn('Team Details are ', response);
        this.teamDetails.next(response);
      }));
  }

  loadClubs() {
    this.http.get(`${environment.API_URL}/uaecf-clubs`)
      .subscribe(((response: any) => {
        const dataModel = response.data as ICLubListModel[];
         //console.log('clubs are ' , dataModel);
        this.clubsData.next(dataModel);
      }));
  }

  loadSpecificClub(id: number) {
    this.http.get(`${environment.API_URL}/club_details/${id}`)
      .subscribe(((response: ClubDetails) => {
        //console.log('club data from service', response);
        this.clubDetails.next(response);
      }));
  }

  loadRidersForSpecificClub(id: number) {
    this.http.get(`${environment.API_URL}/riders_club/${id}`)
      .subscribe(((response: any) => {
         //console.log('club riders data from service ' , response);
        const data = response.data as IClubRider[];
        this.clubRiders.next(data);
      }));
  }

  loadRidersForSpecificTeam(id: number) {
    this.http.get(`${environment.API_URL}/riders_team/${id}`)
      .subscribe(((response: any) => {
        //console.log('team data ' , response);
        const data = response.data as IClubRider[];
        this.teamRiders.next(data);
      }));
  }

}
